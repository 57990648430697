import React, { Component, lazy } from 'react';
import { errorRender } from '../../helpers/functions';
import { connect } from 'react-redux';
import Buttons from '../../components/buttons';
import Links from '../../components/links';
import { routehelp } from '../../Routehelper';
import { versionGet, versionUpdateCall } from '../../actions';
import toastr from 'reactjs-toastr';
const Loader = lazy(() => import('../../components/loader'));

class Options extends Component {

    constructor(props) {
        super(props)
        this.state = {
            versionObj: null,
            isLoading: false,
            serverErrors: ["versionUpdateCall_res"],
        }
    }

    componentDidMount() {
        this.props.versionGet({});
    }

    shouldComponentUpdate(nextProps) {

        if (nextProps !== this.props) {

            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }

            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            if (nextProps.versionGet_res && nextProps.versionGet_res.item) {
                // console.log(nextProps.versionGet_res.item);
                this.setState({
                    versionObj: nextProps.versionGet_res.item || {}
                });
            }

            if (nextProps.versionUpdateCall_res && nextProps.versionUpdateCall_res.success) {
                nextProps.versionUpdateCall_res.success = null;
                toastr.success(`Versions are updated successfully`, 'Success');
                this.props.versionGet({});
            }

        };

        return true;

    }

    handleChange = (e) => {
        let name = e.target.name.split("-")[0];
        let pack = e.target.name.split("-")[1];
        let index = e.target.name.split("-")[2];

        let updateVersionObj = this.state.versionObj;

        // console.log("updateVersionObj", updateVersionObj);
        // console.log(name, index, pack, updateVersionObj[name]);

        if (updateVersionObj && updateVersionObj[name].length > 0) {
            updateVersionObj[name].forEach((ele, ind) => {
                if (Number(index) === ind) {
                    if (pack === "soft") {
                        ele.soft = e.target.value
                    }
                    if (pack === "force") {
                        ele.force = e.target.value
                    }
                }
            });
        }

        // console.log(updateVersionObj);

        this.setState({
            versionObj: updateVersionObj
        });

    };

    onsubmit = (e) => {
        // console.log(Object.keys(this.state.versionObj), this.state.versionObj);
        if (this.state.versionObj && Object.keys(this.state.versionObj).length > 0) {
            let data = {
                details: this.state.versionObj
            };
            e.preventDefault();
            this.setState({ isLoading: true });
            this.props.versionUpdateCall(data);
        }
    };

    render() {
        const { isLoading } = this.state;

        return (
            <main className="main-content mx-auto col-12 px-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title" id="update_profile">
                                        Application Version Controls
                                    </div>
                                </div>

                                <div className="card-body">
                                    <div className="row">
                                        <table className='table table-bordered'>
                                            <tbody>

                                                <tr>
                                                    <th colSpan="5">Android</th>
                                                </tr>

                                                <tr>
                                                    <td><strong>Name</strong></td>
                                                    <td><strong>Package</strong></td>
                                                    <td><strong>Soft</strong></td>
                                                    <td><strong>Force</strong></td>
                                                </tr>

                                                {
                                                    this.state.versionObj && this.state.versionObj.android && this.state.versionObj.android.length > 0 ?
                                                        this.state.versionObj.android.map((ele, index) => {
                                                            return (
                                                                <tr key={"android" + index}>
                                                                    <td>{ele.appName}</td>
                                                                    <td>{ele.package}</td>
                                                                    <td>
                                                                        <input className='form-control' value={ele.soft} type="number" id={"android-soft-" + index} name={"android-soft-" + index} onChange={this.handleChange} />
                                                                    </td>
                                                                    <td>
                                                                        <input className='form-control' value={ele.force} type="number" id={"android-force-" + index} name={"android-force-" + index} onChange={this.handleChange} />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                        :
                                                        <tr  >
                                                            <td className='text-center' colSpan="5">
                                                                {isLoading ? <Loader /> :
                                                                    "No Data Found"}
                                                            </td>
                                                        </tr>

                                                }

                                                <tr>
                                                    <td colSpan="5"><strong>IOS</strong></td>
                                                </tr>

                                                <tr>
                                                    <td><strong>Name</strong></td>
                                                    <td><strong>Package</strong></td>
                                                    <td><strong>Soft</strong></td>
                                                    <td><strong>Force</strong></td>
                                                </tr>

                                                {
                                                    this.state.versionObj && this.state.versionObj.ios && this.state.versionObj.ios.length > 0 ?
                                                        this.state.versionObj.ios.map((ele, index) => {
                                                            return (
                                                                <tr key={"ios" + index}>
                                                                    <td>{ele.appName}</td>
                                                                    <td>{ele.package}</td>
                                                                    <td>
                                                                        <input className='form-control' value={ele.soft} type="number" id={"ios-soft-" + index} name={"ios-soft-" + index} onChange={this.handleChange} />
                                                                    </td>
                                                                    <td>
                                                                        <input className='form-control' value={ele.force} type="number" id={"ios-force-" + index} name={"ios-force-" + index} onChange={this.handleChange} />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                        :
                                                        <tr>
                                                            <td className='text-center' colSpan="5">
                                                                {
                                                                    isLoading ? <Loader /> :
                                                                        "No Data Found"}
                                                            </td>
                                                        </tr>
                                                }

                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                                            <Buttons onclick={this.onsubmit} type="submit" disable={isLoading || (this.state.versionObj && this.state.versionObj.length === 0) || !this.state.versionObj} loading={isLoading} className="btn btn-primary mr-1" name="Save" />
                                            <Links to={routehelp.primarydashboard} className="btn btn-outline-dark" name="Cancel" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </main>
        )
    }

}

function mapStateToProps(state) {
    return {
        versionGet_res: state.versionGet_res,
        versionUpdateCall_res: state.versionUpdateCall_res
    }
};

const mapDispatchToProps = dispatch => ({
    versionGet: data => dispatch(versionGet(data)),
    versionUpdateCall: data => dispatch(versionUpdateCall(data))
});

Options = connect(
    mapStateToProps,
    mapDispatchToProps
)(Options);

export default Options;