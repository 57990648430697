import React, { Component } from "react";
import { connect } from "react-redux";
import { misReportTableReq2 } from "../actions";
// import Entries from "../components/entries/entires";
// import Pagination from "../components/pagination/index";
import Loader from "../components/loader";

import 'react-day-picker/lib/style.css';
import "react-confirm-alert/src/react-confirm-alert.css";
import "reactjs-toastr/lib/toast.css";

// let itemsPerPage = 50;
// let currentPage = 1;
// let sortBy = "";
// let sort = "";

class DashboardTableView extends Component {
    state = {
        isTableLoading: false,
        agent_array: [],
        agent_value: [],
        startDate: null,
        endDate: null,
        from: undefined,
        to: undefined,
    }


    componentDidMount() {
        this.loadResult();
    }


    // componentWillUnmount() {
    //     // currentPage = 1;
    //     // itemsPerPage = 50;
    // }

    loadResult = (value) => {

        // if (this.state.isFilter === true) {
        //     currentPage = 1;
        // }

        let graphReq = {
            filter: {
                createdAt: {
                    "from": this.props.startDate,
                    "to": this.props.endDate
                }
            }
        };

        // graphReq.sortBy = sortBy;
        // graphReq.sort = sort;
        // graphReq.skip = (currentPage - 1) * itemsPerPage;
        // graphReq.limit = itemsPerPage;
        if (this.props.agent_array && this.props.agent_array.length > 0) {
            graphReq.agentNames = this.props.agent_array;
        } else {
            graphReq.agentNames = [];
        }
        this.props.misReportTableReq2(graphReq)
        this.setState({ isTableLoading: true });

    }

    // handleNext = () => {
    //     currentPage = currentPage + 1;
    //     this.loadResult("table");
    // }

    // handlePrevious = () => {
    //     currentPage = currentPage - 1;
    //     this.loadResult("table");
    // }

    // handlePage = (page) => {
    //     currentPage = page;
    //     this.loadResult("table");
    // }

    // handleChangeiItemsPerPage = (items) => {
    //     currentPage = 1;
    //     itemsPerPage = items;
    //     this.loadResult("table");
    // }


    shouldComponentUpdate(nextProps) {

        // if (this.state.isLoading === false) {
        //     this.setState({ isLoading: true });
        // }

        if (this.state.isTableLoading === true) {
            this.setState({ isTableLoading: false });
        }

        if (nextProps.misTableData2 !== undefined) {
            if (nextProps.misTableData2.success === true) {
                nextProps.misTableData2.success = null;
                this.setState({ isLoading: false });
            }
        }

        if (!nextProps.misReportTableReq2) {
            this.setState({ isTableLoading: false })
        }

        return true;

    }

    render() {
        // let misTableData2 = [];
        const { misTableData2 } = this.props;
        // console.log(misTableData2);

        const totResult = misTableData2 !== undefined ? misTableData2.count : 0;
        // console.log(totResult);
        // console.log(this.state.isTableLoading);
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-shadow mb-3">
                            <div className="card-body pb-0 text-center">
                                <h5 className="mb-0 ">User Wise - Brand Wise - Order Matrix</h5>
                            </div>
                            <div className="my-5">
                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">
                                        {/* <div className="row">
                                            <div className="col-9">
                                                {totResult !== 0 ? (
                                                    <Entries
                                                        itemsPerPage={itemsPerPage}
                                                        handleChangeiItemsPerPage={
                                                            this.handleChangeiItemsPerPage
                                                        }
                                                    />
                                                ) : null}
                                            </div>
                                        </div> */}
                                        {this.state.isTableLoading ? <Loader /> : totResult === 0 || !totResult ? (
                                            <p style={{ textAlign: "center", marginTop: 10 }}>No Record Found </p>
                                        ) : (
                                            <div className="row">
                                                <div className="col-sm-12 display-grid">
                                                    <div className="table-responsive">
                                                        <table id="bs4-table"
                                                            className="table table-bordered table-striped dataTable"
                                                            cellSpacing="0">
                                                            <tbody>
                                                                {misTableData2 && misTableData2.list && misTableData2.list.map((item, index) => {
                                                                    // console.log(misTableData2);
                                                                    return (
                                                                        <tr role="row" key={index}>
                                                                            {
                                                                                item && item.isSubCategory ?
                                                                                    <th className="title-center">{"User".toUpperCase()}</th>
                                                                                    :
                                                                                    <td className="title-center" style={item.user !== "Grand Total" && item.user !== "Totals" ? { fontWeight: 'normal' } : { fontWeight: 'bold' }} >
                                                                                        {item.user ? item.user : ""}
                                                                                    </td>
                                                                            }
                                                                            {
                                                                                item && item.isSubCategory ?
                                                                                    <th className="title-center">{"Total Orders".toUpperCase()}</th> :
                                                                                    <td className="title-center"
                                                                                        style={item.user !== "Grand Total" && item.user !== "Totals" ? { fontWeight: 'normal' } : { fontWeight: 'bold' }} >
                                                                                        {item.orderCount ? item.orderCount : ""}
                                                                                    </td>
                                                                            }
                                                                            {
                                                                                item && item.isSubCategory ?
                                                                                    <th className="title-center">{"Customers".toUpperCase()}</th> :
                                                                                    <td className="title-center"
                                                                                        style={item.user !== "Grand Total" && item.user !== "Totals" ? { fontWeight: 'normal' } : { fontWeight: 'bold' }} >
                                                                                        {item.customer_name ? item.customer_name : ""}
                                                                                    </td>
                                                                            }
                                                                            {item && item.columns.map((column, index) => {
                                                                                // console.log(item);
                                                                                return item && item.isDetail ? <td style={item.user !== "Grand Total" && item.user !== "Totals" ?
                                                                                    { fontWeight: 'normal' } :
                                                                                    { fontWeight: 'bold' }} className="title-center"
                                                                                    colSpan={column.colspan} key={index}>{column.displayName.toUpperCase()}</td> :
                                                                                    <th className="title-center" colSpan={column.colspan} key={index}>
                                                                                        {column.displayName.toUpperCase()}</th>
                                                                            }
                                                                            )}
                                                                        </tr>
                                                                    )
                                                                })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {/* {totResult !== undefined ? (
                                            <Pagination
                                                handleChangeiItemsPerPage={
                                                    this.handleChangeiItemsPerPage
                                                }
                                                handlePage={this.handlePage}
                                                handleNext={this.handleNext}
                                                handlePrevious={this.handlePrevious}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                totResult={totResult}
                                                key={currentPage}
                                            />
                                        ) : (
                                            ""
                                        )} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        misTableData2: state.misTableData2,
    };
}

const mapDispatchToProps = (dispatch) => ({
    misReportTableReq2: (data) => dispatch(misReportTableReq2(data)),
});

DashboardTableView = connect(mapStateToProps, mapDispatchToProps)(DashboardTableView);

export default DashboardTableView;