// var _path = require('path');
//var ejs = require('ejs');
//var fs = require('file-system');
//const fileExists = require('file-exists');
module.exports = {
  translate: translate
};

/**
 * Get the value of given path from i11n/{lang}.json file
 * @param {Object} req
 * @param {string} path Required parameter passed to find the specific path from language file
 * @param {Object} [data] Optional parameter passed to make translation dynamic
 * @param {string} [lang=en] Optional parameter passed to get translation to specific language
 * @return {string}
 */
function translate(req, path, data, lang) {
  // var pathToTranslationFile =    '../../i11n/src/' + (lang ? lang : req.lang ? req.lang : 'en') + '.json';
  //var pathToTranslationFile =  '../../i11n/en.json';

  var translationData = require('../i11n/en.json');
  //var translationData = getTranslationData(req, lang);
  if (!translationData) {
    return path;
  }
  // var data = data || {};

  var value = path + '';
  var parts = path.split('.');
  var returnVal = Object.assign({}, translationData);
  var terminateFetching = false;

  parts.forEach(function(part) {
    if (!terminateFetching) {
      if (typeof returnVal[part] !== 'undefined') {
        returnVal = returnVal[part];
      } else {
        terminateFetching = true;
      }
    }
  });

  if (
    returnVal &&
    typeof returnVal === 'string' &&
    returnVal !== value &&
    JSON.stringify(returnVal) !== JSON.stringify(translationData)
  ) {
    return returnVal;
  }

  return value;
}

// function getTranslationData(req, lang) {
//   var pathToTranslationFile = _path.join(
//     __dirname,
//     '../../i11n',
//     (lang ? lang : req.lang ? req.lang : 'en') + '.json'
//   );
// if (!fs.existsSync(pathToTranslationFile)) {
//   return null;
// }
// if (!fileExists.sync(pathToTranslationFile)) {
//   return null;
// }

//   return require(pathToTranslationFile);
// }
