import React, { lazy } from 'react';
import Select from 'react-select';
const Loader = lazy(() => import('../components/loader'));


let SearchableDropDown = ({ instanceId, prefixValue, disable, onChange, options, value, placeholder, isSearchable, isMulti, onSelectResetsInput, isClearable, onInputChange, noData }) => {
    const [loading, setLoading] = React.useState(false);
  
    React.useEffect(() => {
      if (!options.length && noData) {
        setLoading(true);
      } else {
        setLoading(false);
      }
    }, [options, noData]);
  

    return (
        <Select
            isDisabled={disable}
            isClearable={isClearable}
            onInputChange={onInputChange}
            isSearchable={isSearchable}
            isMulti={isMulti}
            onSelectResetsInput={onSelectResetsInput}
            placeholder={placeholder}
            instanceId={instanceId}
            value={value}
            onChange={onChange}
            options={options}
            noOptionsMessage={() => 
              loading ? <Loader height='70px' width='70px' name="Loading..." /> : "No Data Found"
            }
            classNamePrefix={prefixValue || 'pre'}
        />
    );
}

export default SearchableDropDown