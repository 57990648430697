import React, { lazy, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { city_search, city_create } from '../../actions';
import SearchableDropDown from '../../components/searchableDropDown';
import { optionsList } from '../../helpers/functions';
import ErrorView from '../errorView';
import toastr from 'reactjs-toastr';

let Citydropdown = ({ ondropdownChange, value, placeholder, errMessage, dontPreload, country, state }) => {
  const dispatch = useDispatch();

  let city_response_search = useSelector(state => state.city_response_search);
  let city_create_res = useSelector(state => state.city_create_res);

  let [searchList, setSearchList] = React.useState([]);
  let [isCreate, setCreate] = React.useState(false);
  let [city, setCity] = React.useState("");
  let [cityError, setCityError] = React.useState("");

  React.useEffect(() => {
    let data = {};
    if (!dontPreload) {
      dispatch(city_search(data));
    }
  }, []);

  React.useEffect(() => {
    if (city_response_search !== undefined && city_response_search.list) {
      setSearchList(optionsList(city_response_search.list))
    };
  }, [city_response_search])


  React.useEffect(() => {
    if (city_create_res && city_create_res.success) {
      dispatch(city_search({
        country: country ? country.value : null,
        state: state ? state.value : null,
      }));
      toastr.success('City Has been Added Successfully', 'success');
      setCreate(false);
      setCity("");
    } else {
      if (city_create_res) {
        city_create_res.errors.map((item, index) =>
          toastr.error(item.msg, 'Error', {
            displayDuration: 5000
          })
        );
      }
    };
  }, [city_create_res]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCity(value);
    setCityError("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!city) {
      setCityError("City is required");
    } else {
      dispatch(city_create({
        name: city,
        country: country ? country.value : null,
        state: state ? state.value : null,
        isActive: true
      }));
    }
  };

  const handleAdd = () => {
    setCreate(!isCreate);
    setCity("");
  };

  return (
    <div>
      <div className='d-flex w-100 justify-content-between'>
        <label className="control-label">City</label>
        <span className='add-btn ml-auto d-inline-block float-right' onClick={handleAdd} title='Add City'><i className='fa fa-plus-circle'></i></span>
      </div>
      {
        !isCreate ?
          <Fragment>
            <SearchableDropDown
              value={value}
              onChange={ondropdownChange}
              options={searchList}
              instanceId="city-id"
              prefixValue="city"
              placeholder={placeholder !== undefined ? placeholder : "City"}
            />
            <ErrorView message={errMessage} />
          </Fragment> :
          <Fragment>
            <div className="input-group add-input">
              <input onChange={handleInputChange}
                maxLength="100"
                type="text"
                name="city"
                value={city}
                className="form-control"
                id="city-name"
                placeholder="City Name" />
              <div className="input-group-append">
                <span className="input-group-text" onClick={handleSubmit} title='create'>
                  <i className='fa fa-plus'></i>
                </span>
              </div>
            </div>
            <ErrorView message={cityError} />
            {/* <input onChange={handleInputChange}
              maxLength="100"
              type="text"
              name="city"
              value={city}
              className="form-control"
              id="city-name"
              placeholder="City Name" />
            <ErrorView message={cityError} />
            <button type="button" onClick={handleSubmit}>Add</button> */}
          </Fragment>
      }
    </div>
  )
};

export default Citydropdown;