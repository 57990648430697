import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { roleactive, roleslist } from '../../actions';
import { delete_roles } from '../../actions';
import { routehelp } from '../../Routehelper';
import { errorRender, successRender, commonDebounce, redirect, loaderUpdate } from '../../helpers/functions';
import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';

const Loader = lazy(() => import('../../components/loader'));
const Pagination = lazy(() => import('../../components/pagination/index'));
const DataTable = lazy(() => import('../../components/dataTalble'));
const Entries = lazy(() => import('../../components/entries/entires'));
const LinkWithItag = lazy(() => import('../../components/linkwithItag'));
const SearchInput = lazy(() => import('../../components/searchInput'));

let itemsPerPage = ITEM_PER_PAGES;
let currentPage = CURRENT_PAGE;
let sortBy = SORT_BY;
let sort = SORT;
class Role extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            code: '',
            isLoading: false,
            successRequest: [{ name: "roles_delete_res", msg: 'Role has been Deleted Successfully' }, { name: "roleactive_update", msg: 'Successfully Done' }],
            serverErrors: ["roles_delete_res", "roleactive_update", "roles_res"],
            tableHeaders: [
                {
                    name: 'roleName',
                    label: "Name",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'roleCode',
                    label: "Code",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'createdAt',
                    label: "CreatedAt",
                    type: "date",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'isActive',
                    label: "Status",
                    type: "checkbox",
                    sortable: true,
                    isShow: true,
                    function: this.checkHandler
                },
                {
                    name: 'actions',
                    label: "Actions",
                    type: "button",
                    sortable: false,
                    isShow: true,
                    allactions: [{
                        name: "edit",
                        title: "Edit",
                        classNameI: "ti-pencil",
                        className: "btn btn-link text-pramiry action-btn mr-2 p-0",
                        isPermission: true,
                        function: redirect
                    }, {
                        name: "delete",
                        title: "Delete",
                        classNameI: "ti-trash",
                        className: "btn btn-link text-danger action-btn p-0",
                        isPermission: true,
                        function: this.deleteRow
                    }]
                }
            ]
        }

    }
    componentDidMount() {
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        this.loadResult();
    }

    componentWillUnmount() {
        currentPage = CURRENT_PAGE;
        itemsPerPage = ITEM_PER_PAGES;
    }


    loadResult = () => {
        let data = {}
        data.filter = { roleName: this.state.name ? this.state.name : undefined }
        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        data.skip = this.state.name ? 0 : (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.roleslist(data);
        this.setState({ isLoading: true })
    }

    sortBy = (key) => {
        sortBy = key;
        if (sort === 'desc') {
            sort = 'asc';
        } else {
            sort = 'desc';
        }
        this.loadResult();
    }

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    }

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    }

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    }

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }

    deleteRow = (e, item) => {
        if (window.confirm(`Are you sure you wish to delete "${item.roleName}" ?`)) {
            this.props.delete_roles(item._id);
            e.preventDefault();
        }
    }

    checkHandler = (e) => {
        this.setState({ [e.target.name]: e.target.checked });
        let id = e.target.name;
        let data = {
            isActive: e.target.checked
        }
        this.props.roleactive(data, id)
    }

    onChangeforDebounce = ({ target: { value } }) => {
        if (!/^\s/.test(value)) {
            this.setState({ name: value }, () => this.callforDebounce(value))
        }
    }
    callforDebounce = () => this.loadResult();


    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "roles_res") });
            }
            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            this.state.successRequest.forEach((el) => successRender(nextProps, el.name, this.loadResult, el.msg));
        }
        return true;
    }

    render() {
        const { roles_res } = this.props;
        const { name } = this.state;
        const totResult = roles_res !== undefined ? roles_res.count : 0;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        Roles 
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                {
                                                    totResult === 0 ? null :
                                                        <Entries
                                                            itemsPerPage={itemsPerPage}
                                                            handleChangeiItemsPerPage={
                                                                this.handleChangeiItemsPerPage
                                                            }
                                                        />
                                                }
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="add-btn-icon mb-4">
                                                    <SearchInput onChangeforDebounce={this.onChangeforDebounce} value={name} />
                                                    <LinkWithItag to={routehelp.roleadd} className="btn float-left  btn-primary mr-2" role="button" classNameI="fa fa-plus" title="Add Zone" />
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            totResult === undefined ? <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={""} props={this.props} /> :
                                                this.state.isLoading ?
                                                    <Loader />
                                                    : roles_res !== undefined && roles_res.count === 0 ? "No Data Found" : roles_res && roles_res.count > 0 ?
                                                        <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={roles_res} props={this.props} />
                                                        : "Something Went Wrong"
                                        }
                                        {totResult !== undefined ? roles_res !== undefined && roles_res.count === 0 ? "" :
                                            <Pagination
                                                handleChangeiItemsPerPage={
                                                    this.handleChangeiItemsPerPage
                                                }
                                                handlePage={this.handlePage}
                                                handleNext={this.handleNext}
                                                handlePrevious={this.handlePrevious}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                totResult={totResult}
                                                key={currentPage}
                                            />
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        roles_res: state.roles_res,
        roles_delete_res: state.roles_delete_res,
        roleactive_update: state.roleactive_update,
    };
}


const mapDispatchToProps = dispatch => ({
    roleslist: data => dispatch(roleslist(data)),
    delete_roles: item_id => dispatch(delete_roles(item_id)),
    roleactive: (data, id) => dispatch(roleactive(data, id)),
});

Role = connect(
    mapStateToProps,
    mapDispatchToProps
)(Role);

export default Role;