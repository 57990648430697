import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { routehelp } from '../../Routehelper';
import { commissionRangeCreate, commissionRangeSingle, commissionRangeUpdate } from '../../actions';
import { errorRender, validate, successRedriect } from '../../helpers/functions';

const SelectInputField = lazy(() => import('../../components/selectInputField'));
const Links = lazy(() => import('../../components/links'));
const Buttons = lazy(() => import('../../components/buttons'));
const Inputfield = lazy(() => import('../../components/inputfields'));
const Loader = lazy(() => import('../../components/loader'));

class CommissionForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageType: 'add',
            itemId: '',
            min: "",
            max: "",
            commissionLevel1: "",
            commissionLevel2: "",
            commissionLevel3: "",
            commissionType: "percentage",
            commissionTypeList: [
                { label: "Percentage %", value: "percentage" },
                { label: "Flat", value: "flat" },
            ],
            setFields: [
                { name: "min", type: "text" },
                { name: "max", type: "text" },
                { name: "commissionLevel1", type: "text" },
                { name: "commissionLevel2", type: "text" },
                { name: "commissionLevel3", type: "text" },
                { name: "commissionType", type: "text" },
                { name: "isOtherMax", type: "text" }
            ],
            serverErrors: ["commissionRangeCreate_res", "commissionRangeUpdate_res", "commissionRangeSingle_res"],
            forRedirect: [
                { name: "commissionRangeCreate_res", pageName: 'Commission Range', pageUrl: 'commissionRange', action: "Added" },
                { name: "commissionRangeUpdate_res", pageName: 'Commission Range', pageUrl: 'commissionRange', action: "Updated" }
            ],
            isLoading: false,
            getSingleLoader: false,
            isOtherMax: false
        };
    }

    componentDidMount() {
        let id = this.props.match.params.item_id;
        if (id) {
            this.setState({ pageType: "update", itemId: id, getSingleLoader: true })
            this.props.commissionRangeSingle(id);
        }
    }

    componentWillUnmount() {
        this.setState({});
    }

    handleChange = (name, e) => {
        const re = /([^\s]+)/;
        if (name === "conFact" && !e.target.value) {
            this.setState({ conFact: 1 });
        } else if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [name]: e.target.value });
        }

    }

    onChange = (e) => this.setState({ [e.target.name]: e.target.value });

    onsubmit = (e) => {

        let validationFields = [
            { name: 'min', label: "Min Value", value: this.state.min, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 100, type: "text", message: "", errName: "minError" },
            { name: 'max', label: "Max Value", value: this.state.max, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 100, type: "text", message: "", errName: "maxError" },
            { name: 'commissionLevel1', label: "First Level", value: this.state.commissionLevel1, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 100, type: "text", message: "", errName: "commissionLevel1Error" },
            { name: 'commissionLevel2', label: "Second Level", value: this.state.commissionLevel2, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 100, type: "text", message: "", errName: "commissionLevel2Error" },
            { name: 'commissionLevel3', label: "Third Level", value: this.state.commissionLevel3, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 100, type: "text", message: "", errName: "commissionLevel3Error" }
        ];

        if (this.state.isOtherMax) {
            validationFields.splice(1, 1);
        }

        e.preventDefault();

        let { newArr, valid } = validate(validationFields);

        let data = {
            min: this.state.min,
            max: this.state.max,
            commissionType: this.state.commissionType,
            commissionLevel1: this.state.commissionLevel1,
            commissionLevel2: this.state.commissionLevel2,
            commissionLevel3: this.state.commissionLevel3,
            isOtherMax: this.state.isOtherMax
        };

        if (valid) {
            this.setState({ isLoading: true });
            if (this.state.pageType === "update") {
                this.props.commissionRangeUpdate(data, this.state.itemId);
            } else {
                this.props.commissionRangeCreate(data);
            }
            validationFields = null;
            newArr = null;
        } else {
            newArr.forEach((el) => this.setState({ [el.errName]: el.message }))
        }

    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {

            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }

            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));

            if (nextProps.commissionRangeSingle_res && nextProps.commissionRangeSingle_res.success) {
                this.setState({ getSingleLoader: false });
                this.state.setFields.forEach((value) => {
                    if (value.type === "text") {
                        this.setState({ [value.name]: nextProps.commissionRangeSingle_res.item[value.name] })
                    }
                })
                nextProps.commissionRangeSingle_res.success = null;
            }

        }
        return true;
    }

    selectRangeAbove = (ev) => {
        this.setState({
            isOtherMax: true,
            max: ""
        });
    }

    removeRangeAbove = (ev) => {
        this.setState({
            isOtherMax: false
        });
    }

    render() {
        const { pageType, isLoading, getSingleLoader } = this.state;

        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">

                                <div className="card-header">
                                    <div className="card-title">
                                        {pageType === "update" ? "Update Commission Range" : "Add Commission Range"}
                                    </div>
                                </div>

                                {
                                    getSingleLoader ? <Loader /> :
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="form-group required col-12 col-lg-2 mt-3">
                                                    <Inputfield errMessage={this.state.minError} handleChange={this.handleChange} min="0" type="number" name="min" value={this.state.min} className="form-control"
                                                        id="min" placeholder="Min Value" />
                                                </div>

                                                <div className="form-group col-12 col-lg-1 mt-3">
                                                    <label className='control-label'>&nbsp;</label>
                                                    <div className='form-control border-0'>
                                                        To
                                                    </div>
                                                    <small className='d-block'>&nbsp;</small>
                                                </div>

                                                <div className="form-group required col-12 col-lg-2 mt-3">
                                                    <Inputfield errMessage={this.state.maxError}
                                                        disabled={this.state.isOtherMax ? true : false} handleChange={this.handleChange} min="0" type="number" name="max" value={this.state.max} className="form-control"
                                                        id="max" placeholder="Max Value" />
                                                </div>

                                                <div className="form-group col-12 col-lg-5 mt-3 px-0">
                                                    <label>&nbsp;</label>
                                                    <div className='form-control border-0 p-0'>
                                                        {
                                                            this.state.isOtherMax ?
                                                                <button className='btn btn-primary' type='button' onClick={(ev) => this.removeRangeAbove(ev)}>Remove Above</button> :
                                                                <button className='btn btn-success' type='button' onClick={(ev) => this.selectRangeAbove(ev)}>Above</button>
                                                        }
                                                        <small className='text-primary ml-2'>To give the more option click this button instead of write max value</small>
                                                        <small className='d-block'>&nbsp;</small>
                                                    </div>
                                                </div>

                                                <div className="form-group col-12 col-lg-2 mt-3">
                                                    <label>&nbsp;</label>
                                                    <label className='control-label'>Selected Range</label>
                                                    <div className='form-control text-center'>
                                                        <span>{this.state.min || 0}</span> To <span>{!this.state.isOtherMax ? (this.state.max || 0) : 'Above'}</span>
                                                    </div>
                                                    <small className='d-block'>&nbsp;</small>
                                                </div>

                                            </div>

                                            <div className='row mx-0'>
                                                <div className='form-group col-12 alert alert-success '>We follow the Botton to Top approach. So, type commission details accordingly.</div>
                                            </div>

                                            <div className="row">

                                                <div className="form-group col-12 col-lg-4">
                                                    <SelectInputField label="Commission Type" onChange={this.onChange} value={this.state.commissionType} name="commissionType" className="form-control" options={this.state.commissionTypeList} />
                                                </div>

                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield errMessage={this.state.commissionLevel1Error} handleChange={this.handleChange} min="0" type="number" name="commissionLevel1" value={this.state.commissionLevel1} className="form-control"
                                                        id="commissionLevel1" placeholder="First Level" />
                                                </div>

                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield errMessage={this.state.commissionLevel2Error} handleChange={this.handleChange} min="0" type="number" name="commissionLevel2" value={this.state.commissionLevel2} className="form-control"
                                                        id="commissionLevel2" placeholder="Second Level" />
                                                </div>

                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield errMessage={this.state.commissionLevel3Error} handleChange={this.handleChange} min="0" type="number" name="commissionLevel3" value={this.state.commissionLevel3} className="form-control"
                                                        id="commissionLevel3" placeholder="Third Level" />
                                                </div>

                                            </div>
                                        </div>
                                }

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" disable={isLoading || getSingleLoader} loading={isLoading} name={pageType === "update" ? "Update" : "Save"} />
                            <Links to={routehelp.commissionRange} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        commissionRangeCreate_res: state.commissionRangeCreate_res,
        commissionRangeSingle_res: state.commissionRangeSingle_res,
        commissionRangeUpdate_res: state.commissionRangeUpdate_res
    };
}


const mapDispatchToProps = dispatch => ({
    commissionRangeSingle: data => dispatch(commissionRangeSingle(data)),
    commissionRangeCreate: data => dispatch(commissionRangeCreate(data)),
    commissionRangeUpdate: (data, id) => dispatch(commissionRangeUpdate(data, id))
});

CommissionForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(CommissionForm);

export default CommissionForm;