import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { transporterlist, delete_transporter, transporterUpload } from '../../actions';
import { routehelp } from '../../Routehelper';
import toastr from 'reactjs-toastr';
import { CSVLink } from "react-csv";
import { errorRender, successRender, commonDebounce, loaderUpdate, redirect, isPermission } from '../../helpers/functions';
import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';
import 'reactjs-toastr/lib/toast.css';

const SearchInput = lazy(() => import('../../components/searchInput'));
const Loader = lazy(() => import('../../components/loader'));
const Pagination = lazy(() => import('../../components/pagination/index'));
const Entries = lazy(() => import('../../components/entries/entires'));
const LinkWithItag = lazy(() => import('../../components/linkwithItag'));
const ButtonWithItag = lazy(() => import('../../components/buttonwithTagi'));
const DataTable = lazy(() => import('../../components/dataTalble'));
const CardTitle = lazy(() => import('../../components/cardTitle'));
const CustomerDropDown = lazy(() => import('../customerManagement/customerDropdown'));


let itemsPerPage = ITEM_PER_PAGES;
let currentPage = CURRENT_PAGE;
let sortBy = SORT_BY;
let sort = SORT;
let file = new FormData();


const csvData = [
    ["name", "description", "customer"],
    ["MAHALAXMI CARRIERS", "MAHALAXMI CARRIERS", "AVANI ENTERPRISES - ELPHINSTONE ROAD -MUMBAI"],
    ["APS TRANSPORT", "APS TRANSPORT", "BEE KAY SONS                  -LUDHIANA"]
];
class Transporter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            code: '',
            customer: null,
            isLoading: false,
            isFilter: false,
            filterBy: { limit: 5000 },
            deleteMessage: "Transporter has been Deleted Successfully",
            serverError: ["transporter_res", "transporter_delete", "transporterUploadresponse"],
            tableHeaders: [
                {
                    name: 'name',
                    label: "Transporter",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'customer.name',
                    label: "Customer",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'description',
                    label: "Description",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'createdAt',
                    label: "CreatedAt",
                    type: "date",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'actions',
                    label: "Actions",
                    type: "button",
                    sortable: false,
                    isShow: true,
                    allactions: [{
                        name: "edit",
                        title: "Edit",
                        classNameI: "ti-pencil",
                        className: "btn btn-link text-pramiry action-btn mr-2 p-0",
                        isPermission: "update",
                        function: redirect
                    }, {
                        name: "delete",
                        title: "Delete",
                        classNameI: "ti-trash",
                        className: "btn btn-link text-danger action-btn p-0",
                        isPermission: "delete",
                        function: this.deleteRow
                    }]
                }
            ]
        }
    }



    componentDidMount() {
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        this.loadResult();
    }

    componentWillUnmount() {
        currentPage = CURRENT_PAGE;
        itemsPerPage = ITEM_PER_PAGES;
    }



    loadResult = () => {
        if (this.state.isFilter === true) {
            currentPage = 1;
        }
        let data = {}
        file = new FormData();
        data.filter = {
            name: this.state.name !== "" ? this.state.name : undefined,
            customer: this.state.customer && this.state.customer.value ? this.state.customer.value : undefined
        }
        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        data.skip = (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.transporterlist(data);
        this.setState({ isLoading: true, isFilter: false })
    }


    clearFile = () => {
        document.getElementById("myFile").value = "";
        file = new FormData();
    }

    resetfilter = () => this.setState({ isFilter: false, isLoading: true, name: "", customer: null }, () => this.loadResult());

    toggleFilter = () => this.setState({ isFilter: !this.state.isFilter });

    sortBy = (key) => {
        sortBy = key;
        if (sort === 'desc') {
            sort = 'asc';
        } else {
            sort = 'desc';
        }
        this.loadResult();
    }

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    }

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    }

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    }

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    deleteRow = (e, item) => {
        if (window.confirm(`Are you sure you wish to delete "${item.name}" ?`)) {
            this.props.delete_transporter(item._id);
            e.preventDefault();
        }
    }

    fileupload = () => {
        if (document.getElementById("myFile").value === "") {
            return toastr.error('Please Select Csv file ', 'error', {
                displayDuration: 5000, width: '400px'
            });
        } else {
            this.props.transporterUpload(file);
        }
    }

    ondropdownChange = (val, name) => this.setState({ [name]: val });

    handleFile = event => {
        if (event.target.files && event.target.files.length) {
            if (event.target.files[0].name.includes(".csv")) {
                file.append('file', event.target.files[0]);
            } else {
                document.getElementById("myFile").value = "";
                toastr.error('Please Select only Csv file ', 'error', {
                    displayDuration: 5000, width: '400px'
                });
            }
        }
    };

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "transporter_res") });
            }
            this.state.serverError.forEach((el) => errorRender(nextProps, el));
            successRender(nextProps, "transporter_delete", this.loadResult, this.state.deleteMessage);
            if (nextProps.transporterUploadresponse !== undefined && nextProps.transporterUploadresponse.success === true && this.props !== nextProps) {
                let str = `Total Records - ${nextProps.transporterUploadresponse.totalCount} \n Success Records - ${nextProps.transporterUploadresponse.successCount} \n Failed Records - ${nextProps.transporterUploadresponse.rejectedCount}`;
                toastr.success(str, "Upload Details", {
                    displayDuration: 10000
                });
                document.getElementById("myFile").value = "";
                this.loadResult();
                nextProps.transporterUploadresponse.success = null;
            }
        }
        return true;
    }


    onChangeforDebounce = ({ target: { value } }) => {
        if (!/^\s/.test(value)) {
            this.setState({ name: value }, () => this.callforDebounce(value))
        }
    }
    callforDebounce = () => this.loadResult();

    render() {
        const { transporter_res } = this.props;
        const { name } = this.state;
        const totResult = transporter_res !== undefined ? transporter_res.count : 0;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                <CardTitle title="Transporter Management"/>
                                </div>
                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                {totResult === undefined ? "" : <Entries
                                                    itemsPerPage={itemsPerPage}
                                                    handleChangeiItemsPerPage={
                                                        this.handleChangeiItemsPerPage
                                                    }
                                                />}
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="add-btn-icon mb-4">
                                                    <SearchInput onChangeforDebounce={this.onChangeforDebounce} value={name} />
                                                    {isPermission("transporter", "create") ? 
                                                        <LinkWithItag to={routehelp.transporterAdd} className="btn float-left  btn-primary mr-2" role="button" classNameI="fa fa-plus" title="Add Transporter" />
                                                    : null}
                                                    <ButtonWithItag classNameI="ti-filter" type="button" onclick={this.toggleFilter} className="btn btn-primary mr-2" data-toggle="tooltip" data-placement="top" title="Filter" data-original-title="Click for Filter option" />
                                                    <ButtonWithItag classNameI="fa tai-ico-import" type="button" className="btn btn-primary mr-2" datatoggle="modal" datatarget=".bd-example-modal-lg" dataplacement="top" title="Import a file" data-original-title="Click to download all" />
                                                    <CSVLink
                                                        data={csvData}
                                                        filename={"transporterSample.csv"}
                                                        className="btn btn-primary"
                                                        target="_blank"
                                                        title="Sample"
                                                    >
                                                        <i className="fa fa-file-excel-o"></i>
                                                    </CSVLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" style={{ display: "none" }} aria-hidden="true">
                                            <div className="modal-dialog modal-lg">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="myLargeModalLabel">Transporter Upload</h5>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">×</span>
                                                        </button>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="custom-file p-2 form-group required">
                                                            <input accept=".csv" onChange={this.handleFile} type="file" id="myFile" name="filename2"></input>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" onClick={this.fileupload} data-dismiss="modal" className="btn btn-primary">Save changes</button>
                                                        <button type="button" className="btn btn-secondary" onClick={this.clearFile} data-dismiss="modal">Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {totResult === undefined ? <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={""} props={this.props} moduleName={"transporter"} /> :
                                            this.state.isLoading ? <Loader /> : transporter_res !== undefined && transporter_res.count === 0 ? "No Data Found" : transporter_res && transporter_res.count > 0 ? <div className="row">
                                                <div className="col-sm-12 display-grid">
                                                    <div className="table-responsive">
                                                        <DataTable mappingObj={this.state.isOnlineMapping} headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={transporter_res} props={this.props} moduleName={"transporter"}/>
                                                    </div>
                                                </div>
                                            </div> : "Something Went Wrong"}
                                        {totResult !== undefined ? transporter_res !== undefined && transporter_res.count === 0 ? null :
                                            <Pagination
                                                handleChangeiItemsPerPage={
                                                    this.handleChangeiItemsPerPage
                                                }
                                                handlePage={this.handlePage}
                                                handleNext={this.handleNext}
                                                handlePrevious={this.handlePrevious}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                totResult={totResult}
                                                key={currentPage}
                                            />
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={!this.state.isFilter ? "right-sidebarfilter hidden" : "right-sidebarfilter show"}>
                    <div className="modal-header bg-primary rounded-0">
                        <h6 className="filter-modal-title"> Filter</h6>
                        <button type="button" onClick={this.toggleFilter} className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" className="navbar-toggler right-sidebar-toggler pointer">×</span>
                        </button>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className=" form-group">
                                <CustomerDropDown
                                    filterBy={this.state.filterBy}
                                    value={this.state.customer}
                                    ondropdownChange={(e) => this.ondropdownChange(e, "customer")}
                                    placeholder="Search Customer"
                                />
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6 mb-4 offset-md-3 text-center">
                        <button onClick={this.loadResult} data-dismiss="modal" type="button" aria-hidden="true" className="btn btn-primary navbar-toggler right-sidebar-toggler pointer mr-1">Apply </button>
                        <button type="button" data-dismiss="modal" onClick={this.resetfilter} className="btn btn-outline-dark">Reset</button>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        transporter_res: state.transporter_res,
        transporter_delete: state.transporter_delete,
        transporterUploadresponse: state.transporterUploadresponse
    };
}


const mapDispatchToProps = dispatch => ({
    transporterlist: data => dispatch(transporterlist(data)),
    delete_transporter: item_id => dispatch(delete_transporter(item_id)),
    transporterUpload: file => dispatch(transporterUpload(file)),
});

Transporter = connect(
    mapStateToProps,
    mapDispatchToProps
)(Transporter);

export default Transporter;