import React, { Component } from 'react';
import Nologo from "../../src/nologo.webp";
import { uploadUrl } from '../config';
import { Link } from 'react-router-dom';
import { routehelp } from '../Routehelper';
class CompanyCard extends Component {
    render() {
        let { name, email, phone, createdAt, logo, id } = this.props;
        let url = uploadUrl;
        return (
            <Link to={routehelp.companyUpdate + id} item_id={id} className="col-md-4 mt-4">
                <div className="card mb-4"><div className="card-body user-details">
                    <div className="media">
                        <img className="align-self-center mr-3 rounded-circle cmp-logo" src={logo ? url + logo : Nologo} alt=" " />
                        <div className="media-body card-special">
                            <p className="mb-0" title={name}>
                                <strong >{name}</strong>
                            </p>
                            <span>{email}</span>
                            <p >
                                <span>{phone}</span>
                            </p>
                        </div>
                        <div className="btn-group float-right task-list-action">
                            {createdAt}
                        </div>
                    </div>
                </div>
                </div>
            </Link>
        )
    }
}

export default CompanyCard;
