import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { dispatchUpload, dispatchlist, dispatchpartyName_list } from '../../actions';
import toastr from 'reactjs-toastr';
import { CSVLink } from "react-csv";
import { errorRender, loaderUpdate, successRender } from '../../helpers/functions';
import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';
import DispatchUserDropdown from './dispatchUserDropDown';
import SearchableDropDown from '../../components/searchableDropDown';

const Entries = lazy(() => import('../../components/entries/entires'));
const Pagination = lazy(() => import('../../components/pagination/index'));
const ButtonWithItag = lazy(() => import('../../components/buttonwithTagi'));
// const Userdropdown = lazy(() => import('../userManagement/userDropdwon'));
const Loader = lazy(() => import('../../components/loader'));
const DataTable = lazy(() => import('../../components/dataTalble'));
const CardTitle = lazy(() => import('../../components/cardTitle'));

let itemsPerPage = ITEM_PER_PAGES;
let currentPage = CURRENT_PAGE;
let sortBy = SORT_BY;
let sort = SORT;
let file = new FormData();

const csvData = [
    ["SNO", "ORDERNO", "ORDERDATE", "BRANDNAME", "PARTYNAME", "INDIRECTPARTY", "SOPARTYORDERNO", "AGENTNAME", "DESIGNNO", "SHADENO", "PACKSIZE", "ORDEREDQTY", "TOTALPACKS", "PACKINGSLIPNO", "PACKINGSLIPDATE", "PACKINGSLIPPACKS", "PACKINGSLIPPENDINGQTY", "PACKINGSLIPPENDINGPACK", "SOPENDINGQTY", "SOPENDINGPACK", "INVOICENO", "INVOICEDATE", "NETSALEQUANTITY", "NETGROSSAMOUNT", "NETSALEAMOUNT", "TYPEOFORDER", "DELIVERYNOTBEFORE", "SOTRANSPORTER", "LRNO", "LRDATE", "DISPATCHEDPACK", "GATEPASSDOCUMENT", "GATEPASSNO", "GATEPASSDATE", "SOSERIES"],
    ["1", "test order 1", "04/11/17", "test brand", "test partyName", "(NIL)", "SAB507", "SHAMIM RANGREJ", "3742", "5", "TAKA", "13", "", "", "", "", "", "", "13", "1", "", "", "", "", "", "(NIL)", "", "(NIL)", "", "", "", "", "", "", ""]
];

class DispatchDetails extends Component {
    state = {
        isLoading: false,
        isFilter: false,
        agent: undefined,
        orderNo: undefined,
        partyName:'',
        partyNameList:[],
        isExportLoader: false,
        serverError: ["dispatch_res", "dispatchUploadresponse", "dispatchpartyname_list_res"],
        message: "Successfully Uploaded Dispatch. Please wait for sometimes.",
        tableHeaders: [
            {
                name: 'orderNo',
                label: "Order No.",
                type: "text",
                sortable: true,
                isShow: true
            },
            {
                name: 'orderDate',
                label: "Order Date",
                type: "date",
                sortable: true,
                isShow: true
            },
            {
                name: 'brand',
                label: "Brand Name",
                type: "text",
                sortable: true,
                isShow: true
            },
            {
                name: 'partyName',
                label: "Party Name",
                type: "text",
                sortable: true,
                isShow: true
            },
            {
                name: 'agentName',
                label: "Agent Name",
                type: "text",
                sortable: true,
                isShow: true
            },
            {
                name: 'designNo',
                label: "Design No.",
                type: "text",
                sortable: true,
                isShow: true
            },
            {
                name: "shadeNo",
                label: "Shade No.",
                type: "text",
                sortable: true,
                isShow: true
            },
            {
                name: "orderedQuantity",
                label: "Ordered Quantity",
                type: "text",
                sortable: true,
                isShow: true
            },
            {
                name: "netSaleQuantity",
                label: "Net Sale Quantity",
                type: "text",
                sortable: true,
                isShow: true
            },
            {
                name: "invoiceNumber",
                label: "Invoice No.",
                type: "text",
                sortable: true,
                isShow: true
            },
            {
                name: "invoiceDate",
                label: "Invoice Date",
                type: "date",
                sortable: true,
                isShow: true
            },
            {
                name: "lrNo",
                label: "LR No.",
                type: "text",
                replaceMentText: "Pending",
                sortable: true,
                isShow: true
            },
            {
                name: "lrDate",
                label: "LR Date",
                type: "date",
                replaceMentText: "Pending",
                sortable: true,
                isShow: true
            },
            {
                name: "soTransport",
                label: "Transporter",
                type: "text",
                sortable: true,
                isShow: true
            },
            {
                name: 'createdAt',
                label: "Created At",
                type: "date",
                sortable: true,
                isShow: true
            }
        ]

    }

    componentDidMount() {
        this.loadResult();
        this.props.dispatchpartyName_list();
    }

    componentWillUnmount() {
        currentPage = CURRENT_PAGE;
        itemsPerPage = ITEM_PER_PAGES;
    }

    loadResult = () => {
        if (this.state.isFilter === true) {
            currentPage = 1;
        };
        let data = {}
        data.skip = (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        data.sortBy = sortBy;
        data.sort = sort;
        data.filter = { 
            agent: this.state.agent && this.state.agent.label ? this.state.agent.label : undefined,
            orderNo: this.state.orderNo,
            partyName:this.state.partyName && this.state.partyName.label ? this.state.partyName.label : undefined ,
        };
        this.props.dispatchlist(data);
        this.setState({ isLoading: true, isFilter: false });
    }

    resetfilter = () => {
        let data = {}
        data.skip = (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        data.filter = { agent: undefined }
        this.setState({ isLoading: true, isFilter: false, agent: null, orderNo: undefined, partyName:'' })
        this.props.dispatchlist(data);
    }

    sortBy = (key) => {
        sortBy = key;
        if (sort === 'asc') {
            sort = 'desc';
        } else {
            sort = 'asc';
        }
        this.loadResult();
    }

    ondropdownChangeagent = (e, t) => {
        this.setState({ agent: e });
    };

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
    };

    ondropdownChange = (e, t) => {
        this.setState({ [e]: t });
    }


    toggleFilter = () => this.setState({ isFilter: !this.state.isFilter });

    handleFile = event => {

        if (event.target.files && event.target.files.length) {
            if (event.target.files[0].name.includes(".csv" || ".CSV")) {
                file.append('file', event.target.files[0]);
                // file.append('agent', this.state.agent);
            } else {
                document.getElementById("myFile").value = "";
                toastr.error('Please Select only Csv file ', 'error', {
                    displayDuration: 5000, width: '400px'
                });
            }
        }
    };

    fileupload = () => {
        if (document.getElementById("myFile").value === "") {
            return toastr.error('Please Select Csv file ', 'error', {
                displayDuration: 5000, width: '400px'
            });
        } else {
            this.setState({
                isExportLoader: true
            });
            this.props.dispatchUpload(file);
            file = new FormData();
        }
    }

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    }

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    }

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    }

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }

    clearFile = () => {
        this.setState({ isLoading: false, agent: null });
        document.getElementById("myFile").value = "";
        file = new FormData();
    }

    clearValue = () => {
        this.setState({ agent: '' }, () => {
            document.getElementById("myFile").value = "";
            this.loadResult();
        });
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "dispatch_res") });
            }
            successRender(nextProps, "dispatchUploadresponse", this.clearValue, this.state.message);
            if (nextProps.dispatchUploadresponse) {
                this.setState({
                    isExportLoader: false
                });
            }       
            if(nextProps && nextProps.dispatchpartyname_list_res && nextProps.dispatchpartyname_list_res.list && nextProps.dispatchpartyname_list_res.list.length>0){
                this.setState({
                    partyNameList:nextProps.dispatchpartyname_list_res.list.map((ele) => { return { value: ele.partyName, label: ele.partyName } })
                })
            }        
            this.state.serverError.forEach((el) => errorRender(nextProps, el, el === "dispatchUploadresponse" ? this.clearFile : undefined))
        }
        return true;
    }

    render() {
        let { dispatch_res } = this.props;
        const totResult = dispatch_res !== undefined ? dispatch_res.count : 0;

        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                <CardTitle title="Dispatch Details"/>
                                </div>
                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">
                                        <div className="row">
                                            {/* <div className="col-sm-12 col-md-12 mb-3">
                                                    <LinkWithItag to={routehelp.ordermanagementadd} className="btn btn-primary" role="button" classNameI="ti-plus pr-2" name="Add New" />
                                                </div> */}
                                            <div className="col-sm-12 col-md-6">
                                                {totResult !== undefined ?
                                                    <Entries
                                                        itemsPerPage={itemsPerPage}
                                                        handleChangeiItemsPerPage={
                                                            this.handleChangeiItemsPerPage
                                                        }
                                                    />
                                                    : ""}
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div id="bs4-table_filter" className="dataTables_filter">
                                                    {/* <ButtonWithItag onclick={this.export} classNameI="fa tai-ico-import" type="button" className="btn btn-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="Click to Upload all" /> */}
                                                    <ButtonWithItag classNameI="ti-filter" onclick={this.toggleFilter} type="button" className="btn btn-primary right-sidebar-toggler mr-2" data-toggle="tooltip" data-placement="top" title="Filter" data-original-title="Click for Filter option" />
                                                    <ButtonWithItag classNameI="fa tai-ico-import" type="button" className="btn btn-primary mr-2" datatoggle="modal" disabled={this.state.isExportLoader} datatarget=".bd-example-modal-lg" dataplacement="top" title="Import a file" data-original-title="Click to download all" />
                                                    <CSVLink
                                                        data={csvData}
                                                        filename={"dispatchSample.csv"}
                                                        className="btn btn-primary"
                                                        target="_blank"
                                                        title="Sample"
                                                    >
                                                        <i className="fa fa-file-excel-o"></i>
                                                    </CSVLink>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" style={{ display: "none" }} aria-hidden="true">
                                            <div className="modal-dialog modal-lg">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="myLargeModalLabel">Dispatch Upload</h5>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">×</span>
                                                        </button>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="custom-file mb-3 col">
                                                            <input accept=".csv" onChange={this.handleFile} type="file" id="myFile" name="filename2"></input>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" onClick={(event) => this.fileupload(event)} data-dismiss="modal" className="btn btn-primary">Save changes</button>
                                                        <button type="button" className="btn btn-secondary" onClick={this.clearFile} data-dismiss="modal">Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            this.state.isLoading ? <Loader /> :
                                                (dispatch_res !== undefined && dispatch_res.count === 0) || !dispatch_res ?
                                                    <div className="row">
                                                        <div className="col-sm-12 display-grid">
                                                            <div className="table-responsive">
                                                                <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={""} props={this.props} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    dispatch_res && dispatch_res.count > 0 ?
                                                        <div className="row">
                                                            <div className="col-sm-12 display-grid">
                                                                <div className="table-responsive">
                                                                    <DataTable mappingObj={this.state.isOnlineMapping} headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={dispatch_res} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <p className='text-center'>Something Went Wrong</p>
                                        }

                                        {
                                            totResult !== undefined ?
                                                <Pagination
                                                    handleChangeiItemsPerPage={
                                                        this.handleChangeiItemsPerPage
                                                    }
                                                    handlePage={this.handlePage}
                                                    handleNext={this.handleNext}
                                                    handlePrevious={this.handlePrevious}
                                                    currentPage={currentPage}
                                                    itemsPerPage={itemsPerPage}
                                                    totResult={totResult}
                                                    key={currentPage}
                                                />
                                                : null
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={!this.state.isFilter ? "right-sidebarfilter hidden" : "right-sidebarfilter show"}>
                    <div className="modal-header bg-primary rounded-0">
                        <h6 className="filter-modal-title"> Filter</h6>
                        <button type="button" onClick={this.toggleFilter} className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" className="navbar-toggler right-sidebar-toggler pointer">×</span>
                        </button>
                    </div>

                    <div className="card-body">
                        <form>
                            {/* <div className="form-group">
                                <label htmlFor="name" className="control-label">Order No.</label>
                                <input maxlength="20" type="text" name="orderNo" value={this.state.orderNo} onChange={this.onChange} className="form-control form-control-square" id="name" placeholder="Order No." />
                            </div> */}
                            <div className="form-group">
                                    <label className="control-label">Party Name</label>
                                    <SearchableDropDown
                                        value={this.state.partyName}
                                        onChange={(e) => this.ondropdownChange("partyName", e)}
                                        options={this.state.partyNameList}
                                        instanceId="partyName-id"
                                        prefixValue="partyName"
                                        placeholder={'Party Name'}
                                    />
                            </div>
                            <div className=" form-group">
                                {/* <Userdropdown
                                    placeholder="User"
                                    ondropdownChange={this.ondropdownChangeagent}
                                    name="agent"
                                    value={this.state.agent}
                                    className="form-control form-control-square"
                                /> */}
                                <DispatchUserDropdown
                                    labelValue="Agent"
                                    ondropdownChange={this.ondropdownChangeagent}
                                    name="agent"
                                    value={this.state.agent}
                                    className="form-control form-control-square"
                                />
                            </div>
                        </form>
                    </div>

                    <div className="col-md-6 mb-4 offset-md-3 text-center">
                        <button onClick={this.loadResult} data-dismiss="modal" type="button" aria-hidden="true" className="btn btn-primary navbar-toggler right-sidebar-toggler pointer mr-1">Apply </button>
                        <button type="button" data-dismiss="modal" onClick={this.resetfilter} className="btn btn-outline-dark">Reset</button>
                    </div>

                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        // order_delete: state.order_delete
        // customermanagement_res: state.customermanagement_res,
        dispatchUploadresponse: state.dispatchUploadresponse,
        dispatch_res: state.dispatch_res,
        dispatchpartyname_list_res: state.dispatchpartyname_list_res,
    };
}

const mapDispatchToProps = dispatch => ({
    // delete_order: item_id => dispatch(delete_order(item_id)),
    dispatchUpload: data => dispatch(dispatchUpload(data)),
    dispatchlist: data => dispatch(dispatchlist(data)),
    dispatchpartyName_list: data => dispatch(dispatchpartyName_list(data)),
});

DispatchDetails = connect(
    mapStateToProps,
    mapDispatchToProps
)(DispatchDetails);

export default DispatchDetails;