// const apiUrl = "http://104.237.4.152:9155";
const apiUrl = "https://dev.invento.solutions";
// const apiUrl = "http://www.radiant.express:9155";
// const apiUrl = "https://invento.solutions";
// const apiUrl = 'http://192.168.2.158:9155';
// const apiUrl = "http://104.237.4.152:19155"; // uom-management only
// const apiUrl = "http://192.168.2.161:19155"; // uom-management only local

const uploadUrl = apiUrl + "/uploads/";
const buildtype = "prod";

// live
export { apiUrl, uploadUrl, buildtype };