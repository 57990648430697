import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import Inputfield from '../../components/inputfields';
import Userdropdown from '../userManagement/userDropdwon';
import Buttons from '../../components/buttons';
import Links from '../../components/links';
import { routehelp } from '../../Routehelper';
import { customer_search, routecreate, srasmassignList } from '../../actions';
import Select from 'react-select';
import ErrorView from '../../components/errorView';
import { errorRender, optionsList, successRedriect, addMonths } from '../../helpers/functions';

const CardTitle = lazy(() => import('../../components/cardTitle'));



let itemsPerPage = 50;
let currentPage = 1;
class Route_add extends Component {
    state = {
        name: '',
        zone: '',
        region: '',
        territory: '',
        beats: '',
        agent: null,
        startdate: "",
        enddate: new Date(),
        customer: [],
        customer_array: [],
        customer_value: null,
        minDate: "",
        srASMoptions: [],
        srAsmSelection: null,
        srASMusers: undefined,
        error: {
            nameError: '',
            userError: '',
            customerError: '',
            dateError: ''
        },
        isLoading: false,
        maxDate: ""
    }

    componentDidMount() {
        let today = new Date(),
            day = today.getDate(),
            month = today.getMonth() + 1, //January is 0
            year = today.getFullYear();
        if (day < 10) {
            day = '0' + day
        }
        if (month < 10) {
            month = '0' + month
        }
        today = year + '-' + month + '-' + day;
        this.setState({ minDate: today });
        if (this.state.isFilter === true) {
            currentPage = 1;
        }
        let data = {}

        data.sortBy = "name";
        data.sort = "asc";
        data.skip = (currentPage - 1) * itemsPerPage;
        data.limit = 5000;
        this.props.srasmassignList(data);
    }

    handleChange = (name, e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [name]: e.target.value });
        }
    }


    ondropdownChange = (e, t) => {
        this.setState({ [e]: t, customer_value: [], customer_array: [] });
        let data = {
            agent: t.value
        }
        this.props.customer_search(data);
    }


    validate = () => {
        let nameError = '';
        let userError = '';
        let customerError = '';
        let dateError = '';
        // if (this.state.name === "") {
        //     nameError = "Name is Required";
        // }
        if ((this.state.agent && this.state.agent.length === 0) || this.state.agent === null) {
            userError = 'User Is Required';
        }
        if (this.state.customer_array && this.state.customer_array.length === 0) {
            customerError = 'Customer Is Required';
        }
        if (this.state.startdate === "" || this.state.enddate === "") {
            dateError = 'Date Is Required'
        }
        if (nameError || userError || customerError || dateError) {
            this.setState({ nameError, userError, customerError, dateError });
            return false;
        }
        return true;
    };



    onChange = (e) => {
        if (e.target.name === "startdate") {
            this.setState({ enddate: '' });

            let maxDate = addMonths(e.target.value, 12);
            this.setState({
                maxDate: maxDate
            });

        }

        this.setState({ [e.target.name]: e.target.value });
    }


    onsubmit = (e) => {
        var d = new Date(this.state.startdate),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;


        var endd = new Date(this.state.enddate),
            endmonth = '' + (endd.getMonth() + 1),
            endday = '' + endd.getDate(),
            endyear = endd.getFullYear();

        if (endmonth.length < 2)
            endmonth = '0' + endmonth;
        if (endday.length < 2)
            endday = '0' + endday;

        let data = {
            name: this.state.name,
            // zone: this.state.zone,
            // region: this.state.region,
            // territory: this.state.territory,
            // beats: this.state.beats,
            srAsm: this.state.srAsmSelection && this.state.srAsmSelection.value ? this.state.srAsmSelection.value : undefined,
            agent: this.state.agent && this.state.agent.value,
            customers: this.state.customer_array,
            startDate: [year, month, day].join('-'),
            endDate: [endyear, endmonth, endday].join('-'),
            company: '',
            isActive: true
        }

        e.preventDefault();
        const isValid = this.validate();
        if (isValid) {
            this.setState(this.state.error);
            this.setState({ isLoading: true });
            this.props.routecreate(data);
        }
    }


    srAsmChange = (val) => {
        let data = []
        if (val) {
            val.data && val.data.agent && val.data.agent.forEach((agent) => {
                data.push({ label: `${agent.name} (${"Agent"})`, value: agent._id });
            })
            val.data && val.data.asm && val.data.asm.forEach((asm) => {
                data.push({ label: `${asm.name} (${"ASM"})`, value: asm._id });
            })
            val.data && val.data.asm2 && val.data.asm2.forEach((asm2) => {
                data.push({ label: `${asm2.name} (${"ASM2"})`, value: asm2._id });
            })
            this.setState({ srAsmSelection: val, srASMusers: data, agent: null, customer_value: null });
        } else {
            this.setState({ srAsmSelection: null, srASMusers: undefined, agent: null, customer_value: null })
        }
    }

    multicustomer = (value) => {
        let temp = [];
        let newTemp = [];
        if (value !== undefined && value !== null) {
            value.forEach((item) => {
                temp.push({
                    _id: item.value,
                    name: item.label
                })
                newTemp.push({
                    value: item.value,
                    label: item.label
                })
                // this.setState({ customer: temparray })
                // console.log(temparray);

            })
            this.setState({ customer_array: temp, customer_value: newTemp })
        } else {
            this.setState({ customer_array: [], customer_value: [] })
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (nextProps.srasmassignList_response !== undefined) {
                if (nextProps.srasmassignList_response.success === true) {
                    let data = []
                    nextProps.srasmassignList_response.success = null;
                    nextProps.srasmassignList_response.list.forEach(item => {
                        data.push({ value: item.srAsm._id, label: item.srAsm.name, data: item })
                    })
                    this.setState({ srASMoptions: data });
                }
            }
            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }
            errorRender(nextProps, "route_create_res");
            successRedriect(nextProps, "route_create_res", "Plan", this.props, "routeplanning", "Added");
            if (nextProps.customer_response_search !== undefined && nextProps.customer_response_search.success === true) {
                this.setState({ customer: optionsList(nextProps.customer_response_search.list) });
                nextProps.customer_response_search.success = null
            }
        }
        return true;
    }

    selectAllCus = () => {
        this.setState({
            customer_value: this.state.customer
        }, () => {
            let temp = [];
            this.state.customer_value.forEach((items) => {
                temp.push({
                    _id: items.value,
                    name: items.label
                })
            });
            this.setState({ customer_array: temp })
        });
    }

    render() {
        const { isLoading } = this.state;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                <CardTitle title= "Add Route" />
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="form-group col-12 col-lg-4">
                                            <Inputfield errMessage={this.state.nameError} disabled={true}
                                                handleChange={this.handleChange} type="text" name="name" value={this.state.name} className="form-control" id="name" ariadescribedby="codeHelp" placeholder="Tour Name" />
                                        </div>
                                        <div className="form-group col-12 col-lg-4">
                                            <label className="control-label">{"Sr. ASM"} </label>
                                            <Select
                                                onChange={this.srAsmChange}
                                                value={this.state.srAsmSelection}
                                                options={this.state.srASMoptions}
                                                placeholder={"Select SrASM"}
                                                classNamePrefix="sr_asm"
                                                // isMulti={isMulti}
                                                isSearchable={true}
                                                isClearable={true}
                                            />
                                        </div>
                                        <div className="form-group required col-12 col-lg-4">
                                            <Userdropdown
                                                ondropdownChange={this.ondropdownChange}
                                                name="agent"
                                                value={this.state.agent}
                                                className="form-control"
                                                placeholder="User"
                                                srASMusers={this.state.srASMusers}
                                            />
                                            <ErrorView message={this.state.userError} />
                                        </div>

                                        <div className="form-group required col-12 col-lg-4">
                                            <label className="control-label">Customer</label>
                                            <Select closeMenuOnSelect={false} classNamePrefix="customer" placeholder='Select Customer' value={this.state.customer_value} onChange={this.multicustomer} options={this.state.customer} isMulti={true} isSearchable={true} />
                                            <ErrorView message={this.state.customerError} />
                                        </div>

                                        <div className="form-group  col-12 col-lg-4">
                                            <label className="control-label d-block">&nbsp;</label>
                                            <button type='button' name='selectall' className='btn btn-primary' onClick={(e) => this.selectAllCus()}>Select All</button>
                                        </div>

                                        <div className="form-group required col-12 col-lg-4"></div>

                                        <div className="form-group required col-12 col-lg-4">
                                            <label className="control-label">From Date</label>
                                            <input
                                                className="form-control"
                                                type="date"
                                                name="startdate"
                                                onChange={this.onChange}
                                                value={this.state.startdate}
                                                min={this.state.minDate}
                                                onKeyDown={(e) => e.preventDefault()}
                                            >
                                            </input>
                                            <ErrorView message={this.state.dateError} />
                                        </div>
                                        <div className="form-group required col-12 col-lg-4">
                                            <label className="control-label "> To Date</label>
                                            {/* {console.log(this.state.startdate)} */}
                                            {/* {console.log(this.state.maxDate)} */}
                                            <input className="form-control"
                                                onKeyDown={(e) => e.preventDefault()}
                                                type="date"
                                                name="enddate"
                                                onChange={this.onChange}
                                                disabled={this.state.startdate === "" ? true : false}
                                                value={this.state.enddate}
                                                min={this.state.startdate}
                                                max={this.state.maxDate}
                                            />
                                            <ErrorView message={this.state.dateError} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" disable={isLoading} loading={isLoading} name="Save" />
                            <Links to={routehelp.routeplanning} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        customer_response_search: state.customer_response_search,
        route_create_res: state.route_create_res,
        srasmassignList_response: state.srasmassignList_response,
    };
}


const mapDispatchToProps = dispatch => ({
    customer_search: data => dispatch(customer_search(data)),
    routecreate: data => dispatch(routecreate(data)),
    srasmassignList: data => dispatch(srasmassignList(data)),
});

Route_add = connect(
    mapStateToProps,
    mapDispatchToProps
)(Route_add);

export default Route_add;