import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { profilesingle, updateprofile, state_search, city_search } from '../../actions';
import { Redirect } from 'react-router-dom';
import Inputfield from '../../components/inputfields';
import Buttons from '../../components/buttons';
import Links from '../../components/links';
import { routehelp } from '../../Routehelper';
import toastr from 'reactjs-toastr';
import { apiUrl, uploadUrl } from '../../config';
import { ProgressBar } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import axios from 'axios';
import 'reactjs-toastr/lib/toast.css';
import 'react-quill/dist/quill.snow.css';
import { errorRender } from '../../helpers/functions';
import ErrorView from '../../components/errorView';
const CardTitle = lazy(() => import('../../components/cardTitle'));
class Setting extends Component {
  state = {
    headerName: '',
    headerText: '',
    footerText: '',
    logo: [],
    uploadPercentage: 0,
    error: {
      headerNameError: "",
      headerTextError: "",
      footerTextError: "",
      logoError: ''
    }
  }



  componentDidMount() {
    let data = {}
    this.props.profilesingle(data);
  }




  shouldComponentUpdate(nextProps) {
    errorRender(nextProps, "profile_update");
    return true;
  }

  uploadFile = ({ target: { files } }) => {
    console.log(files[0])
    let data = new FormData();
    data.append('file', files[0])
    const token = localStorage.getItem('token');
    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total)
        console.log(`${loaded}kb of ${total}kb | ${percent}%`);
        if (percent < 100) {
          this.setState({ uploadPercentage: percent })
        }
      }
    }
    axios.defaults.headers = { 'x-access-token': token }
    axios.post(apiUrl + "/api/account/profile/upload", data, options).then(res => {
      console.log(res)
      this.setState({ logo: res.data.item.logo, uploadPercentage: 100 }, () => {
        setTimeout(() => {
          this.setState({ uploadPercentage: 0 })
        }, 1000);
      })
    })
  }




  handleChange = (name, e) => {
    const re = /([^\s]+)/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ [name]: e.target.value });
    }
  }

  onChange = (e, val) => {
    const re = /([^\s]+)/;
    if (val !== undefined) {
      this.setState({ [e]: val });
    }
    if (e && e.target) {
      if (e.target.value === '' || re.test(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value });
      }
    }
  }

  validate = () => {
    let headerNameError = '';
    let headerTextError = '';
    let footerTextError = '';
    let logoError = '';
    if (this.state.headerName.length === 0) {
      headerNameError = 'Header Name Is Required';
    } else if (this.state.headerName.length > 20) {
      headerNameError = 'Header Name must be in 20 characters';
    }
    if (this.state.headerText.length === 0) {
      headerTextError = 'Header Text Is Required';
    }
    if (this.state.footerText.length === 0) {
      footerTextError = 'Footer Text Is Required';
    }
    if (this.state.logo.length === 0) {
      logoError = 'Logo Is Required';
    }
    if (headerNameError || headerTextError || footerTextError || logoError) {
      this.setState({ headerNameError, headerTextError, footerTextError, logoError });
      return false;
    }
    return true;
  };




  onsubmit = (e) => {
    let data = {
      headerName: this.state.headerName,
      headerText: this.state.headerText,
      footerText: this.state.footerText,
      logo: this.state.logo,

    }
    e.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      this.setState(this.state.error);
      console.log(data);
      // this.props.updateprofile(data);
    }
  }

  render() {
    let { uploadPercentage } = this.state;
    let { profile_update } = this.props;
    if (profile_update !== undefined) {
      if (profile_update.success === true) {
        toastr.success('Settings Has been Added Successfully', 'success');
        profile_update.success = null;
        return <Redirect to={routehelp.maindashboard} />
      }
    }
    return (
      <main className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 mt-4">
              <div className="card card-shadow mb-4">
                <div className="card-header">
                <CardTitle title="Update Settings"/>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="form-group required col-12 col-lg-4">
                      <Inputfield errMessage={this.state.headerNameError} handleChange={this.handleChange} type="text" name="headerName" value={this.state.headerName} className="form-control" id="headerName" ariadescribedby="emailHelp" placeholder="Header Name" />
                    </div>
                    <div className="form-group required col-12 col-lg-4">
                      <Inputfield errMessage={this.state.headerTextError} handleChange={this.handleChange} type="text" name="headerText" value={this.state.headerText} className="form-control" id="headerText" placeholder="Header Text" />
                    </div>
                    {this.state.logo && this.state.logo.length !== 0 ?
                      <div className="col-12 col-lg-3">
                        <div className="document-media">
                          <div onClick={() => { this.setState({ logo: [] }) }} className="close-btn"> <i className="fa fa-times"></i></div>
                          <img alt="img" className="img" src={uploadUrl + this.state.logo.medium} />
                          <div className="media-content">
                          </div>

                        </div>
                      </div> :
                      <div className="form-group required ">
                        <label htmlFor="exampleInputName1" className="control-label">Logo</label>
                        <input
                          maxLength="5"
                          onChange={this.uploadFile}
                          name="logo"
                          type="file"
                          className="form-control"
                        />
                        <ErrorView message={this.state.logoError} />
                        <div className="progressbar-image">
                          {uploadPercentage > 0 && <ProgressBar now={uploadPercentage} active label={`${uploadPercentage}%`} />}
                        </div>
                      </div>}
                    <div className="form-group required col-12 col-lg-6">
                      <label htmlFor="exampleInputEmail" className="control-label">Footer Text</label>
                      <ReactQuill
                        value={this.state.footerText}
                        name="footerText"
                        ref="footerText"
                        onChange={value =>
                          this.onChange('footerText', value)
                        }
                      />
                      <ErrorView message={this.state.footerTextError} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-4 offset-md-3 text-center">
              <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" name="Update" />
              <Links to={routehelp.maindashboard} className="btn btn-outline-dark" name="Cancel" />
            </div>
          </div>
        </div>
      </main>
    )
  }
}

function mapStateToProps(state) {
  return {
    profile_single: state.profile_single,
    profile_update: state.profile_update
  };
}


const mapDispatchToProps = dispatch => ({
  profilesingle: data => dispatch(profilesingle(data)),
  updateprofile: (data) => dispatch(updateprofile(data)),
  state_search: data => dispatch(state_search(data)),
  city_search: data => dispatch(city_search(data)),
});

Setting = connect(
  mapStateToProps,
  mapDispatchToProps
)(Setting);

export default Setting;