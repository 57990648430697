import React, { Component } from 'react';

class Entires extends Component {
  state = {
    options: [
      {
        name: '5',
        value: 5
      },
      {
        name: '10',
        value: 10
      },
      {
        name: '25',
        value: 25
      },
      {
        name: '50',
        value: 50
      },
      {
        name: '100',
        value: 100
      },
      {
        name: '1000',
        value: 1000
      }
    ],
    value: this.props.itemsPerPage
  };

  handleChange = event => {
    this.setState({ value: event.target.value });

    this.props.handleChangeiItemsPerPage(event.target.value);
  };

  render() {
    // const { handleKeyPress } = this.props;
    const { options } = this.state;
    let { itemsPerPage } = this.props;
    return (
      <div
        className="dataTables_info dataTables_length pt-0 mb-4"
        id="zero_config_info"
        role="status"
        aria-live="polite"
      >
        <label className="mb-0">
          Show
          <select
            onChange={this.handleChange}
            value={itemsPerPage}
            name="zero_config_length"
            aria-controls="zero_config"
            className="form-control form-control-sm mx-1 h-auto"
          >
            {options.map(item => (
              <option key={item.value} value={item.value}>
                {item.name}
              </option>
            ))}
          </select>
          entries
        </label>
      </div>
    );
  }
}

export default Entires;
