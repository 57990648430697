import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { outstandingUpload, outstandlist, outstandingPartyName_list } from '../../actions';
import toastr from 'reactjs-toastr'
import { CSVLink } from "react-csv";
import { errorRender, loaderUpdate, successRender } from '../../helpers/functions';
import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';
import 'reactjs-toastr/lib/toast.css';
import SearchableDropDown from '../../components/searchableDropDown';
const OutstandingUserDropdown = lazy(() => import('./outstandingUserDropDown'));
const Entries = lazy(() => import('../../components/entries/entires'));
const Pagination = lazy(() => import('../../components/pagination/index'));
const ButtonWithItag = lazy(() => import('../../components/buttonwithTagi'));
const DataTable = lazy(() => import('../../components/dataTalble'));
const CardTitle = lazy(() => import('../../components/cardTitle'));
const Loader = lazy(() => import('../../components/loader'));

let itemsPerPage = ITEM_PER_PAGES;
let currentPage = CURRENT_PAGE;
let sortBy = SORT_BY;
let sort = SORT;
let file = new FormData();

const csvData = [
    ["SNO.", "BRAND", "AGENTNAME", "PARTYNAME", "BILLNO.", "BILLDATE", "BILLAMOUNT", "AMOUNTADJUSTED", "UNADJUSTEDRCPTAMT", "TOTALDUES", "DAYS"],
    ["1", "FREEFIT", "SACHIN PORWAL", "Tushar", "19-20/RYF-9236", "03-09-2019", "18974", "", "", "18974", "32"],
    ["2", "DON & JULIO", "SACHIN PORWAL", "Tushar", "19-20/RYF-9253", "03-09-2019", "7064", "", "", "7064", "32"],
];
class Outstanding extends Component {
    state = {
        name: '',
        code: '',
        isLoading: false,
        brand: '',
        customer: [],
        customer_array: '',
        agent: '',
        billNo:'',
        partyName:'',
        partyNameList:[],
        isFilter: false,
        message: 'Successfully Uploaded Outstand',
        serverError: ["outstand_res", "outstandingUploadresponse", "outstandingpartyname_list_res"],
        tableHeaders: [
            {
                name: 'billNo',
                label: "Bill No.",
                type: "text",
                sortable: true,
                isShow: true
            },
            {
                name: 'partyName',
                label: "Party Name",
                type: "text",
                sortable: true,
                isShow: true
            },
            {
                name: 'agentName',
                label: "Agent Name",
                type: "text",
                sortable: true,
                isShow: true
            },
            {
                name: 'brand',
                label: "Brand",
                type: "text",
                sortable: true,
                isShow: true
            },
            {
                name: 'billAmount',
                label: "Bill Amount",
                type: "text",
                sortable: true,
                isShow: true
            },
            {
                name: 'billDate',
                label: "Bill Date",
                type: "text",
                sortable: true,
                isShow: true
            },
            {
                name: 'createdAt',
                label: "Created At",
                type: "date",
                sortable: true,
                isShow: true
            },
        ]
    }

    componentDidMount() {
        this.loadResult();
        this.props.outstandingPartyName_list();
    }

    componentWillUnmount() {
        currentPage = CURRENT_PAGE;
        itemsPerPage = ITEM_PER_PAGES;
    }

    loadResult = () => {
        if (this.state.isFilter === true) {
            currentPage = 1;
        }
        file = new FormData();
        document.getElementById("myFile").value = "";   
        let data2 = {
            agent: this.state.agent && this.state.agent.label ? this.state.agent.label : undefined,
        };
        data2.filter = {
            partyName:this.state.partyName && this.state.partyName.label ? this.state.partyName.label : null,
            billNo:this.state.billNo ? this.state.billNo : null,
        };
        data2.skip = (currentPage - 1) * itemsPerPage;
        data2.limit = itemsPerPage;
        data2.sortBy = sortBy;
        data2.sort = sort;
        this.setState({ isLoading: true, isFilter: false });
        this.props.outstandlist(data2);
    }

    resetfilter = () => {
        let data2 = {};
        data2.skip = (currentPage - 1) * itemsPerPage;
        data2.limit = itemsPerPage;
        this.setState({ isLoading: true, isFilter: false, agent: null, partyName:'', billNo:'' })
        this.props.outstandlist(data2);
    }

    ondropdownChangeagent = (e, t) => {
        this.setState({ agent: e });
    };

    ondropdownChange = (e, t) => {
        this.setState({ [e]: t });
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
    };

    toggleFilter = () => this.setState({ isFilter: !this.state.isFilter })

    sortBy = (key) => {
        sortBy = key;
        if (sort === 'asc') {
            sort = 'desc';
        } else {
            sort = 'asc';
        }
        this.loadResult();
    };

    handleFile = event => {
        if (event.target.files && event.target.files.length) {
            if (event.target.files[0].name.includes(".csv")) {
                file.append('file', event.target.files[0]);
            } else {
                document.getElementById("myFile").value = "";
                toastr.error('Please Select only Csv file ', 'error', {
                    displayDuration: 5000, width: '400px'
                });
            }
        }
    };

    fileupload = () => {
        if (document.getElementById("myFile").value === "") {
            return toastr.error('Please Select Csv file ', 'error', {
                displayDuration: 5000, width: '400px'
            });
        } else {
            this.props.outstandingUpload(file);
            file = new FormData();
        }
    };

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    };

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    };

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    };

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    };

    clearFile = () => {
        document.getElementById("myFile").value = "";
        file = new FormData();
    };

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            this.state.serverError.forEach((el) => errorRender(nextProps, el, el === "outstandingUploadresponse" ? this.clearFile : undefined))
            successRender(nextProps, "outstandingUploadresponse", this.loadResult, this.state.message);
            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "outstand_res") });
            }
            if(nextProps && nextProps.outstandingpartyname_list_res && nextProps.outstandingpartyname_list_res.list && nextProps.outstandingpartyname_list_res.list.length>0){
                this.setState({
                    partyNameList:nextProps.outstandingpartyname_list_res.list.map((ele) => { return { value: ele.partyName, label: ele.partyName } })
                });
            }    
        }
        return true;
    };

    render() {
        let { outstand_res } = this.props;
        const totResult = outstand_res !== undefined ? outstand_res.count : 0;

        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                <CardTitle title="Outstanding"/>
                                </div>

                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                {totResult !== undefined ?
                                                    <Entries
                                                        itemsPerPage={itemsPerPage}
                                                        handleChangeiItemsPerPage={
                                                            this.handleChangeiItemsPerPage
                                                        }
                                                    />
                                                    : ""}
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div id="bs4-table_filter" className="dataTables_filter">
                                                    <ButtonWithItag classNameI="ti-filter" onclick={this.toggleFilter} type="button" className="btn btn-primary navbar-toggler right-sidebar-toggler mr-1" data-toggle="tooltip" data-placement="top" title="Filter" data-original-title="Click for Filter option" />
                                                    <ButtonWithItag classNameI="fa tai-ico-import" type="button" className="btn btn-primary mr-1" datatoggle="modal" datatarget=".bd-example-modal-lg" dataplacement="top" title="Import a file" data-original-title="Click to download all" />
                                                    <CSVLink
                                                        data={csvData}
                                                        filename={"outstandingSample.csv"}
                                                        className="btn btn-primary"
                                                        target="_blank"
                                                        title="Sample"
                                                    >
                                                        <i className="fa fa-file-excel-o"></i>
                                                    </CSVLink>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" style={{ display: "none" }} aria-hidden="true">
                                            <div className="modal-dialog modal-lg">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="myLargeModalLabel">Outstanding Upload</h5>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">×</span>
                                                        </button>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="custom-file mb-3 col">
                                                            <input accept=".csv" onChange={this.handleFile} type="file" id="myFile" name="filename2"></input>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" onClick={this.fileupload} data-dismiss="modal" className="btn btn-primary">Save changes</button>
                                                        <button type="button" className="btn btn-secondary" onClick={this.clearFile} data-dismiss="modal">Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {totResult === undefined ? <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={""} props={this.props} /> :
                                            this.state.isLoading ? <Loader /> : outstand_res !== undefined && outstand_res.count === 0 ? "No Data Found" : outstand_res && outstand_res.count > 0 ? <div className="row">
                                                <div className="col-sm-12 display-grid">
                                                    <div className="table-responsive">
                                                        <DataTable mappingObj={this.state.isOnlineMapping} headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={outstand_res} />
                                                    </div>
                                                </div>
                                            </div> : "Something Went Wrong"}

                                        {totResult !== undefined ?
                                            <Pagination
                                                handleChangeiItemsPerPage={
                                                    this.handleChangeiItemsPerPage
                                                }
                                                handlePage={this.handlePage}
                                                handleNext={this.handleNext}
                                                handlePrevious={this.handlePrevious}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                totResult={totResult}
                                                key={currentPage}
                                            />
                                            : ""}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={!this.state.isFilter ? "right-sidebarfilter hidden" : "right-sidebarfilter show"}>
                    <div className="modal-header bg-primary rounded-0">
                        <h6 className="filter-modal-title"> Filter</h6>
                        <button type="button" onClick={this.toggleFilter} className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" className="navbar-toggler right-sidebar-toggler pointer">×</span>
                        </button>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="form-group">
                                <OutstandingUserDropdown
                                    labelValue="Agent"
                                    ondropdownChange={this.ondropdownChangeagent}
                                    name="agent"
                                    value={this.state.agent}
                                    className="form-control form-control-square"
                                />
                            </div>
                            <div className="form-group">
                                    <label className="control-label">Party Name</label>
                                    <SearchableDropDown
                                        value={this.state.partyName}
                                        onChange={(e) => this.ondropdownChange("partyName", e)}
                                        options={this.state.partyNameList}
                                        instanceId="partyName-id"
                                        prefixValue="partyName"
                                        placeholder={'Party Name'}
                                    />
                            </div>
                            <div className="form-group">
                                <label htmlFor="name" className="control-label">Bill No.</label>
                                <input maxlength="20" type="text" name="billNo" value={this.state.billNo} onChange={this.onChange} className="form-control form-control-square" id="billNo" placeholder="Bill No." />
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6 mb-4 offset-md-3 text-center">
                        <button onClick={this.loadResult} data-dismiss="modal" type="button" aria-hidden="true" className="btn btn-primary navbar-toggler right-sidebar-toggler pointer mr-1">Apply </button>
                        <button type="button" data-dismiss="modal" onClick={this.resetfilter} className="btn btn-outline-dark">Reset</button>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        outstandingUploadresponse: state.outstandingUploadresponse,
        outstand_res: state.outstand_res,
        outstandingpartyname_list_res: state.outstandingpartyname_list_res,
    };
}

const mapDispatchToProps = dispatch => ({
    outstandingUpload: data => dispatch(outstandingUpload(data)),
    outstandingPartyName_list: data => dispatch(outstandingPartyName_list(data)),
    outstandlist: data => dispatch(outstandlist(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Outstanding);