import * as React from 'react';

const SearchInput = ({ onChangeforDebounce, value }) => {
    return (
        <div className="input-group mr-sm-2 search-group">
            <input onChange={(e) => onChangeforDebounce(e)} value={value} type="text" className="form-control" id="inlineFormInputGroupUsername2" placeholder="search..." />
            <div className="input-group-prepend">
                <div className="input-group-text"><i className="fa fa-search "></i></div>
            </div>
        </div>
    )
}

export default SearchInput;