import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class LinkWithItag extends Component {
    render() {
        let { title, className, to, classNameI, type, name, role, id, dataId } = this.props;
        return (
            <Link title={title} className={className} to={to} type={type} role={role} id={id} data-id={dataId}>
                <i className={classNameI}></i>{name}
            </Link>
        )
    }
}

export default LinkWithItag;