import React, { Component } from 'react'

class ButtonWithItag extends Component {
    render() {
        let { className, title, type, classNameI, dataoriginaltitle, dataplacement, datatoggle, onclick, datatarget, id, dataId, disabled = false, dataBackdrop, dataKeyboard } = this.props;
        return (
            <button title={title} className={className} disabled={disabled} type={type} data-original-title={dataoriginaltitle} data-target={datatarget} id={id} data-id={dataId} onClick={onclick !== undefined ? onclick : null} data-placement={dataplacement} data-toggle={datatoggle} data-backdrop={dataBackdrop} data-keyboard={dataKeyboard}>
                <i className={classNameI}></i>
            </button>
        )
    }
}

export default ButtonWithItag;