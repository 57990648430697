import React, { Component } from 'react';
import { routehelp } from '../../Routehelper';
import Inputfield from '../../components/inputfields';
import Button from '../../components/buttons';
import Links from '../../components/links';
import LinkwithImg from '../../components/linkwithimg';
import Logo from '../../logo.svg';

class Signup extends Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.onsubmit = this.onsubmit.bind(this);
        this.state = {
            name: "",
            email: "",
            password: "",
            confirmpassword: ""
        }
    }

    handleChange(name, e) {
        this.setState({ [name]: e.target.value });
    }

    onsubmit(e) {
        let data = {
            name: this.state.name,
            email: this.state.email,
            password: this.state.password,
            confirmpassword: this.state.confirmpassword
        }
        e.preventDefault();
        console.log(data);
    }
    render() {
        return (
            <div className="container">
                <form onSubmit={this.onsubmit} className="form-signin">
                    <LinkwithImg to={routehelp.login} className="brand text-center logo-1" src={Logo} alt="" />
                    <h2 className="form-signin-heading">Please sign up</h2>
                    <div className="form-group">
                        <label htmlFor="inputName" className="sr-only">Full Name</label>
                        <Inputfield type="text" value={this.state.name} name="name" handleChange={this.handleChange} id="name" className="form-control" placeholder="Full Name" required={true} />
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputEmail" className="sr-only">Email address</label>
                        <Inputfield type="email" value={this.state.email} name="email" id="email" handleChange={this.handleChange} className="form-control" placeholder="Email address" required={true} />
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputPassword" className="sr-only">Password</label>
                        <Inputfield type="password" value={this.state.password} name="password" id="password" handleChange={this.handleChange} className="form-control" placeholder="Password" required={true} />
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputConfirmPassword" className="sr-only">Confirm Password</label>
                        <Inputfield type="password" value={this.state.confirmpassword} name="confirmpassword" id="confirmpassword" handleChange={this.handleChange} className="form-control" placeholder="Confirm Password" required={true} />
                    </div>

                    <div className="checkbox mb-4 mt-4">
                        <label className="custom-control custom-checkbox">
                            <Inputfield type="checkbox" className="custom-control-input" />
                            <span className="custom-control-indicator"></span>
                            <span className="custom-control-description">
                                I Agree the <Links to={routehelp.default} className="default-color" name="terms and conditions." />
                            </span>
                        </label>
                    </div>
                    <Button className="btn btn-lg btn-primary btn-block" type="submit" name="Sign up" />

                    <div className="mt-4">
                        <span>
                            Already have an account ?
                    </span>
                        <Links to={routehelp.login} className="text-primary" name="Sign In" />
                    </div>
                </form>

            </div>
        )
    };
};

export default Signup;