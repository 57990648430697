import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { customers_visit, customers_visit_export } from '../../actions';
import { Link } from 'react-router-dom';
import { routehelp } from '../../Routehelper';
import { confirmAlert } from "react-confirm-alert";
import { errorRender, successRender, commonDebounce, loaderUpdate, fileDownload } from '../../helpers/functions';
import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';
import Userdropdown from '../userManagement/userDropdwon';

const DataTable = lazy(() => import('../../components/dataTalble'));
const Loader = lazy(() => import('../../components/loader'));
const Entries = lazy(() => import('../../components/entries/entires'));
const Pagination = lazy(() => import('../../components/pagination/index'));

let itemsPerPage = ITEM_PER_PAGES;
let currentPage = CURRENT_PAGE;
let sortBy = 'mobileVisitingList.date';
let sort = 'desc';

class CustomersVisit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isExportLoader: false,
            startDate: new Date().toJSON().slice(0, 10).split`-`.join("-"),
            maxDate: new Date().toJSON().slice(0, 10).split`-`.join("-"),
            endDate: new Date().toJSON().slice(0, 10).split`-`.join("-"),
            successRequest: [],
            serverErrors: ["customers_visit_res", "customers_visit_export_res"],
            isLoading: false,
            isFilter: false,
            tableHeaders: [
                {
                    name: 'date',
                    label: "Checkin Date",
                    type: "date_time",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'checkoutDate',
                    label: "Checkout Date",
                    type: "date_time",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'agent.name',
                    label: "User Name",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'name',
                    label: 'Customer Name',
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'address',
                    label: 'Geo Location',
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'remarks',
                    label: "Remarks",
                    type: "text__with_icon",
                    sortable: false,
                    isShow: true,
                    allactions: [
                        {
                            name: "remarks",
                            title: "Remarks",
                            classNameI: "fa fa-info-circle ml-2",
                            className: "btn btn-link text-pramiry action-btn mr-2 p-0",
                            function: this.openRemraks
                        }
                    ]
                }
            ]
        }
    }

    openRemraks = (event, item, props) => {
        // console.log(item);
        confirmAlert({
            title: "Remarks",
            message: item.remarks || "N/A",
            buttons: [
                {
                    label: "Close",
                    onClick: () => null,
                }
            ],
        });
    }

    componentDidMount() {
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        this.loadResult()
    }

    resetData = () => {
        // console.log(new Date().toISOString())

        this.setState({
            startDate: new Date().toJSON().slice(0, 10).split`-`.join("-"),
            endDate: new Date().toJSON().slice(0, 10).split`-`.join("-"),
            agent: null
        }, () => {
            this.loadResult('reset');
        });
    }

    loadResult = (type = 'all') => {
        // console.log(this.state.startDate, this.state.endDate);
        this.setState({
            daterangeMsg: ""
        });

        if (this.state.startDate && this.state.endDate) {
            let sdate = new Date(this.state.startDate);
            sdate.setHours(0, 0, 0);

            let edate = new Date(this.state.endDate);
            edate.setHours(59, 59, 0);

            // console.log(sdate, edate);

            if (edate.getTime() < sdate.getTime()) {
                this.setState({
                    daterangeMsg: "End Date must be bigger than start date"
                });
                return true;
            };

            let difffTime = edate.getTime() - sdate.getTime();
            if (difffTime <= 62 * 24 * 60 * 60 * 1000) { // 2 months
                let data = {};

                data.filter = {
                    agent: this.state.agent && this.state.agent.value !== "" ? this.state.agent.value : undefined,
                };

                data.visitDates = {
                    from: this.state.startDate,
                    to: this.state.endDate
                };

                data.sortBy = sortBy;
                data.sort = sort;
                data.skip = (currentPage - 1) * itemsPerPage;
                data.limit = itemsPerPage;

                if (type && type === 'reset') {
                    data.sortBy = sortBy;
                    data.sort = sort;
                    data.skip = (currentPage - 1) * itemsPerPage;
                    data.limit = itemsPerPage;
                    data.filter = {
                        agent: undefined,
                    }
                }

                this.props.customers_visit(data);
                this.setState({ isLoading: true });

            } else {
                this.setState({
                    daterangeMsg: "Select date in 2 months range."
                });
            }
        } else {
            this.setState({
                daterangeMsg: "Please select start date and end date to export the file."
            });
        }

    }

    export = () => {
        if (this.state.startDate && this.state.endDate) {
            let sdate = new Date(this.state.startDate);
            sdate.setHours(0, 0, 0);

            let edate = new Date(this.state.endDate);
            edate.setHours(59, 59, 0);

            if (edate.getTime() < sdate.getTime()) {
                this.setState({
                    daterangeMsg: "End Date must be bigger than start date"
                });
                return true;
            };

            let difffTime = edate.getTime() - sdate.getTime();
            if (difffTime <= 62 * 24 * 60 * 60 * 1000) { // 2 months
                let data = {};
                data.filter = {
                    agent: this.state.agent && this.state.agent.value !== "" ? this.state.agent.value : undefined,
                };
                data.visitDates = {
                    from: this.state.startDate,
                    to: this.state.endDate
                };
                data.sortBy = sortBy;
                data.sort = sort;
                data.skip = (currentPage - 1) * itemsPerPage;
                data.limit = itemsPerPage;
                this.props.customers_visit_export(data);
                this.setState({ isExportLoader: true });
            } else {
                this.setState({
                    daterangeMsg: "Select date in 2 months range."
                });
            }
        } else {
            this.setState({
                daterangeMsg: "Please select start date and end date to export the file."
            });
        };

    }

    componentWillUnmount() {
        currentPage = 1;
        itemsPerPage = 50;
    }

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    };

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    };

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    };

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    };

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
    };

    callforDebounce = () => this.loadResult();

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "customers_visit_res") });
            }

            fileDownload(nextProps, "customers_visit_export_res", "fileName");

            if (nextProps.customers_visit_export_res) {
                this.setState({
                    isExportLoader: false
                });
            }

            this.state.serverErrors.forEach((el) => errorRender(nextProps, el, el === "customers_visit_update" ? this.loadResult : undefined));
            this.state.successRequest.forEach((el) => successRender(nextProps, el.name, this.loadResult, el.msg));
        }
        return true;
    }

    ondropdownChangeagent = (e, t) => {
        this.setState({ [e]: t });
    }

    render() {
        const { customers_visit_res } = this.props;

        // orders_least_10 = [];
        // console.log(customers_visit_res);

        const totResult = customers_visit_res !== undefined ? customers_visit_res.count : 0;

        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className='row justify-content-end'>
                                </div>
                                <div>
                                    <div className="card-header">
                                        <div className='row'>
                                            <div className='col-md-8 col-12'>
                                                <div className="card-title">
                                                    Customers Visits
                                                </div>
                                            </div>
                                            <div className="col-sm-3 col-md-3 inline-label">
                                                {
                                                    totResult === undefined ? "" : <Entries
                                                        itemsPerPage={itemsPerPage}
                                                        handleChangeiItemsPerPage={
                                                            this.handleChangeiItemsPerPage
                                                        }
                                                    />}

                                            </div>
                                            <div className='col-12 col-md-1'>
                                                <div className="mr-3">
                                                    <Link to={routehelp.maindashboard} className="btn btn-primary">Back</Link>

                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className="col-sm-3 col-md-3">
                                            <Userdropdown
                                                ondropdownChange={this.ondropdownChangeagent}
                                                name="agent"
                                                value={this.state.agent}
                                                className="form-control"
                                                placeholder="User"
                                            />
                                        </div>

                                        <div className="col-sm-3 col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="Mobile" className="control-label">Start Date</label>
                                                <input maxLength="10" type="date" className="form-control" max={this.state.maxDate} name="startDate" value={this.state.startDate} onChange={this.onChange} id="startdate" placeholder="Code" />
                                            </div>
                                        </div>

                                        <div className="col-sm-3 col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="Mobile" className="control-label">End Date</label>
                                                <input maxLength="10" type="date" className="form-control" max={this.state.maxDate} name="endDate" value={this.state.endDate} onChange={this.onChange} id="enddate" placeholder="Code" />
                                            </div>
                                        </div>

                                        <div className="col-sm-3 col-md-3">
                                            <div className='d-flex'>
                                                <div className="form-group mr-2">
                                                    <label htmlFor="Mobile" className="control-label d-block">&nbsp;</label>
                                                    <button type="button" disabled={this.state.isLoading} className="btn btn-primary" onClick={this.loadResult}>Apply</button>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="Mobile" className="control-label d-block">&nbsp;</label>
                                                    <button type="button" disabled={this.state.isLoading} className="btn btn-primary" onClick={this.resetData}>Reset</button>
                                                </div>
                                                <div className="form-group ml-2">
                                                    <label htmlFor="Mobile" className="control-label d-block">&nbsp;</label>
                                                    <button type="button" disabled={this.state.isExportLoader || this.state.isLoading || !customers_visit_res || customers_visit_res.count === 0} className="btn btn-primary" onClick={this.export}>Export</button>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            this.state.daterangeMsg ?
                                                <div className="col-12 col-md-8">
                                                    <small className="alert alert-danger w-100 d-block">{this.state.daterangeMsg}</small>
                                                </div> : null
                                        }

                                    </div>
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">
                                        {/* <div className="row">
                                            <div className="col-sm-12"> */}
                                        {/* <div className="table-responsive"> */}
                                        {
                                            totResult === undefined ?
                                                <div className="text-center"> <div className="text-center">
                                                    <DataTable headers={this.state.tableHeaders}
                                                        data={[]} />
                                                </div>
                                                </div> :
                                                this.state.isLoading ? <Loader /> :
                                                    customers_visit_res &&
                                                        customers_visit_res.list &&
                                                        customers_visit_res.list.length === 0 ?
                                                        <div className="row">
                                                            <div className="col-sm-12 display-grid">
                                                                <div className="table-responsive">
                                                                    <DataTable headers={this.state.tableHeaders}
                                                                        data={[]} /></div></div></div> :
                                                        customers_visit_res && customers_visit_res.list && customers_visit_res.list.length > 0 ?
                                                            <div className="row">
                                                                <div className="col-sm-12 display-grid">
                                                                    <div className="table-responsive">
                                                                        <DataTable headers={this.state.tableHeaders}
                                                                            data={customers_visit_res} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <p className="text-center">
                                                                Something Went Wrong
                                                            </p>
                                        }
                                        {totResult !== undefined ?
                                            <Pagination
                                                handleChangeiItemsPerPage={
                                                    this.handleChangeiItemsPerPage
                                                }
                                                handlePage={this.handlePage}
                                                handleNext={this.handleNext}
                                                handlePrevious={this.handlePrevious}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                totResult={totResult}
                                                key={currentPage}
                                            />
                                            : ""}
                                        {/* </div> */}
                                        {/* </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        customers_visit_res: state.customers_visit_res,
        customers_visit_export_res: state.customers_visit_export_res
    };
}

const mapDispatchToProps = dispatch => ({
    customers_visit_export: data => dispatch(customers_visit_export(data)),
    customers_visit: data => dispatch(customers_visit(data))
});

CustomersVisit = connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomersVisit);

export default CustomersVisit;