import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logsList } from '../../actions';
import Header from '../../components/header';
import Links from '../../components/links';
import { routehelp } from '../../Routehelper';
import Entries from '../../components/entries/entires';
import 'reactjs-toastr/lib/toast.css';
require('react-datetime');

let itemsPerPage = 50;
let currentPage = 1;
let sortBy = 'createdAt';
let sort = 'desc';

class LogicalLogs extends Component {

    state = {
        isLoading: false,
        isFilter: false,
        logsListData: [],
        selectAll: false,
        valid: false,
    };

    componentDidMount() {
        this.loadResult();
    }

    componentWillUnmount() {
        currentPage = 1;
        itemsPerPage = 50;
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    loadResult = () => {
        let data2 = {};
        data2.skip = (currentPage - 1) * itemsPerPage;
        data2.limit = itemsPerPage;
        data2.sortBy = sortBy;
        data2.sort = sort;
        this.setState({ isLoading: true, isFilter: false, selectAll: false });
        this.props.logsList(data2);
    }

    sortBy = (key) => {
        sortBy = key;
        if (sort === 'asc') {
            sort = 'desc';
        } else {
            sort = 'asc';
        }
        this.loadResult();
    }

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }

    shouldComponentUpdate(nextProps) {
        if (this.state.isLoading) {
            this.setState({ isLoading: false });
        }
        if (nextProps.logsListData !== undefined && nextProps.logsListData.success) {
            if (nextProps.logsListData.count > 0) {
                this.setState({ logsListData: nextProps.logsListData.list, isLoading: false });
            } else {
                this.setState({ logsListData: [], isLoading: false });
            }
            nextProps.logsListData.success = null;
        }
        return true;
    }

    render() {
        const totResult = this.state.logsListData && this.state.logsListData.length > 0 ? this.state.logsListData.length : 0;
        // console.log(this.state.logsListData);
        let myStyle = {
            backgroundColor: '#f1f1f1',
            padding: '10px'
        };

        return (
            <div className="app header-fixed left-sidebar-fixed scrollbarcustom left-sidebar-hidden">
                <Header />
                <div className="app-body">
                    <main className="main-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12 mt-4">
                                    <div className="card card-shadow mb-4">

                                        <div className="card-header">
                                            <div className="card-title">
                                                Logs
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div id="bs4-table_wrapper" className="dataTables_wrapper p-0">

                                                <div className="row">
                                                    <div className="col-sm-12 col-md-6">
                                                        {totResult !== undefined ?
                                                            <Entries
                                                                itemsPerPage={itemsPerPage}
                                                                handleChangeiItemsPerPage={
                                                                    this.handleChangeiItemsPerPage
                                                                }
                                                            />
                                                            : ""}
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div id="accordion">
                                                            {this.state.logsListData && this.state.logsListData.map((el, index) => (
                                                                <div className="card" key={index}>
                                                                    <div className="card-header" id={`headingOne_${index}`}>
                                                                        <h5 className="mb-0">
                                                                            <button className="btn btn-link" data-toggle="collapse" data-target={`#collapseOne_${index}`} aria-expanded="true" aria-controls={`collapseOne_${index}`}>
                                                                                {'Company Order Number: ' + el.order.companyOrderNum}
                                                                                {'\n, Order Number: ' + el.order.orderNumber}
                                                                            </button>
                                                                        </h5>
                                                                    </div>
                                                                    <div id={`collapseOne_${index}`} className="collapse" aria-labelledby={`headingOne_${index}`} data-parent="#accordion">
                                                                        <div className="card-body">
                                                                            <strong>Request:</strong>
                                                                            <pre style={myStyle}>{JSON.stringify(el.body.request, null, 4)}</pre>
                                                                            <strong>Response:</strong>
                                                                            <pre style={myStyle}>{JSON.stringify(el.body.response)}
                                                                            </pre>
                                                                            <strong>Order:</strong>
                                                                            <pre style={myStyle}>{JSON.stringify(el.order)}</pre>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 mb-4 offset-md-3 text-center">
                                    <Links to={routehelp.primarydashboard} className="btn btn-primary mr-1" name="Close" />
                                </div>
                            </div>

                        </div>

                    </main>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        logsListData: state.logsListData,
    };
}

const mapDispatchToProps = dispatch => ({
    logsList: data => dispatch(logsList(data)),
});

LogicalLogs = connect(
    mapStateToProps,
    mapDispatchToProps
)(LogicalLogs);

export default LogicalLogs;