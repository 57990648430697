import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { routehelp } from '../../Routehelper';
import { adminusercreate, adminusersingle, adminuserupdate, rolenamelist } from '../../actions';
import { errorRender, successRedriect, validate, getValue, inputCheck } from '../../helpers/functions';
import RolesDropDown from './rolesDropDown';

const Inputfield = lazy(() => import('../../components/inputfields'));
const Buttons = lazy(() => import('../../components/buttons'));
const Links = lazy(() => import('../../components/links'));
const Loader = lazy(() => import('../../components/loader'));

class AdminUserCreation extends Component {
    state = {
        pageType: 'add',
        commissionFlag: null,
        itemId: '',
        name: '',
        email: '',
        phone: '',
        password: '',
        role: "",
        company: "",
        companyDetails: "",
        serverErrors: ["adminuser_create_res", "adminuser_update", "adminuser_single"],
        forRedirect: [
            { name: "adminuser_create_res", pageName: 'Admin User', pageUrl: 'admin', action: "Added" },
            { name: "adminuser_update", pageName: 'Admin User', pageUrl: 'admin', action: "Updated" }
        ],
        setFields: [
            { name: "name", stateName: "name", type: "text" },
            { name: "email", stateName: "email", type: "text" },
            { name: "phone", stateName: "phone", type: "text" },
            { name: "password", stateName: "password", type: "text" },
            { name: "role", stateName: "role", type: "select" },
        ],
        typeList: [
            { value: "", label: "Select a Type" },
            { value: "AG", label: "Agent" },
            { value: "ASM", label: "Area Sales Manager" },
            { value: "ASM2", label: "Area Sales Manager 2" },
            { value: "SASM", label: "Sr. AREA SALES MANAGER" },
            { value: "WHS", label: "WHOLESALER" }
        ],
        nameError: '',
        emailError: '',
        phoneError: '',
        passwrodError: '',
        typeError: '',
        isLoading: false,
        getSingleLoader: false,
    }

    componentDidMount() {
        let id = this.props.match.params.item_id;
        this.setState({ getSingleLoader: false })
        this.props.rolenamelist();
        if (id) {
            this.setState({ pageType: "update", itemId: id, getSingleLoader: true })
            this.props.adminusersingle(id);
        };
    }

    componentWillUnmount() {
        this.setState(this.state);
    }

    handleChange = (name, e) => {
        let isValid = inputCheck(name, e.target.value, "user");
        if (isValid) {
            this.setState({ [name]: e.target.value });
        }
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ role : e.target.value });
        }
    };

    onsubmit = (e) => {
        // console.log(this.state);
        let validationFields = [
            { name: 'name', label: "Name", value: this.state.name, hasError: false, isRequired: true, isNumber: false, onlyChar: true, maxLength: 100, type: "text", message: "", errName: "nameError" },
            { name: 'email', label: "Email", value: this.state.email, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "email", message: "", errName: "emailError" },
            { name: 'phone', label: "Mobile", value: this.state.phone, hasError: false, isRequired: true, isNumber: true, onlyChar: false, minLength: 10, maxLength: 10, type: "text", message: "", errName: "phoneError" },
            { name: 'password', label: "Password", value: this.state.password, hasError: false, isRequired: this.state.pageType === "update" ? false : true, isNumber: false, maxLength: 16, minLength: 8, onlyChar: false, type: "password", message: "", errName: "passwrodError" },
            { name: 'role', label: "Role", value: this.state.role, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "typeError" },
        ];
        let data = {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            password: this.state.password,
            role: this.state.role && this.state.role.value ? this.state.role.value : undefined,
        };
        e.preventDefault();
        let { newArr, valid } = validate(validationFields);
        newArr.forEach((el) => this.setState({ [el.errName]: el.message }));
        if (valid) {
            this.setState({ isLoading: true });
            if (this.state.pageType === "update") {
                this.props.adminuserupdate(data, this.state.itemId);
            } else {
                this.props.adminusercreate(data);
            }
            validationFields = null;
            newArr = null;
        }
    }

    ondropdownChange = (t) => {
        this.setState({ role: t });
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }
            if (nextProps.adminuser_single && nextProps.adminuser_single.success === true) {
                this.setState({ getSingleLoader: false });
                if (nextProps.adminuser_single.item) {
                    this.state.setFields.forEach((value) => {
                        if (value.type === "text") {
                            this.setState({ [value.stateName]: getValue(nextProps.adminuser_single.item, value.name) })
                        } 
                        else if (value.type === "select" && nextProps.adminuser_single.item.role  ) {
                            this.setState({ [value.stateName]: {
                                value: nextProps.adminuser_single.item.role._id,
                                label: nextProps.adminuser_single.item.role.roleName
                            } })
                        }
                    });
                    nextProps.adminuser_single.success = null;
                }
            }
            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));
        }
        return true;
    }

    render() {
        const { pageType, isLoading, getSingleLoader } = this.state;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        {
                                            pageType === "update" ? "Update Admin User" : "Add Admin User"
                                        }
                                    </div>
                                </div>
                                {
                                    getSingleLoader ? <Loader /> :
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield errMessage={this.state.nameError} handleChange={this.handleChange} maxlength="100" type="text" name="name" value={this.state.name} className="form-control" id="name" ariadescribedby="nameHelp" placeholder="Name" />
                                                </div>

                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield errMessage={this.state.emailError} handleChange={this.handleChange} maxlength="100" type="text" name="email" value={this.state.email} className="form-control" id="email" aria-describedby="emailHelp" placeholder="Email" />
                                                </div>
                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield errMessage={this.state.phoneError} handleChange={this.handleChange} maxlength="10" type="text" name="phone" value={this.state.phone} className="form-control" id="phone" placeholder="Mobile" />
                                                </div>
                                                <div className={pageType === "add" ? "form-group required col-12 col-lg-4" : "form-group  col-12 col-lg-4"}>
                                                    <Inputfield errMessage={this.state.passwrodError} handleChange={this.handleChange} type="password" name="password" value={this.state.password} className="form-control" placeholder="Password" maxlength="16" minlength="8" arialabel="Amount (to the nearest dollar)" />
                                                    <small className="text-info d-flex">Password must contain atleast one uppercase, one lowercase, one digit and one special character. Length of password must be between 8 to 16 charcter long.</small>
                                                </div>

                                                <div className="form-group required col-12 col-lg-4">
                                                    <RolesDropDown
                                                        label="Roles"
                                                        value={this.state.role}
                                                        ondropdownChange={(e) => this.ondropdownChange(e)}
                                                        errMessage={this.state.typeError}
                                                        placeholder="Select an role"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" disable={isLoading || getSingleLoader} loading={isLoading} name={pageType === "update" ? "Update" : "Save"} />
                            <Links to={routehelp.admin} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>

                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        adminuser_create_res: state.adminuser_create_res,
        adminuser_single: state.adminuser_single,
        adminuser_update: state.adminuser_update,
    };
}


const mapDispatchToProps = dispatch => ({
    adminusersingle: data => dispatch(adminusersingle(data)),
    adminusercreate: data => dispatch(adminusercreate(data)),
    rolenamelist: data => dispatch(rolenamelist(data)),

    adminuserupdate: (data, id) => dispatch(adminuserupdate(data, id)),
});

AdminUserCreation = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminUserCreation);

export default AdminUserCreation;