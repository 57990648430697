import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { routehelp } from '../../Routehelper';
import { agencyCreate, brand_search, agencySingle, agencyUpdate } from '../../actions';
import toastr from 'reactjs-toastr';
import { errorRender, successRedriect, getValue, validate, inputCheck } from '../../helpers/functions';
import 'reactjs-toastr/lib/toast.css';

const CustomerDropDown = lazy(() => import('../../container/customerManagement/customerDropdown'));
const SingleBrandDropDown = lazy(() => import('../../container/brandsManagement/singleBrandDropDown'));
const Inputfield = lazy(() => import('../../components/inputfields'));
const CardTitle = lazy(() => import('../../components/cardTitle'));
const Buttons = lazy(() => import('../../components/buttons'));
const Links = lazy(() => import('../../components/links'));
const Loader = lazy(() => import('../../components/loader'));
class AgencyAdd extends Component {
    state = {
        pageType: 'add',
        itemId: '',
        name: '',
        code: '',
        erpItemCode: '',
        price: '',
        whlprice: '',
        brand: '',
        customer: [],
        serverError: ["agency_Single"],
        forRedirect: [
            { name: "agencyCreateRes", pageName: 'Agency', pageUrl: 'agencyMaster', action: "Added" },
            { name: "agencyUpdateRes", pageName: 'Agency', pageUrl: 'agencyMaster', action: "Updated" }
        ],
        setFields: [
            { name: "name", stateName: "name", type: "text" },
            { name: "code", stateName: "code", type: "text" },
        ],
        attributes: [{ wholeSaler: null, brand: null }],
        nameError: '',
        codeError: '',
        isLoading: false,
        getSingleLoader: false
    };

    componentDidMount() {
        let id = this.props.match.params.item_id;
        if (id) {
            this.setState({ pageType: "update", itemId: id, getSingleLoader: true })
            this.props.agencySingle(id);
        }
    };

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    handleChange = (name, e) => {
        let isValid = inputCheck(name, e.target.value);

        if (isValid) {
            this.setState({ [name]: e.target.value });
        }
        // const re = /([^\s]+)/;
        // if (e.target.value === '' || re.test(e.target.value)) {
        //     this.setState({ [name]: e.target.value });
        // }
    };

    handleatttributesChange = (idx, value, name) => {
        if (value) {
            const newattributes = this.state.attributes.map((attribute, sidx) => {
                if (idx !== sidx) return attribute;
                return { ...attribute, [name]: value };
            });
            this.setState({ attributes: newattributes });
        }
    };

    handleAddattributes = () => {
        if (this.state.attributes && this.state.attributes.length > 0 && this.state.attributes[this.state.attributes && this.state.attributes.length - 1].wholeSaler === null) {
            toastr.error("Customer is Required", 'Error', {
                displayDuration: 5000
            })
        } else if (this.state.attributes && this.state.attributes.length > 0 && this.state.attributes[this.state.attributes && this.state.attributes.length - 1].brand === null) {
            toastr.error("Brand is Required", 'Error', {
                displayDuration: 5000
            })
        } else {
            this.setState({
                attributes: this.state.attributes.concat([{ wholeSaler: null, brand: null }])
            });
        }
    };

    handleRemoveattributes = idx => () => this.setState({ attributes: this.state.attributes.filter((s, sidx) => idx !== sidx) });

    onsubmit = (e) => {
        let validationFields = [
            { name: 'name', label: "Name", value: this.state.name, hasError: false, isRequired: true, isNumber: false, onlyChar: true, maxLength: 50, type: "text", message: "", errName: "nameError" },
            { name: 'code', label: "Code", value: this.state.code, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 50, type: "text", message: "", errName: "codeError" },
        ]
        let { newArr, valid } = validate(validationFields);
        newArr.map((el) => this.setState({ [el.errName]: el.message }));

        if (valid) {
            if (this.state.attributes && this.state.attributes.length > 0 && this.state.attributes[this.state.attributes && this.state.attributes.length - 1].wholeSaler === null) {
                toastr.error("Customer is Required", 'Error', {
                    displayDuration: 5000
                })
            } else if (this.state.attributes && this.state.attributes.length > 0 && this.state.attributes[this.state.attributes && this.state.attributes.length - 1].brand === null) {
                toastr.error("Brand is Required", 'Error', {
                    displayDuration: 5000
                });
            } else {
                let attri = this.state.attributes;
                let attributes = [];

                attri.forEach((item) => {
                    attributes.push({
                        brand: item.brand.value,
                        wholesaler: item.wholeSaler.value,
                    });
                });

                let counter = 0;
                if (attributes && attributes.length > 0) {
                    attributes.forEach((ele, index) => {
                        if (ele.brand && ele.wholesaler) {
                            let findDuplicate = attributes.filter((find, ind) => {
                                if (find.brand === ele.brand && ele.wholesaler === find.wholesaler && ind !== index) {
                                    return find;
                                };
                            });
                            // console.log(findDuplicate);
                            if (findDuplicate && findDuplicate.length > 0) {
                                counter++;
                            }
                        };
                    });
                };

                if (counter !== 0) {
                    toastr.error("Duplicate record of brand and customer is not allowed", 'Error', {
                        displayDuration: 5000
                    });
                    return true;
                };

                let data = {
                    name: this.state.name,
                    code: this.state.code,
                    attributes
                };

                e.preventDefault();

                this.setState({ isLoading: true, alertMessage: [] });

                if (this.state.pageType === "update") {
                    this.props.agencyUpdate(data, this.state.itemId);
                } else {
                    this.props.agencyCreate(data);
                }

                validationFields = null;
                newArr = null;
            }
        }
    };

    agencyErrorMassge = (nextProps) => {
        if (nextProps.agencyCreateRes && nextProps.agencyCreateRes.success === false){
            if (nextProps.agencyCreateRes.errors && nextProps.agencyCreateRes.errors.length === 1) {
                errorRender(nextProps, "agencyCreateRes")
            }
            this.setState({
                alertMessage : nextProps.agencyCreateRes.errors
            });
           return nextProps.agencyCreateRes.success = null;

        } else if (nextProps.agencyUpdateRes && nextProps.agencyUpdateRes.success === false) {
            if (nextProps.agencyUpdateRes.errors && nextProps.agencyUpdateRes.errors.length === 1) {
                errorRender(nextProps, "agencyUpdateRes")
            }
            this.setState({
                alertMessage : nextProps.agencyUpdateRes.errors
            });
           return nextProps.agencyUpdateRes.success = null;
        };
    };

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }
            this.agencyErrorMassge(nextProps);
            this.state.serverError.forEach((el) => errorRender(nextProps, el));
            this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));
            if (nextProps.agency_Single && nextProps.agency_Single.success === true) {
                this.setState({ getSingleLoader: false });
                nextProps.agency_Single.success = null;
                if (nextProps.agency_Single && nextProps.agency_Single.attributes && nextProps.agency_Single.attributes.length > 0) {
                    let attributes = [];
                    nextProps.agency_Single.attributes.map((attriItem) => {
                        const wholeSaler = {
                            value: attriItem.wholesaler && attriItem.wholesaler._id && attriItem.wholesaler._id !== null ? attriItem.wholesaler._id : "",
                            label: attriItem.wholesaler && attriItem.wholesaler.name && attriItem.wholesaler.name !== null ? attriItem.wholesaler.name : "",
                        };
                        const brand = {
                            value: attriItem.brand && attriItem.brand._id && attriItem.brand._id !== null ? attriItem.brand._id : "",
                            label: attriItem.brand && attriItem.brand.name && attriItem.brand.name !== null ? attriItem.brand.name : ""
                        }
                        this.setState({ attributes: attributes });
                        return attributes.push({ wholeSaler: wholeSaler, brand: brand });
                    });
                    attributes = null;
                }
                if (nextProps.agency_Single.item) {
                    this.state.setFields.forEach((value) => {
                        if (value.type === "text") {
                            this.setState({ [value.stateName]: getValue(nextProps.agency_Single.item, value.name) })
                        }
                    })
                }
            }
        }
        return true;
    };

    render() {
        const { pageType, isLoading, getSingleLoader } = this.state;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                <CardTitle title={pageType === "update" ? "Update Agency" : "Add Agency"}/>
                                </div>
                                {getSingleLoader ? <Loader /> :
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="form-group required col-12 col-lg-6">
                                                <Inputfield errMessage={this.state.nameError} handleChange={this.handleChange} maxlength="100" type="text" name="name" value={this.state.name} className="form-control" id="name" ariadescribedby="codeHelp" placeholder="Agency Name" />
                                            </div>
                                            <div className="form-group required col-12 col-lg-6">
                                                <Inputfield errMessage={this.state.codeError} handleChange={this.handleChange} maxlength="10" type="text" name="code" value={this.state.code} className="form-control" id="code" aria-describedby="codeHelp" placeholder="Code" />
                                            </div>
                                        </div>
                                        <div >
                                        {
                                            this.state.alertMessage  && this.state.alertMessage.length > 1  && 
                                                <div className="col-12 alert alert-danger">
                                                    <div className="text-center text-danger fs">
                                                        There are pre-existing records for the mentioned Customer & Brand
                                                    </div>
                                                    {this.state.alertMessage[0].map((customer, index) => (      
                                                        this.state.alertMessage[1].map((brand, ind) => (
                                                            ind === index && (
                                                                <div className='offset-5'>
                                                                    {brand.param} : {customer.param} 
                                                                </div>
                                                                
                                                            )
                                                        ))
                                                    ))}
                                                </div>
                                        }
                                            <div className="form-row m-2">
                                                <div className="form-group required col-5"><label htmlFor="exampleInputName1" className="control-label">Customer</label></div>
                                                <div className="form-group required col-5"><label htmlFor="exampleInputName1" className="control-label">Brand</label></div>
                                                <div className="col-2" />
                                                {/* <div className="col"><label for="exampleInputName1" className="control-label">Price</label></div> */}
                                                {this.state.attributes.length !== 0 ? null :
                                                    <button type="button" className="btn  btn-primary" onClick={this.handleAddattributes} id="add1"><i className="fa fa-plus "></i> </button>
                                                }
                                            </div>
                                            {this.state.attributes.map((attributes, idx) => (
                                                <div key={idx}>
                                                    <div className="form-row m-2" key={idx}>
                                                        <div className="col-12 col-lg-5" id={"customer" + idx}>
                                                            <CustomerDropDown
                                                                isLabel={false}
                                                                ondropdownChange={(e) => this.handleatttributesChange(idx, e, "wholeSaler")}
                                                                placeholder="Search Customer"
                                                                preFixValue={"customer" + idx}
                                                                value={attributes.wholeSaler}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-lg-5" id={"brand" + idx}>
                                                            <SingleBrandDropDown
                                                                isLabel={false}
                                                                ondropdownChange={(e) => this.handleatttributesChange(idx, e, "brand")}
                                                                placeholder="Search Brand"
                                                                preFixValue={"brand" + idx}
                                                                value={attributes.brand}
                                                            />
                                                        </div>
                                                        {
                                                            idx === this.state.attributes.length - 1 ?
                                                                <div className="prod-button">
                                                                    <div className="flex-shrink-1">
                                                                        <button type="button" className="btn  btn-primary mr-2" onClick={this.handleAddattributes} id={"add" + idx}><i className="fa fa-plus "></i> </button>
                                                                    </div>
                                                                    {
                                                                        this.state.attributes.length > 1 ?
                                                                            <div className="flex-shrink-1">
                                                                                <button type="button" className="btn  btn-danger" onClick={this.handleRemoveattributes(idx)} id={"remove" + idx}><i className="fa fa-minus"></i>  </button>
                                                                            </div> : null
                                                                    }
                                                                </div> :
                                                                <div className="flex-shrink-1">
                                                                    <button type="button" className="btn  btn-danger" onClick={this.handleRemoveattributes(idx)} id={"remove_other" + idx}> <i className="fa fa-minus"></i>  </button>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-4 offset-md-3 text-center">
                        <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" disable={isLoading || getSingleLoader} loading={isLoading} name={pageType === "update" ? "Update" : "Save"} />
                        <Links to={routehelp.agencyMaster} className="btn btn-outline-dark" name="Cancel" />
                    </div>
                </div>
            </main >
        )
    }
}

function mapStateToProps(state) {
    return {
        agency_Single: state.agency_Single,
        agencyCreateRes: state.agencyCreateRes,
        brand_single: state.brand_single,
        agencyUpdateRes: state.agencyUpdateRes,
    };
}


const mapDispatchToProps = dispatch => ({
    agencyCreate: data => dispatch(agencyCreate(data)),
    brand_search: data => dispatch(brand_search(data)),
    agencySingle: data => dispatch(agencySingle(data)),
    agencyUpdate: (data, id) => dispatch(agencyUpdate(data, id)),
});

AgencyAdd = connect(
    mapStateToProps,
    mapDispatchToProps
)(AgencyAdd);

export default AgencyAdd;