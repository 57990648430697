import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Inputfield from '../../components/inputfields';
import Buttons from '../../components/buttons';
import Links from '../../components/links';
import { routehelp } from '../../Routehelper';
import { package_create, package_update, package_get } from '../../actions';
import Translate from '../../libs/translation';
import { apiUrl, uploadUrl } from "../../config";
import { ProgressBar } from "react-bootstrap";
import helperFunc from '../../helpers/helperFuncs';
import { errorRender, successRedriect } from '../../helpers/functions';
import axios from "axios";
import toastr from 'reactjs-toastr';
import 'reactjs-toastr/lib/toast.css';
class PackageForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageType: "add",
            isActive: true,
            name: '',
            description: "",
            uploadPercentage: 0,
            packageImg: {},
            error: {
                nameError: ''
            },
            numberOfMaxUser: 0,
            price: "",
            numberOfMaxRetailer: 0,
            isAdvertiseDisplay: true,
            isCodeEditable: true,
            reportFormat: "default",
            stockUpdateOn: "beforeConfirm",
            reportList: [
                { label: "Default", value: "default" },
                { label: "Invoice", value: "invoice" }
            ],
            stockUpdateOnList: [
                { label: "Before order confirm", value: "beforeConfirm" },
                { label: "After order confirm", value: "afterConfirm" }
            ],
            notificationType: "default",
            notificationTypeList: [
                {
                    label: "Default",
                    value: "default"
                },
                {
                    label: "Custom",
                    value: "custom"
                }
            ],
            commissionSelectedOn: "user",
            commissionDependencyList: [
                {
                    label: "User wise",
                    value: "user"
                },
                {
                    label: "Range",
                    value: "range"
                }
            ],
            userAuthentication: "one-way",
            userAuthenticationList: [
                {
                    label: "One Way",
                    value: "one-way"
                },
                {
                    label: "Two Way",
                    value: "two-way"
                }
            ],
            webFeature: {
                payment: false,
                dashboard: false,
                zone: false,
                region: false,
                territory: false,
                beat: false,
                users: false,
                customers: false,
                indirectCustomers: false,
                srASM: false,
                userHierarchy: false,
                brand: false,
                product: false,
                uom: false,
                stock: false,
                agency: false,
                transporter: false,
                scheme: false,
                outstanding: false,
                dispatchDetails: false,
                order: false,
                routePlanning: false,
                categoryExpense: false,
                subCategoryExpense: false,
                expenseApprovalExpense: false,
                advanceApproval: false,
                discountMaster: false,
                userRetailers: false,
                trending: false,
                categoriesRetailers: false,
                ecatalogue: false,
                orderRetailers: false,
                commission: false,
                orderFailed: false,
                commissionRange: false,
                totalUsers: false,
                totalProducts: false,
                totalOrders: false,
                totalCustomers: false,
                inactiveUsers: false,
                inactiveCustomers: false,
                top10Products: false,
                least10Products: false,
                top10Orders: false,
                least10Orders: false,
                top10Customers: false,
                least10Customers: false,
                zeroStock: false,
                profile: false,
                changePassword: false,
                misReportSetting: false,
                companyConfig: false,
                emailConfig: false,
                logs: false,
                totalBrands: false,
                productCommission: false,
                orderCommission: false,
                customersVisities: false
            },
            mobileFeature: {
                payment: false,
                myOrders: false,
                newOrders: false,
                stock: false,
                customers: false,
                outstanding: false,
                dispatchDetails: false,
                commission: false,
                childs: false,
                advance: false,
                myTours: false,
                productCommission: false
            },
            webFeatureList: [],
            mobileFeatureList: [],
            forRedirect: [
                { name: "package_create_res", pageName: 'package', pageUrl: 'package', action: "added" },
                { name: "package_update_res", pageName: 'package', pageUrl: 'package', action: "updated" }
            ],
            serverError: ["package_create_res", "package_update_res", "package_get_res"]
        };
    }


    isActiveFun = () => {
        let isActiveToggle = !this.state.isActive;
        this.setState({
            isActive: isActiveToggle
        });
    }

    featureChange = (name, e) => {
        let splitName = name.split(".");
        let updatedData = this.state[splitName[0]];
        updatedData[splitName[1]] = e.target.checked;
        this.setState({
            [splitName[0]]: updatedData
        });
    }

    handleChange = (name, e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [name]: e.target.value });
        }
    }

    checkBoxChange = (name, e) => this.setState({ [name]: e.target.checked });

    uploadFile = ({ target: { files } }) => {
        let data = new FormData();
        data.append("file", files[0]);
        const token = localStorage.getItem("token");
        const options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
                if (percent < 100) {
                    this.setState({ uploadPercentage: percent });
                }
            },
        };
        axios.defaults.headers = { "x-access-token": token };
        axios.post(apiUrl + "/api/packages/upload", data, options).then((res) => {
            this.setState({ packageImg: res.data.item.packageImg, uploadPercentage: 100 }, () => {
                setTimeout(() => {
                    this.setState({ uploadPercentage: 0 });
                }, 1000);
            });
        });
    };

    validate = () => {
        let nameError = '';
        let numberOfMaxUserError = '';
        let priceError = '';
        let numberOfMaxRetailerError = '';

        if (this.state.name.length === 0) {
            nameError = 'Name Is Required';
        } else if (/[0-9]/.test(this.state.name)) {
            nameError = 'Only Aplhabets';
        } else if (this.state.name.length > 100) {
            nameError = 'Name must be in 100 characters';
        }

        if (!/^\d+$/.test(this.state.numberOfMaxUser)) {
            numberOfMaxUserError = 'Only Numbers';
        }

        if (!/^\d+$/.test(this.state.price)) {
            priceError = 'Price for Package is required. It accepts only numbers';
        }

        if (!/^\d+$/.test(this.state.numberOfMaxRetailer)) {
            numberOfMaxRetailerError = 'Only Numbers';
        }

        if (nameError || numberOfMaxUserError || numberOfMaxRetailerError || priceError) {
            this.setState({ nameError, numberOfMaxUserError, numberOfMaxRetailerError, priceError });
            return false;
        }

        return true;
    };

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    onsubmit = (e) => {
        let data = {
            isActive: this.state.isActive,
            name: this.state.name,
            description: this.state.description,
            packageImg: this.state.packageImg,
            isAdvertiseDisplay: this.state.isAdvertiseDisplay,
            isCodeEditable: this.state.isCodeEditable,
            reportFormat: this.state.reportFormat,
            stockUpdateOn: this.state.stockUpdateOn,
            userAuthentication: this.state.userAuthentication,
            commissionSelectedOn: this.state.commissionSelectedOn,
            notificationType: this.state.notificationType,
            webFeature: this.state.webFeature,
            mobileFeature: this.state.mobileFeature,
            price: Number(this.state.price),
            numberOfMaxUser: Number(this.state.numberOfMaxUser),
            numberOfMaxRetailer: Number(this.state.numberOfMaxRetailer),
        };

        e.preventDefault();
        const isValid = this.validate();

        if (isValid) {
            this.setState(this.state.error);
            if (this.state.pageType === "add") {
                this.props.package_create(data);
            } else {
                this.props.package_update(data, this.props.match.params.item_id);
            }
        };

    }

    componentDidMount() {
        let id = this.props.match.params.item_id;
        if (id) {
            this.props.package_get(id);
            this.setState({
                pageType: "update"
            });
        }

        this.setState({
            mobileFeatureList: helperFunc.mobileFeatureList(),
            webFeatureList: helperFunc.webFeatureList()
        });

    };

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }

            this.state.serverError.forEach((el) => errorRender(nextProps, el));
            this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));
            let req = {};

            if (nextProps.package_create_res !== undefined && nextProps.package_create_res.success === false && this.props !== nextProps) {
                nextProps.package_create_res.errors.map((item, index) =>
                    toastr.error(Translate.translate(req, item.msg), 'Error', {
                        displayDuration: 5000
                    })
                );
                nextProps.package_create_res.success = null;
            };

            if (nextProps.package_create_res !== undefined && nextProps.package_create_res.success) {
                if (nextProps.package_create_res.success === true) {
                    toastr.success('Package Has been Added Successfully', 'success');
                    nextProps.package_create_res.success = null;

                    return <Redirect to={routehelp.package} />
                }
            };

            if (nextProps.package_get_res && nextProps.package_get_res.success === true) {
                nextProps.package_get_res.success = null;

                this.setState({
                    name: nextProps.package_get_res !== undefined && nextProps.package_get_res.item
                        ? nextProps.package_get_res.item.name
                        : '',
                    description: nextProps.package_get_res !== undefined && nextProps.package_get_res.item && nextProps.package_get_res.item.description
                        ? nextProps.package_get_res.item.description
                        : '',
                    packageImg:
                        nextProps.package_get_res !== undefined && nextProps.package_get_res.item && nextProps.package_get_res.item.packageImg
                            ? nextProps.package_get_res.item.packageImg
                            : {},
                    numberOfMaxUser: nextProps.package_get_res !== undefined && nextProps.package_get_res.item && nextProps.package_get_res.item.numberOfMaxUser
                        ? nextProps.package_get_res.item.numberOfMaxUser
                        : '',
                    price: nextProps.package_get_res !== undefined && nextProps.package_get_res.item && nextProps.package_get_res.item.price
                        ? nextProps.package_get_res.item.price
                        : '',
                    numberOfMaxRetailer: nextProps.package_get_res !== undefined && nextProps.package_get_res.item && nextProps.package_get_res.item.numberOfMaxRetailer
                        ? nextProps.package_get_res.item.numberOfMaxRetailer
                        : '',
                    isAdvertiseDisplay:
                        nextProps.package_get_res !== undefined && nextProps.package_get_res.item
                            ? nextProps.package_get_res.item.isAdvertiseDisplay
                            : true,
                    isCodeEditable:
                        nextProps.package_get_res !== undefined && nextProps.package_get_res.item
                            ? nextProps.package_get_res.item.isCodeEditable
                            : true,
                    reportFormat:
                        nextProps.package_get_res !== undefined && nextProps.package_get_res.item
                            ? nextProps.package_get_res.item.reportFormat
                            : 'default',
                    isActive:
                        nextProps.package_get_res !== undefined && nextProps.package_get_res.item
                            ? nextProps.package_get_res.item.isActive
                            : true,
                    stockUpdateOn:
                        nextProps.package_get_res !== undefined && nextProps.package_get_res.item
                            ? nextProps.package_get_res.item.stockUpdateOn
                            : 'beforeConfirm',
                    notificationType:
                        nextProps.package_get_res !== undefined && nextProps.package_get_res.item
                            ? nextProps.package_get_res.item.notificationType
                            : 'default',
                    userAuthentication:
                        nextProps.package_get_res !== undefined && nextProps.package_get_res.item
                            ? nextProps.package_get_res.item.userAuthentication
                            : 'one-way',
                    commissionSelectedOn:
                        nextProps.package_get_res !== undefined && nextProps.package_get_res.item
                            ? nextProps.package_get_res.item.commissionSelectedOn
                            : 'user',
                    webFeature:
                        nextProps.package_get_res !== undefined && nextProps.package_get_res.item
                            ? nextProps.package_get_res.item.webFeature
                            : this.state.webFeature,
                    mobileFeature:
                        nextProps.package_get_res !== undefined && nextProps.package_get_res.item
                            ? nextProps.package_get_res.item.mobileFeature
                            : this.state.mobileFeature
                });
            };

            if (nextProps.package_update_res !== undefined && nextProps.package_update_res.success === false && this.props !== nextProps) {
                nextProps.package_update_res.errors.map((item, index) =>
                    toastr.error(Translate.translate({}, item.msg), 'Error', {
                        displayDuration: 5000
                    })
                );
                nextProps.package_update_res.success = null;
            };

        }
        return true;

    }

    render() {
        const { uploadPercentage } = this.state;
        return (
            <div className="app header-fixed left-sidebar-fixed scrollbarcustom left-sidebar-hidden">
                <Header />
                <div className="app-body">
                    <main className="main-content ml-0">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 mt-4">
                                    <div className="card card-shadow mb-4">
                                        <div className="card-header">
                                            <div className="card-title">
                                                {this.state.pageType === "add" ? "Add Package" : "Update Package"}
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield handleChange={this.handleChange} maxlength="200" type="text" name="name" value={this.state.name} className="form-control" id="name" ariadescribedby="emailHelp" placeholder="Name" />
                                                    <small style={{ fontSize: 12, color: 'red' }}>
                                                        {' '}
                                                        {this.state.nameError}{' '}
                                                    </small>
                                                </div>
                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield handleChange={this.handleChange} maxlength="100" type="text" name="price" value={this.state.price} className="form-control" id="maxuser" placeholder="Price for Package" />
                                                    <small style={{ fontSize: 12, color: 'red' }}>
                                                        {' '}
                                                        {this.state.priceError}{' '}
                                                    </small>
                                                </div>
                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield handleChange={this.handleChange} maxlength="10" type="text" name="numberOfMaxUser" value={this.state.numberOfMaxUser} className="form-control" id="maxuser" placeholder="Max Users" />
                                                    <small style={{ fontSize: 12, color: 'red' }}>
                                                        {' '}
                                                        {this.state.numberOfMaxUserError}{' '}
                                                    </small>
                                                </div>
                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield handleChange={this.handleChange} maxlength="10" type="text" name="numberOfMaxRetailer" value={this.state.numberOfMaxRetailer} className="form-control" id="retailerMaxUser" placeholder="Max Users for Retailers" />
                                                    <small style={{ fontSize: 12, color: 'red' }}>
                                                        {' '}
                                                        {this.state.numberOfMaxRetailerError}{' '}
                                                    </small>
                                                </div>
                                                <div className="form-group required col-12 col-lg-4">
                                                    <label htmlFor="exampleInputAddress" className="control-label">Description</label>
                                                    <textarea onChange={this.onChange} maxlength="400" name="description" value={this.state.description} className="form-control" id="password" rows="2"></textarea>
                                                    <small style={{ fontSize: 12, color: 'red' }}>
                                                        {' '}
                                                        {this.state.descriptionError}{' '}
                                                    </small>
                                                </div>
                                                <div className="form-group required col-12 col-lg-4">
                                                    <label htmlFor="exampleInputtype1" className="control-label">Report Format</label>
                                                    <select onChange={this.onChange} value={this.state.reportFormat} name="reportFormat" id="reportFormat" className="form-control" required>
                                                        {this.state.reportList.map((el, i) => {
                                                            return <option key={i} value={el.value}>{el.label}</option>
                                                        })}
                                                    </select>
                                                </div>

                                                <div className="form-group required col-12 col-lg-4">
                                                    <label htmlFor="exampleInputtype2" className="control-label">Stock Update on?</label>
                                                    <select onChange={this.onChange} value={this.state.stockUpdateOn} name="stockUpdateOn" id="stockUpdateOn" className="form-control" required>
                                                        {this.state.stockUpdateOnList.map((el, i) => {
                                                            return <option key={i} value={el.value}>{el.label}</option>
                                                        })}
                                                    </select>
                                                </div>


                                                <div className="form-group required col-12 col-lg-4">
                                                    <label htmlFor="exampleInputtype2" className="control-label">Notification Type?</label>
                                                    <select onChange={this.onChange} value={this.state.notificationType} name="notificationType" id="notificationType" className="form-control" required>
                                                        {this.state.notificationTypeList.map((el, i) => {
                                                            return <option key={i} value={el.value}>{el.label}</option>
                                                        })}
                                                    </select>
                                                </div>

                                                <div className="form-group required col-12 col-lg-4">
                                                    <label htmlFor="exampleInputtype3" className="control-label">User Authentication Type?</label>
                                                    <select onChange={this.onChange} value={this.state.userAuthentication} name="userAuthentication" id="userAuthentication" className="form-control" required>
                                                        {this.state.userAuthenticationList.map((el, i) => {
                                                            return <option key={i} value={el.value}>{el.label}</option>
                                                        })}
                                                    </select>
                                                </div>

                                                <div className="form-group required col-12 col-lg-4">
                                                    <label htmlFor="commissionDetails" className="control-label">Commission selected on?</label>
                                                    <select onChange={this.onChange} value={this.state.commissionSelectedOn} name="commissionSelectedOn" id="commissionSelectedOn" className="form-control" required>
                                                        {this.state.commissionDependencyList.map((el, i) => {
                                                            return <option key={i} value={el.value}>{el.label}</option>
                                                        })}
                                                    </select>
                                                </div>


                                                <div className="col-md-4">
                                                    {this.state.packageImg &&
                                                        this.state.packageImg.medium !== undefined ? (
                                                        <div className="upload-div">
                                                            <label
                                                                htmlFor="update-packageImg"
                                                                className="control-label"
                                                            >
                                                                packageImg
                                                            </label>
                                                            <div className="document-media">
                                                                <div
                                                                    onClick={() => {
                                                                        this.setState({ packageImg: {} });
                                                                    }}
                                                                    className="close-btn"
                                                                >
                                                                    {" "}
                                                                    <i className="fa fa-times"></i>
                                                                </div>
                                                                <img
                                                                    alt="img"
                                                                    className="img"
                                                                    src={uploadUrl + this.state.packageImg.medium}
                                                                />
                                                                <div className="media-content">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="form-group upload-div">
                                                            <label htmlFor="packageImg" className="control-label">
                                                                Package Image
                                                            </label>
                                                            <div className="upload-img">
                                                                <input
                                                                    maxLength="5"
                                                                    onChange={this.uploadFile}
                                                                    // value={this.state.code}
                                                                    name="packageImg"
                                                                    type="file"
                                                                    className="form-control"
                                                                    accept='.jpeg, .jpg, .png'
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="progressbar-image mt-3">
                                                        {uploadPercentage > 0 && (
                                                            <ProgressBar
                                                                now={uploadPercentage}
                                                                active
                                                                label={`${uploadPercentage}%`}
                                                            />
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group col-12 col-lg-4">
                                                    <Inputfield handleChange={this.checkBoxChange} type="checkbox" name="isAdvertiseDisplay" checked={this.state.isAdvertiseDisplay} placeholder="Advertise display on mobile application ? " className="ml-2" />
                                                </div>

                                                <div className="form-group col-12 col-lg-4">
                                                    <Inputfield handleChange={this.checkBoxChange} type="checkbox" name="isCodeEditable" checked={this.state.isCodeEditable} placeholder="Is user code is editable ?" className="ml-2" />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-shadow mb-4">
                                        <div className="card-header">
                                            <div className="card-title">
                                                Package Includes
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className='row ml-0 mb-3'>
                                                <label><b>Mobile Features</b></label>
                                            </div>
                                            <div className="row">
                                                {
                                                    this.state.mobileFeatureList && this.state.mobileFeatureList.length > 0 ? this.state.mobileFeatureList.map((ele, index) =>
                                                        <div className="form-group required col-12 col-lg-4" key={index}>
                                                            <label htmlFor={ele.label} className='label-text' id={ele.label}>{ele.label}</label>&nbsp;&nbsp;
                                                            <label className="switch">
                                                                <input type="checkbox" onChange={(e) => this.featureChange(`mobileFeature.${ele.name}`, e)} name={ele.name} checked={this.state.mobileFeature[ele.checked]} />
                                                                <span className="slider"></span>
                                                            </label>
                                                        </div>
                                                    ) : null
                                                }
                                            </div>
                                            <div className='row ml-0 mb-3'>
                                                <label><b>Web Features</b></label>
                                            </div>
                                            <div className="row">
                                                {
                                                    this.state.webFeatureList && this.state.webFeatureList.length > 0 ?
                                                        this.state.webFeatureList.map((ele, index) =>
                                                            <div className="form-group required col-12 col-lg-4" key={index}>
                                                                <label htmlFor={ele.label} className='label-text' id={ele.label}>{ele.label}</label>&nbsp;&nbsp;
                                                                <label className="switch">
                                                                    <input type="checkbox" onChange={(e) => this.featureChange(`webFeature.${ele.name}`, e)} name={ele.name} checked={this.state.webFeature[ele.checked]} />
                                                                    <span className="slider"></span>
                                                                </label>
                                                            </div>
                                                        ) : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 mb-4 offset-md-3 text-center">
                                    <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" name="Save" />
                                    <Links to={routehelp.package} className="btn btn-outline-dark" name="Cancel" />
                                </div>
                            </div>

                        </div>
                    </main>
                </div>
                <Footer className="ml-0" />
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        package_create_res: state.package_create_res,
        package_get_res: state.package_get_res,
        package_update_res: state.package_update_res
    };
}

const mapDispatchToProps = dispatch => ({
    package_update: (data, id) => dispatch(package_update(data, id)),
    package_create: data => dispatch(package_create(data)),
    package_get: data => dispatch(package_get(data)),
});

PackageForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(PackageForm);

export default PackageForm;