import React, { Component } from 'react';
import { errorRender, successRedriect, validate, inputCheck, isPermission } from '../../helpers/functions';
import { connect } from 'react-redux';
import Inputfield from '../../components/inputfields';
import Buttons from '../../components/buttons';
import Links from '../../components/links';
import { routehelp } from '../../Routehelper';
import { profilesingle, changeEmailConfig } from '../../actions';

class Email extends Component {

  state = {
    host: "",
    port: "",
    email: "",
    password: "",
    username: "",
    setFields: [
      { name: "Host", stateName: "host", type: "text" },
      { name: "Port", stateName: "port", type: "text" },
      { name: "Email", stateName: "email", type: "text" },
      { name: "username", stateName: "username", type: "text" },
      { name: "Password", stateName: "password", type: "text" }
    ],
    error: {
      hostError: "",
      emailError: "",
      passwordError: "",
      portError: "",
      usernameError: ""
    },
    isLoading: false
  }

  componentDidMount() {
    let data = {};
    this.props.profilesingle(data);
  }

  shouldComponentUpdate(nextProps) {

    if (nextProps !== this.props) {

      if (this.state.isLoading) {
        this.setState({ isLoading: false });
      }

      successRedriect(nextProps, "changeEmailConfig_res", "Email Configuration", this.props, "maindashboard", "Updated");

      if (nextProps.profile_single && nextProps.profile_single.success) {
        if (nextProps.profile_single.item && nextProps.profile_single.item.emailConfig) {
          let data = nextProps.profile_single.item.emailConfig;
          this.setState(data);
        }
      };

      // if (nextProps.changeEmailConfig_res && nextProps.changeEmailConfig_res.success === true) {
      //   console.log(nextProps.changeEmailConfig_res);
      // }

      errorRender(nextProps, "changeEmailConfig_res");
    }
    return true;
  }

  handleChange = (name, e) => {
    // console.log(name, e.target.value);
    let isValid = inputCheck(name, e.target.value);

    if (isValid) {
      this.setState({ [name]: e.target.value });
    }

  };

  onsubmit = (e) => {
    let validationFields = [
      { name: 'username', label: "Username", value: this.state.username, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 100, type: "text", message: "", errName: "usernameError" },
      { name: 'host', label: "Host", value: this.state.host, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 100, type: "text", message: "", errName: "hostError" },
      { name: 'port', label: "Port", value: this.state.port, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 100, type: "text", message: "", errName: "portError" },
      { name: 'email', label: "Email", value: this.state.email, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 100, type: "text", message: "", errName: "emailError" },
      { name: 'password', label: "Password", value: this.state.password, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 100, type: "text", message: "", errName: "passwordError" }
    ];

    let data = {
      host: this.state.host,
      port: this.state.port,
      password: this.state.password,
      email: this.state.email,
      username: this.state.username
    };

    e.preventDefault();

    let { newArr, valid } = validate(validationFields);

    newArr.forEach((el) => this.setState({ [el.errName]: el.message }));

    if (valid) {
      // console.log("valid");
      this.setState({ isLoading: true });
      this.props.changeEmailConfig(data);
      validationFields = null;
      newArr = null;
    }

  }

  render() {
    const { isLoading } = this.state;
    return (
      <main className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 mt-4">
              <div className="card card-shadow mb-4">
                <div className="card-header">
                  <div className="card-title" id="update_profile">
                    Email Configuration
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="form-group required col-12 col-lg-4">
                      <Inputfield errMessage={this.state.hostError} handleChange={this.handleChange} type="text" name="host" value={this.state.host} className="form-control" id="host" ariadescribedby="hostHelp" placeholder="Host" />
                    </div>
                    <div className="form-group required col-12 col-lg-4">
                      <Inputfield errMessage={this.state.portError} handleChange={this.handleChange} type="text" name="port" value={this.state.port} className="form-control" id="port" placeholder="Port" />
                    </div>
                    <div className="form-group required col-12 col-lg-4">
                      <Inputfield errMessage={this.state.usernameError} handleChange={this.handleChange} type="text" name="username" value={this.state.username} className="form-control" id="username" placeholder="Username" />
                    </div>
                    <div className="form-group required col-12 col-lg-4">
                      <Inputfield errMessage={this.state.passwordError} handleChange={this.handleChange} type="password" name="password" value={this.state.password} className="form-control" id="password" placeholder="Password" />
                    </div>
                    <div className="form-group required col-12 col-lg-4">
                      <Inputfield errMessage={this.state.emailError} handleChange={this.handleChange} type="text" name="email" value={this.state.email} className="form-control" id="email" aria-describedby="emailHelp" placeholder="Email" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-4 offset-md-3 text-center">
              {isPermission("emailConfig", "update") ? 
                <Buttons onclick={this.onsubmit} type="submit" disable={isLoading} loading={isLoading} className="btn btn-primary mr-1" name="Save" />
              : null}
              <Links to={routehelp.maindashboard} className="btn btn-outline-dark" name="Cancel" />
            </div>
          </div>
        </div>
      </main>
    )
  }

}

function mapStateToProps(state) {
  return {
    profile_single: state.profile_single,
    changeEmailConfig_res: state.changeEmailConfig_res
  }
};

const mapDispatchToProps = dispatch => ({
  changeEmailConfig: data => dispatch(changeEmailConfig(data)),
  profilesingle: data => dispatch(profilesingle(data))
});

Email = connect(
  mapStateToProps,
  mapDispatchToProps
)(Email);

export default Email;