import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  let token = localStorage.getItem('token');
  const isLoggedIn = token === null ? false : true;

  return (
    <Route {...rest} render={props => isLoggedIn ? <Component {...props} /> : <Redirect to={{ pathname: '/Login', state: { from: props.location } }} />
    }
    />
  );
};

export default PrivateRoute;
