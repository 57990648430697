import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { userHierarchyList, userHierarchyDelete } from '../../actions';
import { routehelp } from '../../Routehelper';
import { errorRender, successRender, commonDebounce, redirect, loaderUpdate } from '../../helpers/functions';
import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';

const Loader = lazy(() => import('../../components/loader'));
const Pagination = lazy(() => import('../../components/pagination/index'));
const DataTable = lazy(() => import('../../components/dataTalble'));
const Entries = lazy(() => import('../../components/entries/entires'));
const LinkWithItag = lazy(() => import('../../components/linkwithItag'));

let itemsPerPage = ITEM_PER_PAGES;
let currentPage = CURRENT_PAGE;
let sortBy = SORT_BY;
let sort = SORT;

class UserHierarchyList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            code: '',
            isLoading: false,
            deleteSuccess: "User Hierarchy Has been Deleted Successfully",
            serverErrors: ["userHierarchyDelete_res", "userHierarchyList_res"],
            tableHeaders: [
                {
                    name: 'name',
                    label: "Parent",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'child',
                    label: "Child",
                    keyName: "nameCode",
                    type: "loop",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'createdAt',
                    label: "CreatedAt",
                    type: "date",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'actions',
                    label: "Actions",
                    type: "button",
                    sortable: false,
                    isShow: true,
                    allactions: [{
                        name: "edit",
                        title: "Edit",
                        classNameI: "ti-pencil",
                        className: "btn btn-link text-pramiry action-btn mr-2 p-0",
                        function: redirect
                    }, {
                        name: "delete",
                        title: "Delete",
                        classNameI: "ti-trash",
                        className: "btn btn-link text-danger action-btn p-0",
                        function: this.deleteRow
                    }]
                }
            ]
        }

    }
    componentDidMount() {
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        this.loadResult();
    }

    componentWillUnmount() {
        currentPage = CURRENT_PAGE;
        itemsPerPage = ITEM_PER_PAGES;
    }

    loadResult = () => {
        let data = {}
        data.filter = { name: this.state.name ? this.state.name : undefined }
        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        data.skip = (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.userHierarchyList(data);
        this.setState({ isLoading: true });
    }

    resetfilter = () => {
        let data = {}
        data.filter = { name: '', code: '' }
        this.props.uommanagementlist(data);
    }

    sortBy = (key) => {
        sortBy = key;
        if (sort === 'desc') {
            sort = 'asc';
        } else {
            sort = 'desc';
        }
        this.loadResult();
    }

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    }

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    }

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    }

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    deleteRow = (e, item) => {
        if (window.confirm(`Are you sure you wish to delete "${item.parent.name}" ?`)) {
            this.props.userHierarchyDelete(item._id);
            e.preventDefault();
        }
    }

    onChangeforDebounce = ({ target: { value } }) => {
        if (!/^\s/.test(value)) {
            this.setState({ name: value }, () => this.callforDebounce(value))
        }
    }

    callforDebounce = () => this.loadResult();

    shouldComponentUpdate(nextProps) {
        // console.log(nextProps);
        if (nextProps !== this.props) {
            // console.log(this.state.isLoading)
            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "userHierarchyList_res") });
            }
            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            successRender(nextProps, "userHierarchyDelete_res", this.loadResult, this.state.deleteSuccess);
        }
        return true;
    }

    render() {
        const { userHierarchyList_res } = this.props;
        const totResult = userHierarchyList_res ? userHierarchyList_res.count : 0;

        let updatedRes = userHierarchyList_res;

        if (updatedRes && updatedRes.list && updatedRes.list.length > 0) {
            updatedRes.list.forEach((ele) => {
                ele.name = `${ele.parent.name} (${ele.parent.code})`
                ele.child.forEach((elev) => {
                    elev.nameCode = `${elev.name}  (${elev.code})`
                });
            });
        };

        // console.log(updatedRes);
        
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        User Hierarchy
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">

                                        <div className="row">

                                            <div className="col-sm-12 col-md-6">
                                                {totResult === 0 ? null :
                                                    <Entries
                                                        itemsPerPage={itemsPerPage}
                                                        handleChangeiItemsPerPage={
                                                            this.handleChangeiItemsPerPage
                                                        }
                                                    />
                                                }
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <div className="add-btn-icon mb-4">
                                                    {/* <SearchInput onChangeforDebounce={this.onChangeforDebounce} value={name} /> */}
                                                    <LinkWithItag to={routehelp.userHierarchyAdd} className="btn float-left  btn-primary mr-2" role="button" classNameI="fa fa-plus" title="Add User Hierarchy" />
                                                </div>
                                            </div>

                                        </div>

                                        {
                                            totResult === undefined ?
                                                <p className="text-center">No Data Found</p> :
                                                this.state.isLoading ? <Loader /> : updatedRes !== undefined && updatedRes.count === 0 ?
                                                    <p className="text-center">No Data Found</p>
                                                    :
                                                    updatedRes && updatedRes.count > 0 ?
                                                        <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy}
                                                            sortByvalue={sortBy} sortvalue={sort}
                                                            data={updatedRes} props={this.props} />
                                                        : <p className="text-center">Something Went Wrong</p>
                                        }

                                        {
                                            totResult !== undefined ? updatedRes !== undefined && updatedRes.count === 0 ? "" :
                                                <Pagination
                                                    handleChangeiItemsPerPage={
                                                        this.handleChangeiItemsPerPage
                                                    }
                                                    handlePage={this.handlePage}
                                                    handleNext={this.handleNext}
                                                    handlePrevious={this.handlePrevious}
                                                    currentPage={currentPage}
                                                    itemsPerPage={itemsPerPage}
                                                    totResult={totResult}
                                                    key={currentPage}
                                                />
                                                : ""
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        userHierarchyList_res: state.userHierarchyList_res,
        userHierarchyDelete_res: state.userHierarchyDelete_res
    };
}

const mapDispatchToProps = dispatch => ({
    userHierarchyList: data => dispatch(userHierarchyList(data)),
    userHierarchyDelete: item_id => dispatch(userHierarchyDelete(item_id))
});

UserHierarchyList = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserHierarchyList);

export default UserHierarchyList;