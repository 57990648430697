import React, { Component } from 'react';
import { routehelp } from '../../Routehelper';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { requestotp } from '../../actions';
import Inputfield from '../../components/inputfields';
import Button from '../../components/buttons';
// import Links from '../../components/links';
import LinkwithImg from '../../components/linkwithimg';
import Translate from '../../libs/translation';
import Logo from '../../logo.svg';
import toastr from 'reactjs-toastr';
import 'reactjs-toastr/lib/toast.css';

class ForgotPassword extends Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.onsubmit = this.onsubmit.bind(this);
        this.state = {
            email: ''
        }
    }

    handleChange(name, e) {
        this.setState({ [name]: e.target.value });
    }

    onsubmit(e) {
        let data = {
            username: this.state.email,
        }
        e.preventDefault();
        this.props.requestotp(data);
    }

    shouldComponentUpdate(nextProps) {
        let req = {};
        if (
            nextProps.otp_req_res !== undefined &&
            nextProps.otp_req_res.success === false

        ) {
            nextProps.otp_req_res.errors.map((item, index) =>
                toastr.error(Translate.translate(req, item.code), 'Error', {
                    displayDuration: 5000
                })
            );
            nextProps.otp_req_res.success = null;
        }
        return true;
    }

    render() {

        let { otp_req_res } = this.props;
        if (otp_req_res !== undefined) {
            if (otp_req_res.success === true) {
                toastr.success('Otp send to your email', 'success');
                otp_req_res.success = null;
                let username = this.state.email;
                localStorage.setItem('user', username);
                return <Redirect to={routehelp.otp} />
            }
        }

        return (
            <div className="container">
                <form onSubmit={this.onsubmit} className="form-signin">
                    <LinkwithImg to={routehelp.login} className="brand text-center logo-1" src={Logo} alt="" />
                    <h2 className="form-signin-heading">Forgotten Password ? </h2>
                    <span className="text-muted">Enter your email to reset your password:</span>
                    <div className="form-group mt-2">
                        <label htmlFor="inputEmail" className="sr-only">Email address</label>
                        <Inputfield type="text" id="email" name="email" value={this.state.email} handleChange={this.handleChange} className="form-control" placeholder="Email address Or Phone" required={true} />
                    </div>
                    <Button className="btn btn-lg btn-primary btn-block" type="submit" name="Request Password" />
                    {/* <div className="mt-4">
                        <span>
                            Don't have an account yet ?
                    </span>
                        <Links to={routehelp.signup} className="text-primary" name="Sign Up" />
                    </div> */}
                </form>
            </div>
        )

    }
}

function mapStateToProps(state) {
    return { otp_req_res: state.otp_req_res };
}

const mapDispatchToProps = dispatch => ({
    requestotp: data => dispatch(requestotp(data))
});

ForgotPassword = connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPassword);

export default ForgotPassword;