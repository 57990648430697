import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class LinkWithSpanAndI extends Component {
    render() {
        let { to, className, classi, name, text, classSpam } = this.props;
        return (
            <div>
                <li className="sidebar-item">
                    <NavLink to={to} className={className}>
                        <i className={classi} aria-hidden="true"></i>
                        <span className={classSpam}>{name}</span>
                        {text}
                    </NavLink>
                </li>
            </div>
        )
    }
}

export default LinkWithSpanAndI;