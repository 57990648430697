import React, { Component } from 'react';
import { routehelp } from '../../Routehelper';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { validateotp } from '../../actions';
import Inputfield from '../../components/inputfields';
import Button from '../../components/buttons';
import LinkwithImg from '../../components/linkwithimg';
import Translate from '../../libs/translation';
import Logo from '../../logo.svg';
import toastr from 'reactjs-toastr';
import 'reactjs-toastr/lib/toast.css';

class Otp extends Component {

    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.onsubmit = this.onsubmit.bind(this);
        this.state = {
            otp: '',
            password: ''
        }
    }

    handleChange(name, e) {
        this.setState({ [name]: e.target.value });
    }

    onsubmit(e) {
        let data = {
            otp: this.state.otp,
            password: this.state.password,
            username: localStorage.getItem('user')
        }
        e.preventDefault();
        this.props.validateotp(data);
    }

    shouldComponentUpdate(nextProps) {
        let req = {};
        if (
            nextProps.otp_val_res !== undefined &&
            nextProps.otp_val_res.success === false

        ) {
            nextProps.otp_val_res.errors.map((item, index) =>
                toastr.error(Translate.translate(req, item.code), 'Error', {
                    displayDuration: 5000
                })
            );
            nextProps.otp_val_res.success = null;
        }
        return true;
    }

    render() {

        let { otp_val_res } = this.props;
        if (otp_val_res !== undefined) {
            if (otp_val_res.success === true) {
                toastr.success('Password has been changed Please Log In', 'success');
                otp_val_res.success = null;
                localStorage.clear();
                return <Redirect to={routehelp.login} />
            }
        }

        return (
            <div className="container">
                <form onSubmit={this.onsubmit} className="form-signin">
                    <LinkwithImg to={routehelp.login} className="brand text-center logo-1" src={Logo} alt="" />
                    <h2 className="form-signin-heading">Forgotten Password ? </h2>
                    <span className="text-muted">Enter Otp and your email to reset your password:</span>
                    <div className="form-group mt-2">
                        <label htmlFor="inputEmail" className="sr-only">Otp</label>
                        <Inputfield type="text" id="otp" name="otp" value={this.state.otp} handleChange={this.handleChange} className="form-control" placeholder="Otp" required={true} />
                    </div >
                    <div className="form-group mt-2">
                        <label htmlFor="inputEmail" className="sr-only">Email address</label>
                        <Inputfield type="password" id="password" name="password" value={this.state.password} handleChange={this.handleChange} className="form-control" placeholder="New password" required={true} />
                    </div>
                    <Button className="btn btn-lg btn-primary btn-block" type="submit" name="Submit" />
                </form>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return { otp_val_res: state.otp_val_res };
}

const mapDispatchToProps = dispatch => ({
    validateotp: data => dispatch(validateotp(data))
});

Otp = connect(
    mapStateToProps,
    mapDispatchToProps
)(Otp);

export default Otp;