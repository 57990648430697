import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import Inputfield from '../../components/inputfields';
import Buttons from '../../components/buttons';
import Links from '../../components/links';
import { routehelp } from '../../Routehelper';
import { expenseCatagoryCreate, singleExpenseCategory, updateExpenseCategory } from '../../actions';
import { errorRender, getValue, successRedriect, validate, inputCheck } from '../../helpers/functions';

const CardTitle = lazy(() => import('../../components/cardTitle'));

class AllowanceCatagoryAdd extends Component {
    state = {
        pageType: 'add',
        itemId: '',
        name: '',
        code: '',
        serverError: ["singleExpenseCategoryData", "expenseCatagoryResponseData", "expenseCategoryUpdateRes"],
        forRedirect: [
            { name: "expenseCatagoryResponseData", pageName: 'Expense Category', pageUrl: 'allowanceCatagory', action: "Added" },
            { name: "expenseCategoryUpdateRes", pageName: 'Expense Category', pageUrl: 'allowanceCatagory', action: "Updated" }
        ],
        setFields: [
            { name: "name", stateName: "name", type: "text" },
            { name: "code", stateName: "code", type: "text" }
        ],
        nameError: '',
        codeError: '',
        isLoading: false
    };

    componentDidMount() {
        let id = this.props.match.params.item_id;
        if (id) {
            this.setState({ pageType: "update", itemId: id })
            this.props.singleExpenseCategory(id);
        }
    }

    handleChange = (name, e) => {
        let isValid = inputCheck(name, e.target.value);

        if (isValid) {
            this.setState({ [name]: e.target.value });
        }

        // const re = /([^\s]+)/;
        // if (e.target.value === '' || re.test(e.target.value)) {
        //     this.setState({ [name]: e.target.value });
        // }
    }

    onsubmit = (e) => {
        let validationFields = [
            { name: 'name', label: "Name", value: this.state.name, hasError: false, isRequired: true, isNumber: false, onlyChar: true, maxLength: 50, type: "text", message: "", errName: "nameError" },
            { name: 'code', label: "Code", value: this.state.code, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 50, type: "text", message: "", errName: "codeError" }
        ]
        let data = {
            name: this.state.name,
            code: this.state.code,
        }
        e.preventDefault();
        let { newArr, valid } = validate(validationFields);
        newArr.map((el) => this.setState({ [el.errName]: el.message }));
        if (valid) {
            this.setState({ isLoading: true });
            if (this.state.pageType === "update") {
                this.props.updateExpenseCategory(data, this.state.itemId);
            } else {
                this.props.expenseCatagoryCreate(data);
            }
            validationFields = null;
            newArr = null;
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }
            this.state.serverError.forEach((el) => errorRender(nextProps, el));
            this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));
            if (nextProps.singleExpenseCategoryData && nextProps.singleExpenseCategoryData.success) {
                if (nextProps.singleExpenseCategoryData.item) {
                    this.state.setFields.forEach((value) => {
                        if (value.type === "text") {
                            this.setState({ [value.stateName]: getValue(nextProps.singleExpenseCategoryData.item, value.name) })
                        }
                    })
                }
                nextProps.singleExpenseCategoryData.success = null;
            }
        }
        return true;
    }

    render() {
        const { pageType, isLoading } = this.state;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <CardTitle title={pageType === "update" ? "Update Expense Category" : "Add Expense Category"} />
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="form-group required col-12 col-lg-6">
                                            <Inputfield errMessage={this.state.nameError} handleChange={this.handleChange} maxlength="100" type="text" name="name" value={this.state.name} className="form-control" id="name" ariadescribedby="codeHelp" placeholder="Name" />
                                        </div>
                                        <div className="form-group required col-12 col-lg-6">
                                            <Inputfield errMessage={this.state.codeError} handleChange={this.handleChange} type="text" maxlength="10" name="code" value={this.state.code} className="form-control" id="code" aria-describedby="codeHelp" placeholder="Code" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" disable={isLoading} loading={isLoading} name={pageType === "update" ? "Update" : "Save"} />
                            <Links to={routehelp.allowanceCatagory} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        expenseCatagoryResponseData: state.expenseCatagoryResponseData,
        singleExpenseCategoryData: state.singleExpenseCategoryData,
        expenseCategoryUpdateRes: state.expenseCategoryUpdateRes
    };
}


const mapDispatchToProps = dispatch => ({
    expenseCatagoryCreate: data => dispatch(expenseCatagoryCreate(data)),
    singleExpenseCategory: data => dispatch(singleExpenseCategory(data)),
    updateExpenseCategory: (data, id) => dispatch(updateExpenseCategory(data, id))
});

AllowanceCatagoryAdd = connect(
    mapStateToProps,
    mapDispatchToProps
)(AllowanceCatagoryAdd);

export default AllowanceCatagoryAdd;