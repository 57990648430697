/*eslint-disable */
import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { routehelp } from '../../Routehelper';
import { zonemanagecreate, orderSingle, orderUpdate, productmanagementlist, uom_product_search } from '../../actions';
import { errorRender, successRedriect, getValue, checkForDigits } from '../../helpers/functions';
import toastr from 'reactjs-toastr';
import 'reactjs-toastr/lib/toast.css';
require('react-datetime');
var dateFormat = require('dateformat');

const Links = lazy(() => import('../../components/links'));
const Buttons = lazy(() => import('../../components/buttons'));
const Inputfield = lazy(() => import('../../components/inputfields'));
const CardTitle = lazy(() => import('../../components/cardTitle'));
const Loader = lazy(() => import('../../components/loader'));
const CustomerDropDown = lazy(() => import('../customerManagement/customerDropdown'));
const ButtonWithItag = lazy(() => import('../../components/buttonwithTagi'));
const ProductDropdown = lazy(() => import('../productManagement/productDropdown'));
class OrderForm extends Component {
    state = {
        createdAt: new Date(),
        pageType: 'add',
        itemId: '',
        name: '',
        code: '',
        description: '',
        setFields: [{ name: "createdAt", stateName: "createdAt", type: "text" }, { name: "companyOrderNum", stateName: "companyOrderNum", type: "text" }, { name: "brand.name", stateName: "brand", type: "text" }, { name: "brand._id", stateName: "brandId", type: "text" }, { name: "agent.name", stateName: "agent", type: "text" }, { name: "agent._id", stateName: "agentId", type: "text" }, { name: "customer", stateName: "customer", type: "select" }, { name: "indirectCustomer", stateName: 'indirectCustomer', type: 'select' }],
        serverErrors: ["zone_create_res", "order_update", "order_single"],
        forRedirect: [
            { name: "zone_create_res", pageName: 'Order', pageUrl: 'ordermanagement', action: "Added" },
            { name: "order_update", pageName: 'Order', pageUrl: 'ordermanagement', action: "Updated" }
        ],
        nameError: '',
        codeError: '',
        descriptionError: '',
        isLoading: false,
        getSingleLoader: false,
        product: null,
        orderDetails: {},
        totalAmt: 0,
        selectedUom: '',
        // measumentUnit: JSON.parse(localStorage.getItem('measumentUnit')),
        measumentUnit: [],
        dataNew: [],
        loadingShade: false,
        indexForUpdate: undefined
    }

    componentDidMount() {
        let id = this.props.match.params.item_id;
        if (id) {
            this.setState({ pageType: "update", itemId: id, getSingleLoader: true })
            this.props.orderSingle(id);
        }
    }

    componentWillUnmount() {
        this.setState({});
    }

    handleChange = (name, e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [name]: e.target.value });
        }
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    onProductChange = (e, t) => {
        // console.log(t);
        this.setState({ [e]: t, dataNew: [] }, () => {

            let isStockAvailable = true;
            let tempData = [];
            let data = t.items.attributes;
            let data2 = t.items.stockAttributes;

            if (data !== undefined && data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    for (let k = 0; k < data2.length; k++) {
                        if (data[i].name === data2[k].name) {
                            data[i].quantity = data2[k].quantity;
                        }
                    }
                    data[i].selectedQuantity = 0;
                    let selectedQuantity = 0;
                    let isSelected = false;

                    // data3.filter((previouseData) => {
                    //     if (previouseData.name == data[i].name) {
                    //         selectedQuantity = previouseData.shadeQuantity;
                    //     }
                    // });

                    if (this.state.selectedField && Object.keys(this.state.selectedField).length > 0) {
                        this.state.selectedField.attributes.forEach((previouseData) => {
                            if (previouseData.name === data[i].name) {
                                selectedQuantity = previouseData.shadeQuantity;
                                isSelected = true
                            }
                        });
                    }

                    data[i].selectedQuantity = selectedQuantity;
                    data[i].isSelected = isSelected;

                    if (data[i].quantity > 0) {
                        tempData.push({
                            code: data[i].code,
                            convFact: data[i].convFact,
                            name: data[i].name,
                            price: data[i].price,
                            quantity: data[i].quantity,
                            quantityUnit: data[i].quantityUnit,
                            id: parseFloat(data[i].name),
                            isQuantity: data[i].selectedQuantity,
                            shadeQuantity: data[i].selectedQuantity,
                            disabled: !isStockAvailable,
                            unitCodes: data[i].unitCodes,
                            isSelected: data[i].isSelected
                        })
                    } else {
                        tempData.push({
                            code: data[i].code,
                            convFact: data[i].convFact,
                            name: data[i].name,
                            price: data[i].price,
                            quantity: data[i].quantity,
                            quantityUnit: data[i].quantityUnit,
                            shadeQuantity: data[i].selectedQuantity,
                            isQuantity: 0,
                            id: parseFloat(data[i].name),
                            disabled: isStockAvailable,
                            unitCodes: data[i].unitCodes,
                            isSelected: data[i].isSelected
                        })
                    }
                }

                tempData.sort((a, b) => {
                    return a.id - b.id
                });

            }

            this.setState({ dataNew: tempData });

            // console.log(t.value);
            if (t.value) {
                this.props.uom_product_search({
                    product: t.value
                });
            }

        });
    }

    ondropdownChangeagent = (e, t) => {
        this.setState({ [e]: t });
    }

    deleteRow = (idx) => {
        this.state.orderDetails.products = this.state.orderDetails.products.filter((s, sidx) => idx !== sidx);
        this.forceUpdate();
    }

    onsubmit = (e) => {

        let totalQunatity = 0;
        let newTotal = 0;
        if (this.state.orderDetails.products.length > 0) {
            for (var i = 0; i < this.state.orderDetails.products.length; i++) {
                newTotal = newTotal + parseFloat(this.state.orderDetails.products[i].subTotal)
                for (var j = 0; j < this.state.orderDetails.products[i].attributes.length; j++) {
                    totalQunatity = totalQunatity + parseFloat(this.state.orderDetails.products[i].attributes[j].shadeQuantity);
                }
            }
            const data = {
                customer: this.state.orderDetails && this.state.orderDetails.customer && this.state.orderDetails.customer._id,
                brand: this.state.orderDetails && this.state.orderDetails.brand && this.state.orderDetails.brand._id,
                totalQuantity: totalQunatity + "",
                totalSaleAmount: newTotal.toFixed(2) + "",
                type: this.state.orderDetails.type,
                indirectcustomers: this.state.indirectCustomer && this.state.indirectCustomer.value ? this.state.indirectCustomer.value : undefined,
                tax: '0',
                totalAmount: newTotal.toFixed(2) + "",
                totalPaidAmount: newTotal.toFixed(2) + "",
                totalPandingAmount: '0',
                note: this.state.orderDetails && this.state.orderDetails.note,
                products: this.state.orderDetails.products,
                company: this.state.orderDetails.company,
                longitude: this.state.orderDetails.longitude,
                latitude: this.state.orderDetails.latitude,
                series: this.state.orderDetails.series,
                requestFrom: "B2B",
                scheme: this.state.orderDetails && this.state.orderDetails.scheme && this.state.orderDetails.scheme._id,
                transporter: this.state.orderDetails && this.state.orderDetails.transporter && this.state.orderDetails.transporter._id
            }
            e.preventDefault();
            this.setState({ isLoading: true });
            this.props.orderUpdate(data, this.state.orderDetails._id)
        } else {
            return toastr.error('Please Select alteast one shade', 'Error', {
                displayDuration: 5000
            });
        }
    }

    addDesign = () => {

        if (this.state.selectedUom.length === 0) {
            return toastr.error('Please Select UOM First', 'Error', {
                displayDuration: 5000
            })
        }

        if (this.state.product === null) {
            return toastr.error('Please Select Product', 'Error', {
                displayDuration: 5000
            })
        }

        let unit = {};
        this.state.dataNew = this.state.dataNew.filter((el) => el.isSelected);

        if (this.state.dataNew.length <= 0) {
            return toastr.error('Please Select atleast one shade.', 'Error', {
                displayDuration: 5000
            });
        }

        // console.log(this.state.selectedUom);
        // console.log(this.state.measumentUnit);

        Object.keys(this.state.measumentUnit).forEach((item) => {
            // console.log(item);
            // console.log(this.state.measumentUnit[item].value);
            if (JSON.stringify(this.state.measumentUnit[item].value) === JSON.stringify(this.state.selectedUom)) {
                unit = this.state.measumentUnit[item];
                unit.unitName = item;
            }
        });

        // console.log(unit);

        this.state.dataNew.forEach((el) => {
            el.unitCodes.forEach((newEl) => {
                if (newEl.unitName === unit.unitName) {
                    el.unitCode = unit
                    el.convFact = unit.convFact
                    el.code = unit.value
                    el.quantityUnit = unit.unitName
                }
            })
        });

        // console.log(this.state.dataNew);

        this.state.product.items.attributes = this.state.dataNew;
        let tempTot = 0;

        if (!this.state.product.items.price) {
            this.state.product.items.price = 0;
        }

        this.state.product.items.attributes.forEach((el) => {
            if (!el.convFact) {
                el.convFact = this.state.selectedUom
            }
            tempTot = tempTot + parseFloat(this.state.product.items.price) * parseFloat(el.convFact) * parseFloat(el.isQuantity)
        });

        const data = {
            measurementUnit: unit,
            product: this.state.product.items._id,
            productName: this.state.product.items.name,
            productErpCode: this.state.product.items.itemCode,
            productPrice: this.state.product.items.price,
            retailerPrice: this.state.product.items.retailerPrice,
            attributes: this.state.dataNew,
            shadeShow: this.state.selectedItems,
            subTotal: tempTot.toFixed(2)
        }
        typeof this.state.indexForUpdate === "number" ?
            this.state.orderDetails.products[this.state.indexForUpdate] = data
            : this.state.orderDetails.products.push(data);
        this.forceUpdate();
    }

    shouldComponentUpdate(nextProps) {

        if (nextProps !== this.props) {

            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }

            if (this.state.loadingShade) {
                if (nextProps.productmanagement_res && nextProps.productmanagement_res.list && nextProps.productmanagement_res.list.length > 0) {
                    nextProps.productmanagement_res.list.forEach((el) => {
                        if (el.name === this.state.selectedField.productName) {
                            const data = {
                                value: el._id, label: el.name, items: el
                            }
                            this.onProductChange('product', data)
                        }
                    })
                }
                this.setState({ loadingShade: false })
            }

            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));
            if (nextProps.order_single && nextProps.order_single.success) {
                this.setState({ getSingleLoader: false, orderDetails: nextProps.order_single.item });
                this.state.setFields.forEach((value) => {
                    if (value.type === "text") {
                        this.setState({ [value.stateName]: getValue(nextProps.order_single.item, value.name) })
                    } else if (value.type === "select" && getValue(nextProps.order_single.item, value.name)) {
                        this.setState({
                            [value.stateName]: {
                                value: getValue(nextProps.order_single.item, value.name)._id,
                                label: getValue(nextProps.order_single.item, value.name).name
                            }
                        })
                    } else {
                        this.setState({
                            [value.stateName]: null
                        })
                    }
                })
                nextProps.order_single.success = null;
            }

            // console.log(nextProps);
            if (nextProps.uom_product_search_res) {
                // console.log(nextProps.uom_product_search_res);
                if (nextProps.uom_product_search_res && nextProps.uom_product_search_res.list
                    && nextProps.uom_product_search_res.list.length > 0) {
                    let unitsData = {};
                    nextProps.uom_product_search_res.list.forEach((ele) => {
                        // console.log(ele);
                        unitsData[(ele.code).toLowerCase()] = {
                            label: ele.name,
                            value: ele.code,
                            convFact: ele.convFact || 1,
                            id: ele._id
                        }
                    });
                    // console.log(unitsData);
                    this.setState({
                        measumentUnit: unitsData
                    });
                    // console.log(this.state.measumentUnit);
                }
            }

        }
        return true;
    }

    checkShade = (e, idx) => {
        if (e.target.checked) {
            this.state.dataNew[idx].isQuantity = 1;
            this.state.dataNew[idx].shadeQuantity = 1;
        } else {
            this.state.dataNew[idx].isQuantity = 0;
            this.state.dataNew[idx].shadeQuantity = 0;
        }
        this.state.dataNew[idx].isSelected = e.target.checked;
        this.setState({ dataNew: this.state.dataNew });
    }


    chnageShadeQuantity = (idx, number) => {

        if (this.state.dataNew[idx].isQuantity === "") {
            this.state.dataNew[idx].isQuantity = 0;
        }

        if ((parseFloat(this.state.dataNew[idx].isQuantity) + number) > 0) {
            this.state.dataNew[idx].isQuantity = parseFloat(this.state.dataNew[idx].isQuantity) + number;
            this.state.dataNew[idx].shadeQuantity = this.state.dataNew[idx].isQuantity;
            this.state.dataNew[idx].isSelected = true
        } else {
            this.state.dataNew[idx].isQuantity = 0;
            this.state.dataNew[idx].shadeQuantity = this.state.dataNew[idx].isQuantity;
            this.state.dataNew[idx].isSelected = false
        }

        this.forceUpdate();
    }


    quantityChange = (e, item) => {

        let reg = /\A[+-]?\d+(?:\.\d{1,2})?\z/
        if (e.target.value === "0" || e.target.value === "") {
            item.isQuantity = e.target.value;
            item.shadeQuantity = item.isQuantity;
            item.isSelected = false
        }
        // console.log();

        if (e.target.value !== "") {
            if (!reg.test(e.target.value)) {
                // if (parseFloat(e.target.value) > 0) {
                e.target.value = e.target.value.replace(/^0+/, '')
                item.isQuantity = e.target.value.replace(/[^\d.-]/g, '');
                item.shadeQuantity = item.isQuantity;
                item.isSelected = true
                // }
            }
        }
        this.forceUpdate();

    }


    setUpdateFields = (fieldObj, idx) => {
        this.setState({ indexForUpdate: idx, selectedUom: fieldObj.measurementUnit.value, loadingShade: true, selectedField: fieldObj })
        let data = {};
        data.limit = 50000
        data.filter = {
            brand: this.state.orderDetails.brand._id,
            name: fieldObj.productName
        };
        this.props.productmanagementlist(data);
    }

    render() {
        const { pageType, isLoading, getSingleLoader } = this.state;
        this.state.totalAmt = 0;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                <CardTitle title={pageType === "update" ? "Update Order" : "Add Order"}/>
                                </div>
                                {
                                    getSingleLoader ? <Loader /> :
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="form-group col-12 col-lg-4">
                                                    <label className="control-label">Order No.</label><br />
                                                    <span>
                                                        {this.state.companyOrderNum}
                                                    </span>
                                                </div>
                                                <div className="form-group col-12 col-lg-4">
                                                    <label className="control-label">Order Date</label><br />
                                                    <span>
                                                        {dateFormat(new Date(this.state.createdAt), 'd mmm, yyyy')}
                                                    </span>
                                                </div>
                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield errMessage={this.state.brandError} disabled={true} handleChange={this.handleChange} type="text" name="brand" value={this.state.brand} className="form-control" id="brand" ariadescribedby="codeHelp" placeholder="Brand" />
                                                </div>
                                                <div className="form-group col-12 col-lg-4">
                                                    <Inputfield errMessage={this.state.agentError} disabled={true} handleChange={this.handleChange} type="text" name="agent" value={this.state.agent} className="form-control" id="agent" aria-describedby="codeHelp" placeholder="Agent" />
                                                </div>
                                                {/* <div className="form-group col-12 col-lg-4">
                                                <CustomerDropDown
                                                    disable={true}
                                                    ondropdownChange={(e) => this.ondropdownChangeagent("customer", e)}
                                                    placeholder="Search Customer"
                                                    filterBy={{ limit: 50000, filter: { agent: this.state.agentId } }}
                                                    value={this.state.customer}
                                                />
                                            </div> */}
                                                <div className="form-group col-12 col-lg-4">
                                                    <Inputfield disabled={true} placeholder="Customer" type="text" value={this.state.customer && this.state.customer.label} className="form-control" id="label" />
                                                </div>
                                                {this.state.indirectCustomer && this.state.indirectCustomer.label ?
                                                    <div className="form-group col-12 col-lg-4">
                                                        <Inputfield disabled={true} placeholder="Indirect Customer" id="indirectCustomer" type="text" value={this.state.indirectCustomer.label} className="form-control" />
                                                    </div>
                                                    : null}
                                            </div>
                                            <div className="flex-shrink-1 ml-3">
                                                <button
                                                    onClick={() => this.setState({ selectedUom: '', product: null, dataNew: [], selectedField: {}, indexForUpdate: undefined })}
                                                    data-toggle="modal"
                                                    data-target=".bd-example-modal-lg"
                                                    data-placement="top"
                                                    title="Order Details"
                                                    data-original-title="Click to Order Details" type="button" className="btn  btn-primary mr-2" ><i className="fa fa-plus "></i> </button>
                                            </div>
                                            <div className="col-sm-12">
                                                <table className="table table-bordered table-striped mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="bg-primary text-white">
                                                                Design No.
                                                            </th>
                                                            <th className="bg-primary text-white" scope="col">
                                                                Shade-Qty.
                                                            </th>
                                                            <th className="bg-primary text-white" scope="col">
                                                                Qty.
                                                            </th>
                                                            <th className="bg-primary text-white" scope="col">
                                                                Rate
                                                            </th>
                                                            <th className="bg-primary text-white" scope="col">
                                                                Unit
                                                            </th>
                                                            <th className="bg-primary text-white" scope="col">
                                                                Total
                                                            </th>
                                                            <th className="bg-primary text-white" scope="col">
                                                                Action
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.orderDetails &&
                                                            this.state.orderDetails.products &&
                                                            this.state.orderDetails.products.map((item, index) => {
                                                                // shadequan = 0;
                                                                // eslint-disable-next-line
                                                                this.state.totalAmt = this.state.totalAmt + parseFloat(item.subTotal)
                                                                this.state.totQty = 0;
                                                                item.attributes.map((attriItem) => {
                                                                    // eslint-disable-next-line
                                                                    return this.state.totQty = this.state.totQty + parseFloat(attriItem.shadeQuantity);
                                                                });
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{item.productName}</td>
                                                                        <td>
                                                                            {item.attributes.map((attri, attriIndex) => {
                                                                                return (
                                                                                    <p key={attriIndex}>
                                                                                        {attri.name} - {attri.shadeQuantity}
                                                                                        <br />
                                                                                    </p>
                                                                                );
                                                                            })}
                                                                        </td>
                                                                        <td>{this.state.totQty}</td>
                                                                        <td>{item.retailerPrice ? item.retailerPrice : item.productPrice}</td>
                                                                        <td>
                                                                            {item.measurementUnit &&
                                                                                item.measurementUnit.label}
                                                                        </td>
                                                                        <td>{item.subTotal}</td>
                                                                        <td className="text-center">
                                                                            <button
                                                                                onClick={() => this.setUpdateFields(item, index)}
                                                                                data-toggle="modal"
                                                                                data-target=".bd-example-modal-lg"
                                                                                data-placement="top"
                                                                                title="Order Details"
                                                                                data-original-title="Click to Order Details" type="button" className="btn btn-link text-pramiry action-btn mr-2 p-0" ><i className="ti-pencil"></i> </button>
                                                                            {/* <ButtonWithItag to={routehelp.SrAsmAssignmentUpdate + item._id} item_id={item._id} type="button" classNameI="ti-pencil" className="btn btn-link text-pramiry action-btn mr-2 p-0" /> */}
                                                                            <ButtonWithItag onclick={ev => { if (window.confirm(`Are you sure you wish to remove this ${item.productName} ?`)) this.deleteRow(index); }} type="button" classNameI="ti-trash" className="btn btn-link text-danger action-btn p-0" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" />
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="col-sm-4 ml-auto">
                                                <table className="table table-bordered table-striped">
                                                    <tbody>
                                                        <tr>
                                                            <td className="bg-primary text-white">
                                                                <strong>TOTAL</strong>
                                                            </td>
                                                            <td className="bg-primary text-white">
                                                                <strong>
                                                                    {this.state.totalAmt.toFixed(2)}
                                                                </strong>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" disable={isLoading || getSingleLoader} loading={isLoading} name={pageType === "update" ? "Update" : "Save"} />
                            <Links to={routehelp.ordermanagement} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>

                    <div
                        className="modal fade bd-example-modal-lg"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="myLargeModalLabel"
                        // style={{ display: "none" }}
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-dialog-centered">

                            {
                                this.state.loadingShade ? <Loader /> :
                                    <div className="modal-content">
                                        <div className="modal-header">
                                        <CardTitle title={this.state.indexForUpdate !== undefined ? "Update Design" : "Add Design"} type="h5"/>
                                            <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body h-fix">
                                            <div className="row">

                                                <div className="card-title col-6">
                                                    <ProductDropdown
                                                        isLabel={true}
                                                        ondropdownChange={(e) => this.onProductChange("product", e)}
                                                        placeholder="Search Product"
                                                        value={this.state.product}
                                                        filterBy={{ brand: this.state.brandId }}
                                                    />
                                                </div>

                                                <div className="card-title col-6">
                                                    <label>Uom</label>
                                                    <select
                                                        className="form-control"
                                                        name="selectedUom"
                                                        value={this.state.selectedUom}
                                                        onChange={this.onChange}
                                                        id="excelType"
                                                    >
                                                        <option value="">Select Uom</option>
                                                        {Object.keys(this.state.measumentUnit).map((item) => {
                                                            return <option value={this.state.measumentUnit[item].value}>{this.state.measumentUnit[item].label}</option>
                                                        })}
                                                    </select>
                                                </div>

                                                <div id="wrapper" className="wrapper w-100">
                                                    {this.state.dataNew.map((item, idx) => {
                                                        return (
                                                            <React.Fragment>
                                                                <div key={idx} style={{ justifyContent: 'center' }} className="d-flex align-items-center text-center">
                                                                    <div className="custom-control custom-checkbox text-center">
                                                                        <label className="custom-control-label mb-0" htmlFor={item.name}>{item.name}</label>
                                                                        <input checked={item.isSelected} onChange={(e) => this.checkShade(e, idx)} disabled={item.disabled} type="checkbox" className="form-control mt-2" name={item.name} id="checbox" />
                                                                    </div>
                                                                    {item.disabled ? <span className="text-center w-50" >Out Of Stock</span> :
                                                                        <div className="input-group mb-0 w-50">
                                                                            <div className="input-group-append border-0">
                                                                                <button onClick={() => this.chnageShadeQuantity(idx, -1)} className="btn btn-danger">
                                                                                    <i className="fa fa-minus"></i>
                                                                                </button>
                                                                            </div>
                                                                            <input onChange={(e) => this.quantityChange(e, item)} maxLength="5" value={item.isQuantity} type="text" className="form-control p-0 text-center w-50" placeholder="" />
                                                                            <div className="input-group-prepend border-0">
                                                                                <button onClick={() => this.chnageShadeQuantity(idx, 1)} type='button' className="btn btn-primary">
                                                                                    <i className="fa fa-plus"></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>}
                                                                </div>
                                                                <hr className="my-2" />
                                                            </React.Fragment>
                                                        )
                                                        // return <div className="card-title col-12"> <p className="text-center">{item.name} - {item.disabled ? "Out of Stock" : item.quantity}</p><br /></div>
                                                    })}
                                                </div>

                                            </div>
                                        </div>

                                        <div className="modal-footer bg-white text-center justify-content-center">
                                            <button
                                                onClick={() => this.addDesign()}
                                                type="button"
                                                data-dismiss="modal"
                                                className="btn btn-primary">
                                                Confrim
                                            </button>
                                            <button type="button" className="btn btn-dark" data-dismiss="modal">
                                                Cancel
                                            </button>
                                        </div>

                                    </div>
                            }
                        </div>

                    </div>
                </div>
            </main >
        )
    }
}

function mapStateToProps(state) {
    return {
        uom_product_search_res: state.uom_product_search_res,
        order_single: state.order_single,
        order_update: state.order_update,
        productmanagement_res: state.productmanagement_res
    };
}

const mapDispatchToProps = dispatch => ({
    uom_product_search: data => dispatch(uom_product_search(data)),
    orderSingle: data => dispatch(orderSingle(data)),
    zonemanagecreate: data => dispatch(zonemanagecreate(data)),
    orderUpdate: (data, id) => dispatch(orderUpdate(data, id)),
    productmanagementlist: (data) => dispatch(productmanagementlist(data))
});

OrderForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderForm);

export default OrderForm;
/*eslint-enable */