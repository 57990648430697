import React, { Component, lazy, Suspense } from 'react';

const Header = lazy(() => import('../components/header'));
const Sidebar = lazy(() => import('../components/sidebar'));
const Footer = lazy(() => import('../components/footer'));
class Base extends Component {
    render() {
        if (!this.props.children) {
            return <React.Fragment>{this.props.children}</React.Fragment>;
        } else {
            return (
                <React.Fragment>
                    <Suspense fallback="">
                        <Header />
                        <div className="app-body">
                            <Sidebar />
                            {this.props.children}
                        </div>
                        <Footer />
                    </Suspense>
                </React.Fragment>
            );
        }
    }
}

export default Base;