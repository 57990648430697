import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { routehelp } from '../../Routehelper';
import { indirectcustomermanageupdate, indirectcustomersingle, indirectcustomercreate, region_search, territory_search, beat_search, state_search, district_search, city_search, wholesalerSearch } from '../../actions';
import { errorRender, successRedriect, getValue, validate, inputCheck } from '../../helpers/functions';

const Buttons = lazy(() => import('../../components/buttons'));
const Loader = lazy(() => import('../../components/loader'));
const Inputfield = lazy(() => import('../../components/inputfields'));
const CardTitle = lazy(() => import('../../components/cardTitle'));
const Links = lazy(() => import('../../components/links'));
const CustomerDropDown = lazy(() => import('../customerManagement/customerDropdown'));
const Userdropdown = lazy(() => import('../userManagement/userDropdwon'));
const Zonedropdown = lazy(() => import('../zoneManagement/zoneDropdwon'));
const RegionDropdown = lazy(() => import('../regionManagement/RegionDropdown'));
const Territorydropdown = lazy(() => import('../territoryManagement/territoryDropdown'));
const Beatdropdown = lazy(() => import('../beatsManagement/beatDropdwon'));
const CountryDropdown = lazy(() => import('../../components/address/countrydropdown'));
const StateDropdown = lazy(() => import('../../components/address/stateDropdown'));
const CityDropdown = lazy(() => import('../../components/address/citydropdown'));
const TextAreaInputField = lazy(() => import('../../components/textAreaInputField'));

class IndirectCustomer_manage_add extends Component {
    state = {
        pageType: 'add',
        itemId: '',
        name: "",
        code: "",
        company: "",
        email: "",
        mobile: "",
        beat: null,
        territory: null,
        region: null,
        zone: null,
        shopName: "",
        accountCode: "",
        marketName: "",
        customerType: "",
        ownersName: "",
        ownersContactNumber: "",
        shopErpId: "",
        shopSegmentation: "",
        tag: "",
        agent: "",
        wholesaler: null,
        country: null,
        state: null,
        district: null,
        city: null,
        line1: "",
        line2: "",
        postalCode: "",
        filter: { customerType: "wholesaler", limit: 5000 },
        serverError: ["indirectcustomercustomer_create_res", "indirectcustomer_single", "indirectcustomer_update"],
        forRedirect: [
            { name: "indirectcustomercustomer_create_res", pageName: 'Indirect Customer', pageUrl: 'indirectcustomer', action: "Added" },
            { name: "indirectcustomer_update", pageName: 'Indirect Customer', pageUrl: 'indirectcustomer', action: "Updated" }
        ],
        setFields: [
            { name: "name", stateName: "name", type: "text" },
            { name: "code", stateName: "code", type: "text" },
            { name: "email", stateName: "email", type: "text" },
            { name: "mobile", stateName: "mobile", type: "text" },
            { name: 'accountCode', stateName: 'accountCode', type: 'text' },
            { name: 'zone', stateName: 'zone', type: 'select' },
            { name: 'region', stateName: 'region', type: 'select' },
            { name: 'territory', stateName: 'territory', type: 'select' },
            { name: 'beat', stateName: 'beat', type: 'select' },
            { name: 'agent', stateName: 'agent', type: 'select' },
            { name: "ownersName", stateName: "ownersName", type: "text" },
            { name: "ownersContactNumber", stateName: "ownersContactNumber", type: "text" },
            { name: "shopName", stateName: "shopName", type: "text" },
            { name: "shopSegmentation", stateName: "shopSegmentation", type: "text" },
            { name: "marketName", stateName: "marketName", type: "text" },
            { name: "shopErpId", stateName: "shopErpId", type: "text" },
            { name: "tag", stateName: "tag", type: "text" },
            { name: "address.country", stateName: "country", type: "select" },
            { name: "address.state", stateName: "state", type: "select" },
            { name: "address.city", stateName: "city", type: "select" },
            { name: "wholesaler", stateName: "wholesaler", type: "select" },
            { name: "address.line1", stateName: "line1", type: "text" },
            { name: "address.line2", stateName: "line2", type: "text" },
            { name: "address.postalCode", stateName: "postalCode", type: "text" }
        ],
        nameError: '',
        codeError: '',
        emailError: '',
        phoneError: '',
        zoneError: '',
        regionError: '',
        territoryError: '',
        beatError: '',
        agentError: '',
        countryError: '',
        stateError: '',
        districtError: '',
        accountCodeError: '',
        cityError: '',
        customerTypeError: '',
        wholesalerError: '',
        shopNameError: '',
        marketNameError: '',
        ownersNameError: '',
        ownersContactNumberError: '',
        shopErpIdError: '',
        shopSegmentationError: '',
        tagError: '',
        line1Error: '',
        line2Error: '',
        postalCodeError: '',
        isLoading: false,
        getSingleLoader: false
    }

    componentDidMount() {
        let id = this.props.match.params.item_id;
        if (id) {
            this.setState({ pageType: "update", itemId: id, getSingleLoader: true })
            this.props.indirectcustomersingle(id);
        }
    }

    handleChange = (name, e) => {
        // console.log(name);

        // const re = /([^\s]+)/;
        // if (!re.test(e.target.value)) {
        //     return true;
        // }

        // e.preventDefault();

        let isValid = inputCheck(name, e.target.value);

        if (isValid) {
            this.setState({ [name]: e.target.value });
        }

        // console.log(isValid);

        // if (name && name === "code") {
        //     const re = /^[a-zA-Z0-9_-]*$/;
        //     if (re.test(e.target.value)) {
        //         this.setState({ [name]: e.target.value });
        //     }
        // } else {
        //     const re = /([^\s]+)/;
        //     if (e.target.value === '' || re.test(e.target.value)) {
        //         this.setState({ [name]: e.target.value });
        //     }
        // }
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    ondropdownChangeagent = (e, t) => this.setState({ [e]: t });

    ondropdownChangeagentwholesale = (e, t) => this.setState({ [e]: t, wholesaler: null, filter: { filter: { agent: t.value }, customerType: 'wholesaler', limit: 5000 } });

    ondropdownChange = (val, name) => {
        if (val && val.value) {
            if (name === "zone") {
                if (this.state.zone !== null) {
                    if (val.value !== this.state.zone.value) {
                        this.setState({ region: null, territory: null, beat: null });
                        let data = {
                            zone: val.value
                        };
                        this.props.region_search(data);
                    }
                } else {
                    let data = {
                        zone: val.value
                    };
                    this.props.region_search(data);
                }
            }
            if (name === "region") {
                if (this.state.region !== null) {
                    if (val.value !== this.state.region.value) {
                        this.setState({ territory: null, beat: null });
                        let data = {
                            region: val.value
                        };
                        this.props.territory_search(data);
                    }
                } else {
                    let data = {
                        region: val.value
                    };
                    this.props.territory_search(data);
                }
            }
            if (name === "territory") {
                if (this.state.territory !== null) {
                    if (val.value !== this.state.territory.value) {
                        this.setState({ beat: null });
                        let data = {
                            territory: val.value
                        };
                        this.props.beat_search(data);
                    }
                } else {
                    let data = {
                        territory: val.value
                    };
                    this.props.beat_search(data);
                }
            }
            if (name === "country") {
                if (this.state.country !== null) {
                    if (val.value !== this.state.country.value) {
                        this.setState({ state: null, city: null });
                        let data = {
                            country: val.value
                        };
                        this.props.state_search(data)
                    }
                } else {
                    let data = {
                        country: val.value
                    };
                    this.props.state_search(data)
                }
            }
            if (name === "state") {
                if (this.state.state !== null) {
                    if (val.value !== this.state.state.value) {
                        this.setState({ city: null });
                        let data = {
                            state: val.value
                        };
                        this.props.city_search(data);
                    }
                } else {
                    let data = {
                        state: val.value
                    };
                    this.props.city_search(data);
                }
            }
            this.setState({ [name]: val });
        }
    }

    onsubmit = (e) => {

        let validationFields = [
            { name: 'name', label: "Name", value: this.state.name, hasError: false, isRequired: true, isNumber: false, onlyChar: true, maxLength: 50, type: "text", message: "", errName: "nameError" },
            { name: 'code', label: "Code", value: this.state.code, hasError: false, isRequired: false, isNumber: false, onlyChar: false, maxLength: 50, type: "text", message: "", errName: "codeError" },
            { name: 'accountCode', label: "Account Code", value: this.state.accountCode, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 50, type: "text", message: "", errName: "accountCodeError" },
            { name: 'wholesaler', label: "Wholesaler", value: this.state.wholesaler, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "text", message: "", errName: "wholesalerError" },
            { name: 'agent', label: "ASM", value: this.state.agent, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "agentError" },
            { name: 'email', label: "Email", value: this.state.email, hasError: false, isRequired: false, isNumber: false, onlyChar: false, type: "email", message: "", errName: "emailError" }
        ];

        let data = {
            name: this.state.name,
            code: this.state.code,
            email: this.state.email,
            mobile: this.state.mobile,
            beat: this.state.beat && this.state.beat.value ? this.state.beat.value : "",
            territory: this.state.territory && this.state.territory.value ? this.state.territory.value : "",
            region: this.state.region && this.state.region.value ? this.state.region.value : "",
            zone: this.state.zone && this.state.zone.value ? this.state.zone.value : "",
            accountCode: this.state.accountCode,
            shopName: this.state.shopName,
            marketName: this.state.marketName,
            customerType: "Indirect",
            ownersName: this.state.ownersName,
            ownersContactNumber: this.state.ownersContactNumber,
            shopErpId: this.state.shopErpId,
            shopSegmentation: this.state.shopSegmentation,
            tag: this.state.tag,
            agent: this.state.agent && this.state.agent.value,
            wholesaler: this.state.wholesaler && this.state.wholesaler.value,
            address: {
                country: this.state.country && this.state.country.value ? this.state.country.value : "",
                state: this.state.state && this.state.state.value ? this.state.state.value : "",
                city: this.state.city && this.state.city.value ? this.state.city.value : "",
                line1: this.state.line1,
                line2: this.state.line2,
                postalCode: this.state.postalCode
            }
        }

        e.preventDefault();

        let { newArr, valid } = validate(validationFields);

        newArr.map((el) => this.setState({ [el.errName]: el.message }));

        if (valid) {
            this.setState({ isLoading: true });
            if (this.state.pageType === "update") {
                this.props.indirectcustomermanageupdate(data, this.state.itemId);
            } else {
                this.props.indirectcustomercreate(data);
            }
            validationFields = null;
            newArr = null;
        }

    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }
            if (nextProps.indirectcustomer_single && nextProps.indirectcustomer_single.success === true) {
                nextProps.indirectcustomer_single.success = null;
                this.setState({ getSingleLoader: false });
                if (nextProps.indirectcustomer_single.item) {
                    this.state.setFields.forEach((value) => {
                        if (value.type === "text") {
                            this.setState({ [value.stateName]: getValue(nextProps.indirectcustomer_single.item, value.name) })
                        } else if (value.type === "select" && getValue(nextProps.indirectcustomer_single.item, value.name)) {
                            let obj = {
                                label: getValue(nextProps.indirectcustomer_single.item, value.name).name,
                                value: getValue(nextProps.indirectcustomer_single.item, value.name)._id
                            }
                            if (value.name === "agent") {
                                this.ondropdownChangeagentwholesale(value.stateName, obj)
                            } else {
                                this.ondropdownChange(obj, value.stateName);
                            }
                            obj = null
                        }
                    })
                }
            }
            this.state.serverError.forEach((el) => errorRender(nextProps, el));
            this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));
        }
        return true;
    }

    render() {
        const { pageType, isLoading, getSingleLoader } = this.state;

        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">

                                    {/* <div className="card-title" id={pageType === "update" ? "update_customer" : "add_indirect_customer"}>
                                        {pageType === "update" ? "Update Indirect Customer" : "Add Indirect Customer"}
                                    </div> */}
                                    <CardTitle title={pageType === "update" ? "Update Indirect Customer" : "Add Indirect Customer"} />
                                </div>
                                {getSingleLoader ? <Loader /> :
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="form-group required col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.nameError} maxlength="100" handleChange={this.handleChange} type="text" name="name" value={this.state.name} className="form-control" id="name" ariadescribedby="emailHelp" placeholder="Name" />
                                            </div>
                                            <div className="form-group  col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.codeError} maxlength="10" handleChange={this.handleChange} type="text" name="code" value={this.state.code} className="form-control" id="code" aria-describedby="emailHelp" placeholder="Code" />
                                            </div>
                                            <div className="form-group  col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.phoneError} maxlength="10" handleChange={this.handleChange} type="text" name="mobile" value={this.state.mobile} className="form-control" id="mobile" placeholder="Mobile" />
                                            </div>
                                            {/* {console.log(this.state.email || "na")} */}
                                            <div className="form-group col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.emailError} maxlength="100" handleChange={this.handleChange} type="text" name="email" value={this.state.email} className="form-control" id="email" placeholder="Email" />
                                            </div>
                                            <div className="form-group  col-12 col-lg-4">
                                                <Zonedropdown
                                                    ondropdownChange={(e) => this.ondropdownChange(e, "zone")}
                                                    placeholder="Search Zone"
                                                    value={this.state.zone}
                                                    errMessage={this.state.zoneError}
                                                />
                                            </div>
                                            <div className="form-group col-12 col-lg-4">
                                                <RegionDropdown
                                                    dontPreload={pageType === "update"}
                                                    ondropdownChange={(e) => this.ondropdownChange(e, "region")}
                                                    placeholder="Search Region"
                                                    value={this.state.region}
                                                    errMessage={this.state.regionError}
                                                />
                                            </div>
                                            <div className="form-group  col-12 col-lg-4">
                                                <Territorydropdown
                                                    dontPreload={pageType === "update"}
                                                    ondropdownChange={(e) => this.ondropdownChange(e, "territory")}
                                                    placeholder="Search Territory"
                                                    value={this.state.territory}
                                                    errMessage={this.state.territoryError}
                                                />
                                            </div>
                                            <div className="form-group  col-12 col-lg-4">
                                                <Beatdropdown
                                                    dontPreload={pageType === "update"}
                                                    ondropdownChange={(e) => this.ondropdownChange(e, "beat")}
                                                    placeholder="Search Beat"
                                                    value={this.state.beat}
                                                    errMessage={this.state.beatError}
                                                />
                                            </div>
                                            <div className="form-group required col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.accountCodeError} handleChange={this.handleChange} maxlength="50" type="text" name="accountCode" value={this.state.accountCode} className="form-control" id="accountCode" aria-describedby="emailHelp" placeholder="Account Code (ERP Accountcode)" />
                                            </div>
                                            <div className="form-group required col-12 col-lg-4">
                                                <Userdropdown
                                                    type="ASM"
                                                    ondropdownChange={this.ondropdownChangeagentwholesale}
                                                    name="agent"
                                                    placeholder="Area Sales Manager"
                                                    value={this.state.agent}
                                                    className="form-control form-control-square"
                                                    errMessage={this.state.agentError}
                                                />
                                            </div>
                                            <div className=" form-group required col-12 col-lg-4">
                                                <CustomerDropDown
                                                    lable="Wholesaler"
                                                    value={this.state.wholesaler}
                                                    ondropdownChange={(e) => this.ondropdownChange(e, "wholesaler")}
                                                    placeholder="Search Wholesaler"
                                                    filterBy={this.state.filter}
                                                    errMessage={this.state.wholesalerError}
                                                />
                                            </div>
                                            <div className="form-group  col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.ownersNameError} maxlength="50" handleChange={this.handleChange} type="text" name="ownersName" value={this.state.ownersName} className="form-control" id="ownersName" placeholder="Owners Name" />
                                            </div>
                                            <div className="form-group  col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.ownersContactNumberError} maxlength="10" handleChange={this.handleChange} type="text" name="ownersContactNumber" value={this.state.ownersContactNumber} className="form-control" id="ownersContactNumber" placeholder="Owner's Contact Number" />
                                            </div>
                                            <div className="form-group col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.shopNameError} maxlength="100" handleChange={this.handleChange} type="text" name="shopName" value={this.state.shopName} className="form-control" id="shopName" placeholder="Shop Name" />
                                            </div>
                                            <div className="form-group  col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.shopSegmentationError} maxlength="50" handleChange={this.handleChange} type="text" name="shopSegmentation" value={this.state.shopSegmentation} className="form-control" id="shopSegmentation" placeholder="Shop Segmentation" />
                                            </div>
                                            <div className="form-group col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.marketNameError} maxlength="50" handleChange={this.handleChange} type="text" name="marketName" value={this.state.marketName} className="form-control" id="marketName" placeholder="Market Name" />
                                            </div>
                                            <div className="form-group  col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.shopErpIdError} maxlength="100" handleChange={this.handleChange} type="text" name="shopErpId" value={this.state.shopErpId} className="form-control" id="shopErpId" placeholder="Shop Erpld" />
                                            </div>
                                            <div className="form-group  col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.tagError} naxlength="10" handleChange={this.handleChange} type="text" name="tag" value={this.state.tag} className="form-control" id="tag" placeholder="Tag" />
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                    {getSingleLoader ? null :
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-shadow mb-4">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <CardTitle title={pageType === "update" ? "Update Address" : "Add Address"} />
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className=" form-group  col-12 col-lg-4">
                                                <CountryDropdown
                                                    ondropdownChange={(e) => this.ondropdownChange(e, "country")}
                                                    placeholder="Search Country"
                                                    value={this.state.country}
                                                    errMessage={this.state.countryError}
                                                />
                                            </div>
                                            <div className=" form-group  col-12 col-lg-4">
                                                <StateDropdown
                                                    dontPreload={pageType === "update"}
                                                    ondropdownChange={(e) => this.ondropdownChange(e, "state")}
                                                    placeholder="Search State"
                                                    value={this.state.state}
                                                    country={this.state.country}
                                                    errMessage={this.state.stateError}
                                                />
                                            </div>
                                            <div className=" form-group  col-12 col-lg-4">
                                                <CityDropdown
                                                    dontPreload={pageType === "update"}
                                                    ondropdownChange={(e) => this.ondropdownChange(e, "city")}
                                                    placeholder="Search City"
                                                    value={this.state.city}
                                                    state={this.state.state}
                                                    country={this.state.country}
                                                    errMessage={this.state.cityError}
                                                />
                                            </div>
                                            <div className="form-group col-12 col-lg-4">
                                                <TextAreaInputField errMessage={this.state.line1Error} maxlength="5000" onChange={this.onChange} name="line1" value={this.state.line1} className="form-control" id="line1" rows="2" placeholder="Address line-1" />
                                            </div>
                                            <div className="form-group col-12 col-lg-4 ">
                                                <TextAreaInputField errMessage={this.state.line2Error} maxlength="5000" onChange={this.onChange} name="line2" value={this.state.line2} className="form-control" id="line2" rows="2" placeholder="Address line-2" />
                                            </div>
                                            <div className="form-group col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.postalCodeError} maxlength="8" handleChange={this.handleChange} type="text" name="postalCode" value={this.state.postalCode} className="form-control" id="text" placeholder="Zip Code" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" disable={isLoading || getSingleLoader} loading={isLoading} name={pageType === "update" ? "Update" : "Save"} />
                            <Links to={routehelp.indirectcustomer} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        indirectcustomercustomer_create_res: state.indirectcustomercustomer_create_res,
        indirectcustomer_single: state.indirectcustomer_single,
        indirectcustomer_update: state.indirectcustomer_update
    };
}

const mapDispatchToProps = dispatch => ({
    indirectcustomermanageupdate: (data, id) => dispatch(indirectcustomermanageupdate(data, id)),
    indirectcustomercreate: data => dispatch(indirectcustomercreate(data)),
    region_search: data => dispatch(region_search(data)),
    territory_search: data => dispatch(territory_search(data)),
    beat_search: data => dispatch(beat_search(data)),
    state_search: data => dispatch(state_search(data)),
    district_search: data => dispatch(district_search(data)),
    city_search: data => dispatch(city_search(data)),
    wholesalerSearch: data => dispatch(wholesalerSearch(data)),
    indirectcustomersingle: data => dispatch(indirectcustomersingle(data)),
});

IndirectCustomer_manage_add = connect(
    mapStateToProps,
    mapDispatchToProps
)(IndirectCustomer_manage_add);

export default IndirectCustomer_manage_add;