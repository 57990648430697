import React from 'react';
import ErrorView from '../components/errorView';
const Inputfield = ({ max, min, noLabel, pattern, disabled, errMessage, type, id, maxlength, minlength, className, placeholder, required, name, value, arialabel, handleChange, ariadescribedby, checked }) => {
    // { console.log(maxlength) }
    return (
        <React.Fragment>
            {!noLabel ? <label htmlFor="exampleInputName1" className="control-label">{placeholder}</label> : null}
            <input pattern={pattern} min={min} max={max} checked={checked} disabled={disabled} minlength={minlength} name={name} maxLength={maxlength} type={type} id={id} className={className} aria-label={arialabel} aria-describedby={ariadescribedby} onChange={handleChange ? (value) => handleChange(name, value) : null} value={value} placeholder={placeholder} required={required} autoComplete="off" />
            <ErrorView message={errMessage} />
        </React.Fragment>
    )
}
export default Inputfield;