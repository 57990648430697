import React from "react";
import ErrorView from '../components/errorView';
class EmailChips extends React.Component {
    render() {
        const { handleKeyDown, handleChange, handlePaste, handleDelete, value, error, items, errMessage, label } = this.props
        return (
            <div>
                <label htmlFor="exampleInputMobile1" className="control-label">{label} <small>(Type Email and hit enter)</small></label>
                <input
                    className={"input " + (error && " has-error")}
                    value={value}
                    placeholder={"Email"}
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                    onPaste={handlePaste}
                />
                {error && <p className="error">{error}</p>}
                {items && items.map(item => (
                    <div style={{ marginTop: 4 }} className="tag-item" key={item}>
                        {item}
                        <button
                            type="button"
                            className="button"
                            onClick={() => handleDelete(item)}
                        >
                            &times;
                        </button>
                    </div>
                ))}
                <ErrorView message={errMessage} />
            </div>
        );
    }
};

export default EmailChips;

