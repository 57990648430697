import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { feedbacklist, delete_feedback, feedbackSingle, partyNamesList, feedbackExport } from '../../actions';
import { errorRender, successRender, commonDebounce, fileDownload, loaderUpdate, isPermission } from '../../helpers/functions';
import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';
import Userdropdown from '../userManagement/userDropdwon';
import toastr from 'reactjs-toastr';
var dateFormat = require('dateformat');

const Loader = lazy(() => import('../../components/loader'));
const Pagination = lazy(() => import('../../components/pagination/index'));
const DataTable = lazy(() => import('../../components/dataTalble'));
const ButtonWithItag = lazy(() => import('../../components/buttonwithTagi'));
const Entries = lazy(() => import('../../components/entries/entires'));
const CustomerDropDown = lazy(() => import('../../container/customerManagement/customerDropdown'));


let itemsPerPage = ITEM_PER_PAGES;
let currentPage = CURRENT_PAGE;
let sortBy = SORT_BY;
let sort = SORT;

class FeedbackList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mappingObj: {
                true: "Yes",
                false: "No"
            },
            filter: {},
            from: "",
            to: "",
            partyNamesList: [],
            user: null,
            partyName: null,
            isFilter: false,
            isExportLoading: false,
            isViewLoading: false,
            isLoading: false,
            statusUpdated: "Status has been updated successfully",
            deleteSuccess: "Feedback Has been Deleted Successfully",
            serverErrors: ["feedback_delete", "feedback_res", 'feedbackExp_res', 'feedback_delete_res', 'feedback_status_res'],
            tableHeaders: [
                {
                    name: 'user.name',
                    label: "User",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'forSelf',
                    label: "From User",
                    type: "boolean",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'customer.name',
                    label: "Party Name",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'feedbackNote',
                    label: "Feedback",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'address',
                    label: "Location",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'createdAt',
                    label: "CreatedAt",
                    type: "date",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'actions',
                    label: "Actions",
                    type: "button",
                    sortable: false,
                    isShow: true,
                    allactions: [
                        {
                            name: "view",
                            classNameI: "ti-eye",
                            className: "btn btn-link text-primary action-btn p-0",
                            datatoggle: "modal",
                            datatarget: ".bd-example-modal-lg",
                            dataplacement: "top",
                            title: "Feedback Details",
                            dataoriginaltitle: "Click to Feedback Details",
                            isPermission: "view",
                            function: this.openView
                        },
                        {
                            name: "delete",
                            title: "Delete",
                            classNameI: "ti-trash",
                            className: "btn btn-link text-danger action-btn p-0",
                            isPermission: "delete",
                            function: this.deleteRow
                        }
                    ]
                }
            ]
        }
    }

    openView = (e, item) => {
        this.setState({
            isViewLoading: true
        });
        this.props.feedbackSingle(item._id);
    }

    componentDidMount() {
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        this.loadResult();
        this.props.partyNamesList({});
    }

    componentWillUnmount() {
        currentPage = CURRENT_PAGE;
        itemsPerPage = ITEM_PER_PAGES;
    }

    loadResult = () => {
        let data = {};
        data.filter = {
            user: this.state.user && this.state.user.value ? this.state.user.value : null,
            customer: this.state.partyName && this.state.partyName.value ? this.state.partyName.value : null
        }

        // console.log(this.state.from, this.state.to);

        if (this.state.from && this.state.to) {
            let splitFrom = this.state.from.split('-');
            let splitTo = this.state.to.split('-');
            data.date = {
                from: `${splitFrom[2]}-${splitFrom[1]}-${splitFrom[0]}`,
                to: `${splitTo[2]}-${splitTo[1]}-${splitTo[0]}`
            };
        } else if (this.state.from) {
            let splitFrom = this.state.from.split('-');
            data.date = {
                from: `${splitFrom[2]}-${splitFrom[1]}-${splitFrom[0]}`,
                to: `${splitFrom[2]}-${splitFrom[1]}-${splitFrom[0]}`
            };
        } else if (this.state.to) {
            alert("Please select From date");
            return true;
        };

        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        data.skip = this.state.name ? 0 : (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;

        // console.log(data);

        this.props.feedbacklist(data);
        this.setState({ isLoading: true, isFilter: false });
    }

    resetfilter = () => {
        this.setState({ isLoading: true, isFilter: false, user: null, partyName: null, from: "", to: "", filter: {} });
        let data = {};
        data.filter = { user: null };
        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        data.skip = this.state.name ? 0 : (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.feedbacklist(data);
    }

    sortBy = (key) => {
        sortBy = key;
        if (sort === 'desc') {
            sort = 'asc';
        } else {
            sort = 'desc';
        }
        this.loadResult();
    }

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    }

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    }

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    }

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    deleteRow = (e, item) => {
        // console.log(item._id);
        if (window.confirm(`Are you sure you wish to delete "${item.feedbackNote}" ?`)) {
            this.props.delete_feedback(item._id);
            e.preventDefault();
        }
    }

    onChangeforDebounce = ({ target: { value } }) => {
        if (!/^\s/.test(value)) {
            this.setState({ name: value }, () => this.callforDebounce(value))
        }
    }

    ondropdownChange = (e, t) => {
        if (e === 'user') {
            this.setState({
                filter: {
                    filter: { agent: t.value ? t.value : null }
                }
            });
        }
        this.setState({ [e]: t });
    }

    callforDebounce = () => this.loadResult();

    shouldComponentUpdate(nextProps) {
        // console.log(nextProps);
        if (nextProps !== this.props) {


            // console.log(this.state.isLoading)
            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "feedback_res") });
            }

            if (nextProps && nextProps.feedbackSingle_res) {
                this.setState({
                    isViewLoading: false
                });
            }

            if (nextProps && nextProps.feedbackExp_res) {
                this.setState({
                    isExportLoading: false
                });
            }

            if (nextProps.feedbackExp_res !== undefined && nextProps.feedbackExp_res.success === true) {
                if (nextProps.feedbackExp_res.success && nextProps.feedbackExp_res.item && nextProps.feedbackExp_res.item.fileName === null) {
                    toastr.error('No Record Found', 'Error', {
                        displayDuration: 5000
                    });
                } else {
                    fileDownload(nextProps, "feedbackExp_res", "fileName");
                }
            }

            if (nextProps && nextProps.partynamelist_res &&
                nextProps.partynamelist_res.success && nextProps.partynamelist_res.list && nextProps.partynamelist_res.list.length > 0) {
                // console.log(nextProps.partynamelist_res);
                // console.log(nextProps.partynamelist_res.list.map((ele) => { return { value: ele.partyName, label: ele.partyName } }));
                this.setState({
                    partyNamesList: nextProps.partynamelist_res.list.map((ele) => { return { value: ele.partyName, label: ele.partyName } })
                });
            }

            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            successRender(nextProps, "feedback_delete_res", this.loadResult, this.state.deleteSuccess);

            if (nextProps && nextProps.feedback_status_res &&
                nextProps.feedback_status_res.success) {
                // console.log(nextProps.feedback_status_res.item._id);
                this.openView('', nextProps.feedback_status_res.item ? nextProps.feedback_status_res.item : null)
                successRender(nextProps, "feedback_status_res", this.loadResult, this.state.statusUpdated);
            }

        }

        return true;
    }

    export = () => {

        this.setState({
            isExportLoading: true
        });

        let data = {};
        data.filter = {
            user: this.state.user && this.state.user.value ? this.state.user.value : null,
            customer: this.state.partyName && this.state.partyName.value ? this.state.partyName.value : null,
        }

        // console.log(this.state.from, this.state.to);

        if (this.state.from && this.state.to) {
            let splitFrom = this.state.from.split('-');
            let splitTo = this.state.to.split('-');
            data.date = {
                from: `${splitFrom[2]}-${splitFrom[1]}-${splitFrom[0]}`,
                to: `${splitTo[2]}-${splitTo[1]}-${splitTo[0]}`
            };
        } else if (this.state.from) {
            let splitFrom = this.state.from.split('-');
            data.date = {
                from: `${splitFrom[2]}-${splitFrom[1]}-${splitFrom[0]}`,
                to: `${splitFrom[2]}-${splitFrom[1]}-${splitFrom[0]}`
            };
        } else if (this.state.to) {
            alert("Please select From date");
            return true;
        };

        // console.log(data);
        this.props.feedbackExport(data);
    }

    toggleFilter = () => {
        this.setState({ isFilter: !this.state.isFilter })
    };

    render() {
        const { feedback_res, feedbackSingle_res } = this.props;
        const totResult = feedback_res ? feedback_res.count : 0;

        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        Feedback
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">

                                        <div className="row">

                                            <div className="col-sm-12 col-md-6">
                                                {totResult === 0 ? null :
                                                    <Entries
                                                        itemsPerPage={itemsPerPage}
                                                        handleChangeiItemsPerPage={
                                                            this.handleChangeiItemsPerPage
                                                        }
                                                    />
                                                }
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <div className="add-btn-icon mb-4">
                                                    <ButtonWithItag classNameI="fa tai-ico-Export" disabled={this.state.isExportLoading} type="button" onclick={this.export} className="btn btn-primary mr-1" data-toggle="tooltip" data-placement="top" title="Export as a file" data-original-title="Click to Upload all" />
                                                    <ButtonWithItag classNameI="ti-filter" type="button" onclick={this.toggleFilter} className="btn btn-primary mr-1" data-toggle="tooltip" data-placement="top" title="Filter" data-original-title="Click for Filter option" />
                                                    {/* <SearchInput onChangeforDebounce={this.onChangeforDebounce} value={name} /> */}
                                                    {/* <LinkWithItag to={routehelp.uomAdd} className="btn float-left  btn-primary mr-2" role="button" classNameI="fa fa-plus" title="Add UOM" /> */}
                                                </div>
                                            </div>

                                        </div>

                                        {
                                            totResult === undefined ?
                                                <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy}
                                                    sortByvalue={sortBy} sortvalue={sort}
                                                    data={[]} props={''} moduleName={"feedback"} /> :
                                                this.state.isLoading ?
                                                    <Loader />
                                                    :
                                                    feedback_res !== undefined && feedback_res.count === 0 ?
                                                        <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy}
                                                            sortByvalue={sortBy} sortvalue={sort}
                                                            data={[]} props={''} moduleName={"feedback"} />
                                                        :
                                                        feedback_res && feedback_res.count > 0 ?
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <div className='table-responsive'>
                                                                        {/* <div className="table table-bordered table-striped dataTable"> */}
                                                                        <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy}
                                                                            sortByvalue={sortBy} sortvalue={sort} mappingObj={this.state.mappingObj}
                                                                            data={feedback_res} props={this.props} moduleName={"feedback"} />
                                                                        {/* </div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : <p className="text-center">Something Went Wrong</p>
                                        }

                                        {totResult !== undefined ? feedback_res !== undefined && feedback_res.count === 0 ? "" :
                                            <Pagination
                                                handleChangeiItemsPerPage={
                                                    this.handleChangeiItemsPerPage
                                                }
                                                handlePage={this.handlePage}
                                                handleNext={this.handleNext}
                                                handlePrevious={this.handlePrevious}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                totResult={totResult}
                                                key={currentPage}
                                            />
                                            : ""}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="modal fade bd-example-modal-lg"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myLargeModalLabel"
                    style={{ display: "none" }}
                    aria-hidden="true"
                >
                    <div className="modal-dialog commission-modal-md modal-custom-width">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="myLargeModalLabel">
                                    Feedback Details
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            {
                                this.state.isViewLoading ? <div className='justify-content-center ml-5 mt-5 mb-5'><Loader /></div> :
                                    <div className="modal-body">
                                        {
                                            feedbackSingle_res && feedbackSingle_res.item ?
                                                <div className='row'>

                                                    <div className='form-group col-md-6'>
                                                        <label className='control-label'>User</label>
                                                        <div className='form-control'>{feedbackSingle_res.item.user && feedbackSingle_res.item.user.name ? feedbackSingle_res.item.user.name : "NA"}</div>
                                                    </div>

                                                    <div className='form-group col-md-6'>
                                                        <label className='control-label'>Party Name</label>
                                                        <div className='form-control'>{feedbackSingle_res.item.customer && feedbackSingle_res.item.customer.name ? feedbackSingle_res.item.customer.name : "NA"}</div>
                                                    </div>

                                                    <div className='form-group col-md-6'>
                                                        <label className='control-label'>Feedback</label>
                                                        <textarea className='form-control' disabled>{feedbackSingle_res.item.feedbackNote ? feedbackSingle_res.item.feedbackNote : "NA"}</textarea>
                                                    </div>

                                                    <div className='form-group col-md-6'>
                                                        <label className='control-label'>Address</label>
                                                        <textarea className='form-control' disabled>{feedbackSingle_res.item.address ? feedbackSingle_res.item.address : "NA"}</textarea>
                                                    </div>

                                                    <div className='form-group col-md-6'>
                                                        <label className='control-label'>From User</label>
                                                        <div className='form-control'>{feedbackSingle_res.item.forSelf ? 'Yes' : "No"}</div>
                                                    </div>

                                                    <div className='form-group col-md-6'>
                                                        <label className='control-label'>Created At</label>
                                                        <div className='form-control'>{feedbackSingle_res.item.createdAt ? dateFormat(new Date(feedbackSingle_res.item.createdAt), 'mmm d, yyyy') : "NA"}</div>
                                                    </div>

                                                </div> :
                                                <div className='text-center'>No Record Found</div>
                                        }
                                    </div>
                            }
                        </div>
                    </div>
                </div>

                <div className={!this.state.isFilter ? "right-sidebarfilter hidden" : "right-sidebarfilter show"}>
                    <div className="modal-header bg-primary rounded-0">
                        <h6 className="filter-modal-title">Filter</h6>
                        <button type="button" onClick={this.toggleFilter} className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" className="navbar-toggler right-sidebar-toggler pointer">×</span>
                        </button>
                    </div>
                    <form>
                        <div className="card-body">
                            <div className="form-group">
                                {/* {console.log(this.state.user)} */}
                                <Userdropdown
                                    ondropdownChange={this.ondropdownChange}
                                    name="user"
                                    value={this.state.user}
                                    className="form-control"
                                    placeholder="Users"
                                />
                            </div>

                            <div className="form-group">
                                <div>
                                    <label className="control-label">Party Names</label>
                                    <CustomerDropDown
                                        isLabel={false}
                                        ondropdownChange={(e) => this.ondropdownChange("partyName", e)}
                                        placeholder="Search Customer"
                                        preFixValue={"customer"}
                                        value={this.state.partyName}
                                        filterBy={this.state.filter}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-6">
                                    <label className="control-label">Date From</label>
                                    <input maxLength="10" type="date" className="form-control" name="from" value={this.state.from} onChange={this.onChange} id="fromdate" placeholder="From" />
                                </div>
                                <div className="form-group col-6">
                                    <label className="control-label">Date To</label>
                                    <input maxLength="10" type="date" className="form-control" name="to" value={this.state.to} onChange={this.onChange} id="todate" placeholder="To" />
                                </div>
                            </div>

                        </div>
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <button onClick={this.loadResult} data-dismiss="modal" type="button" aria-hidden="true" className="btn btn-primary navbar-toggler right-sidebar-toggler pointer mr-1">Apply </button>
                            <button type="button" data-dismiss="modal" onClick={this.resetfilter} className="btn btn-outline-dark">Reset</button>
                        </div>
                    </form>
                </div>
            </main >
        )
    }
}

function mapStateToProps(state) {
    return {
        feedback_res: state.feedback_res,
        feedback_delete_res: state.feedback_delete_res,
        feedback_status_res: state.feedback_status_res,
        feedbackSingle_res: state.feedbackSingle_res,
        partynamelist_res: state.partynamelist_res,
        feedbackExp_res: state.feedbackExp_res
    };
}

const mapDispatchToProps = dispatch => ({
    feedbacklist: data => dispatch(feedbacklist(data)),
    feedbackSingle: id => dispatch(feedbackSingle(id)),
    delete_feedback: item_id => dispatch(delete_feedback(item_id)),
    feedbackExport: data => dispatch(feedbackExport(data)),

    partyNamesList: data => dispatch(partyNamesList(data)),
});

FeedbackList = connect(
    mapStateToProps,
    mapDispatchToProps
)(FeedbackList);

export default FeedbackList;