import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class LinkwithImg extends Component {
    render() {
        let { onClick, className, to, src, srcset, classNameImg, alt, datatoggle, role, ariahaspopup, ariaexpanded, id } = this.props;
        return (
            <Link className={className} to={to} data-toggle={datatoggle} role={role} aria-haspopup={ariahaspopup} aria-expanded={ariaexpanded} >
                <img onClick={onClick} src={src} srcSet={srcset} className={classNameImg} alt={alt} id={id || 'img'} />
            </Link>
        )
    }
}

export default LinkwithImg;