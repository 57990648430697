import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Inputfield from '../../components/inputfields';
import Buttons from '../../components/buttons';
import Links from '../../components/links';
import { routehelp } from '../../Routehelper';
import { companyUpdate, state_search, district_search, city_search, companySingle, generatesecretkey } from '../../actions';
import Translate from '../../libs/translation';
import CountryDropdown from '../../components/address/countrydropdown';
import StateDropdown from '../../components/address/stateDropdown'
// import DistrictDropdown from '../../components/address/districtDropdown';
import CityDropdown from '../../components/address/citydropdown';
import toastr from 'reactjs-toastr';
import { apiUrl, uploadUrl } from "../../config";
import { ProgressBar } from "react-bootstrap";
import helperFunc from '../../helpers/helperFuncs';
import axios from "axios";
import 'reactjs-toastr/lib/toast.css';
// const CardTitle = lazy(() => import('../../components/cardTitle'));

class CompanyUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isActive: '',
            apiSecret: null,
            name: '',
            email: '',
            phone: '',
            password: '',
            country: "",
            state: "",
            city: "",
            district: "",
            line1: "",
            description: '',
            postalCode: '',
            type: "",
            company: "",
            uploadPercentage: 0,
            logo: {},
            modulePermi: [
                { name: 'package 1', label: 'Package 1', value: true },
                { name: 'package 2', label: 'Package 2', value: false },
                { name: 'package 3', label: 'Package 3', value: false },
                { name: 'package 4', label: 'Package 4', value: true },
                { name: 'package 5', label: 'Package 5', value: false }
            ],
            error: {
                nameError: '',
                emailError: '',
                phoneError: '',
                passwrodError: '',
                descriptionError: '',
                countryError: '',
                stateError: '',
                districtError: '',
                cityError: '',
                line1Error: '',
                postalCodeError: '',
                typeError: '',
                customerError: ""
            },
            numberOfMaxUser: 0,
            numberOfMaxRetailer: 0,
            isAdvertiseDisplay: true,
            isCodeEditable: true,
            reportFormat: "default",
            stockUpdateOn: "beforeConfirm",
            reportList: [
                { label: "Default", value: "default" },
                { label: "Invoice", value: "invoice" }
            ],
            stockUpdateOnList: [
                { label: "Before order confirm", value: "beforeConfirm" },
                { label: "After order confirm", value: "afterConfirm" },
                { label: "Auto order confirm", value: "autoConfirm" }
            ],
            notificationType: "default",
            notificationTypeList: [
                {
                    label: "Default",
                    value: "default"
                },
                {
                    label: "Custom",
                    value: "custom"
                }
            ],
            userAuthentication: "one-way",
            userAuthenticationList: [
                {
                    label: "One Way",
                    value: "one-way"
                },
                {
                    label: "Two Way",
                    value: "two-way"
                }
            ],
            webFeature: {
                brandVsUomG: false,
                orderVsDaysG: false,
                sentStock: false,
                readyStock: false,
                stockHistory: false,
                payment: false,
                feedback: false,
                dashboard: true,
                zone: true,
                region: true,
                territory: true,
                beat: true,
                users: true,
                customers: true,
                indirectCustomers: true,
                srASM: true,
                userHierarchy: false,
                brand: true,
                product: true,
                uom: true,
                stock: true,
                agency: true,
                transporter: true,
                scheme: true,
                outstanding: true,
                dispatchDetails: true,
                order: true,
                routePlanning: true,
                categoryExpense: true,
                subCategoryExpense: true,
                expenseApprovalExpense: true,
                advanceApproval: true,
                discountMaster: false,
                userRetailers: true,
                trending: true,
                categoriesRetailers: true,
                ecatalogue: true,
                orderRetailers: true,
                commission: false,
                orderFailed: false,
                commissionRange: false,
                totalUsers: false,
                totalProducts: false,
                totalOrders: false,
                totalCustomers: false,
                inactiveUsers: false,
                inactiveCustomers: false,
                top10Products: false,
                least10Products: false,
                top10Orders: false,
                least10Orders: false,
                top10Customers: false,
                least10Customers: false,
                zeroStock: false,
                profile: true,
                changePassword: true,
                misReportSetting: false,
                companyConfig: false,
                emailConfig: true,
                logs: false,
                totalBrands: false,
                productCommission: false,
                orderCommission: false,
                customersVisities: false,
                orderTemplate: false
            },
            mobileFeature: {
                payment: false,
                feedback: false,
                myOrders: true,
                newOrders: true,
                stock: true,
                customers: true,
                outstanding: true,
                dispatchDetails: true,
                commission: false,
                childs: false,
                advance: true,
                myTours: true,
                productCommission: false,
                orderTemplate: false
            },
            webFeatureList: [],
            mobileFeatureList: [],
            commissionSelectedOn: "user",
            commissionDependencyList: [
                {
                    label: "User wise",
                    value: "user"
                },
                {
                    label: "Range",
                    value: "range"
                }
            ],
        }
    }

    featureChange = (name, e) => {
        let splitName = name.split(".");
        let updatedData = this.state[splitName[0]];
        updatedData[splitName[1]] = e.target.checked;
        this.setState({
            [splitName[0]]: updatedData
        });
    }

    generateSeceretKey = () => {
        this.props.generatesecretkey({ company: this.props.match.params.item_id });
    }

    isActiveFun = () => {
        let isActiveToggle = !this.state.isActive;
        this.setState({
            isActive: isActiveToggle
        });
    }

    componentDidMount() {
        let id = this.props.match.params.item_id;
        this.props.companySingle(id);
        this.setState({
            mobileFeatureList: helperFunc.mobileFeatureList(),
            webFeatureList: helperFunc.webFeatureList()
        });
    }

    handleChange = (name, e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [name]: e.target.value });
        }
    }

    validate = () => {
        let nameError = '';
        let emailError = '';
        let phoneError = '';
        let passwrodError = '';
        let countryError = '';
        let stateError = '';
        let districtError = '';
        let cityError = '';
        let line1Error = '';
        let descriptionError = '';
        let postalCodeError = '';
        let typeError = '';
        let customerError = '';
        let numberOfMaxUserError = '';
        let numberOfMaxRetailerError = '';
        if (this.state.name.length === 0) {
            nameError = 'Name Is Required';
        } else if (/[0-9]/.test(this.state.name)) {
            nameError = 'Only Aplhabets';
        } else if (this.state.name.length > 100) {
            nameError = 'Name must be in 100 characters';
        }
        if (this.state.name.length === 0) {
            emailError = 'Email is Required';
        } else if (this.state.email.length !== 0) {
            // eslint-disable-next-line
            if (/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(this.state.email) === false) {
                emailError = 'Email is Incorrect';
            }
        }

        if (this.state.phone.length === 0) {
            phoneError = 'Mobile No. Is Required';
        } else if (!/^\d+$/.test(this.state.phone)) {
            phoneError = 'Only Numbers';
        } else if (this.state.phone.length !== 10) {
            phoneError = '10 Digits Please';
        }

        if (this.state.description.length === 0) {
            descriptionError = 'Description Is Required';
        }
        // if (this.state.password.length === 0) {
        //     passwrodError = 'Password is Required';
        // }
        if (this.state.country === '') {
            countryError = 'Country Is Required';
        }
        if (this.state.state === '') {
            stateError = 'State Is Required';
        }
        // if (this.state.district === '') {
        //     districtError = 'District Is Required';
        // }
        if (this.state.city === '') {
            cityError = 'City Is Required';
        }
        if (this.state.line1 === '') {
            line1Error = 'Address is Required';
        } else if (this.state.line1.length >= 500) {
            line1Error = 'Address must be in 500 characters';
        } if (this.state.postalCode.length === 0) {
            postalCodeError = 'Zip Code is Required';
        } else if (!/^\d+$/.test(this.state.postalCode)) {
            postalCodeError = 'Only Numbers';
        }
        if (this.state.type === '') {
            typeError = 'Type Is Required';
        }
        if (!/^\d+$/.test(this.state.numberOfMaxUser)) {
            numberOfMaxUserError = 'Only Numbers';
        }
        if (!/^\d+$/.test(this.state.numberOfMaxRetailer)) {
            numberOfMaxRetailerError = 'Only Numbers';
        }
        // if (this.state.type === "WHS" && this.state.customer_array === "") {
        //     customerError = "Wholesaler is Required*";
        // }
        if (nameError || emailError || phoneError || passwrodError || countryError || stateError || districtError || cityError || line1Error || postalCodeError || typeError || customerError || descriptionError || numberOfMaxUserError || numberOfMaxRetailerError) {
            this.setState({ nameError, emailError, phoneError, passwrodError, countryError, stateError, districtError, cityError, line1Error, postalCodeError, typeError, customerError, descriptionError, numberOfMaxUserError, numberOfMaxRetailerError });
            return false;
        }
        return true;
    };

    uploadFile = ({ target: { files } }) => {
        let data = new FormData();
        data.append("file", files[0]);
        const token = localStorage.getItem("token");
        const options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
                if (percent < 100) {
                    this.setState({ uploadPercentage: percent });
                }
            },
        };
        axios.defaults.headers = { "x-access-token": token };
        axios.post(apiUrl + "/api/brands/upload", data, options).then((res) => {
            this.setState({ logo: res.data.item.logo, uploadPercentage: 100 }, () => {
                setTimeout(() => {
                    this.setState({ uploadPercentage: 0 });
                }, 1000);
            });
        });
    };

    ondropdownChange = (val, name) => {
        if (val && val.value) {
            if (name === "country") {
                if (this.state.country !== null) {
                    if (val.value !== this.state.country.value) {
                        this.setState({ state: null, city: null });
                        let data = {
                            country: val.value
                        };
                        this.props.state_search(data)
                    }
                } else {
                    let data = {
                        country: val.value
                    };
                    this.props.state_search(data)
                }
            }
            if (name === "state") {
                if (this.state.state !== null) {
                    if (val.value !== this.state.state.value) {
                        this.setState({ city: null });
                        let data = {
                            state: val.value
                        };
                        this.props.city_search(data);
                    }
                } else {
                    let data = {
                        state: val.value
                    };
                    this.props.city_search(data);
                }
            }
            this.setState({ [name]: val });
        }
    }

    shouldComponentUpdate(nextProps) {

        if (nextProps.singleCompanyData && nextProps.singleCompanyData.success === true) {
            nextProps.singleCompanyData.success = null;
            let countryObj = {
                value:
                    nextProps.singleCompanyData !== undefined && nextProps.singleCompanyData.country
                        ? nextProps.singleCompanyData.country._id
                        : '',
                label:
                    nextProps.singleCompanyData !== undefined && nextProps.singleCompanyData.country
                        ? nextProps.singleCompanyData.country.name
                        : '',
            };
            let stateObj = {
                value:
                    nextProps.singleCompanyData !== undefined && nextProps.singleCompanyData.state
                        ? nextProps.singleCompanyData.state._id
                        : '',
                label:
                    nextProps.singleCompanyData !== undefined && nextProps.singleCompanyData.state
                        ? nextProps.singleCompanyData.state.name
                        : '',
            };
            let cityObj = {
                value:
                    nextProps.singleCompanyData !== undefined && nextProps.singleCompanyData.city
                        ? nextProps.singleCompanyData.city._id
                        : '',
                label:
                    nextProps.singleCompanyData !== undefined && nextProps.singleCompanyData.city
                        ? nextProps.singleCompanyData.city.name
                        : '',
            }

            this.setState({
                name:
                    nextProps.singleCompanyData !== undefined && nextProps.singleCompanyData.item
                        ? nextProps.singleCompanyData.item.name
                        : '',
                email:
                    nextProps.singleCompanyData !== undefined && nextProps.singleCompanyData.item
                        ? nextProps.singleCompanyData.item.email
                        : '',
                phone:
                    nextProps.singleCompanyData !== undefined && nextProps.singleCompanyData.item
                        ? nextProps.singleCompanyData.item.phone
                        : '',
                description: nextProps.singleCompanyData !== undefined && nextProps.singleCompanyData.item && nextProps.singleCompanyData.item.description
                    ? nextProps.singleCompanyData.item.description
                    : '',
                type:
                    nextProps.singleCompanyData !== undefined && nextProps.singleCompanyData.item
                        ? nextProps.singleCompanyData.item.type
                        : '',
                logo:
                    nextProps.singleCompanyData !== undefined && nextProps.singleCompanyData.item && nextProps.singleCompanyData.item.logo
                        ? nextProps.singleCompanyData.item.logo
                        : {},
                line1:
                    nextProps.singleCompanyData !== undefined && nextProps.singleCompanyData.item && nextProps.singleCompanyData.item.address
                        ? nextProps.singleCompanyData.item.address.line1
                        : '',
                postalCode:
                    nextProps.singleCompanyData !== undefined && nextProps.singleCompanyData.item && nextProps.singleCompanyData.item.address
                        ? nextProps.singleCompanyData.item.address.postalCode
                        : '',
                numberOfMaxUser: nextProps.singleCompanyData !== undefined && nextProps.singleCompanyData.item && nextProps.singleCompanyData.item.numberOfMaxUser
                    ? nextProps.singleCompanyData.item.numberOfMaxUser
                    : '',
                numberOfMaxRetailer: nextProps.singleCompanyData !== undefined && nextProps.singleCompanyData.item && nextProps.singleCompanyData.item.numberOfMaxRetailer
                    ? nextProps.singleCompanyData.item.numberOfMaxRetailer
                    : '',
                isAdvertiseDisplay:
                    nextProps.singleCompanyData !== undefined && nextProps.singleCompanyData.item
                        ? nextProps.singleCompanyData.item.isAdvertiseDisplay
                        : true,
                isCodeEditable:
                    nextProps.singleCompanyData !== undefined && nextProps.singleCompanyData.item
                        ? nextProps.singleCompanyData.item.isCodeEditable
                        : true,
                reportFormat:
                    nextProps.singleCompanyData !== undefined && nextProps.singleCompanyData.item
                        ? nextProps.singleCompanyData.item.reportFormat
                        : 'default',
                apiSecret:
                    nextProps.singleCompanyData !== undefined && nextProps.singleCompanyData.item && nextProps.singleCompanyData.item.auth
                        && nextProps.singleCompanyData.item.auth.apiSecret
                        ? nextProps.singleCompanyData.item.auth.apiSecret
                        : null,
                isActive:
                    nextProps.singleCompanyData !== undefined && nextProps.singleCompanyData.item
                        ? nextProps.singleCompanyData.item.isActive
                        : true,
                stockUpdateOn:
                    nextProps.singleCompanyData !== undefined && nextProps.singleCompanyData.item
                        ? nextProps.singleCompanyData.item.stockUpdateOn
                        : 'beforeConfirm',
                notificationType:
                    nextProps.singleCompanyData !== undefined && nextProps.singleCompanyData.item
                        ? nextProps.singleCompanyData.item.notificationType
                        : 'default',
                userAuthentication:
                    nextProps.singleCompanyData !== undefined && nextProps.singleCompanyData.item
                        ? nextProps.singleCompanyData.item.userAuthentication
                        : 'one-way',
                commissionSelectedOn:
                    nextProps.singleCompanyData !== undefined && nextProps.singleCompanyData.item
                        ? nextProps.singleCompanyData.item.commissionSelectedOn
                        : 'user',
                webFeature:
                    nextProps.singleCompanyData !== undefined && nextProps.singleCompanyData.item
                        ? nextProps.singleCompanyData.item.webFeature
                        : this.state.webFeature,
                mobileFeature:
                    nextProps.singleCompanyData !== undefined && nextProps.singleCompanyData.item
                        ? nextProps.singleCompanyData.item.mobileFeature
                        : this.state.mobileFeature
            });
            this.ondropdownChange(countryObj, "country");
            this.ondropdownChange(stateObj, "state");
            this.ondropdownChange(cityObj, "city");
        }

        if (nextProps.company_update !== undefined && nextProps.company_update.success === false && this.props !== nextProps) {
            nextProps.company_update.errors.map((item, index) =>
                toastr.error(Translate.translate({}, item.msg), 'Error', {
                    displayDuration: 5000
                })
            );
            nextProps.company_update.success = null;
        }

        if (nextProps.generatesecretkey_res !== undefined && nextProps.generatesecretkey_res.success === true && this.props !== nextProps) {
            // console.log(nextProps.generatesecretkey_res);

            if (nextProps.generatesecretkey_res.item && nextProps.generatesecretkey_res.item._id) {
                this.setState({
                    apiKey: nextProps.generatesecretkey_res.item._id
                });
            }

            if (nextProps.generatesecretkey_res.item && nextProps.generatesecretkey_res.item.secret) {
                this.setState({
                    apiSecret: nextProps.generatesecretkey_res.item.secret
                });
            }

        }

        if (nextProps.generatesecretkey_res !== undefined && nextProps.generatesecretkey_res.success === false && this.props !== nextProps) {
            nextProps.generatesecretkey_res.errors.map((item, index) =>
                toastr.error(Translate.translate({}, item.msg), 'Error', {
                    displayDuration: 5000
                })
            );
            nextProps.generatesecretkey_res.success = null;
        }

        return true;
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    checkBoxChange = (name, e) => this.setState({ [name]: e.target.checked });

    onsubmit = (e) => {
        e.preventDefault();
        const isValid = this.validate();
        if (isValid) {
            let id = this.props.match.params.item_id;
            let data = {
                isActive: this.state.isActive,
                name: this.state.name,
                description: this.state.description,
                email: this.state.email,
                phone: this.state.phone,
                logo: this.state.logo,
                type: "CO",
                password: this.state.password,
                address: {
                    country: this.state.country && this.state.country.value ? this.state.country.value : null,
                    state: this.state.state && this.state.state.value ? this.state.state.value : null,
                    city: this.state.city && this.state.city.value ? this.state.city.value : null,
                    // district: this.state.district,
                    line1: this.state.line1,
                    postalCode: this.state.postalCode
                },
                numberOfMaxUser: Number(this.state.numberOfMaxUser),
                numberOfMaxRetailer: Number(this.state.numberOfMaxRetailer),
                isAdvertiseDisplay: this.state.isAdvertiseDisplay,
                isCodeEditable: this.state.isCodeEditable,
                reportFormat: this.state.reportFormat,
                stockUpdateOn: this.state.stockUpdateOn,
                userAuthentication: this.state.userAuthentication,
                commissionSelectedOn: this.state.commissionSelectedOn,
                notificationType: this.state.notificationType,
                webFeature: this.state.webFeature,
                mobileFeature: this.state.mobileFeature
            }
            this.setState(this.state.error);
            this.props.companyUpdate(data, id);
        }
    }

    // shouldComponentUpdate(nextProps) {
    //     let req = {};

    // }

    render() {
        const { company_update } = this.props;
        const { uploadPercentage } = this.state;
        if (company_update !== undefined) {
            if (company_update.success === true) {
                toastr.success('Company Has been Updated Successfully', 'success');
                company_update.success = null;
                return <Redirect to={routehelp.primarydashboard} />
            }
        }
        return (
            <div className="app header-fixed left-sidebar-fixed scrollbarcustom left-sidebar-hidden">
                <Header />
                <div className="app-body">
                    <main className="main-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 mt-4">
                                    <div className="card card-shadow mb-4">
                                        <div className="card-header">
                                        <div className="card-title" id="add-company">Add Company</div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield handleChange={this.handleChange} maxlength="100" type="text" name="name" value={this.state.name} className="form-control" id="name" ariadescribedby="emailHelp" placeholder="Name" />
                                                    <small style={{ fontSize: 12, color: 'red' }}>
                                                        {' '}
                                                        {this.state.nameError}{' '}
                                                    </small>
                                                </div>
                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield handleChange={this.handleChange} maxlength="100" type="email" name="email" value={this.state.email} className="form-control" id="email" aria-describedby="emailHelp" placeholder="Email" />
                                                    <small style={{ fontSize: 12, color: 'red' }}>
                                                        {' '}
                                                        {this.state.emailError}{' '}
                                                    </small>
                                                </div>
                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield handleChange={this.handleChange} maxlength="10" type="text" name="phone" value={this.state.phone} className="form-control" id="phone" placeholder="Mobile" />
                                                    <small style={{ fontSize: 12, color: 'red' }}>
                                                        {' '}
                                                        {this.state.phoneError}{' '}
                                                    </small>
                                                </div>
                                                <div className="form-group col-12 col-lg-4">
                                                    <Inputfield handleChange={this.handleChange} maxlength="20" type="password" name="password" value={this.state.password} className="form-control" placeholder="Password" />
                                                </div>
                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield handleChange={this.handleChange} maxlength="10" type="text" name="numberOfMaxUser" value={this.state.numberOfMaxUser} className="form-control" id="maxuser" placeholder="Max Users" />
                                                    <small style={{ fontSize: 12, color: 'red' }}>
                                                        {' '}
                                                        {this.state.numberOfMaxUserError}{' '}
                                                    </small>
                                                </div>
                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield handleChange={this.handleChange} maxlength="10" type="text" name="numberOfMaxRetailer" value={this.state.numberOfMaxRetailer} className="form-control" id="retailerMaxUser" placeholder="Max Users for Retailers" />
                                                    <small style={{ fontSize: 12, color: 'red' }}>
                                                        {' '}
                                                        {this.state.numberOfMaxRetailerError}{' '}
                                                    </small>
                                                </div>
                                                <div className="form-group required col-12 col-lg-4">
                                                    <label htmlFor="exampleInputAddress" className="control-label">Description</label>
                                                    <textarea onChange={this.onChange} maxlength="200" name="description" value={this.state.description} className="form-control" id="description" rows="2"></textarea>
                                                    <small style={{ fontSize: 12, color: 'red' }}>
                                                        {' '}
                                                        {this.state.descriptionError}{' '}
                                                    </small>
                                                </div>

                                                <div className="form-group required col-12 col-lg-4">
                                                    <label htmlFor="exampleInputtype1" className="control-label">Report Format</label>
                                                    <select onChange={this.onChange} value={this.state.reportFormat} name="reportFormat" id="reportFormat" className="form-control" required>
                                                        {this.state.reportList.map((el, i) => {
                                                            return <option key={i} value={el.value}>{el.label}</option>
                                                        })}
                                                    </select>
                                                </div>

                                                <div className="form-group required col-12 col-lg-4">
                                                    <label htmlFor="exampleInputtype2" className="control-label">Stock Update on?</label>
                                                    <select onChange={this.onChange} value={this.state.stockUpdateOn} name="stockUpdateOn" id="stockUpdateOn" className="form-control" required>
                                                        {this.state.stockUpdateOnList.map((el, i) => {
                                                            return <option key={i} value={el.value}>{el.label}</option>
                                                        })}
                                                    </select>
                                                </div>

                                                <div className="form-group required col-12 col-lg-4">
                                                    <label htmlFor="exampleInputtype2" className="control-label">Notification Type?</label>
                                                    <select onChange={this.onChange} value={this.state.notificationType} name="notificationType" id="notificationType" className="form-control" required>
                                                        {this.state.notificationTypeList.map((el, i) => {
                                                            return <option key={i} value={el.value}>{el.label}</option>
                                                        })}
                                                    </select>
                                                </div>

                                                <div className="form-group required col-12 col-lg-4">
                                                    <label htmlFor="exampleInputtype3" className="control-label">User Authentication Type?</label>
                                                    <select onChange={this.onChange} value={this.state.userAuthentication} name="userAuthentication" id="userAuthentication" className="form-control" required>
                                                        {this.state.userAuthenticationList.map((el, i) => {
                                                            return <option key={i} value={el.value}>{el.label}</option>
                                                        })}
                                                    </select>
                                                </div>

                                                <div className="form-group required col-12 col-lg-4">
                                                    <label htmlFor="commissionDetails" className="control-label">Commission selected on?</label>
                                                    <select onChange={this.onChange} value={this.state.commissionSelectedOn} name="commissionSelectedOn" id="commissionSelectedOn" className="form-control" required>
                                                        {this.state.commissionDependencyList.map((el, i) => {
                                                            return <option key={i} value={el.value}>{el.label}</option>
                                                        })}
                                                    </select>
                                                </div>

                                                <div className="col-md-4">

                                                    {this.state.logo &&
                                                        this.state.logo.medium !== undefined ? (
                                                        <div className="upload-div">
                                                            <label
                                                                htmlFor="exampleInputName1"
                                                                className="control-label"
                                                            >
                                                                Logo
                                                            </label>
                                                            <div className="document-media">
                                                                <div
                                                                    onClick={() => {
                                                                        this.setState({ logo: {} });
                                                                    }}
                                                                    className="close-btn"
                                                                >
                                                                    {" "}
                                                                    <i className="fa fa-times"></i>
                                                                </div>
                                                                <img
                                                                    alt="img"
                                                                    className="img"
                                                                    src={uploadUrl + this.state.logo.medium}
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="form-group upload-div">
                                                            <label
                                                                htmlFor="exampleInputName1"
                                                                className="control-label"
                                                            >
                                                                Logo
                                                            </label>
                                                            <div className="upload-img">
                                                                <input
                                                                    maxLength="5"
                                                                    onChange={this.uploadFile}
                                                                    // value={this.state.code}
                                                                    name="logo"
                                                                    type="file"
                                                                    className="form-control"
                                                                    accept='.jpeg, .jpg, .png'
                                                                />
                                                            </div>
                                                            {/* <ErrorView message={this.state.logoError} /> */}
                                                            {/* <div className="progressbar-image mt-3">
                                                                {uploadPercentage > 0 && (
                                                                    <ProgressBar
                                                                        now={uploadPercentage}
                                                                        active
                                                                        label={`${uploadPercentage}%`}
                                                                    />
                                                                )}
                                                            </div> */}
                                                        </div>
                                                    )}
                                                    <div className="progressbar-image mt-3">
                                                        {uploadPercentage > 0 && (
                                                            <ProgressBar
                                                                now={uploadPercentage}
                                                                active
                                                                label={`${uploadPercentage}%`}
                                                            />
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group col-12 col-lg-4">
                                                    <Inputfield handleChange={this.checkBoxChange} type="checkbox" name="isAdvertiseDisplay" checked={this.state.isAdvertiseDisplay} placeholder="Advertise display on mobile application ? " className="ml-2" />
                                                </div>

                                                <div className="form-group col-12 col-lg-4">
                                                    <Inputfield handleChange={this.checkBoxChange} type="checkbox" name="isCodeEditable" checked={this.state.isCodeEditable} placeholder="Is user code is editable ? " className="ml-2" />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-shadow mb-4">
                                        <div className="card-header">
                                            <div className="card-title">
                                                Add Address
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="form-group required col-12 col-lg-4">
                                                    <CountryDropdown
                                                        value={this.state.country}
                                                        ondropdownChange={(e) => this.ondropdownChange(e, "country")}
                                                        placeholder="Search Country"
                                                    />
                                                    <small style={{ fontSize: 10, color: 'red' }}>
                                                        {' '}
                                                        {this.state.countryError}{' '}
                                                    </small>
                                                </div>
                                                <div className="form-group required col-12 col-lg-4">
                                                    <StateDropdown
                                                        ondropdownChange={(e) => this.ondropdownChange(e, "state")}
                                                        value={this.state.state}
                                                        country={this.state.country}
                                                        placeholder="Search State"
                                                    />
                                                    <small style={{ fontSize: 10, color: 'red' }}>
                                                        {' '}
                                                        {this.state.stateError}{' '}
                                                    </small>
                                                </div>
                                                <div className="form-group required col-12 col-lg-4">
                                                    <CityDropdown
                                                        ondropdownChange={(e) => this.ondropdownChange(e, "city")}
                                                        value={this.state.city}
                                                        placeholder="Search City"
                                                        state={this.state.state}
                                                        country={this.state.country}
                                                    />
                                                    <small style={{ fontSize: 10, color: 'red' }}>
                                                        {' '}
                                                        {this.state.cityError}{' '}
                                                    </small>
                                                </div>
                                                <div className="form-group required col-12 col-lg-4">
                                                    <label htmlFor="exampleInputAddress" className="control-label">Address line-1</label>
                                                    <textarea onChange={this.onChange} maxlength="200" name="line1" value={this.state.line1} className="form-control" id="line1" rows="2"></textarea>
                                                    <small style={{ fontSize: 12, color: 'red' }}>
                                                        {' '}
                                                        {this.state.line1Error}{' '}
                                                    </small>
                                                </div>
                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield handleChange={this.handleChange} maxlength="6" type="text" name="postalCode" value={this.state.postalCode} className="form-control" id="postalCode" placeholder="Zip Code" />
                                                    {/* <Inputfield handleChange={this.handleChange} type="text" name="postalCode" value={this.state.postalCode} className="form-control" id="exampleInputZipCode" placeholder="Zip Code" /> */}
                                                    <small style={{ fontSize: 12, color: 'red' }}>
                                                        {' '}
                                                        {this.state.postalCodeError}{' '}
                                                    </small>
                                                </div>
                                                {/* <div className="row ml-4">
                                                    {this.state.modulePermi.map((item, idx) => (
                                                        <div className="form-group required m-3">
                                                            <label className="tgl-btn m-0" htmlFor={idx}>{item.label}</label>
                                                            <input className="tgl tgl-light tgl-warning" name={item.name} id={idx} defaultChecked={item.value} type="checkbox" onChange ={(e) => this.checkhandler(e, idx)} />
                                                            <label className="tgl-btn m-0" htmlFor={idx}></label>
                                                        </div>
                                                    ))}
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-shadow mb-4">
                                        <div className="card-header">
                                            <div className="card-title">
                                                Third Party Access Details
                                            </div>
                                        </div>

                                        <div className="card-body">

                                            <div className="row">
                                                <div className='col-12 col-md-3'>
                                                    <div className="form-group required">
                                                        <label htmlFor="isActive" id="isActive">Is Active?</label>&nbsp;&nbsp;
                                                        <label className="switch">
                                                            <input type="checkbox" onChange={(e) => this.isActiveFun()} name="isActive" checked={this.state.isActive} />
                                                            <span className="slider"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className='col-12 col-md-9'>
                                                    <div className='card-shadow-key'>
                                                        <div className="form-group required text-center">
                                                            <button type='button' name="generate-secret-key" className='btn btn-success' onClick={(e) => this.generateSeceretKey()}>
                                                                {
                                                                    !this.state.apiSecret ? "Generate Secret Key" : "Re-Generate Secret Key"
                                                                }
                                                            </button>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-12 col-md-6'>
                                                                <div className="form-group">
                                                                    <label htmlFor="apiKey">API Key:</label>
                                                                    <input type='text' value={this.props.match.params.item_id} name="apiKey" className='form-control' disabled />
                                                                </div>
                                                            </div>

                                                            <div className='col-12 col-md-6'>
                                                                <div className="form-group ">
                                                                    <label htmlFor="apiSecret">API Secret:</label>
                                                                    <input type='text' value={this.state.apiSecret || ""} name="apiSecret" className='form-control' disabled />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-shadow mb-4">
                                        <div className="card-header">
                                            <div className="card-title">
                                                Company Users Access Details
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className='row ml-0 mb-3'>
                                                <label><b>Mobile Features</b></label>
                                            </div>
                                            <div className="row">
                                                {
                                                    this.state.mobileFeatureList && this.state.mobileFeatureList.length > 0 ? this.state.mobileFeatureList.map((ele, index) =>
                                                        <div className="form-group required col-12 col-lg-4" key={index}>
                                                            <label htmlFor={ele.label} className='label-text' id={ele.label}>{ele.label}</label>&nbsp;&nbsp;
                                                            <label className="switch">
                                                                <input type="checkbox" onChange={(e) => this.featureChange(`mobileFeature.${ele.name}`, e)} name={ele.name} checked={this.state.mobileFeature[ele.checked]} />
                                                                <span className="slider"></span>
                                                            </label>
                                                        </div>
                                                    ) : null
                                                }
                                            </div>

                                            <div className='row ml-0 mb-3'>
                                                <label><b>Web Features</b></label>
                                            </div>

                                            <div className="row">

                                                {
                                                    this.state.webFeatureList && this.state.webFeatureList.length > 0 ?
                                                        this.state.webFeatureList.map((ele, index) =>
                                                            <div className="form-group required col-12 col-lg-4" key={index}>
                                                                <label className='label-text' key={index} htmlFor={ele.label} id={ele.label}>{ele.label}</label>&nbsp;&nbsp;
                                                                <label className="switch" key={"s" + index}>
                                                                    <input type="checkbox" key={"i" + index} onChange={(e) => this.featureChange(`webFeature.${ele.name}`, e)} name={ele.name} checked={this.state.webFeature[ele.checked]} />
                                                                    <span className="slider" key={"span" + index}></span>
                                                                </label>
                                                            </div>
                                                        ) : null
                                                }
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 mb-4 offset-md-3 text-center">
                                    <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" name="Update" />
                                    <Links to={routehelp.primarydashboard} className="btn btn-outline-dark" name="Cancel" />
                                </div>
                            </div>

                        </div >
                    </main >
                </div>
                <Footer />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        company_update: state.company_update,
        singleCompanyData: state.singleCompanyData,
        generatesecretkey_res: state.generatesecretkey_res
    };
}

const mapDispatchToProps = dispatch => ({
    companyUpdate: (data, id) => dispatch(companyUpdate(data, id)),
    state_search: data => dispatch(state_search(data)),
    district_search: data => dispatch(district_search(data)),
    city_search: data => dispatch(city_search(data)),
    companySingle: data => dispatch(companySingle(data)),
    generatesecretkey: data => dispatch(generatesecretkey(data))
});

CompanyUpdate = connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyUpdate);

export default CompanyUpdate;