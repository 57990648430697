import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { uploadUrl } from '../../config';
import { paymentlist, delete_payment, paymentSingle, partyNamesList, paymentExport, status_payment, invoiceNumList } from '../../actions';
import { errorRender, successRender, commonDebounce, fileDownload, loaderUpdate } from '../../helpers/functions';
import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';
import Userdropdown from '../userManagement/userDropdwon';
import SearchableDropDown from '../../components/searchableDropDown';
import toastr from 'reactjs-toastr';
import Buttons from '../../components/buttons';

const Loader = lazy(() => import('../../components/loader'));
const Pagination = lazy(() => import('../../components/pagination/index'));
const DataTable = lazy(() => import('../../components/dataTalble'));
const ButtonWithItag = lazy(() => import('../../components/buttonwithTagi'));
const Entries = lazy(() => import('../../components/entries/entires'));
// const LinkWithItag = lazy(() => import('../../components/linkwithItag'));
// const SearchInput = lazy(() => import('../../components/searchInput'));

let itemsPerPage = ITEM_PER_PAGES;
let currentPage = CURRENT_PAGE;
let sortBy = SORT_BY;
let sort = SORT;

class PaymentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            from: "",
            to: "",
            createdFrom: "",
            createdTo: "",
            statusList: [
                {value: 'Pending', label: 'Pending'},
                {value: 'Approved', label: 'Approved'},
                {value: 'Rejected', label: 'Rejected'},
            ],
            status: "",
            partyNamesList: [],
            invoiceNumList:[],
            user: null,
            partyName: null,
            invoiceNum:null,
            isFilter: false,
            isExportLoading: false,
            isViewLoading: false,
            isLoading: false,
            statusUpdated: "Status has been updated successfully",
            deleteSuccess: "Payment Has been Deleted Successfully",
            serverErrors: ["payment_delete", "payment_res", 'paymentExp_res', 'payment_delete_res', 'payment_status_res'],
            tableHeaders: [
                {
                    name: 'user.name',
                    label: "User",
                    type: "obj",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'partyName',
                    label: "Party Name",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'invoiceNum',
                    label: "Invoices",
                    type: "loop",
                    keyName: "name",
                    isLink: false,
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'amount',
                    label: "Amount",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                // {
                //     name: 'modeOfPayment',
                //     label: "Mode of Payment",
                //     type: "text",
                //     sortable: false,
                //     isShow: true
                // },
                {
                    name: 'accountDetails.accHolderName',
                    label: "Account Holder Name",
                    type: "obj",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'accountDetails.account',
                    label: "Account No",
                    type: "obj",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'status',
                    label: "Payment Status",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'date',
                    label: "Payment Date",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'createdAt',
                    label: "CreatedAt",
                    type: "date",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'actions',
                    label: "Actions",
                    type: "button",
                    sortable: false,
                    isShow: true,
                    allactions: [
                        // {
                        //     name: "edit",
                        //     title: "Edit",
                        //     classNameI: "ti-pencil",
                        //     className: "btn btn-link text-pramiry action-btn mr-2 p-0",
                        //     function: redirect
                        // },
                        {
                            name: "view",
                            classNameI: "ti-eye",
                            className: "btn btn-link text-primary action-btn p-0",
                            datatoggle: "modal",
                            datatarget: ".bd-example-modal-lg",
                            dataplacement: "top",
                            title: "Payment Details",
                            dataoriginaltitle: "Click to Payment Details",
                            isPermission: "view",
                            function: this.openView
                        },
                        {
                            name: "delete",
                            title: "Delete",
                            classNameI: "ti-trash",
                            className: "btn btn-link text-danger action-btn p-0",
                            isPermission: "delete",
                            function: this.deleteRow
                        }
                    ]
                }
            ]
        }
    }

    openView = (e, item) => {
        this.setState({
            isViewLoading: true
        });
        this.props.paymentSingle(item._id);
    }

    componentDidMount() {
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        this.loadResult();
        this.props.partyNamesList({});
        this.props.invoiceNumList({});
    }

    componentWillUnmount() {
        currentPage = CURRENT_PAGE;
        itemsPerPage = ITEM_PER_PAGES;
    }

    loadResult = () => {
        let data = {};
        data.filter = { 
            user: this.state.user && this.state.user.value ? this.state.user.value : null,
            invoiceNum:this.state.invoiceNum && this.state.invoiceNum.label ? this.state.invoiceNum.label : null
         };

        if (this.state.partyName && this.state.partyName.label) {
            data.partyNames = [this.state.partyName.label];
        };

        if (this.state.status && this.state.status.label) {
            data.filter.status = this.state.status.value;
        };

        // console.log(this.state.from, this.state.to);

        if (this.state.from && this.state.to) {
            let splitFrom = this.state.from.split('-');
            let splitTo = this.state.to.split('-');
            data.date = {
                from: `${splitFrom[2]}-${splitFrom[1]}-${splitFrom[0]}`,
                to: `${splitTo[2]}-${splitTo[1]}-${splitTo[0]}`
            };
        } else if (this.state.from) {
            let splitFrom = this.state.from.split('-');
            data.date = {
                from: `${splitFrom[2]}-${splitFrom[1]}-${splitFrom[0]}`,
                to: `${splitFrom[2]}-${splitFrom[1]}-${splitFrom[0]}`
            };
        } else if (this.state.to) {
            alert("Please select From date");
            return true;
        };

        if (this.state.createdFrom && this.state.createdTo) {
            let splitFrom = this.state.createdFrom.split('-');
            let splitTo = this.state.createdTo.split('-');
            data.createdAt = {
                from: `${splitFrom[2]}-${splitFrom[1]}-${splitFrom[0]}`,
                to: `${splitTo[2]}-${splitTo[1]}-${splitTo[0]}`
            };
        } else if (this.state.createdFrom) {
            let splitFrom = this.state.createdFrom.split('-');
            data.createdAt = {
                from: `${splitFrom[2]}-${splitFrom[1]}-${splitFrom[0]}`,
                to: `${splitFrom[2]}-${splitFrom[1]}-${splitFrom[0]}`
            };
        } else if (this.state.createdTo) {
            alert("Please select From date");
            return true;
        };

        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        data.skip = this.state.name ? 0 : (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;

       
        this.props.paymentlist(data);
        this.setState({ isLoading: true, isFilter: false });
    }

    resetfilter = () => {
        this.setState({ isLoading: true, isFilter: false, user: null, partyName: null, invoiceNum:null, from: "", to: "", status: "", createdFrom:"", createdTo: "" });
        let data = {};
        data.filter = { user: null };
        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        data.skip = this.state.name ? 0 : (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        delete data.partyNames;
        delete data.invoiceNum;
        this.props.paymentlist(data);
    }

    sortBy = (key) => {
        sortBy = key;
        if (sort === 'desc') {
            sort = 'asc';
        } else {
            sort = 'desc';
        }
        this.loadResult();
    }

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    }

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    }

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    }

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    deleteRow = (e, item) => {
        // console.log(item._id);
        if (window.confirm(`Are you sure you wish to delete "${item.partyName}" ?`)) {
            this.props.delete_payment(item._id);
            e.preventDefault();
        }
    }

    onChangeforDebounce = ({ target: { value } }) => {
        if (!/^\s/.test(value)) {
            this.setState({ name: value }, () => this.callforDebounce(value))
        }
    }

    ondropdownChange = (e, t) => {
        // console.log(e, t);
        this.setState({ [e]: t });
    }

    callforDebounce = () => this.loadResult();

    shouldComponentUpdate(nextProps) {
        // console.log(nextProps);
        if (nextProps !== this.props) {


            // console.log(this.state.isLoading)
            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "payment_res") });
            }

            if (nextProps && nextProps.paymentSingle_res) {
                this.setState({
                    isViewLoading: false
                });
            }

            if (nextProps && nextProps.paymentExp_res) {
                this.setState({
                    isExportLoading: false
                });
            }

            if (nextProps.paymentExp_res !== undefined && nextProps.paymentExp_res.success === true) {
                if (nextProps.paymentExp_res.success && nextProps.paymentExp_res.item && nextProps.paymentExp_res.item.fileName === null) {
                    toastr.error('No Record Found', 'Error', {
                        displayDuration: 5000
                    });
                } else {
                    fileDownload(nextProps, "paymentExp_res", "fileName");
                }
            }

            if (nextProps && nextProps.partynamelist_res &&
                nextProps.partynamelist_res.success && nextProps.partynamelist_res.list && nextProps.partynamelist_res.list.length > 0) {
                // console.log(nextProps.partynamelist_res);
                // console.log(nextProps.partynamelist_res.list.map((ele) => { return { value: ele.partyName, label: ele.partyName } }));
                this.setState({
                    partyNamesList: nextProps.partynamelist_res.list.map((ele) => { return { value: ele.partyName, label: ele.partyName } })
                });
            }

            if (nextProps && nextProps.invoicenumlist_res &&
                nextProps.invoicenumlist_res.success && nextProps.invoicenumlist_res.list && nextProps.invoicenumlist_res.list.length > 0) {
                this.setState({
                    invoiceNumList : nextProps.invoicenumlist_res.list.map((ele) => { return { value: ele.name, label: ele.name } })
                });
            }

            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            successRender(nextProps, "payment_delete_res", this.loadResult, this.state.deleteSuccess);

            if (nextProps && nextProps.payment_status_res &&
                nextProps.payment_status_res.success) {
                // console.log(nextProps.payment_status_res.item._id);
                this.openView('', nextProps.payment_status_res.item ? nextProps.payment_status_res.item : null)
                successRender(nextProps, "payment_status_res", this.loadResult, this.state.statusUpdated);
            }

        }
        
        return true;
    }

    export = () => {

        this.setState({
            isExportLoading: true
        });

        let data = {};
        data.filter = { 
            user: this.state.user && this.state.user.value ? this.state.user.value : null,
            invoiceNum:this.state.invoiceNum && this.state.invoiceNum.label ? this.state.invoiceNum.label : null
        };

        if (this.state.partyName && this.state.partyName.label) {
            data.partyNames = [this.state.partyName.label];
        };

        if (this.state.status && this.state.status.label) {
            data.filter.status = this.state.status.value;
        };

        // console.log(this.state.from, this.state.to);

        if (this.state.from && this.state.to) {
            let splitFrom = this.state.from.split('-');
            let splitTo = this.state.to.split('-');
            data.date = {
                from: `${splitFrom[2]}-${splitFrom[1]}-${splitFrom[0]}`,
                to: `${splitTo[2]}-${splitTo[1]}-${splitTo[0]}`
            };
        } else if (this.state.from) {
            let splitFrom = this.state.from.split('-');
            data.date = {
                from: `${splitFrom[2]}-${splitFrom[1]}-${splitFrom[0]}`,
                to: `${splitFrom[2]}-${splitFrom[1]}-${splitFrom[0]}`
            };
        } else if (this.state.to) {
            alert("Please select From date");
            return true;
        };

        if (this.state.createdFrom && this.state.createdTo) {
            let splitFrom = this.state.createdFrom.split('-');
            let splitTo = this.state.createdTo.split('-');
            data.createdAt = {
                from: `${splitFrom[2]}-${splitFrom[1]}-${splitFrom[0]}`,
                to: `${splitTo[2]}-${splitTo[1]}-${splitTo[0]}`
            };
        } else if (this.state.createdFrom) {
            let splitFrom = this.state.createdFrom.split('-');
            data.createdAt = {
                from: `${splitFrom[2]}-${splitFrom[1]}-${splitFrom[0]}`,
                to: `${splitFrom[2]}-${splitFrom[1]}-${splitFrom[0]}`
            };
        } else if (this.state.createdTo) {
            alert("Please select From date");
            return true;
        };

        // console.log(data);
        this.props.paymentExport(data);
    }

    toggleFilter = () => {
        this.setState({ isFilter: !this.state.isFilter })
    };

    statusCall = (status, id) => {
        this.props.status_payment(id, status);
    }

    render() {
        const { payment_res, paymentSingle_res } = this.props;
        const totResult = payment_res ? payment_res.count : 0;

        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        Payments
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">

                                        <div className="row">

                                            <div className="col-sm-12 col-md-6">
                                                {totResult === 0 ? null :
                                                    <Entries
                                                        itemsPerPage={itemsPerPage}
                                                        handleChangeiItemsPerPage={
                                                            this.handleChangeiItemsPerPage
                                                        }
                                                    />
                                                }
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <div className="add-btn-icon mb-4">
                                                    <ButtonWithItag classNameI="fa tai-ico-Export" disabled={this.state.isExportLoading} type="button" onclick={this.export} className="btn btn-primary mr-1" data-toggle="tooltip" data-placement="top" title="Export as a file" data-original-title="Click to Upload all" />
                                                    <ButtonWithItag classNameI="ti-filter" type="button" onclick={this.toggleFilter} className="btn btn-primary mr-1" data-toggle="tooltip" data-placement="top" title="Filter" data-original-title="Click for Filter option" />
                                                    {/* <SearchInput onChangeforDebounce={this.onChangeforDebounce} value={name} /> */}
                                                    {/* <LinkWithItag to={routehelp.uomAdd} className="btn float-left  btn-primary mr-2" role="button" classNameI="fa fa-plus" title="Add UOM" /> */}
                                                </div>
                                            </div>

                                        </div>

                                        {
                                            totResult === undefined ?
                                                <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy}
                                                    sortByvalue={sortBy} sortvalue={sort}
                                                    data={[]} props={''} moduleName={"payment"} /> :
                                                this.state.isLoading ?
                                                    <Loader />
                                                    :
                                                    payment_res !== undefined && payment_res.count === 0 ?
                                                        <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy}
                                                            sortByvalue={sortBy} sortvalue={sort}
                                                            data={[]} props={''} moduleName={"payment"} />
                                                        :
                                                        payment_res && payment_res.count > 0 ?
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <div className='table-responsive'>
                                                                        {/* <div className="table table-bordered table-striped dataTable"> */}
                                                                        <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy}
                                                                            sortByvalue={sortBy} sortvalue={sort}
                                                                            data={payment_res} props={this.props} moduleName={"payment"} />
                                                                        {/* </div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : <p className="text-center">Something Went Wrong</p>
                                        }

                                        {totResult !== undefined ? payment_res !== undefined && payment_res.count === 0 ? "" :
                                            <Pagination
                                                handleChangeiItemsPerPage={
                                                    this.handleChangeiItemsPerPage
                                                }
                                                handlePage={this.handlePage}
                                                handleNext={this.handleNext}
                                                handlePrevious={this.handlePrevious}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                totResult={totResult}
                                                key={currentPage}
                                            />
                                            : ""}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="modal fade bd-example-modal-lg"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myLargeModalLabel"
                    style={{ display: "none" }}
                    aria-hidden="true"
                >
                    <div className="modal-dialog commission-modal-md modal-custom-width">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="myLargeModalLabel">
                                    Payment Details
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            {
                                this.state.isViewLoading ? <div className='justify-content-center ml-5 mt-5 mb-5'><Loader /></div> :
                                    <div className="modal-body">
                                        {
                                            paymentSingle_res && paymentSingle_res.item ?
                                                <div className='row'>

                                                    <div className='form-group col-md-6'>
                                                        <label className='control-label'>User</label>
                                                        <div className='form-control'>{paymentSingle_res.item.user && paymentSingle_res.item.user.name ? paymentSingle_res.item.user.name : "NA"}</div>
                                                    </div>

                                                    <div className='form-group col-md-6'>
                                                        <label className='control-label'>Party Name</label>
                                                        <div className='form-control'>{paymentSingle_res.item.partyName ? paymentSingle_res.item.partyName : "NA"}</div>
                                                    </div>

                                                    <div className='form-group col-md-6'>
                                                        <label className='control-label'>Invoices</label>
                                                        <div className='form-control'>
                                                            {
                                                                paymentSingle_res.item.invoiceNum && paymentSingle_res.item.invoiceNum.length > 0 ?
                                                                    paymentSingle_res.item.invoiceNum.map((ele) => ele.name).join(', ') : "NA"
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className='form-group col-md-6'>
                                                        <label className='control-label'>Amount</label>
                                                        <div className='form-control'>{paymentSingle_res.item.amount ? paymentSingle_res.item.amount : "NA"}</div>
                                                    </div>

                                                    <div className='form-group col-md-6'>
                                                        <label className='control-label'>Mode of Payment</label>
                                                        <div className='form-control'>{paymentSingle_res.item.modeOfPayment ? paymentSingle_res.item.modeOfPayment : "NA"}</div>
                                                    </div>

                                                    <div className='form-group col-md-6'>
                                                        <label className='control-label'>Referance Number</label>
                                                        <div className='form-control'>{paymentSingle_res.item.refNum ? paymentSingle_res.item.refNum : "NA"}</div>
                                                    </div>

                                                    <div className='form-group col-md-6'>
                                                        <label className='control-label'>Payment Date</label>
                                                        <div className='form-control'>{paymentSingle_res.item.date ? paymentSingle_res.item.date : "NA"}</div>
                                                    </div>

                                                    <div className='form-group col-md-6'>
                                                        <label className='control-label'>Payment status</label>
                                                        <div className='form-control'>{paymentSingle_res.item.status ? paymentSingle_res.item.status : "NA"}</div>
                                                    </div>

                                                    {/* <div className='form-group col-md-6'>
                                                        <label className='control-label'>Mode of Payment</label>
                                                        <div className='form-control'>{paymentSingle_res.item.modeOfPayment ? paymentSingle_res.item.modeOfPayment : "NA"}</div>
                                                    </div> */}

                                                    <div className='form-group col-md-6'>
                                                        <label className='control-label'>Account Holder Name</label>
                                                        <div className='form-control'>{paymentSingle_res.item.accountDetails && paymentSingle_res.item.accountDetails.accHolderName ? paymentSingle_res.item.accountDetails.accHolderName : "NA"}</div>
                                                    </div>

                                                    <div className='form-group col-md-6'>
                                                        <label className='control-label'>Account No</label>
                                                        <div className='form-control'>{paymentSingle_res.item.accountDetails && paymentSingle_res.item.accountDetails.account ? paymentSingle_res.item.accountDetails.account : "NA"}</div>
                                                    </div>

                                                    <div className='form-group col-md-6'>
                                                        <label className='control-label'>Remarks</label>
                                                        <div className='form-control'>{paymentSingle_res.item.remarks ? paymentSingle_res.item.remarks : "NA"}</div>
                                                    </div>

                                                    <div className='form-group col-md-6'></div>
                                                    <div className='form-group col-md-12'>
                                                        <label className='control-label'>Payment Docs</label>
                                                        {/* {
                                                            paymentSingle_res.item.paymentImg && paymentSingle_res.item.paymentImg.medium ?
                                                                <div className="form-group required col-12 col-lg-6">
                                                                    <a href={paymentSingle_res.item.paymentImg.large ? uploadUrl + paymentSingle_res.item.paymentImg.large : uploadUrl + paymentSingle_res.item.paymentImg.medium} target="_blank" title='View Image'>
                                                                        <div className="document-media">
                                                                            <img alt="img" className="img" src={uploadUrl + paymentSingle_res.item.paymentImg.medium} />
                                                                            <div className="media-content">
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                :
                                                                <div className='form-control'>NA</div>
                                                        } */}
                                                        <div className='row'>
                                                            {
                                                                paymentSingle_res.item.paymentDocs && paymentSingle_res.item.paymentDocs.length > 0 ?
                                                                    paymentSingle_res.item.paymentDocs.map((ele) => {
                                                                        return (
                                                                            <a href={uploadUrl + ele.filename} target="_blank" title={ele.filename} className="col-md-6 col-12 custom-link-card">
                                                                                <div className='document-media'>
                                                                                    {
                                                                                        ['png', 'jpeg', 'jpg', 'PNG', 'JPEG', 'JPG'].indexOf(ele.type) > -1 ?
                                                                                            // <img alt="img" className="img" src={uploadUrl + ele.filename} />
                                                                                            <img alt="img" className="img" src="/assets/img/image.png" />
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        ele.type === 'pdf' || ele.type === 'PDF' ?
                                                                                            <img alt="img" className="img" src="/assets/img/pdf.png" />
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        ['docs', 'doc', 'docx', 'DOCS', 'DOC', 'DOCX'].indexOf(ele.type) > -1 ?
                                                                                            <img alt="img" className="img" src="/assets/img/word.png" />
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        ['docs', 'doc', 'docx', 'pdf', 'jpeg', 'jpg', 'png', 'DOCS', 'DOC', 'DOCX', 'PDF', 'PNG', 'JPEG', 'JPG'].indexOf(ele.type) < 0 ?
                                                                                            <img alt="img" className="img" src="/assets/img/other.png" /> : null
                                                                                    }
                                                                                    <div className='overlay'>{ele.filename}</div>
                                                                                </div>
                                                                            </a>
                                                                        )
                                                                    }) : <div className='col-xl-auto col-md-auto'>
                                                                        <div className='document-media d-flex align-items-center justify-content-center'>No Data Found</div>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>

                                                    {
                                                        !paymentSingle_res.item.status || paymentSingle_res.item.status === 'Pending' ?
                                                            <div className='form-group col-md-12'>
                                                                <div className='row justify-content-center'>
                                                                    <Buttons onclick={(e) => this.statusCall('approve', paymentSingle_res.item._id)} type="button" className="btn btn-primary mr-1" name="Approve" />
                                                                    <Buttons onclick={(e) => this.statusCall('reject', paymentSingle_res.item._id)} type="button" className="btn btn-danger" name="Reject" />
                                                                </div>
                                                            </div> : paymentSingle_res.item.status === 'Rejected' ?
                                                                <div className='form-group col-md-12'>
                                                                    <div className='row justify-content-center'>
                                                                        <Buttons onclick={(e) => this.statusCall('approve', paymentSingle_res.item._id)} type="button" className="btn btn-primary mr-1" name="Approve" />
                                                                    </div>
                                                                </div> : null
                                                    }

                                                </div>
                                                :
                                                <div className='row mt-5 mb-5 text-center'>No Data Found</div>
                                        }
                                    </div>
                            }
                        </div>
                    </div>
                </div>

                <div className={!this.state.isFilter ? "right-sidebarfilter hidden" : "right-sidebarfilter show"}>
                    <div className="modal-header bg-primary rounded-0">
                        <h6 className="filter-modal-title">Filter</h6>
                        <button type="button" onClick={this.toggleFilter} className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" className="navbar-toggler right-sidebar-toggler pointer">×</span>
                        </button>
                    </div>
                    <form>
                        <div className="card-body">
                            <div className="form-group">
                                {/* {console.log(this.state.user)} */}
                                <Userdropdown
                                    ondropdownChange={this.ondropdownChange}
                                    name="user"
                                    value={this.state.user}
                                    className="form-control"
                                    placeholder="Users"
                                />
                            </div>

                            <div className="form-group">
                                <div>
                                    <label className="control-label">Party Names</label>
                                    <SearchableDropDown
                                        value={this.state.partyName}
                                        onChange={(e) => this.ondropdownChange("partyName", e)}
                                        options={this.state.partyNamesList}
                                        instanceId="partyname-id"
                                        prefixValue="partyname"
                                        placeholder={'PartyName'}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                    <label className="control-label">Invoices Number</label>
                                    <SearchableDropDown
                                        value={this.state.invoiceNum}
                                        onChange={(e) => this.ondropdownChange("invoiceNum", e)}
                                        options={this.state.invoiceNumList}
                                        instanceId="invoiceNum-id"
                                        prefixValue="invoiceNum"
                                        placeholder={'Invoice Number'}
                                    />
                            </div>
                            
                            <div className="form-group">
                                <div>
                                    <label className="control-label">Payment Status</label>
                                    <SearchableDropDown
                                        value={this.state.status}
                                        onChange={(e) => this.ondropdownChange("status", e)}
                                        options={this.state.statusList}
                                        instanceId="status-id"
                                        prefixValue="status"
                                        placeholder={'Payment Status'}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-6">
                                    <label className="control-label">Payment Date From</label>
                                    <input maxLength="10" type="date" className="form-control" name="from" value={this.state.from} onChange={this.onChange} id="fromdate" placeholder="From" />
                                </div>
                                <div className="form-group col-6">
                                    <label className="control-label">Payment Date To</label>
                                    <input maxLength="10" type="date" className="form-control" name="to" value={this.state.to} onChange={this.onChange} id="todate" placeholder="To" />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-6">
                                    <label className="control-label">Payment Created Date From</label>
                                    <input maxLength="10" type="date" className="form-control" name="createdFrom" value={this.state.createdFrom} onChange={this.onChange} id="createdTo" placeholder="From" />
                                </div>
                                <div className="form-group col-6">
                                    <label className="control-label">Payment Created Date To</label>
                                    <input maxLength="10" type="date" className="form-control" name="createdTo" value={this.state.createdTo} onChange={this.onChange} id="createdTo" placeholder="To" />
                                </div>
                            </div>

                        </div>
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <button onClick={this.loadResult} data-dismiss="modal" type="button" aria-hidden="true" className="btn btn-primary navbar-toggler right-sidebar-toggler pointer mr-1">Apply </button>
                            <button type="button" data-dismiss="modal" onClick={this.resetfilter} className="btn btn-outline-dark">Reset</button>
                        </div>
                    </form>
                </div>
            </main >
        )
    }
}

function mapStateToProps(state) {
    return {
        payment_res: state.payment_res,
        payment_delete_res: state.payment_delete_res,
        payment_status_res: state.payment_status_res,
        paymentSingle_res: state.paymentSingle_res,
        partynamelist_res: state.partynamelist_res,
        invoicenumlist_res: state.invoicenumlist_res,
        paymentExp_res: state.paymentExp_res
    };
}

const mapDispatchToProps = dispatch => ({
    paymentlist: data => dispatch(paymentlist(data)),
    partyNamesList: data => dispatch(partyNamesList(data)),
    invoiceNumList: data => dispatch(invoiceNumList(data)),
    paymentSingle: id => dispatch(paymentSingle(id)),
    delete_payment: item_id => dispatch(delete_payment(item_id)),
    status_payment: (item_id, status) => dispatch(status_payment(item_id, status)),
    paymentExport: data => dispatch(paymentExport(data))
});

PaymentList = connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentList);

export default PaymentList;