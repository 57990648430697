import React, { Component, lazy } from "react";
import { connect } from "react-redux";
import Inputfield from "../../components/inputfields";
import Buttons from "../../components/buttons";
import Links from "../../components/links";
import { routehelp } from "../../Routehelper";
import { categoryCreate, categorySingle, categoryUpdate } from "../../actions";
import SingleBrandDropDown from '../brandsManagement/singleBrandDropDown';
import { apiUrl, uploadUrl } from "../../config";
import { ProgressBar } from "react-bootstrap";
import { errorRender, successRedriect, getValue, validate, inputCheck } from "../../helpers/functions";
import axios from "axios";
import TextAreaInputField from "../../components/textAreaInputField";
import ErrorView from "../../components/errorView";
import toastr from "reactjs-toastr";
import Translate from '../../libs/translation';

const CardTitle = lazy(() => import('../../components/cardTitle'));


class CatagoryAdd extends Component {
  state = {
    pageType: 'add',
    itemId: '',
    name: "",
    description: "",
    code: "",
    brand: null,
    uploadPercentage: 0,
    logo: "",
    isLoading: false,
    serverErrors: ["category_create_res", "category_single", "category_update"],
    forRedirect: [
      { name: "category_create_res", pageName: 'Category', pageUrl: 'catagory', action: "Added" },
      { name: "category_update", pageName: 'Category', pageUrl: 'catagory', action: "Updated" }
    ],
    setFields: [
      { name: "name", stateName: "name", type: "text" },
      { name: "code", stateName: "code", type: "text" },
      { name: "brand", stateName: "brand", type: "select" },
      { name: "description", stateName: "description", type: "text" },
      { name: "logo", stateName: "logo", type: "text" },
    ],
    nameError: "",
    codeError: "",
    descriptionError: "",
    logoError: "",
    brandError: ""
  };

  componentDidMount() {
    let id = this.props.match.params.item_id;
    if (id) {
      this.setState({ pageType: "update", itemId: id })
      this.props.categorySingle(id);
    }
  }

  handleChange = (name, e) => {
    let isValid = inputCheck(name, e.target.value);

    if (isValid) {
      this.setState({ [name]: e.target.value });
    }

    // const re = /([^\s]+)/;
    // if (e.target.value === "" || re.test(e.target.value)) {
    //   this.setState({ [name]: e.target.value });
    // }

  }

  ondropdownChangebrand = (e, t) => this.setState({ [t]: e });

  uploadFile = ({ target: { files } }) => {

    if (files && files.length > 0 && files[0].size && files[0].size > 20971520) //20mb
    {
      toastr.error(Translate.translate("", 'error.message.image_size'), 'Error', {
        displayDuration: 5000
      });
      files = [];
      return true;
    }

    let data = new FormData();
    data.append("file", files[0]);
    const token = localStorage.getItem("token");
    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        // console.log(`${loaded}kb of ${total}kb | ${percent}%`);

        if (percent < 100) {
          this.setState({ uploadPercentage: percent });
        }
      },
    };
    axios.defaults.headers = { "x-access-token": token };
    axios.post(apiUrl + "/api/categories/upload", data, options).then((res) => {
      this.setState({ logo: res.data.item.logo, uploadPercentage: 100 }, () => {
        setTimeout(() => {
          this.setState({ uploadPercentage: 0 });
        }, 1000);
      });
    });
  };

  onsubmit = (e) => {
    let validationFields = [
      { name: 'name', label: "Name", value: this.state.name, hasError: false, isRequired: true, isNumber: false, onlyChar: true, maxLength: 50, type: "text", message: "", errName: "nameError" },
      { name: 'code', label: "code", value: this.state.code, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "text", message: "", errName: "codeError" },
      { name: 'brand', label: "Brand", value: this.state.brand, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "brandError" },
      { name: 'description', label: "Description", value: this.state.description, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "descriptionError" },
      { name: 'logo', label: "Image", value: this.state.logo, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "text", message: "", errName: "logoError" }
    ]
    let data = {
      name: this.state.name,
      code: this.state.code,
      brand: this.state.brand && this.state.brand.value,
      description: this.state.description,
      logo: this.state.logo,
    };
    e.preventDefault();
    let { newArr, valid } = validate(validationFields);
    newArr.forEach((el) => this.setState({ [el.errName]: el.message }));
    if (valid) {
      this.setState({ isLoading: true });
      if (this.state.pageType === "update") {
        this.props.categoryUpdate(data, this.state.itemId);
      } else {
        this.props.categoryCreate(data);
      }
      validationFields = null;
      newArr = null;
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps !== this.props) {
      if (this.state.isLoading) {
        this.setState({ isLoading: false });
      }
      this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
      this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));
      if (nextProps.category_single && nextProps.category_single.success) {
        nextProps.category_single.success = null;
        if (nextProps.category_single.item) {
          this.state.setFields.forEach((value) => {
            if (value.type === "text") {
              this.setState({ [value.stateName]: getValue(nextProps.category_single.item, value.name) })
            } else if (value.type === "select" && getValue(nextProps.category_single.item, value.name)) {
              let obj = {
                label: getValue(nextProps.category_single, value.name).name,
                value: getValue(nextProps.category_single, value.name)._id
              }
              this.ondropdownChangebrand(obj, value.name);
              obj = null
            }
          })
        }
      }
    }
    return true;
  }

  render() {
    const { uploadPercentage, pageType, isLoading } = this.state;
    return (
      <main className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 mt-4">
              <div className="card card-shadow mb-4">
                <div className="card-header">
                  <CardTitle title={pageType === "update" ? "Update Category" : "Add Category"} />
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="form-group required col-12 col-lg-4">
                      <Inputfield
                        handleChange={this.handleChange}
                        type="text"
                        name="name"
                        maxlength="100"
                        value={this.state.name}
                        className="form-control"
                        id="name"
                        ariadescribedby="codeHelp"
                        placeholder="Name"
                        errMessage={this.state.nameError}
                      />
                    </div>
                    <div className="form-group required col-12 col-lg-4">
                      <Inputfield
                        handleChange={this.handleChange}
                        type="text"
                        name="code"
                        maxlength="10"
                        value={this.state.code}
                        className="form-control"
                        id="code"
                        ariadescribedby="codeHelp"
                        placeholder="Code"
                        errMessage={this.state.codeError}
                      />
                    </div>
                    <div className="form-group required col-12 col-lg-4">
                      <SingleBrandDropDown
                        placeholder="Search Brand"
                        ondropdownChange={(ev) => this.ondropdownChangebrand(ev, "brand")}
                        value={this.state.brand}
                        onSelectResetsInput={true}
                        isMulti={false}
                        isSearchable={true}
                        isClearable={true}
                        errMessage={this.state.brandError}
                      />
                    </div>
                    <div className="form-group required col-12 col-lg-4">
                      <TextAreaInputField
                        onChange={(e) => this.handleChange("description", e)}
                        type="text"
                        name="description"
                        maxLength="5000"
                        value={this.state.description}
                        className="form-control"
                        id="description"
                        ariadescribedby="codeHelp"
                        placeholder="Description"
                        errMessage={this.state.descriptionError}
                      />
                    </div>
                    {this.state.logo && this.state.logo.length !== 0 ? (
                      <div className="form-group mt-3">
                        <div className="document-img mt-3">
                          <div
                            onClick={() => {
                              this.setState({ logo: [] });
                            }}
                            className="close-btn"
                          >
                            {" "}
                            <i className="fa fa-times"></i>
                          </div>
                          <img
                            alt={"img"}
                            className="images"
                            src={`${uploadUrl}${this.state.logo.medium}`}
                          />
                          <div className="media-content">
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="form-group required col-12 col-lg-4">
                        <label
                          htmlFor="exampleInputName1"
                          className="control-label"
                        >
                          Image
                        </label>
                        <input
                          maxLength="5"
                          onChange={this.uploadFile}
                          // value={this.state.code}
                          name="logo"
                          type="file"
                          className="form-control"
                          accept=".jpg, .jpeg, .png"
                        />
                        <small className="text-muted d-flex">Image extension such as .jpg, .jpeg, .png allow only and size limit is 20MB.</small>
                        <ErrorView message={this.state.logoError} />
                        <div className="progressbar-image">
                          {uploadPercentage > 0 && (
                            <ProgressBar
                              now={uploadPercentage}
                              active
                              label={`${uploadPercentage}%`}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-4 offset-md-3 text-center">
              <Buttons
                disable={isLoading}
                loading={isLoading}
                onclick={this.onsubmit}
                type="submit"
                className="btn btn-primary mr-1"
                name={pageType === "update" ? "Update" : "Save"}
              />
              <Links
                to={routehelp.catagory}
                className="btn btn-outline-dark"
                name="Cancel"
              />
            </div>
          </div>
        </div>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    category_create_res: state.category_create_res,
    category_single: state.category_single,
    category_update: state.category_update,
  };
}

const mapDispatchToProps = (dispatch) => ({
  categoryCreate: (data) => dispatch(categoryCreate(data)),
  categorySingle: data => dispatch(categorySingle(data)),
  categoryUpdate: (data, id) => dispatch(categoryUpdate(data, id))
});

CatagoryAdd = connect(mapStateToProps, mapDispatchToProps)(CatagoryAdd);

export default CatagoryAdd;
