import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Inputfield from '../../components/inputfields';
import Buttons from '../../components/buttons';
import Links from '../../components/links';
import { routehelp } from '../../Routehelper';
import { ratecreate } from '../../actions';
import Translate from '../../libs/translation';
import toastr from 'reactjs-toastr';
import 'reactjs-toastr/lib/toast.css';
const CardTitle = lazy(() => import('../../components/cardTitle'));

class Rate_manage_add extends Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.onsubmit = this.onsubmit.bind(this);
        // this.onChange = this.onChange.bind(this);
        this.state = {
            exMillPrice: '',
            whlPrice: '',
            error: {
                exMillPriceError: '',
                whlPriceError: ''
            }
        }
    }

    handleChange(name, e) {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [name]: e.target.value });
        }
    }

    validate = () => {
        let exMillPriceError = '';
        let whlPriceError = '';
        if (this.state.exMillPrice.length === 0) {
            exMillPriceError = 'ExMillPrice Is Required';
        } else if (!this.state.exMillPrice.match(/^[0-9]+$/)) {
            exMillPriceError = 'Only Number';
        }
        if (this.state.whlPrice.length === 0) {
            whlPriceError = 'Wholesale Price Is Required';
        } else if (!this.state.whlPrice.match(/^[0-9]+$/)) {
            whlPriceError = 'Only Number';
        } else if (this.state.exMillPrice > this.state.whlPrice) {
            whlPriceError = 'WholeSale Should be same or greater then Ex mill Price';
        }
        if (exMillPriceError || whlPriceError) {
            this.setState({ exMillPriceError, whlPriceError });
            return false;
        }
        return true;
    };
    // onChange(e) {
    //     this.setState({ [e.target.name]: e.target.value });
    // }
    onsubmit(e) {
        let data = {
            exMillPrice: this.state.exMillPrice,
            whlPrice: this.state.whlPrice,
        }
        e.preventDefault();
        const isValid = this.validate();
        if (isValid) {
            this.setState(this.state.error);
            this.props.ratecreate(data);
        }

    }

    shouldComponentUpdate(nextProps) {
        let req = {};
        if (nextProps.rate_create_res !== undefined && nextProps.rate_create_res.success === false && this.props !== nextProps) {
            nextProps.rate_create_res.errors.map((item, index) =>
                toastr.error(Translate.translate(req, item.msg), 'Error', {
                    displayDuration: 5000
                })
            );
            nextProps.rate_create_res.success = null;
        }
        return true;
    }

    render() {
        let { rate_create_res } = this.props;
        if (rate_create_res !== undefined) {
            if (rate_create_res.success === true) {
                toastr.success('rate Has been Added Successfully', 'success');
                rate_create_res.success = null;
                return <Redirect to={routehelp.RateMapping} />
            }
        }
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <CardTitle title="Add Rate"/>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <form>
                                                <div className="form-group required">
                                                    <label htmlFor="exampleInputName1" className="control-label">Ex Mill Rate</label>
                                                    <Inputfield handleChange={this.handleChange} type="text" name="exMillPrice" value={this.state.exMillPrice} className="form-control" id="exMillPrice" ariadescribedby="codeHelp" placeholder="Ex Mill Rate" />
                                                    <small style={{ fontSize: 10, color: 'red' }}>
                                                        {' '}
                                                        {this.state.exMillPriceError}{' '}
                                                    </small>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="col-md-6">
                                            <form>
                                                <div className="form-group required">
                                                    <label htmlFor="exampleInputcode" className="control-label">WHS Rate</label>
                                                    <Inputfield handleChange={this.handleChange} maxlength="10" type="code" name="whlPrice" value={this.state.whlPrice} className="form-control" id="whlPrice" aria-describedby="codeHelp" placeholder="WHS rate" />
                                                    <small style={{ fontSize: 10, color: 'red' }}>
                                                        {' '}
                                                        {this.state.whlPriceError}{' '}
                                                    </small>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" name="Save" />
                            <Links to={routehelp.RateMapping} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>
                </div>
            </main>

        )
    }
}

function mapStateToProps(state) {
    return { rate_create_res: state.rate_create_res };
}


const mapDispatchToProps = dispatch => ({
    ratecreate: data => dispatch(ratecreate(data))
});

Rate_manage_add = connect(
    mapStateToProps,
    mapDispatchToProps
)(Rate_manage_add);

export default Rate_manage_add;