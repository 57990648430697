import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { routehelp } from '../../Routehelper';
import { zonemanagecreate, zonesingle, zonemanageupdate } from '../../actions';
import { errorRender, validate, successRedriect, inputCheck } from '../../helpers/functions';

const Links = lazy(() => import('../../components/links'));
const Buttons = lazy(() => import('../../components/buttons'));
const Inputfield = lazy(() => import('../../components/inputfields'));
const CardTitle = lazy(() => import('../../components/cardTitle'));
const Loader = lazy(() => import('../../components/loader'));
class Zone_manage_add extends Component {
    state = {
        pageType: 'add',
        itemId: '',
        name: '',
        code: '',
        setFields: [{ name: "name", type: "text" }, { name: "code", type: "text" }],
        serverErrors: ["zone_create_res", "zone_update", "zone_single"],
        forRedirect: [
            { name: "zone_create_res", pageName: 'Zone', pageUrl: 'zonemanagement', action: "Added" },
            { name: "zone_update", pageName: 'Zone', pageUrl: 'zonemanagement', action: "Updated" }
        ],
        nameError: '',
        codeError: '',
        isLoading: false,
        getSingleLoader: false
    }


    componentDidMount() {
        let id = this.props.match.params.item_id;
        if (id) {
            this.setState({ pageType: "update", itemId: id, getSingleLoader: true })
            this.props.zonesingle(id);
        }
    }

    componentWillUnmount() {
        this.setState({});
    }


    handleChange = (name, e) => {
        let isValid = inputCheck(name, e.target.value);

        if (isValid) {
            this.setState({ [name]: e.target.value });
        }
        // const re = /([^\s]+)/;
        // if (e.target.value === '' || re.test(e.target.value)) {
        //     this.setState({ [name]: e.target.value });
        // }
    }


    onsubmit = (e) => {
        let validationFields = [
            { name: 'name', label: "Name", value: this.state.name, hasError: false, isRequired: true, isNumber: false, onlyChar: true, maxLength: 50, type: "text", message: "", errName: "nameError" },
            { name: 'code', label: "Code", value: this.state.code, hasError: false, isRequired: true, isNumber: false, onlyChar: true, maxLength: 20, type: "text", message: "", errName: "codeError" },
        ]
        let data = {
            name: this.state.name,
            code: this.state.code,
        }
        e.preventDefault();
        let { newArr, valid } = validate(validationFields);
        if (valid) {
            this.setState({ isLoading: true });
            if (this.state.pageType === "update") {
                this.props.zonemanageupdate(data, this.state.itemId);
            } else {
                this.props.zonemanagecreate(data);
            }
            validationFields = null;
            newArr = null;
        } else {
            newArr.forEach((el) => this.setState({ [el.errName]: el.message }))
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }
            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));
            if (nextProps.zone_single && nextProps.zone_single.success) {
                this.setState({ getSingleLoader: false });
                this.state.setFields.forEach((value) => {
                    if (value.type === "text") {
                        this.setState({ [value.name]: nextProps.zone_single.item[value.name] })
                    }
                })
                nextProps.zone_single.success = null;
            }
        }
        return true;
    }

    render() {
        const { pageType, isLoading, getSingleLoader } = this.state;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <CardTitle title={pageType === "update" ? "Update Zone" : "Add Zone"}/>
                                </div>
                                {getSingleLoader ? <Loader /> :
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="form-group required col-12 col-lg-6">
                                                <Inputfield errMessage={this.state.nameError} handleChange={this.handleChange} type="text" maxlength="100" name="name" value={this.state.name} className="form-control" id="name" ariadescribedby="codeHelp" placeholder="Name" />
                                            </div>
                                            <div className="form-group required col-12 col-lg-6">
                                                <Inputfield errMessage={this.state.codeError} handleChange={this.handleChange} type="code" name="code" maxlength="10" value={this.state.code} className="form-control" id="code" aria-describedby="codeHelp" placeholder="Code" />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" disable={isLoading || getSingleLoader} loading={isLoading} name={pageType === "update" ? "Update" : "Save"} />
                            <Links to={routehelp.zonemanagement} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        zone_create_res: state.zone_create_res,
        zone_single: state.zone_single,
        zone_update: state.zone_update
    };
}


const mapDispatchToProps = dispatch => ({
    zonesingle: data => dispatch(zonesingle(data)),
    zonemanagecreate: data => dispatch(zonemanagecreate(data)),
    zonemanageupdate: (data, id) => dispatch(zonemanageupdate(data, id))
});

Zone_manage_add = connect(
    mapStateToProps,
    mapDispatchToProps
)(Zone_manage_add);

export default Zone_manage_add;