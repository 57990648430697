import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { touringVoucherList, touringCSV } from '../../actions';
import ButtonWithItag from '../../components/buttonwithTagi';
import toastr from 'reactjs-toastr';
import { routehelp } from '../../Routehelper';
import Translate from '../../libs/translation';
import Loader from '../../components/loader';
import 'reactjs-toastr/lib/toast.css';
import { fileDownload } from '../../helpers/functions';


let itemsPerPage = 50;
let currentPage = 1;
let sortBy = '';
let sort = '';
let touringData = [];
class TouringVoucher extends Component {
    state = {
        check: '',
        name: '',
        code: '',
        isLoading: false,
        brand: '',
        customer: [],
        customer_array: '',
        agent: '',
        isFilter: false,
        tourdata: {},
        routeFilterData: {},
        backFrom: null
    }

    componentDidMount() {
        if (this.props && this.props.location && this.props.location.state && (this.props.location.state.tourData || this.props.location.state.filter)) {
            this.setState({ tourdata: this.props.location.state.tourData, routeFilterData: this.props.location.state.filter, backFrom: this.props.location.state.backFrom });
            this.loadResult(this.props.location.state.tourData);
        }
    }
    componentWillUnmount() {
        currentPage = 1;
        itemsPerPage = 50;
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };



    touringVoucherCSV = (tourData) => {
        if (this.state.isFilter === true) {
            currentPage = 1;
        }
        let data = {
            agent: tourData.srAsm && tourData.srAsm._id ? tourData.srAsm._id : tourData.agent && tourData.agent._id,
            routePlanId: tourData._id
        }
        data.skip = (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        data.sortBy = sortBy;
        data.sort = sort;
        this.props.touringCSV(data);
    }

    loadResult = (data) => {
        if (this.state.isFilter === true) {
            currentPage = 1;
        }

        let data2 = {
            // srAsm: data.srAsm && data.srAsm._id ? data.srAsm._id : undefined,
            agent: data.srAsm && data.srAsm._id ? data.srAsm._id : data.agent._id,
            routePlanId: data._id
        }
        data2.skip = (currentPage - 1) * itemsPerPage;
        data2.limit = itemsPerPage;
        data2.sortBy = sortBy;
        data2.sort = sort;
        this.setState({ isLoading: true, isFilter: false });
        this.props.touringVoucherList(data2);
    }

    resetfilter = () => this.setState({ isLoading: false, isFilter: false, agent: '' });

    toggleFilter = () => this.setState({ isFilter: !this.state.isFilter });

    sortBy = (key) => {
        sortBy = key;
        if (sort === 'asc') {
            sort = 'desc';
        } else {
            sort = 'asc';
        }
        this.loadResult();
    }

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    }

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    }

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    }

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }

    shouldComponentUpdate(nextProps) {
        let req = {};
        if (this.state.isLoading) {
            this.setState({ isLoading: false })
        }
        if (nextProps.touringVoucherListData !== undefined && nextProps.touringVoucherListData.success) {
            touringData = [];
            touringData = nextProps.touringVoucherListData;
            this.setState({ isLoading: false })
            nextProps.touringVoucherListData.success = null;
        }

        if (nextProps.touringVoucherListData !== undefined && nextProps.touringVoucherListData.success === false) {
            this.setState({ isLoading: false })
            nextProps.touringVoucherListData.errors.map((item, index) =>
                toastr.error(Translate.translate(req, item.code), 'Error', {
                    displayDuration: 5000
                })
            );
            nextProps.touringVoucherListData.success = null;
        }

        fileDownload(nextProps, "touringCSVData", "fileName");

        return true;
    }

    tableValuesPrint = (headers, values) => {
        let value = 0
        headers.expenses && headers.expenses.forEach((item) => {
            if (item.expenseCategoryId && item.expenseCategoryId[0] && item.expenseCategoryId[0].name === values.displayName) {
                value = item.amount
            }
        })
        return value;
    }

    render() {
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title d-flex align-items-center justify-content-between">
                                        <div>
                                            Touring Voucher : {this.state.tourdata && this.state.tourdata.tourId}
                                        </div>
                                        <div className="ml-auto text-right">
                                            <Link to={{ pathname: this.state.backFrom && this.state.backFrom === 'expenseApproval' ? routehelp.expenseApproval : routehelp.routeplanning, state: { filter: this.state.routeFilterData } }} className="btn btn-primary">Back</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12">
                                                <strong>Name of a person</strong> : {this.state.tourdata && this.state.tourdata.srAsm && this.state.tourdata.srAsm.name ? this.state.tourdata.srAsm.name : this.state.tourdata.agent && this.state.tourdata.agent.name} <br />
                                                <strong>Tour Name</strong> : {this.state.tourdata && this.state.tourdata.name}<br />
                                                {/* <strong>Tour Id</strong> : {this.state.tourdata && this.state.tourdata.tourId}<br /> */}
                                            </div>

                                            <div className="col-sm-12 col-md-12">
                                                <div id="bs4-table_filter" className="dataTables_filter">
                                                    {this.state.isLoading ? null : !touringData.columns ? null :
                                                        <ButtonWithItag onclick={() => this.touringVoucherCSV(this.state.tourdata)} classNameI="fa tai-ico-import" type="button" className="btn btn-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="Click to Upload all" />}
                                                    {/* <ButtonWithItag classNameI="ti-filter" onclick={this.toggleFilter} type="button" className="btn btn-primary navbar-toggler right-sidebar-toggler mr-1" data-toggle="tooltip" data-placement="top" title="Filter" data-original-title="Click for Filter option" />
                                                    <ButtonWithItag classNameI="fa tai-ico-import" type="button" className="btn btn-primary mr-1" datatoggle="modal" datatarget=".bd-example-modal-lg" dataplacement="top" title="Import a file" data-original-title="Click to download all" />
                                                    <CSVLink
                                                        data={csvData}
                                                        filename={"outstandingSample.csv"}
                                                        className="btn btn-primary"
                                                        target="_blank"
                                                        title="Sample"
                                                    >
                                                        <i className="fa fa-file-excel-o"></i>
                                                    </CSVLink> */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 display-grid">
                                                <div className="table-responsive">
                                                    {this.state.isLoading ? <Loader /> : !touringData.columns ? <div className="text-center">No Record Found </div> :
                                                        <table id="bs4-table"
                                                            className="table table-bordered table-striped dataTable"
                                                            cellSpacing="0">
                                                            <tr role="row">
                                                                <th className="title-center" >Expense Date</th>
                                                                <th className="title-center" >Place From</th>
                                                                <th className="title-center" >Place To</th>
                                                                {touringData && touringData.columns && touringData.columns.map((item, index) => {
                                                                    return (
                                                                        <th className="title-center" >{item && item.displayName}</th>
                                                                    )
                                                                })
                                                                }
                                                                <th className="title-center" >Retailers Visited</th>
                                                                <th className="title-center" >Total Amount</th>
                                                                <th className="title-center" >Total Amount Approved</th>
                                                            </tr>

                                                            {touringData && touringData.columns && touringData.list.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td className="title-center" >{item && item.expenseDateInString}</td>
                                                                        <td className="title-center" >{item && item.placeFrom ? item.placeFrom : "N/A"}</td>
                                                                        <td className="title-center" >{item && item.placeTo ? item.placeTo : "N/A"}</td>
                                                                        {touringData && touringData.columns && touringData.columns.map((expenseItem, index) => {
                                                                            return (
                                                                                <td className="title-center" >{this.tableValuesPrint(item, expenseItem)}</td>
                                                                            )
                                                                        })
                                                                        }
                                                                        <td className="title-center" >{item && item.retailersVisited ? item.retailersVisited : "0"}</td>
                                                                        <td className="title-center" >{item && item.totalAmount ? item.totalAmount : "0"}</td>
                                                                        <td className="title-center" >{item && item.totalAmountApproved ? item.totalAmountApproved : "0"}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                            }
                                                        </table>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={!this.state.isFilter ? "right-sidebarfilter hidden" : "right-sidebarfilter show"}>
                    <div className="modal-header bg-primary rounded-0">
                        <h6 className="filter-modal-title"> Filter</h6>
                        <button type="button" onClick={this.toggleFilter} className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" className="navbar-toggler right-sidebar-toggler pointer">×</span>
                        </button>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="form-group ">
                                <label htmlFor="Mobile" className="control-label">Person Name</label>
                                <input maxlength="100" type="text" className="form-control" name="name" value={this.state.name} onChange={this.onChange} id="code" placeholder="Name" />
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label htmlFor="Mobile" className="control-label">Created Start Date</label>
                                    <input maxlength="10" type="date" className="form-control" name="startdate" value={this.state.startdate} onChange={this.onChange} id="code" placeholder="Code" />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="Mobile" className="control-label">Created End Date</label>
                                    <input maxlength="10" type="date" className="form-control" name="enddate" value={this.state.enddate} onChange={this.onChange} id="code" placeholder="Code" />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="Mobile" className="control-label">User Type</label>
                                <select
                                    className="form-control"
                                    name="requestFrom"
                                    value={this.state.requestFrom}
                                    onChange={this.onChange}

                                >
                                    <option value="">Select a Type</option>
                                    <option value="ALL">All</option>
                                    <option value="AG">Agent</option>
                                    <option value="ASM">Area Sales Manager</option>
                                    <option value="ASM2">Area Sales Manager 2</option>
                                    <option value="SASM">Sr. AREA SALES MANAGER</option>
                                    <option value="WHS">WholeSaler</option>
                                </select>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6 mb-4 offset-md-3 text-center">
                        <button onClick={this.loadResult} data-dismiss="modal" type="button" aria-hidden="true" className="btn btn-primary navbar-toggler right-sidebar-toggler pointer mr-1">Apply </button>
                        <button type="button" data-dismiss="modal" onClick={this.resetfilter} className="btn btn-outline-dark">Reset</button>
                    </div>
                </div>
            </main>


        )
    }
}

function mapStateToProps(state) {
    return {
        touringVoucherListData: state.touringVoucherListData,
        touringCSVData: state.touringCSVData
    };
}


const mapDispatchToProps = dispatch => ({
    touringVoucherList: data => dispatch(touringVoucherList(data)),
    touringCSV: data => dispatch(touringCSV(data))
});

TouringVoucher = connect(
    mapStateToProps,
    mapDispatchToProps
)(TouringVoucher);

export default TouringVoucher;