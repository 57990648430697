import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { routehelp } from '../../Routehelper';
import { transportercreate, transportersingle, transporterupdate } from '../../actions';
import { errorRender, successRedriect, getValue, validate } from '../../helpers/functions';


const CustomerDropDown = lazy(() => import('../customerManagement/customerDropdown'));
const TextAreaInputField = lazy(() => import('../../components/textAreaInputField'));
const Inputfield = lazy(() => import('../../components/inputfields'));
const CardTitle = lazy(() => import('../../components/cardTitle'));
const Buttons = lazy(() => import('../../components/buttons'));
const Loader = lazy(() => import('../../components/loader'));
const Links = lazy(() => import('../../components/links'));

class Transporter_add extends Component {
    state = {
        pageType: 'add',
        itemId: '',
        name: '',
        description: '',
        customer: null,
        filterBy: { limit: 5000 },
        serverError: ["transporter_create_res", "transporter_single", "transporter_update"],
        forRedirect: [
            { name: "transporter_create_res", pageName: 'Transporter', pageUrl: 'transporter', action: "Added" },
            { name: "transporter_update", pageName: 'Transporter', pageUrl: 'transporter', action: "Updated" }
        ],
        setFields: [
            { name: "name", stateName: "name", type: "text" },
            { name: "description", stateName: "description", type: "text" },
            { name: "customer", stateName: "customer", type: "select" },
        ],
        nameError: '',
        customerError: '',
        descriptionError: '',
        isLoading: false,
        getSingleLoader: false
    }


    componentDidMount() {
        let id = this.props.match.params.item_id;
        if (id) {
            this.setState({ pageType: "update", itemId: id, getSingleLoader: true })
            this.props.transportersingle(id);
        }
    }

    handleChange = (name, e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [name]: e.target.value });
        }
    }


    ondropdownChange = (val, name) => this.setState({ [name]: val })

    onsubmit = (e) => {
        let validationFields = [
            { name: 'name', label: "Name", value: this.state.name, hasError: false, isRequired: true, isNumber: false, onlyChar: true, maxLength: 50, type: "text", message: "", errName: "nameError" },
            { name: 'description', label: "Description", value: this.state.description, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 200, type: "text", message: "", errName: "descriptionError" },
            { name: 'customer', label: "Customer", value: this.state.customer, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "customerError" }
        ]
        let data = {
            name: this.state.name,
            description: this.state.description,
            customer: this.state.customer && this.state.customer.value,
            isActive: true
        }
        e.preventDefault();
        let { newArr, valid } = validate(validationFields);
        newArr.map((el) => this.setState({ [el.errName]: el.message }));
        if (valid) {
            this.setState({ isLoading: true });
            if (this.state.pageType === "update") {
                this.props.transporterupdate(data, this.state.itemId);
            } else {
                this.props.transportercreate(data);
            }
            validationFields = null;
            newArr = null;
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }
            this.state.serverError.forEach((el) => errorRender(nextProps, el));
            this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));
            if (nextProps.transporter_single && nextProps.transporter_single.success) {
                nextProps.transporter_single.success = null;
                this.setState({ getSingleLoader: false });
                this.state.setFields.forEach((value) => {
                    if (value.type === "text") {
                        this.setState({ [value.stateName]: getValue(nextProps.transporter_single.item, value.name) })
                    } else if (value.type === "select" && getValue(nextProps.transporter_single.item, value.name)) {
                        let obj = {
                            label: getValue(nextProps.transporter_single.item, value.name).name,
                            value: getValue(nextProps.transporter_single.item, value.name)._id
                        }
                        this.setState({ [value.stateName]: obj });
                        obj = null;
                    }
                })
            }
        }
        return true;
    }

    render() {
        const { pageType, isLoading, getSingleLoader } = this.state;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                <CardTitle title={pageType === "update" ? "Update Transporter" : "Add Transporter"}/>
                                </div>
                                {getSingleLoader ? <Loader /> :
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="form-group required col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.nameError} handleChange={this.handleChange} type="text" maxlength="100" name="name" value={this.state.name} className="form-control" id="name" ariadescribedby="codeHelp" placeholder="Transporter Name" />
                                            </div>
                                            <div className="form-group required col-12 col-lg-4">
                                                <CustomerDropDown
                                                    filterBy={this.state.filterBy}
                                                    value={this.state.customer}
                                                    ondropdownChange={(e) => this.ondropdownChange(e, "customer")}
                                                    placeholder="Search Customer"
                                                    errMessage={this.state.customerError}
                                                />
                                            </div>
                                            <div className="form-group required col-12 col-lg-4">
                                                <TextAreaInputField errMessage={this.state.descriptionError} maxLength="5000" onChange={(e) => this.handleChange('description', e)} type="text" name="description" value={this.state.description} className="form-control" id="description" ariadescribedby="codeHelp" placeholder="Description" />
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" disable={isLoading || getSingleLoader} loading={isLoading} name={pageType === "update" ? "Update" : "Save"} />
                            <Links to={routehelp.transporter} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        transporter_create_res: state.transporter_create_res,
        transporter_single: state.transporter_single,
        transporter_update: state.transporter_update,
    };
}


const mapDispatchToProps = dispatch => ({
    transportercreate: data => dispatch(transportercreate(data)),
    transportersingle: data => dispatch(transportersingle(data)),
    transporterupdate: (data, id) => dispatch(transporterupdate(data, id)),
});

Transporter_add = connect(
    mapStateToProps,
    mapDispatchToProps
)(Transporter_add);

export default Transporter_add;