import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { routehelp } from '../../Routehelper';
// import Links from '../../components/links';
import Logo from '../../logo.svg';
import { login } from '../../actions';
import Translate from '../../libs/translation';
import toastr from 'reactjs-toastr';
import 'reactjs-toastr/lib/toast.css';
import { redirectList } from '../../helpers/functions';

const Inputfield = lazy(() => import('../../components/inputfields'));
const Button = lazy(() => import('../../components/buttons'));
const LinkwithImg = lazy(() => import('../../components/linkwithimg'));
const Loader = lazy(() => import('../../components/loader'));
class Login extends Component {
    state = {
        email: '',
        password: '',
        passShow: false,
        isLoading: false,
    }

    handleChange = (name, e) => this.setState({ [name]: e.target.value });

    onClick = (e) => {

        let data = {
            deviceInfo: {
                getDeviceId: null,
                getUniqueID: null,
                type: "pc"
            },
            username: this.state.email,
            password: this.state.password
        };

        e.preventDefault();

        this.setState({ isLoading: true }, () => this.props.login(data))
    }

    shouldComponentUpdate(nextProps) {
        let req = {};
        if (nextProps.usersignin !== undefined && nextProps.usersignin.success === false && nextProps.usersignin.errors !== null && this.props !== nextProps) {
            this.setState({ isLoading: false })
            nextProps.usersignin.errors.map((item, index) =>
                toastr.error(Translate.translate(req, item.code), 'Error', {
                    displayDuration: 5000
                })
            );            // nextProps.usersignin.errors = null;
            nextProps.usersignin.success = null;
        }
        return true;
    }
    
    getRedirect = (data) => {
        if (data !== null && data !== undefined) {
            const filteredData = data && data.permission.filter(item => item.list);
            if (filteredData.length > 0) {
                return redirectList.find((item)=>item.match === filteredData[0].name).to;
            } else {
                toastr.error("You don't have any permission");
                return routehelp.logout;
            }
        } else {
            toastr.error("You don't have any permission");
            return routehelp.logout;
        };
    };

    passwordShow = () => this.setState({ passShow: !this.state.passShow });

    render() {
        const { usersignin } = this.props;
        if (usersignin !== undefined) {
            if (usersignin.message) {
                this.setState({ isLoading: false });
                toastr.error(usersignin.message, 'error');
                usersignin.message = null
            }
            if (usersignin.success === true) {
                // console.log(usersignin.item.company);
                localStorage.setItem('token', usersignin.token);
                localStorage.setItem('username', usersignin.item.email);
                localStorage.setItem('measumentUnit', JSON.stringify(usersignin.item.measurementUnits));
                localStorage.setItem('type', usersignin.item.type);
                localStorage.setItem('name', usersignin.item.name);
                localStorage.setItem('userId', usersignin.item._id);
                localStorage.setItem('version', usersignin.item.version);
                localStorage.setItem('versionId', usersignin.item.versionId);
                localStorage.setItem('commissionFlag', usersignin.item.company && usersignin.item.company.commissionSelectedOn ? usersignin.item.company.commissionSelectedOn : "");
                localStorage.setItem('permission', usersignin.item.role ? JSON.stringify(usersignin.item.role) : null);
                localStorage.setItem('companyId', usersignin.item.company && usersignin.item.company._id ? usersignin.item.company._id : "")
                localStorage.setItem('companyLogo', usersignin.item.company
                    && usersignin.item.company.logo ? JSON.stringify(usersignin.item.company.logo) :
                    usersignin.item && usersignin.item.logo ? JSON.stringify(usersignin.item.logo) : '""');
                if (usersignin.item.type === "CO" || usersignin.item.type === "CA") {
                    toastr.success('Successfully logged in', 'success');
                    usersignin.success = null;
                    return <Redirect to={this.getRedirect(usersignin.item.role)} />
                }
                else {
                    toastr.success('Successfully logged in', 'success');
                    usersignin.success = null;
                    return <Redirect to={routehelp.primarydashboard} />
                }
            }
        }
        const { isLoading } = this.state;
        return (
            <div className="bgImage">
                <div className="container">
                    <form onSubmit={this.onClick} className="form-signin" >
                        <Suspense fallback={<div></div>}>
                            <LinkwithImg to={routehelp.login} className="brand text-center logo-1" src={Logo} alt="" />
                        </Suspense>
                        <h2 className="form-signin-heading">Please sign in</h2>
                        <div className="input-group mb-3">
                            <Suspense fallback={<div></div>}>
                                <Inputfield noLabel={true} name="email" value={this.state.email} type="email" id="email" className="form-control" handleChange={this.handleChange} placeholder="Email address" required={true} />
                            </Suspense>

                            <div className="input-group-append">
                                <span className="input-group-text"><i className="fa fa-user"></i></span>
                            </div>
                        </div>
                        <div className="input-group mb-3">

                            <Suspense fallback={<div></div>}>
                                <Inputfield noLabel={true} type={this.state.passShow === false ? "password" : "text"} name="password" value={this.state.password} id="password" className="form-control" placeholder="Password" handleChange={this.handleChange} required={true} />
                            </Suspense>
                            <div onClick={this.passwordShow} className="input-group-append">
                                <span className="input-group-text"><i className={this.state.passShow === false ? "fa fa-eye-slash" : "fa fa-eye"}></i></span>
                            </div>
                        </div>
                        {/* <div className="checkbox mb-4 mt-4">
                        <label className="custom-control custom-checkbox">
                        <Inputfield type="checkbox" className="custom-control-input" />
                        <span className="custom-control-indicator"></span>
                        <span className="custom-control-description">
                        Remember me
                        </span>
                        </label>
                        <Links to={routehelp.forgotpassword} className="float-right text-muted" name="Forgot Password ?" />
                    </div> */}
                        <Suspense fallback={<div></div>}>
                            <Button disable={this.state.isLoading} className="btn btn-lg btn-primary btn-block" type="submit"  >{isLoading ? <Loader colour="#ffffff" type="TailSpin" height="20" width="100" /> : "Sign In"}</Button>
                        </Suspense>

                        {/* temporary commented self login and reg */}
                        {/* <div className="mt-4">
                            <span>
                                Don't have an account yet ?
                            </span> &nbsp;
                            <a href={routehelp.register} className="text-primary" name="Sign Up">Register</a>
                        </div> */}

                    </form>
                </div >
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { usersignin: state.usersignin };
}

const mapDispatchToProps = dispatch => ({
    login: data => dispatch(login(data))
});

Login = connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);

export default Login;