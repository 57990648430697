import React, { Component } from 'react';
import { Fragment } from 'react';

class B2CPrivacyPolicy extends Component {
    render() {
        return (
            <Fragment>
                <title>Privacy Policy</title>
                <div className="bg-white">
                    <div className="policy-container">
                        <p className="font-25"><strong>Vercelli B2C Privacy Policy</strong></p>
                        <p>Rydot Infotech Private Limited respects your privacy and is committed to helping you understand what data we
                            collect, why we collect it, and what we do with it. Please spend some time carefully reading the below-mentioned
                            privacy policies.</p>
                        <ol>
                            <li className="font-25"><strong> Services covered by this Policy</strong></li>
                        </ol>
                        <p>Rydot Infotech Private Limited currently operates the <strong>Vercelli B2C </strong>mobile application (the
                            &ldquo;App&rdquo;) as well as the website(the "Website" and, together with the App, the &ldquo;Services&rdquo;).
                        </p>

                        <ol start="2">
                            <li className="font-25"><strong> Information We Collect From You</strong></li>
                        </ol>
                        <p><strong>2.1.</strong> We may collect personal information (e.g., ID, username, full name, and email address) from
                            you through the services in the ways discussed in the policy. We may also receive your personal information from
                            our partners or clients. You might not be able to access some portions of the services if you choose not to
                            provide personal information when asked.</p>

                        <p><strong>2.2.</strong> We may collect the physical location of your device to record and publish information on
                            your position to the partner. We may collect this information by, for example, using satellites, cell phone
                            towers, or WiFi signals. The ability to utilize the services may be affected by whether you choose to allow or
                            refuse such collection and use of your device's location in particular circumstances.</p>

                        <p><strong>2.3.</strong> Our partner, with whom you are a guest, employee, or contractor, will also provide your
                            details together with additional information (such as your name, employee code, email address, mobile number,
                            address, business information, business card copy, GST details, and other financial information). With the help
                            of the Vercelli B2C application, its users as well as partners and employees of our partners can get access to
                            these documents/files.</p>

                        <p><strong>2.4. </strong>The following are just a few examples of how we might use or divulge the personal data we
                            gather from you if we deem it necessary or permissible to do so:</p>
                        <ul>
                            <li>To protect people or property,</li>
                            <li>To protect our services, rights, or property,</li>
                            <li>To comply with legal requirements,</li>
                            <li>Respond to court orders, requests from the authorities, and orders from other public and governmental
                                agencies.</li>
                        </ul>

                        <ol start="3">
                            <li className="font-25"><strong> Registration</strong></li>
                        </ol>
                        <p><strong>3.1.</strong> To use the services, you need to register by providing us with your email address. We may
                            also collect information including your phone number, name, date of birth, blood group, marital status, address,
                            and gender.</p>

                        <p><strong>3.2. </strong>In order to make information available on the App and the Website, we might ask you for
                            information like a profile photo, name and last name, user name, phone number, and email address. You may not be
                            able to access all the content and features without registering.</p>

                        <ol start="4">
                            <li className="font-25"><strong> Emails and Push Notifications</strong></li>
                        </ol>
                        <p>We may contact you and/or send you electronic communications using email. If you do not want to receive emails
                            from us, you can always opt out by following the unsubscribe instructions provided in such emails. Please note
                            that even if you opt-out from receiving communications, you may still receive administrative communications,
                            such as push notifications about your account activities (e.g. account confirmations, password changes, etc.),
                            and any other important account amendment details. We may also send you push notifications regarding the same.
                            Push notifications can be turned off in your mobile device's settings.</p>

                        <ol start="5">
                            <li className="font-25"><strong> Non-personal information we collect from you</strong></li>
                        </ol>
                        <p><strong>5.1. </strong>A lot of the time, when you use the services, you will routinely get some non-personal
                            information. We gather this data to make sure the services work properly. We might also collect information
                            about your browser or device, pixel tags, information through cookies, app usage data, and other technologies,
                            and aggregated information. This information may include:</p>

                        <p>App usage information, including the date and time your device's app connects to our servers and what documents
                            and information have been transferred to the app based on your device's unique identifier. When you download and
                            use the App, we may also get information that is automatically collected by your browser, device, or the App
                            itself. We may collect computer type (Windows or Macintosh), Media Access Control (MAC) address, device
                            manufacturer and model; Internet browser version and type; screen resolution; the domain name of your Internet
                            service provider; the operating system you are using; language; and the name and version of the services (such
                            as the App) you are using; and your "click path" through the Sites or the App; We may use your IP address for
                            purposes such as calculating usage levels, administering the services, and diagnosing server problems. Your IP
                            address may allow us to determine your general location.</p>

                        <p><strong>5.2. </strong>We might utilize technology, such as cookies, to accomplish this. Unless you give us or
                            have given us personal information in the past, your access to our services and the information given through
                            all these technologies will be anonymized.</p>

                        <p><strong>5.3. </strong>When you voluntarily give it, we may also gather non-personal information, such as your
                            preferred means of contact.</p>

                        <p><strong>5.4. </strong>We may combine personal data that, when combined, does not personally identify you or any
                            other service user. For instance, we may combine personal data to determine the proportion of our users who
                            belong to a specific zip code.</p>

                        <p><strong>5.5.</strong> Except as compelled by the applicable law to do differently, we may use and disclose
                            non-personal information for any purpose. In certain circumstances, we might merge this data with personal data.
                            Your account information might be connected to the partner's server, for instance, if you have an account with
                            our services. If we combine the data, we will treat it as personal data as long as it is combined.</p>

                        <ol start="6">
                            <li className="font-25"><strong> Information on cookies and related technology</strong></li>
                        </ol>
                        <p>We may use cookies to control user sessions, save preferences, track data, and manage language settings. Whether
                            or not you register with us, cookies may still be utilized. Small text files known as "cookies" are delivered by
                            a web server to your hard drive and then saved on your computer. A cookie can gather data such as the time and
                            date of your visit, your browsing history, and your preferences. Normally, you can set your browser to refuse
                            cookies. However, refusing to accept cookies may prevent you from using some website features. For instance, you
                            can have login issues.</p>

                        <ol start="7">
                            <li className="font-25"><strong> Our Promise to Protect the Personal Data We Have Gathered</strong></li>
                        </ol>
                        <p>To protect personal information within our organization, we try to utilize acceptable organizational, technical,
                            and administrative methods. However, no website or Internet transmission is 100% safe. As a result, we can't
                            promise that there won't ever be an intrusion, hack, data loss, or another breach. The App and services are used
                            at the user's own risk. We kindly request that you take precautions to protect the privacy of your data by
                            memorizing your password or storing it in a secure location.</p>

                        <ol start="8">
                            <li className="font-25"><strong> Third Parties</strong></li>
                        </ol>
                        <p>The privacy, information, or other practices of any third party, including any third party running any website or
                            service to which the services link, are not covered by this privacy statement, and we are not liable for them.
                        </p>

                        <ol start="9">
                            <li className="font-25"><strong> Transfer of Personal Data</strong></li>
                        </ol>
                        <p>Your data may need to be transferred to and/or stored at a location outside of your country of residence for both
                            processing and storage purposes. You consent to the sending, keeping, and/or processing of your data if you
                            accept this privacy statement.</p>

                        <ol start="10">
                            <li className="font-25"><strong> Notice of changes to the policy</strong></li>
                        </ol>
                        <p>By alerting registered users via email or the App of the existence of a new policy and/or by publishing the new
                            policy on the services, we retain the right to alter this policy at any time. Your continued use of our services
                            following the posting of any changes to the policy will signify your acceptance of and commitment to be bound by
                            those changes. All changes to the policy will take effect when they are posted.</p>

                    </div>
                </div>
            </Fragment>
        )
    }
}

export default B2CPrivacyPolicy;