import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { routehelp } from '../../Routehelper';
// import Links from '../../components/links';
import Logo from '../../logo.svg';
import { register, package_public_list } from '../../actions';
import Translate from '../../libs/translation';
import toastr from 'reactjs-toastr';
import 'reactjs-toastr/lib/toast.css';
import { errorRender, validate, successRedriect } from '../../helpers/functions';
import ErrorView from '../../components/errorView';

const RazorpayButton = lazy(() => import('../../libs/razorpay'));
const Inputfield = lazy(() => import('../../components/inputfields'));
const Button = lazy(() => import('../../components/buttons'));
const LinkwithImg = lazy(() => import('../../components/linkwithimg'));
const Loader = lazy(() => import('../../components/loader'));

class Register extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            name: '',
            email: '',
            phone: '',
            password: '',
            // package: '',
            serverErrors: ["package_public_list_res", "register_res"],
            forRedirect: [
                { name: "register_res", pageName: 'Login', pageUrl: 'login', action: "login" },
            ],
            packageList: [],
            nameError: '',
            phoneError: '',
            emailError: '',
            passwordError: '',
            // packageError: '',
            isPackage: false
        };
    }

    handleChange = (name, e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [name]: e.target.value });
        };
    };

    submit = (e) => {
        e.preventDefault();

        let validationFields = [
            { name: 'name', label: "Name", value: this.state.name, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 50, type: "text", message: "", errName: "nameError" },
            { name: 'email', label: "Email", value: this.state.email, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 20, type: "text", message: "", errName: "emailError" },
            { name: 'phone', label: "Phone", value: this.state.phone, hasError: false, isRequired: true, isNumber: true, onlyChar: false, maxLength: 20, type: "text", message: "", errName: "phoneError" },
            { name: 'password', label: "Password", value: this.state.password, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 20, type: "text", message: "", errName: "passwordError" },
            // { name: 'package', label: "Package", value: this.state.package, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 20, type: "text", message: "", errName: "packageError" },
        ];

        let { newArr, valid } = validate(validationFields);

        // let data = {
        //     name: this.state.name,
        //     email: this.state.email,
        //     phone: this.state.phone,
        //     password: this.state.password,
        //     package: this.state.package
        // };

        if (valid) {
            this.setState({
                isPackage: true
            });
            // this.setState({ isLoading: true }, () => this.props.register(data))
        } else {
            newArr.forEach((el) => this.setState({ [el.errName]: el.message }))
        };

    };

    componentDidMount() {
        this.props.package_public_list({});
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {

            if (nextProps.register_res && nextProps.register_res.success) {
                // console.log(nextProps.register_res.success);
                this.setState({ isLoading: false });
                // nextProps.register_res.success = null;
            };

            if (nextProps.package_public_list_res && nextProps.package_public_list_res.success) {
                this.setState({
                    packageList: nextProps.package_public_list_res.list || []
                });
            };

            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));

        };
        return true;
    }

    passwordShow = () => this.setState({ passShow: !this.state.passShow });

    handleDataFromChild = (res) => {
        // Do something with the data in the parent component
        if (res.razorpayID) {
            this.callReg({
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                password: this.state.password,
                package: res.package,
                razorpayID: res.razorpayID
            });
        };

    };

    callReg = (data) => {
        this.props.register(data);
    };

    back = () => {
        this.setState({
            isPackage: false
        });
    };

    render() {
        return (
            <div className="bgImage">

                {
                    !this.state.isPackage ?
                        <div className="container">
                            <form onSubmit={this.submit} className="form-signin">

                                <Suspense fallback={<div></div>}>
                                    <LinkwithImg to={routehelp.register} className="brand text-center logo-1" src={Logo} alt="no img" />
                                </Suspense>

                                <h2 className="form-signin-heading">Please Register</h2>

                                <div className="input-group mb-3 flex-wrap">
                                    <Suspense fallback={<div></div>}>
                                        <Inputfield noLabel={true} name="name" value={this.state.name} type="name" id="name" className="form-control" handleChange={this.handleChange} placeholder="Name" />
                                    </Suspense>
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-list"></i></span>
                                    </div>
                                    <ErrorView message={this.state.nameError} />
                                </div>

                                <div className="input-group mb-3 flex-wrap">
                                    <Suspense fallback={<div></div>}>
                                        <Inputfield noLabel={true} name="email" value={this.state.email} type="email" id="email" className="form-control" handleChange={this.handleChange} placeholder="Email" />
                                    </Suspense>
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-user"></i></span>
                                    </div>
                                    <ErrorView message={this.state.emailError} />
                                </div>

                                <div className="input-group mb-3 flex-wrap">
                                    <Suspense fallback={<div></div>}>
                                        <Inputfield noLabel={true} name="phone" value={this.state.phone} type="phone" id="phone" className="form-control" handleChange={this.handleChange} placeholder="Phone Number" />
                                    </Suspense>
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i className="fa fa-phone"></i></span>
                                    </div>
                                    <ErrorView message={this.state.phoneError} />
                                </div>

                                <div className="input-group mb-3 flex-wrap">
                                    <Suspense fallback={<div></div>}>
                                        <Inputfield noLabel={true} type={this.state.passShow === false ? "password" : "text"} name="password" value={this.state.password} id="password" className="form-control" placeholder="Password" handleChange={this.handleChange} />
                                    </Suspense>
                                    <div onClick={this.passwordShow} className="input-group-append">
                                        <span className="input-group-text"><i className={this.state.passShow === false ? "fa fa-eye-slash" : "fa fa-eye"}></i></span>
                                    </div>
                                    <ErrorView message={this.state.passwordError} />
                                </div>

                                {/* <ErrorView message={this.state.packageError} /> */}

                                <Suspense fallback={<div></div>}>
                                    <Button disable={this.state.isLoading} className="btn btn-lg btn-primary btn-block" type="submit"  >{this.state.isLoading ? <Loader colour="#ffffff" type="TailSpin" height="20" width="100" /> : "Register"}</Button>
                                </Suspense>

                                <div>
                                    You have already account ? &nbsp;
                                    <a href={routehelp.login}>
                                        Signin
                                    </a>
                                </div>

                            </form>
                        </div> :
                        null
                }

                {
                    this.state.isPackage ?
                        <div className='container'>
                            <div className='row mx-0'>
                                <div className='packege-cards'>
                                    {
                                        this.state.packageList && this.state.packageList.length > 0 ?
                                            this.state.packageList.map((ele, index) => {
                                                return (
                                                    <div className='col-md-4 col-12' key={index}>
                                                        <div className='card'>
                                                            <div className='price'>
                                                                <div className='inner'>
                                                                    {ele.price}
                                                                </div>
                                                            </div>
                                                            <h5 className='card-title'>
                                                                {ele.name}
                                                            </h5>
                                                            <div className='card-body'>
                                                                <ul>
                                                                    <li><span>1 user per month</span><span className='fa fa-check text-success'></span></li>
                                                                    <li><span>1 user per month</span><span className='fa fa-times text-danger'></span></li>
                                                                    <li><span>1 user per month</span><span className='fa fa-check text-success'></span></li>
                                                                    <li><span>1 user per month</span><span className='fa fa-times text-danger'></span></li>
                                                                </ul>
                                                                <Suspense fallback={<div></div>}>
                                                                    <div>
                                                                        <RazorpayButton price={ele.price} id={ele._id} sendDataToParent={this.handleDataFromChild} />
                                                                    </div>
                                                                </Suspense>
                                                                {/* <button className='btn btn-primary'>Buy Now</button> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }) :
                                            <div className='text-center'>No Package is found</div>
                                    }
                                </div>
                            </div>

                            <div className='text-center'>
                                <button className='btn btn-primary' onClick={(e) => this.back()}>Back</button>
                            </div>

                        </div> :
                        null
                }

            </div>
        )
    };

}

function mapStateToProps(state) {
    return {
        register_res: state.register_res,
        package_public_list_res: state.package_public_list_res
    };
}

const mapDispatchToProps = dispatch => ({
    register: data => dispatch(register(data)),
    package_public_list: data => dispatch(package_public_list(data))
});

Register = connect(
    mapStateToProps,
    mapDispatchToProps
)(Register);

export default Register;