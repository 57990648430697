import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { misSettingSingle, misSettingUpdate } from '../../actions';
import Inputfield from '../../components/inputfields';
import Buttons from '../../components/buttons';
import Links from '../../components/links';
import { routehelp } from '../../Routehelper';
import { apiUrl } from '../../config';
import axios from 'axios';
import EmailChips from '../../components/emailChips';
import { emailCheck, errorRender, isPermission, successRedriect } from '../../helpers/functions';
import TextAreaInputField from '../../components/textAreaInputField';
import 'react-quill/dist/quill.snow.css';
const CardTitle = lazy(() => import('../../components/cardTitle'));

class MisReportSetting extends Component {
  state = {
    name: '',
    schedularTime: '',
    items: [],
    subject: "",
    description: "",
    value: "",
    error: null,
    errors: {
      nameError: '',
      schedularTimeError: "",
      emailError: "",
      subjectError: "",
      descriptionError: ''
    },
    isLoading: false
  }

  componentWillReceiveProps(props) {

    if (props.misSingleSetting && props.misSingleSetting.success) {
      props.misSingleSetting.success = null;
      this.setState({
        schedularTime:
          props.misSingleSetting !== undefined && props.misSingleSetting.item && props.misSingleSetting.item.schedularTime
            ? props.misSingleSetting.item.schedularTime
            : '',
        name:
          props.misSingleSetting !== undefined && props.misSingleSetting.item
            ? props.misSingleSetting.item.name !== undefined ? props.misSingleSetting.item.name : ''
            : '',
        items:
          props.misSingleSetting !== undefined && props.misSingleSetting.item && props.misSingleSetting.item.emails
            ? props.misSingleSetting.item.emails
            : '',
        subject:
          props.misSingleSetting !== undefined && props.misSingleSetting.item && props.misSingleSetting.item.subject
            ? props.misSingleSetting.item.subject
            : '',
        description:
          props.misSingleSetting !== undefined && props.misSingleSetting.item && props.misSingleSetting.item.description
            ? props.misSingleSetting.item.description
            : '',
      });
    }
    return false;

  }

  shouldComponentUpdate(nextProps) {
    if (nextProps !== this.props) {
      if (this.state.isLoading) {
        this.setState({ isLoading: false });
      }
      errorRender(nextProps, "misSetting_update");
      successRedriect(nextProps, "misSetting_update", "Mis Settings", this.props, "maindashboard", "Updated")
    }
    return true;
  }

  uploadFile = ({ target: { files } }) => {
    let data = new FormData();
    data.append('file', files[0])

    const token = localStorage.getItem('token');
    const options = {

      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total)
        console.log(`${loaded}kb of ${total}kb | ${percent}%`);

        if (percent < 100) {
          this.setState({ uploadPercentage: percent })
        }
      }
    }
    axios.defaults.headers = { 'x-access-token': token }
    axios.post(apiUrl + "/api/account/profile/upload", data, options).then(res => {
      // console.log(res)
      this.setState({ logo: res.data.item.logo, uploadPercentage: 100 }, () => {
        setTimeout(() => {
          this.setState({ uploadPercentage: 0 })
        }, 1000);
      })
    })
  }

  handleOnChange = (name, e) => {
    const re = /([^\s]+)/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ [name]: e.target.value });
    }
  }

  onChange = (e, val) => {
    const re = /([^\s]+)/;
    if (val !== undefined) {
      this.setState({ [e]: val });
    }
    if (e && e.target) {
      if (e.target.value === '' || re.test(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value });
      }
    }
  }

  validate = () => {
    let nameError
    let schedularTimeError = '';
    let emailError = '';
    let subjectError = '';
    let descriptionError = '';
    if (this.state.name.length === 0) {
      nameError = 'Name Is Required';
    }
    if (this.state.schedularTime.length === 0) {
      schedularTimeError = 'Schedular Time Is Required';
    }
    //  else if (this.state.schedularTime.length > 20) {
    //   schedularTimeError = 'Header Name must be in 20 characters';
    // }

    if (this.state.items.length === 0) {
      emailError = 'Email Is Required';
    }
    if (this.state.subject.length === 0) {
      subjectError = 'Subject Is Required';
    }
    if (this.state.description.length === 0) {
      descriptionError = 'Description Is Required';
    }
    if (nameError || schedularTimeError || emailError || subjectError || descriptionError) {
      this.setState({ nameError, schedularTimeError, emailError, subjectError, descriptionError });
      return false;
    }
    return true;
  };

  componentDidMount() {
    let data = {};
    this.props.misSettingSingle(data);
  }

  onsubmit = (e) => {
    let data = {
      name: this.state.name,
      schedularTime: this.state.schedularTime,
      emails: this.state.items,
      subject: this.state.subject,
      description: this.state.description
    }
    e.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      this.setState(this.state.error);
      this.setState({ isLoading: true })
      this.props.misSettingUpdate(data);
    }
  }

  handleKeyDown = evt => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      var value = this.state.value.trim();
      if (value && this.isValid(value)) {
        this.setState({
          items: [...this.state.items, this.state.value],
          value: ""
        });
      }
    }
  };

  handleChange = evt => {
    this.setState({
      value: evt.target.value,
      error: null
    });
  };

  handleDelete = item => {
    this.setState({
      items: this.state.items.filter(i => i !== item)
    });
  };

  handlePaste = evt => {
    evt.preventDefault();
    var paste = evt.clipboardData.getData("text");
    // eslint-disable-next-line
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);
    if (emails) {
      var toBeAdded = emails.filter(email => !this.isInList(email));
      this.setState({
        items: [...this.state.items, ...toBeAdded]
      });
    }
  };

  isValid = (email) => {
    let error = null;
    if (this.isInList(email)) {
      error = `${email} has already been added.`;
    }
    if (!emailCheck(email)) {
      error = `${email} is not a valid email address.`;
    }
    if (error) {
      this.setState({ error });
      return false;
    }
    return true;
  };

  isInList = (email) => this.state.items.includes(email);

  render() {
    const { isLoading } = this.state;
    return (

      <main className="main-content">
        <div className="container-fluid">

          <div className="row">
            <div className="col-md-12 mt-4">
              <div className="card card-shadow mb-4">
                <div className="card-header">
                <CardTitle title= "Update Mis Report Settings"/>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="form-group required col-12 col-lg-4">
                      <Inputfield errMessage={this.state.nameError} handleChange={this.handleOnChange} type="text" name="name" value={this.state.name} className="form-control" id="name" ariadescribedby="emailHelp" placeholder="Name" />
                    </div>
                    <div className="form-group required col-12 col-lg-4">
                      <Inputfield errMessage={this.state.schedularTimeError} handleChange={this.handleOnChange} type="time" name="schedularTime" value={this.state.schedularTime} className="form-control" id="schedularTime" ariadescribedby="emailHelp" placeholder="Schedular Time" />
                    </div>
                    <div className="form-group required col-12 col-lg-4">
                      <EmailChips label="Email" errMessage={this.state.emailError} value={this.state.value} error={this.state.error} items={this.state.items} handleKeyDown={this.handleKeyDown} handleChange={this.handleChange} handleDelete={this.handleDelete} handlePaste={this.handlePaste} />
                    </div>
                    <div className="form-group required col-12 col-lg-4">
                      <Inputfield errMessage={this.state.subjectError} handleChange={this.handleOnChange} type="text" name="subject" value={this.state.subject} className="form-control" id="subject" ariadescribedby="emailHelp" placeholder="Subject" />
                    </div>
                    <div className="form-group required col-12 col-lg-4">
                      <TextAreaInputField errMessage={this.state.descriptionError} onChange={this.onChange} maxlength="200" type="text" name="description" value={this.state.description} className="form-control" id="description" aria-describedby="codeHelp" placeholder="Description" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 mb-4 offset-md-3 text-center">
              {isPermission("misReportSetting", "update") ? 
                <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" name="Update" disable={isLoading} loading={isLoading} />
              : null}
              <Links to={routehelp.maindashboard} className="btn btn-outline-dark" name="Cancel" />
            </div>
          </div>

        </div>
      </main>
    )
  }
}

function mapStateToProps(state) {
  return {
    misSingleSetting: state.misSingleSetting,
    misSetting_update: state.misSetting_update
  };
}

const mapDispatchToProps = dispatch => ({
  misSettingSingle: data => dispatch(misSettingSingle(data)),
  misSettingUpdate: data => dispatch(misSettingUpdate(data))
});

MisReportSetting = connect(
  mapStateToProps,
  mapDispatchToProps
)(MisReportSetting);

export default MisReportSetting;