// import React, { Component } from 'react';
// import Select from 'react-select';
// import { connect } from 'react-redux';
// import { state_search } from '../../actions';

// class Statedropdown extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       state: ''
//     };
//   }

//   render() {
//     const {
//       state_response_search,
//       ondropdownChange,
//       name,
//       value,
//       placeholder
//     } = this.props;
//     const options = [];
//     if (state_response_search !== undefined && state_response_search.list) {
//       state_response_search.list.map(item =>
//         options.push({ value: item._id, label: item.name })
//       );
//     }
//     return (
//       <div>
//         <label className="control-label">State</label>
//         <Select
//           name={name}
//           Value={value}
//           onChange={value => {
//             ondropdownChange(name, value);
//           }}
//           options={options}
//           placeholder={placeholder !== undefined ? placeholder : "State"}
//         />
//       </div>
//     );
//   }
// }
// function mapStateToProps(state) {
//   return { state_response_search: state.state_response_search };
// }

// const mapDispatchToProps = dispatch => ({
//   state_search: data => dispatch(state_search(data))
// });

// Statedropdown = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(Statedropdown);

// export default Statedropdown;

import React, { lazy, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { state_search, state_create } from '../../actions';
import SearchableDropDown from '../../components/searchableDropDown';
import { optionsList } from '../../helpers/functions';
import ErrorView from '../errorView';
import toastr from 'reactjs-toastr';

let Statedropdown = ({ ondropdownChange, value, placeholder, errMessage, dontPreload, country }) => {
    const dispatch = useDispatch();
    let state_response_search = useSelector(state => state.state_response_search);
    let state_create_res = useSelector(state => state.state_create_res);

    let [searchList, setSearchList] = React.useState([]);
    let [isCreate, setCreate] = React.useState(false);
    let [state, setState] = React.useState("");
    let [stateError, setStateError] = React.useState("");

    React.useEffect(() => {
        let data = {};
        if (!dontPreload) {
            dispatch(state_search(data));
        }
    }, []);

    React.useEffect(() => {
        if (state_response_search !== undefined && state_response_search.list) {
            setSearchList(optionsList(state_response_search.list))
        };
    }, [state_response_search]);

    React.useEffect(() => {
        if (state_create_res && state_create_res.success) {
            dispatch(state_search({
                country: country ? country.value : null
            }));
            toastr.success('State Has been Added Successfully', 'success');
            setCreate(false);
            setState("");
        } else {
            if (state_create_res) {
                state_create_res.errors.map((item, index) =>
                    toastr.error(item.msg, 'Error', {
                        displayDuration: 5000
                    })
                );
            }
        };
    }, [state_create_res]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState(value);
        setStateError("");
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!state) {
            setStateError("state is required");
        } else {
            dispatch(state_create({
                name: state,
                country: country ? country.value : null,
                isActive: true
            }));
        }
    };

    const handleAdd = () => {
        setCreate(!isCreate);
        setState("");
    };

    return (
        <div>
            <div className='d-flex w-100 justify-content-between'>
                <label className="control-label">State</label>
                <span className='add-btn ml-auto d-inline-block float-right' onClick={handleAdd} title='Add State'><i className='fa fa-plus-circle'></i></span>
            </div>
            {
                !isCreate ?
                    <Fragment>
                        <SearchableDropDown
                            value={value}
                            onChange={ondropdownChange}
                            options={searchList}
                            instanceId="state-id"
                            prefixValue="state"
                            placeholder={placeholder !== undefined ? placeholder : "State"}
                        />
                        <ErrorView message={errMessage} />
                    </Fragment> :
                    <Fragment>
                        <div className="input-group add-input">
                            <input onChange={handleInputChange}
                                maxLength="100"
                                type="text"
                                name="state"
                                value={state}
                                className="form-control"
                                id="state-name"
                                placeholder="State Name" />
                            <div className="input-group-append">
                                <span className="input-group-text" onClick={handleSubmit} title='create'>
                                    <i className='fa fa-plus'></i>
                                </span>
                            </div>
                        </div>
                        <ErrorView message={stateError} />
                        {/* <input onChange={handleInputChange}
                            maxLength="100"
                            type="text"
                            name="state"
                            value={state}
                            className="form-control"
                            id="state-name"
                            placeholder="State Name" />
                        <ErrorView message={stateError} />
                        <button type="button" onClick={handleSubmit}>Add</button> */}
                    </Fragment>
            }
        </div>
    )
}



export default Statedropdown;