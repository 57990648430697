import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { routehelp } from '../../Routehelper';
import { uommanagecreate, uomsingle, uommanageupdate } from '../../actions';
import { errorRender, validate, successRedriect } from '../../helpers/functions';
// import TextAreaInputField from '../../components/textAreaInputField';

const Links = lazy(() => import('../../components/links'));
const Buttons = lazy(() => import('../../components/buttons'));
const Inputfield = lazy(() => import('../../components/inputfields'));
const CardTitle = lazy(() => import('../../components/cardTitle'));
const Loader = lazy(() => import('../../components/loader'));
class Uom_Master_From extends Component {
    state = {
        pageType: 'add',
        itemId: '',
        name: "",
        code: "",
        conFact: 1,
        setFields: [{ name: "name", type: "text" }, { name: "code", type: "text" }, { name: "conFact", type: "text" }],
        serverErrors: ["uom_create_res", "uom_update", "uom_single"],
        forRedirect: [
            { name: "uom_create_res", pageName: 'uom', pageUrl: 'uomMaster', action: "Added" },
            { name: "uom_update", pageName: 'uom', pageUrl: 'uomMaster', action: "Updated" }
        ],
        nameError: '',
        codeError: '',
        convFactError: '',
        conFactError: '',
        isLoading: false,
        getSingleLoader: false
    }

    componentDidMount() {
        let id = this.props.match.params.item_id;
        if (id) {
            this.setState({ pageType: "update", itemId: id, getSingleLoader: true })
            this.props.uomsingle(id);
        }
    }

    componentWillUnmount() {
        this.setState({});
    }

    handleChange = (name, e) => {
        const re = /([^\s]+)/;
        // if (name === "conFact" && !e.target.value) {
        //     this.setState({ conFact: 1 });
        // }
        // else 
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [name]: e.target.value });
        }

    }

    onChange = (e) => this.setState({ [e.target.name]: e.target.value });

    onsubmit = (e) => {
        let validationFields = [
            { name: 'name', label: "UOM Name", value: this.state.name, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 50, type: "text", message: "", errName: "nameError" },
            { name: 'code', label: "UOM Code", value: this.state.code, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 20, type: "text", message: "", errName: "codeError" },
            { name: 'conFact', label: "Conversation Factor", value: this.state.conFact, hasError: false, isRequired: true, isNumber: true, onlyChar: false, maxLength: 20, type: "text", message: "", errName: "convFactError" }
        ];

        let trimName = this.state.name && typeof this.state.name === "string" ? this.state.name.trim() : "";
        let trimCode = this.state.code && typeof this.state.code === "string" ? this.state.code.trim() : "";

        let data = {
            name: trimName,
            code: trimCode,
            conFact: this.state.conFact ? this.state.conFact : 1
        };

        e.preventDefault();

        let { newArr, valid } = validate(validationFields);

        if (valid) {
            const re = /^[a-zA-Z0-9_-\s]*$/;
            if (!re.test(trimCode)) {
                alert('Please check your UOM Code.');
                return true;
            }

            const num = /^[1-9]*$/;
            if (!num.test(data.conFact)) {
                alert('Please check your Conversation Factor. 0 or less then 0 is not allowed.');
                return true;
            }

        }

        if (valid) {
            this.setState({ isLoading: true });
            if (this.state.pageType === "update") {
                this.props.uommanageupdate(data, this.state.itemId);
            } else {
                this.props.uommanagecreate(data);
            }
            validationFields = null;
            newArr = null;
        } else {
            newArr.forEach((el) => this.setState({ [el.errName]: el.message }))
        }

    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }
            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));
            if (nextProps.uom_single && nextProps.uom_single.success) {
                this.setState({ getSingleLoader: false });
                this.state.setFields.forEach((value) => {
                    if (value.type === "text") {
                        this.setState({ [value.name]: nextProps.uom_single.item[value.name] })
                    }
                });
                nextProps.uom_single.success = null;
            }
        }
        return true;
    }

    render() {
        const { pageType, isLoading, getSingleLoader } = this.state;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                <CardTitle title={pageType === "update" ? "Update UOM" : "Add UOM"}/>
                                </div>
                                {
                                    getSingleLoader ? <Loader /> :
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield errMessage={this.state.nameError} handleChange={this.handleChange} maxlength="100" type="text" name="name" value={this.state.name} className="form-control" id="name" ariadescribedby="codeHelp" placeholder="Name" />
                                                </div>
                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield errMessage={this.state.codeError} handleChange={this.handleChange} type="text" maxlength="10"
                                                        disabled={pageType === 'update' ? true : false} name="code" value={this.state.code}
                                                        className="form-control" id="code" aria-describedby="codeHelp" placeholder="Code" />
                                                    {/* <small className="text-info help_text">code must be wrriten without space and _ only allowed as special character</small> */}
                                                </div>
                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield errMessage={this.state.convFactError} handleChange={this.handleChange} min="1" max="15" type="number" name="conFact" value={this.state.conFact} className="form-control" id="conFact" aria-describedby="codeHelp" placeholder="Conversation Factor" />
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" disable={isLoading || getSingleLoader} loading={isLoading} name={pageType === "update" ? "Update" : "Save"} />
                            <Links to={routehelp.uomMaster} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        uom_create_res: state.uom_create_res,
        uom_single: state.uom_single,
        uom_update: state.uom_update
    };
}


const mapDispatchToProps = dispatch => ({
    uomsingle: data => dispatch(uomsingle(data)),
    uommanagecreate: data => dispatch(uommanagecreate(data)),
    uommanageupdate: (data, id) => dispatch(uommanageupdate(data, id))
});

Uom_Master_From = connect(
    mapStateToProps,
    mapDispatchToProps
)(Uom_Master_From);

export default Uom_Master_From;