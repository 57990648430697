import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import Inputfield from '../../components/inputfields';
import Buttons from '../../components/buttons';
import Links from '../../components/links';
import { routehelp } from '../../Routehelper';
import { changePassword } from '../../actions';
import { errorRender, successRedriect } from '../../helpers/functions';
import 'reactjs-toastr/lib/toast.css';
const CardTitle = lazy(() => import('../../components/cardTitle'));
class ChangePassword extends Component {

    state = {
        password: '',
        newPassword: '',
        confirmPassword: '',
        isLoading: false,
        error: {
            passError: '',
            passwordError: '',
            confirmPassError: ''
        }
    }

    handleChange = (name, e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [name]: e.target.value });
        }
    }

    validate = () => {
        let passwordError = '';
        let confirmPassError = '';
        let passError = ''
        if (this.state.password.length === 0) {
            passError = 'Old password Is Required';
        }
        if (this.state.newPassword.length === 0) {
            passwordError = 'New password Is Required';
        } else if (this.state.newPassword.length > 15) {
            passwordError = 'New password must be in 15 characters';
        }
        if (this.state.confirmPassword.length === 0) {
            confirmPassError = 'Confirm Password Is Required';
        } else if (this.state.confirmPassword.length > 15) {
            confirmPassError = 'Confirm Password must be in 15 characters'
        }
        if (this.state.newPassword !== this.state.confirmPassword) {
            confirmPassError = 'Password does not matched!!!'
        }
        if (passwordError || confirmPassError || passError) {
            this.setState({ passwordError, confirmPassError, passError });
            return false;
        }
        return true;
    };

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    onsubmit = (e) => {
        let data = {
            password: this.state.password,
            newPassword: this.state.newPassword,
            confirmPassword: this.state.confirmPassword,
        }

        e.preventDefault();
        const isValid = this.validate();
        if (isValid) {
            this.setState(this.state.error);
            this.setState({ isLoading: true });
            this.props.changePassword(data);
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: false })
            }
            errorRender(nextProps, "changePassword_response");
            successRedriect(nextProps, "changePassword_response", "Password", this.props, "maindashboard", "Updated");
        }
        return true;
    }

    render() {
        const { isLoading } = this.state;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                <CardTitle title="Change Password" />
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="form-group required col-12 col-lg-4">
                                            <Inputfield handleChange={this.handleChange} type="password" name="password" value={this.state.password} className="form-control" id="password" ariadescribedby="codeHelp" placeholder="Old Password" errMessage={this.state.passError} />
                                        </div>
                                        <div className="form-group required col-12 col-lg-4">
                                            <Inputfield handleChange={this.handleChange} type="password" name="newPassword" value={this.state.newPassword} className="form-control" id="newPassword" ariadescribedby="codeHelp" placeholder="New Password" errMessage={this.state.passwordError} />
                                        </div>
                                        <div className="form-group required col-12 col-lg-4">
                                            <Inputfield handleChange={this.handleChange} type="password" name="confirmPassword" value={this.state.confirmPassword} className="form-control" id="confirmPassword" aria-describedby="codeHelp" placeholder="Confirm Password" errMessage={this.state.confirmPassError} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" name="Update" disable={isLoading} loading={isLoading} />
                            <Links to={routehelp.maindashboard} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return { changePassword_response: state.changePassword_response };
}

const mapDispatchToProps = dispatch => ({
    changePassword: data => dispatch(changePassword(data))
});

ChangePassword = connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangePassword);

export default ChangePassword;