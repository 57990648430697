import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { routehelp } from '../../Routehelper';
import { schemecreate, schemesingle, schemeupdate } from '../../actions';
import { errorRender, successRedriect, getValue, validate, inputCheck } from '../../helpers/functions';


const TextAreaInputField = lazy(() => import('../../components/textAreaInputField'));
const Inputfield = lazy(() => import('../../components/inputfields'));
const CardTitle = lazy(() => import('../../components/cardTitle'));
const Buttons = lazy(() => import('../../components/buttons'));
const Loader = lazy(() => import('../../components/loader'));
const Links = lazy(() => import('../../components/links'));
class Scheme_manage_add extends Component {
    state = {
        pageType: 'add',
        itemId: '',
        name: '',
        code: '',
        description: '',
        serverError: ["scheme_create_res", "scheme_single", "scheme_update"],
        forRedirect: [
            { name: "scheme_create_res", pageName: 'Scheme', pageUrl: 'schememanagement', action: "Added" },
            { name: "scheme_update", pageName: 'Scheme', pageUrl: 'schememanagement', action: "Updated" }
        ],
        setFields: [
            { name: "name", stateName: "name", type: "text" },
            { name: "description", stateName: "description", type: "text" },
            { name: "code", stateName: "code", type: "text" },
        ],
        nameError: '',
        codeError: '',
        descriptionError: '',
        isLoading: false,
        getSingleLoader: false
    }

    componentDidMount() {
        let id = this.props.match.params.item_id;
        if (id) {
            this.setState({ pageType: "update", itemId: id, getSingleLoader: true })
            this.props.schemesingle(id);
        }
    }

    handleChange = (name, e) => {
        let isValid = inputCheck(name, e.target.value);

        if (isValid) {
            this.setState({ [name]: e.target.value });
        }
        // const re = /([^\s]+)/;
        // if (e.target.value === '' || re.test(e.target.value)) {
        //     this.setState({ [name]: e.target.value });
        // }
    }

    onsubmit = (e) => {
        let validationFields = [
            { name: 'name', label: "Name", value: this.state.name, hasError: false, isRequired: true, isNumber: false, onlyChar: true, maxLength: 50, type: "text", message: "", errName: "nameError" },
            { name: 'description', label: "Description", value: this.state.description, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 200, type: "text", message: "", errName: "descriptionError" },
            { name: 'code', label: "code", value: this.state.code, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 20, type: "text", message: "", errName: "codeError" }
        ]
        let data = {
            name: this.state.name,
            description: this.state.description,
            code: this.state.code,
            isActive: true
        }
        e.preventDefault();
        let { newArr, valid } = validate(validationFields);
        newArr.map((el) => this.setState({ [el.errName]: el.message }));
        if (valid) {
            this.setState({ isLoading: true });
            if (this.state.pageType === "update") {
                this.props.schemeupdate(data, this.state.itemId);
            } else {
                this.props.schemecreate(data);
            }
            validationFields = null;
            newArr = null;
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }
            this.state.serverError.forEach((el) => errorRender(nextProps, el));
            this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));
            if (nextProps.scheme_single && nextProps.scheme_single.success) {
                this.setState({ getSingleLoader: false });
                nextProps.scheme_single.success = null;
                this.state.setFields.forEach((value) => {
                    if (value.type === "text") {
                        this.setState({ [value.stateName]: getValue(nextProps.scheme_single.item, value.name) })
                    }
                })
            }
        }
        return true;
    }

    render() {
        const { pageType, isLoading, getSingleLoader } = this.state;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                <CardTitle title={pageType === "update" ? "Update Scheme" : "Add Scheme"}/>
                                </div>
                                {getSingleLoader ? <Loader /> :
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="form-group required col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.nameError} maxlength="100" handleChange={this.handleChange} type="text" name="name" value={this.state.name} className="form-control" id="name" ariadescribedby="codeHelp" placeholder="Name" />
                                            </div>
                                            <div className="form-group required col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.codeError} maxlength="10" handleChange={this.handleChange} type="text" name="code" value={this.state.code} className="form-control" id="code" ariadescribedby="codeHelp" placeholder="Code" />
                                            </div>
                                            <div className="form-group required col-12 col-lg-4">
                                                <TextAreaInputField errMessage={this.state.descriptionError} onChange={(e) => this.handleChange('description', e)} type="text" name="description" maxLength="5000" value={this.state.description} className="form-control" id="description" ariadescribedby="codeHelp" placeholder="Description" />
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" disable={isLoading || getSingleLoader} loading={isLoading} name={pageType === "update" ? "Update" : "Save"} />
                            <Links to={routehelp.schememanagement} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        scheme_create_res: state.scheme_create_res,
        scheme_single: state.scheme_single,
        scheme_update: state.scheme_update
    };
}


const mapDispatchToProps = dispatch => ({
    schemecreate: data => dispatch(schemecreate(data)),
    schemesingle: data => dispatch(schemesingle(data)),
    schemeupdate: (data, id) => dispatch(schemeupdate(data, id))
});

Scheme_manage_add = connect(
    mapStateToProps,
    mapDispatchToProps
)(Scheme_manage_add);

export default Scheme_manage_add;