import React from 'react';
import Loader from 'react-loader-spinner';

const loader = ({ type, height, width, colour, name }) => {
  return (
    <div>
      <div className="d-flex align-items-center justify-content-center">
        <Loader type={type ? type : "Rings"} color={colour ? colour : "#2B54BA"} height={height ? height : "100"} width={width ? width : "100"} ></Loader>
      </div>
      {name ? <p className='text-primary'><b> {name} </b></p> : ""}
    </div>
  );
}

export default loader;
