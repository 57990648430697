import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { discountSingle, productmanagementlist, discountUpdate } from '../../actions';
import { Redirect } from 'react-router-dom';
import Inputfield from '../../components/inputfields';
import Buttons from '../../components/buttons';
import Links from '../../components/links';
import { routehelp } from '../../Routehelper';
import Translate from '../../libs/translation';
import toastr from 'reactjs-toastr';
import Branddropdown from '../brandsManagement/brandDropdown'
import moment from 'moment';
import 'reactjs-toastr/lib/toast.css';
const CardTitle = lazy(() => import('../../components/cardTitle'));
class DiscountMasterUpdate extends Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onsubmit = this.onsubmit.bind(this);
        this.ondropdownChange = this.ondropdownChange.bind(this);
        this.state = {
            name: '',
            code: '',
            error: {
                nameError: '',
                codeError: ''
            }
        }
    }

    componentWillReceiveProps(props) {
        let tempBrand = [];
        let tempProd = [];
        if (props.discount_singleRes && props.discount_singleRes.success === true) {
            this.setState({
                name:
                    props.discount_singleRes !== undefined && props.discount_singleRes.item && props.discount_singleRes.item.name
                        ? props.discount_singleRes.item.name
                        : '',
                description:
                    props.discount_singleRes !== undefined && props.discount_singleRes.item && props.discount_singleRes.item.description
                        ? props.discount_singleRes.item.description
                        : '',
                incDicountType:
                    props.discount_singleRes !== undefined && props.discount_singleRes.item && props.discount_singleRes.item.incrDiscount && props.discount_singleRes.item.incrDiscount.unit
                        ? props.discount_singleRes.item.incrDiscount.unit
                        : '',
                incAmount:
                    props.discount_singleRes !== undefined && props.discount_singleRes.item && props.discount_singleRes.item.incrDiscount && props.discount_singleRes.item.incrDiscount.amount
                        ? props.discount_singleRes.item.incrDiscount.amount
                        : '',
                incUnit:
                    props.discount_singleRes !== undefined && props.discount_singleRes.item && props.discount_singleRes.item.incrDiscCriteria && props.discount_singleRes.item.incrDiscCriteria[0].unit
                        ? props.discount_singleRes.item.incrDiscCriteria[0].unit
                        : '',
                incAmtOperation:
                    props.discount_singleRes !== undefined && props.discount_singleRes.item && props.discount_singleRes.item.incrDiscCriteria && props.discount_singleRes.item.incrDiscCriteria[0].amtOperation
                        ? props.discount_singleRes.item.incrDiscCriteria[0].amtOperation
                        : '',
                incValue:
                    props.discount_singleRes !== undefined && props.discount_singleRes.item && props.discount_singleRes.item.incrDiscCriteria && props.discount_singleRes.item.incrDiscCriteria[0].value
                        ? props.discount_singleRes.item.incrDiscCriteria[0].value
                        : '',
                decDicountType:
                    props.discount_singleRes !== undefined && props.discount_singleRes.item && props.discount_singleRes.item.decrDiscount && props.discount_singleRes.item.decrDiscount.unit
                        ? props.discount_singleRes.item.decrDiscount.unit
                        : '',
                decAmount:
                    props.discount_singleRes !== undefined && props.discount_singleRes.item && props.discount_singleRes.item.decrDiscount && props.discount_singleRes.item.decrDiscount.amount
                        ? props.discount_singleRes.item.decrDiscount.amount
                        : '',
                decUnit:
                    props.discount_singleRes !== undefined && props.discount_singleRes.item && props.discount_singleRes.item.decrDiscCriteria && props.discount_singleRes.item.decrDiscCriteria[0].unit
                        ? props.discount_singleRes.item.decrDiscCriteria[0].unit
                        : '',
                decAmtOperation:
                    props.discount_singleRes !== undefined && props.discount_singleRes.item && props.discount_singleRes.item.decrDiscCriteria && props.discount_singleRes.item.decrDiscCriteria[0].amtOperation
                        ? props.discount_singleRes.item.decrDiscCriteria[0].amtOperation
                        : '',
                decValue:
                    props.discount_singleRes !== undefined && props.discount_singleRes.item && props.discount_singleRes.item.decrDiscCriteria && props.discount_singleRes.item.decrDiscCriteria[0].value
                        ? props.discount_singleRes.item.decrDiscCriteria[0].value
                        : '',
                // decValue:
                //     props.discount_singleRes !== undefined && props.discount_singleRes.item && props.discount_singleRes.item.decrDiscCriteria && props.discount_singleRes.item.decrDiscCriteria[0].value
                //         ? props.discount_singleRes.item.decrDiscCriteria[0].value
                //         : '',
                maxDiscount:
                    props.discount_singleRes !== undefined && props.discount_singleRes.item && props.discount_singleRes.item.decrDiscount && props.discount_singleRes.item.maxDiscount
                        ? props.discount_singleRes.item.maxDiscount
                        : '',
                startDate:
                    props.discount_singleRes !== undefined && props.discount_singleRes.item && props.discount_singleRes.item && props.discount_singleRes.item.startDate
                        ? moment(props.discount_singleRes.item.startDate).format("YYYY-MM-DD")
                        : '',
                endDate:
                    props.discount_singleRes !== undefined && props.discount_singleRes.item && props.discount_singleRes.item && props.discount_singleRes.item.endDate
                        ? moment(props.discount_singleRes.item.endDate).format("YYYY-MM-DD")
                        : '',
            });

            props.discount_singleRes !== undefined && props.discount_singleRes.brands && props.discount_singleRes.brands.forEach((item) => {
                tempBrand.push({ value: item._id, label: item.name })
            });

            this.ondropdownChange("brand", tempBrand);
            props.discount_singleRes !== undefined && props.discount_singleRes.products && props.discount_singleRes.products.forEach((item) => {
                tempProd.push({ value: item._id, label: item.name })
            });

            this.ondropdownChange("product", tempProd);

            props.discount_singleRes.success = null;
        }

        return false;
    }

    validate = () => {
        let nameError = '';
        let codeError = '';
        if (this.state.name.length === 0) {
            nameError = 'Name Is Required';
        } else if (/[0-9]/.test(this.state.name)) {
            nameError = 'Only Aplhabets';
        } else if (this.state.name.length > 20) {
            nameError = 'Name must be in 20 characters';
        }
        if (this.state.code.length === 0) {
            codeError = 'Code Is Required';
        } else if (this.state.code.length > 10) {
            codeError = 'Code must be in 10 characters'
        }
        if (nameError || codeError) {
            this.setState({ nameError, codeError });
            return false;
        }
        return true;
    };

    shouldComponentUpdate(nextProps) {
        let req = {};

        if (nextProps.discountUpdateRes !== undefined && nextProps.discountUpdateRes.success === false && this.props !== nextProps) {
            nextProps.discountUpdateRes.errors.map((item, index) =>
                toastr.error(Translate.translate(req, item.msg), 'Error', {
                    displayDuration: 5000
                })
            );
            nextProps.discountUpdateRes.success = null;
        }

        if (nextProps.productmanagement_res !== undefined && nextProps.productmanagement_res.list && nextProps.productmanagement_res.success === true) {
            if (this.state.brand_value && this.state.brand_value.length > 0) {
                this.setState({ options: [] });
                nextProps.productmanagement_res.list.forEach(item => {
                    this.state.options.push({ value: item._id, label: item.name });
                });
            }
            this.forceUpdate();
            nextProps.productmanagement_res.success = null
        }
        return true;
    }

    ondropdownChange(e, t) {
        let temp = [];
        let newTemp = [];

        if (e === "brand") {
            if (t && t !== undefined && t !== null) {
                t.forEach((item) => {
                    temp.push(
                        item.value,
                    )
                    newTemp.push({
                        value: item.value,
                        label: item.label
                    })
                    // this.setState({ customer: temparray })
                    // console.log(temparray);
                });
                this.setState({ brand_array: temp, brand_value: newTemp });
                let data = {};
                data.limit = 50000;
                data.filter = { brand: temp };
                this.props.productmanagementlist(data);
                if (t.length === 0) {
                    this.setState({ product_array: [], product_value: [] });
                }
            } else {
                this.setState({ brand_array: [], brand_value: [], product_array: [], product_value: [] });
                let data = {};
                data.filter = { brand: undefined };
                this.props.productmanagementlist(data);
            }
        }

        if (e === "product") {
            if (t && t !== undefined && t !== null) {
                t.forEach((item) => {
                    temp.push(
                        item.value,
                    )
                    newTemp.push({
                        value: item.value,
                        label: item.label
                    })
                    // this.setState({ customer: temparray })
                    // console.log(temparray);
                })

                this.setState({ product_array: temp, product_value: newTemp });
            } else {
                this.setState({ product_array: [], product_value: [] })
            }
        }

    }

    handleChange(name, e) {
        const re = /([^\s]+)/;

        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [name]: e.target.value });
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    componentDidMount() {
        let id = this.props.match.params.item_id;
        this.props.discountSingle(id);
    }

    onsubmit(e) {
        let id = this.props.match.params.item_id;
        let data = {
            name: this.state.name,
            description: this.state.description,
            incrDiscount: { unit: this.state.incDicountType, amount: this.state.incAmount },
            incrDiscCriteria: [{ unit: this.state.incUnit, amtOperation: this.state.incAmtOperation, value: this.state.incValue }],
            decrDiscount: { unit: this.state.decDicountType, amount: this.state.decAmount },
            decrDiscCriteria: [{ unit: this.state.decUnit, amtOperation: this.state.decAmtOperation, value: this.state.decValue }],
            // categories: this.state.categories,
            brands: this.state.brand_array,
            products: this.state.product_array,
            maxDiscount: this.state.maxDiscount,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            isActive: true,
        }
        e.preventDefault();
        // const isValid = this.validate();
        // if (isValid) {
        // this.setState(this.state.error);
        this.props.discountUpdate(data, id);
        // }
    }

    render() {
        let { discountUpdateRes } = this.props;
        if (discountUpdateRes !== undefined) {
            if (discountUpdateRes.success === true) {
                toastr.success('Discount Has been Updated Successfully', 'success');
                discountUpdateRes.success = null;
                return <Redirect to={routehelp.discountMaster} />
            }
        }

        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                <CardTitle title="Update Discount"/>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="form-group required col-12 col-lg-6">
                                            <label htmlFor="exampleInputName1" className="control-label">Title</label>
                                            <Inputfield handleChange={this.handleChange} type="text" name="name" maxlength="20" value={this.state.name} className="form-control" id="name" ariadescribedby="codeHelp" placeholder="Title" />
                                            <small style={{ fontSize: 10, color: 'red' }}>
                                                {' '}
                                                {this.state.nameError}{' '}
                                            </small>
                                        </div>
                                        <div className="form-group required col-12 col-lg-6">
                                            <label htmlFor="exampleInputcode" className="control-label">Description</label>
                                            <textarea onChange={this.onChange} maxlength="10" type="text" name="description" value={this.state.description} className="form-control" id="description" aria-describedby="codeHelp" placeholder="Description" />
                                            <small style={{ fontSize: 10, color: 'red' }}>
                                                {' '}
                                                {this.state.codeError}{' '}
                                            </small>
                                        </div>
                                    </div>
                                    <div>
                                        <h5 className="card-title">
                                            Increase Discount
                                        </h5>
                                        <div className="row">
                                            <div className="form-group col required">
                                                <label htmlFor="exampleInputName1" className="control-label">Discount Type</label>
                                                <select
                                                    className="form-control"
                                                    name="incDicountType"
                                                    value={this.state.incDicountType}
                                                    onChange={this.onChange}
                                                >
                                                    <option value="">Select Type</option>
                                                    <option value="percent">Percent</option>
                                                    <option value="fixed">Fixed</option>
                                                </select>
                                                <small style={{ fontSize: 10, color: 'red' }}>
                                                    {' '}
                                                    {this.state.nameError}{' '}
                                                </small>
                                            </div>
                                            <div className="form-group col required">
                                                <label htmlFor="exampleInputcode" className="control-label">Amount</label>
                                                <Inputfield handleChange={this.handleChange} type="text" name="incAmount" maxlength="20" value={this.state.incAmount} className="form-control" id="incAmount" ariadescribedby="codeHelp" placeholder="Amount" />
                                                <small style={{ fontSize: 10, color: 'red' }}>
                                                    {' '}
                                                    {this.state.codeError}{' '}
                                                </small>
                                            </div>
                                            <div className="form-group col required">
                                                <label htmlFor="exampleInputName1" className="control-label">On field</label>
                                                <select
                                                    className="form-control"
                                                    name="incUnit"
                                                    value={this.state.incUnit}
                                                    onChange={this.onChange}
                                                // id="excelType"
                                                >
                                                    <option value="">Select Unit</option>
                                                    <option value="quantity">Quantity</option>
                                                    <option value="total">Total</option>
                                                    <option value="price">Price</option>
                                                </select>
                                                <small style={{ fontSize: 10, color: 'red' }}>
                                                    {' '}
                                                    {this.state.nameError}{' '}
                                                </small>
                                            </div>
                                            <div className="form-group col required">
                                                <label htmlFor="exampleInputcode" className="control-label">Amount Operation</label>
                                                <Inputfield handleChange={this.handleChange} maxlength="10" type="text" name="incAmtOperation" value={this.state.incAmtOperation} className="form-control" id="incAmtOperation" aria-describedby="codeHelp" placeholder="Amount Operation" />
                                                <small style={{ fontSize: 10, color: 'red' }}>
                                                    {' '}
                                                    {this.state.codeError}{' '}
                                                </small>
                                            </div>
                                            <div className="form-group col required">
                                                <label htmlFor="exampleInputcode" className="control-label">Value</label>
                                                <Inputfield handleChange={this.handleChange} maxlength="10" type="text" name="incValue" value={this.state.incValue} className="form-control" id="incValue" aria-describedby="codeHelp" placeholder="Value" />
                                                <small style={{ fontSize: 10, color: 'red' }}>
                                                    {' '}
                                                    {this.state.codeError}{' '}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <h5 className="card-title">
                                            Decrease Discount
                                        </h5>
                                        <div className="row">
                                            <div className="form-group col required">
                                                <label htmlFor="exampleInputName1" className="control-label">Discount Type</label>
                                                <select
                                                    className="form-control"
                                                    name="decDicountType"
                                                    value={this.state.decDicountType}
                                                    onChange={this.onChange}

                                                >
                                                    <option value="">Select Type</option>
                                                    <option value="percent">Percent</option>
                                                    <option value="fixed">Fixed</option>
                                                </select>
                                                <small style={{ fontSize: 10, color: 'red' }}>
                                                    {' '}
                                                    {this.state.nameError}{' '}
                                                </small>
                                            </div>
                                            <div className="form-group col required">
                                                <label htmlFor="exampleInputcode" className="control-label">Amount</label>
                                                <Inputfield handleChange={this.handleChange} type="text" name="decAmount" maxlength="20" value={this.state.decAmount} className="form-control" id="decAmount" ariadescribedby="codeHelp" placeholder="Amount" />
                                                <small style={{ fontSize: 10, color: 'red' }}>
                                                    {' '}
                                                    {this.state.codeError}{' '}
                                                </small>
                                            </div>
                                            <div className="form-group col required">
                                                <label htmlFor="exampleInputName1" className="control-label">On field</label>
                                                <select
                                                    className="form-control"
                                                    name="decUnit"
                                                    value={this.state.decUnit}
                                                    onChange={this.onChange}

                                                >
                                                    <option value="">Select Unit</option>
                                                    <option value="quantity">Quantity</option>
                                                    <option value="total">Total</option>
                                                    <option value="price">Price</option>
                                                </select>
                                                <small style={{ fontSize: 10, color: 'red' }}>
                                                    {' '}
                                                    {this.state.nameError}{' '}
                                                </small>
                                            </div>
                                            <div className="form-group col required">
                                                <label htmlFor="exampleInputcode" className="control-label">Amount Operation</label>
                                                <Inputfield handleChange={this.handleChange} maxlength="10" type="text" name="decAmtOperation" value={this.state.decAmtOperation} className="form-control" id="decAmtOperation" aria-describedby="codeHelp" placeholder="Amount Operation" />
                                                <small style={{ fontSize: 10, color: 'red' }}>
                                                    {' '}
                                                    {this.state.codeError}{' '}
                                                </small>
                                            </div>
                                            <div className="form-group col required">
                                                <label htmlFor="exampleInputcode" className="control-label">Value</label>
                                                <Inputfield handleChange={this.handleChange} maxlength="10" type="text" name="decValue" value={this.state.decValue} className="form-control" id="decValue" aria-describedby="codeHelp" placeholder="Value" />
                                                <small style={{ fontSize: 10, color: 'red' }}>
                                                    {' '}
                                                    {this.state.codeError}{' '}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col required">
                                            <label htmlFor="exampleInputcode" className="control-label">Maximum Discount</label>
                                            <Inputfield handleChange={this.handleChange} type="text" name="maxDiscount" value={this.state.maxDiscount} className="form-control" id="maxDiscount" aria-describedby="codeHelp" placeholder="Maximum Discount" />
                                            <small style={{ fontSize: 10, color: 'red' }}>
                                                {' '}
                                                {this.state.codeError}{' '}
                                            </small>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col required">
                                            <Branddropdown
                                                ondropdownChange={this.ondropdownChange}
                                                name="brand"
                                                value={this.state.brand_value}
                                                className="form-control form-control-square"
                                                onSelectResetsInput={true}
                                                isMulti={true} isSearchable={true}
                                            />
                                        </div>
                                        {/* <div className="form-group col required">

                                            <label className="control-label">Product</label>
                                            <Select
                                                name="product"
                                                onChange={value => {
                                                    this.ondropdownChange("product", value);
                                                }}
                                                value={this.state.product_value}

                                                isMulti={true} isSearchable={true}
                                                closeMenuOnSelect={false}
                                                options={this.state.options}
                                                placeholder="Product"
                                            />
                                            <small style={{ fontSize: 12, color: 'red' }}>
                                                {' '}
                                                {this.state.productError}{' '}
                                            </small>
                                        </div> */}
                                    </div>
                                    <div className="row">
                                        <div className="form-group col required">
                                            <label htmlFor="exampleInputcode" className="control-label">Start Date</label>
                                            <Inputfield handleChange={this.handleChange} type="date" name="startDate" value={this.state.startDate} className="form-control" id="startDate" aria-describedby="codeHelp" placeholder="Start Date" />
                                            <small style={{ fontSize: 10, color: 'red' }}>
                                                {' '}
                                                {this.state.codeError}{' '}
                                            </small>
                                        </div>
                                        <div className="form-group col required">
                                            <label htmlFor="exampleInputcode" className="control-label">End date</label>
                                            <Inputfield handleChange={this.handleChange} type="date" name="endDate" value={this.state.endDate} className="form-control" id="endDate" aria-describedby="codeHelp" placeholder="End date" />
                                            <small style={{ fontSize: 10, color: 'red' }}>
                                                {' '}
                                                {this.state.codeError}{' '}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" name="Update" />
                            <Links to={routehelp.discountMaster} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        discount_singleRes: state.discount_singleRes,
        discountUpdateRes: state.discountUpdateRes,
        productmanagement_res: state.productmanagement_res
    };
}

const mapDispatchToProps = dispatch => ({
    productmanagementlist: data => dispatch(productmanagementlist(data)),
    discountSingle: data => dispatch(discountSingle(data)),
    discountUpdate: (data, id) => dispatch(discountUpdate(data, id))
});

DiscountMasterUpdate = connect(
    mapStateToProps,
    mapDispatchToProps
)(DiscountMasterUpdate);

export default DiscountMasterUpdate;