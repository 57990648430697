import toastr from 'reactjs-toastr';
import Translate from '../libs/translation';
import { routehelp } from '../Routehelper';
import { uploadUrl } from '../config';
import _ from 'underscore';
import 'reactjs-toastr/lib/toast.css';


const requiredMsg = "is required";
const alphabetMsg = "Should be Alphabets only";
const numberMsg = "Should be Number only";
const minLenMsg = "Should be Greater then or equal to";
const maxLenMsg = "Should be smaller then";
const invalidMsg = "is invalid";

export const redirectList = [
    { to: routehelp.maindashboard, label: 'Dashboard', match: "dashboard" },
    { to: routehelp.zonemanagement, label: 'Zone', match: "zone" },
    { to: routehelp.Regionmanagement, label: 'Region', match: "region" },
    { to: routehelp.Territorymanagement, label: 'Territory', match: "territory" },
    { to: routehelp.Beatsmanagement, label: 'Beat', match: "beat" },
    { to: routehelp.usermanagement, label: 'User', match: "users" },
    { to: routehelp.customermanagement, label: 'Customer', match: "customers" },
    { to: routehelp.indirectcustomer, label: 'Indirect Customers', match: "indirectCustomers" },
    { to: routehelp.SrAsmAssignment, label: 'Sr. ASM-Users Assignment', match: "srASM" },
    { to: routehelp.Brandmanagement, label: 'Brand', match: "brand" },
    { to: routehelp.productmanagement, label: 'Product', match: "product" },
    { to: routehelp.uomMaster, label: 'UOM', match: "uom" },
    { to: routehelp.stockmanagement, label: 'Stock', match: "stock" },
    { to: routehelp.agencyMaster, label: 'Agency', match: "agency" },
    { to: routehelp.transporter, label: 'Transporter', match: "transporter" },
    { to: routehelp.schememanagement, label: 'Scheme', match: "scheme" },
    { to: routehelp.outstanding, label: 'Outstanding', match: "outstanding" },
    { to: routehelp.dispatchDetails, label: 'Dispatch Details', match: "dispatchDetails" },
    { to: routehelp.ordermanagement, label: 'Order', match: "order" },
    { to: routehelp.routeplanning, label: 'Route Planning', match: "routePlanning" },
    { to: routehelp.allowanceCatagory, label: 'Category', match: "categoryExpense" },
    { to: routehelp.allowanceSubCatagory, label: 'Sub Category', match: "subCategoryExpense" },
    { to: routehelp.expenseApproval, label: 'Expense Approval', match: "expenseApprovalExpense" },
    { to: routehelp.advanceApproval, label: 'Advance Approval', match: "advanceApproval" },
    { to: routehelp.retail, label: 'Retailer Users', match: "userRetailers" },
    { to: routehelp.trending, label: "What's Trending", match: "trending" },
    { to: routehelp.catagory, label: 'Categories', match: "categoriesRetailers" },
    { to: routehelp.eCatalogue, label: 'E-catalogue', match: "ecatalogue" },
    { to: routehelp.retailerOrdermanagement, label: "Retailer's Order", match: "orderRetailers" },
    { to: routehelp.payment, label: 'Payments', match: "payment", },
    { to: routehelp.feedback, label: 'Feedback', match: "feedback", },
    { to: routehelp.Profile, label: 'Profile', match: "profile" },
    { to: routehelp.misReportSetting, label: 'Mis Report Setting', match: "misReportSetting" },
    { to: routehelp.config, label: 'Company Config', match: "companyConfig" },
    { to: routehelp.emailConfig, label: 'Email Config', match: "emailConfig" },
];

export function errorRender(value, key, func) {
    let req = {};
    if (value[key] !== undefined && value[key].success === false) {
        value[key].success = null;
        value[key].errors && value[key].errors.map((item, index) =>
            toastr.error(Translate.translate(req, item.msg), 'Error', {
                displayDuration: 5000
            })
        )
        func && func()
    }
}

export function loadOnSuccess(value, key, func) {
    if (value[key] !== undefined && value[key].success) {
        value[key].success = null;
        func();
    }
}

export function successRender(value, key, func, message) {
    // debugger
    if (value[key] !== undefined && value[key].success === true) {
        toastr.success(message, 'Success');
        value[key].success = null
        func();
    }
}

export function getValue(obj, path) {
    if (path !== undefined && path !== "") {
        path = path.split('.');
        let current = obj;
        while (path.length) {
            if (typeof current !== 'object') return undefined;
            current = current[path.shift()];
        }
        return current;
    } else {
        return obj.slavesData[0]._id;
    }
}


export function optionsList(arr) {
    const options = [];
    arr.sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        // if (b.name.toUpperCase() > a.name.toUpperCase()) return 1;
        return 0;
    }).map(item =>
        options.push({ value: item._id, label: item.name })
    );
    return options;
}


export function imageDownloader(serverUrl, imageName) {
    fetch(serverUrl, {
        method: "GET",
        headers: {}
    })
        .then(response => {
            debugger
            response.arrayBuffer().then(function (buffer) {
                const url = window.URL.createObjectURL(new Blob([buffer]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", imageName); //or any other extension
                document.body.appendChild(link);
                link.click();
            });
        })
        .catch(err => {
            console.log(err);
        });
}


export function commonDebounce(func, timer) {
    func = _.debounce(func, timer);
    return func
}


export function checkForDigits(value) {
    return !/^\d+$/.test(value) ? false : true
}

export function checkForDigitsWithDecimal(value) {
    // eslint-disable-next-line
    return !/\A[+-]?\d+(?:\.\d{1,2})?\z/.test(value) ? false : true
}

export function emailCheck(value) {
    // eslint-disable-next-line
    return !/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(value) ? false : true
}

export function passwordCheck(value) {
    // eslint-disable-next-line
    return !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.!#$%^&*?])[A-Za-z\d@.!#$%^&*?]{8,16}$/.test(value) ? false : true
}

export function validate(arr) {
    let newArr = arr;
    let valid = true;
    newArr.forEach(element => {
        // console.log(element.type)
        switch (element.type) {
            case "text":
                element = textValidator(element);
                break;
            case "select":
                element = selectValidator(element);
                break;
            case "email":
                element = emailValidator(element);
                break;
            case "password":
                element = passwordValidator(element);
                break;
            default:
        }
        if (element.hasError) valid = !element.hasError;
    });
    return { newArr, valid };
}

export function getCompanyId (type) {
    if (type === "CA") {
        return JSON.parse(JSON.stringify(localStorage.getItem('companyId')));
    } else {
        return JSON.parse(JSON.stringify(localStorage.getItem('userId')));
    }
}

export function formatOfDecimal(val, dec = 2) {
    return parseFloat(parseFloat(val).toFixed(dec));
}

export function isPermission(moduleName, isShow) {
    const permission = JSON.parse(localStorage.getItem("permission"));
    const ispermissions = permission !== null && permission !== undefined && permission.permission.find(permission => permission.name === moduleName);
    if (ispermissions) {
        return ispermissions[isShow];
    } else {
        return false;
    }
}

export function getSeriesValues(item) {
    const seriesValue = [
        { name: "CLS" },
        { name: "ACC" },
        { name: "DTR" },
        { name: "WHS" },
        { name: "WHB" },
        { name: "RMB" },
        { name: "MID" },
        { name: "PIN" },
        { name: "RMG" },
        { name: "SRC" },
        { name: "ONL" },
        { name: "CDTR" },
        { name: "CWHS" },
        { name: "CWHB" },
        { name: "CRMB" },
        { name: "CMID" },
        { name: "CPIN" },
        { name: "CRMG" },
        { name: "ACS" },
        { name: "ICS" },
        { name: "CICS" },
        { name: "MIX" }
      ];
    const existingSeriesNames = seriesValue.map((item) => item.name); 
    if (item.series && item.series.length > 0) {
        const newSeries = item.series.filter((item) => !existingSeriesNames.includes(item.label)).map((item) => ({ name: item.label }));
        return [...seriesValue, ...newSeries];
    } else {
        return seriesValue;
    }
}

export function redirect(e, item, props) {
    props.history.push(`${props.location.pathname}${"/update/"}${item._id}`);
}

export function successRedriect(value, key, name, props, redirecTo, action) {
    if (value[key] !== undefined && value[key].success) {
        value[key].success = null;
        toastr.success(`${name} has been ${action} successfully`, 'Success');
        props.history.push(routehelp[redirecTo]);
    }
}

export function loaderUpdate(value, key) {
    let loading = true;
    if (value[key] && value[key].success) {
        value[key].success = null;
        loading = false
    }
    return loading
}


export function singleArrFunc(arr, value) {
    let newArr = [];
    arr.forEach(element => newArr.push(element[value]));
    return newArr
}


export function fileDownload(value, key, location) {
    if (value[key] && value[key].success) {
        value[key].success = null;
        window.open(`${uploadUrl}${value[key].item[location]}`);
    }
}


export function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours %= 12;
    hours = hours || 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;
    const strTime = `${hours}:${minutes}:${seconds} ${ampm.toUpperCase()}`;
    return strTime;
};



export function textValidator(element) {
    switch (true) {
        case (element.isRequired && (element.value === null || element.value === "" || element.value.length === 0)):
            setValidation(element, requiredMsg);
            break;
        case (element.onlyChar && element.value !== null && element.value && element.value.length !== 0):
            if (checkForDigits(element.value)) setValidation(element, alphabetMsg);
            break;
        case (element.isNumber && element.value !== null && element.value && element.value.length !== 0):
            if (!checkForDigits(element.value)) setValidation(element, numberMsg);
            if (element.value && element.value.length < element.minLength) customSetValidation(element, minLenMsg, "minLength", "characters");
            if (element.value && element.value.length > element.maxLength) customSetValidation(element, maxLenMsg, "maxLength", "characters");
            break;
        case (element.isDecimal && element.value !== null && element.value && element.value.length !== 0):
            if (checkForDigitsWithDecimal(element.value)) setValidation(element, numberMsg);
            if (element.value && element.value.length < element.minLength) customSetValidation(element, minLenMsg, "minLength", "characters");
            if (element.value && element.value.length > element.maxLength) customSetValidation(element, maxLenMsg, "maxLength", "characters");
            break;
        default:
    }
    return element;
}



export function selectValidator(element) {
    if (element.isRequired && (element.value === null || element.value === "" || (typeof element.value === "object" && element.value.length === 0))) setValidation(element, requiredMsg);
    return element;
}


export function emailValidator(element) {
    switch (true) {
        case (element.isRequired && (element.value === null || element.value === "" || element.value === undefined)):
            element = setValidation(element, requiredMsg);
            break;
        case (element.value && element.value.length > 0):
            if (!emailCheck(element.value)) element = setValidation(element, invalidMsg);
            break;
        default:
    }
    return element;
};

export function passwordValidator(element) {
    switch (true) {
        case (element.isRequired && (element.value === null || element.value === "" || element.value === undefined)):
            element = setValidation(element, requiredMsg);
            break;
        case (element.value && element.value.length > 0):
            if (!passwordCheck(element.value)) {
                element = setValidation(element, invalidMsg);
            }
            break;
        default:
    }
    return element;
};

export function setValidation(element, message) {
    element.message = `${element.label} ${message}`;
    element.hasError = true;
    return element
};


export function customSetValidation(element, firstMsg, setFor, secondMsg) {
    element.message = `${element.label} ${firstMsg} ${element[setFor]} ${secondMsg}`;
    element.hasError = true;
    return element
}


export function inputCheck(name, value, pageName = null) {
    // console.log(pageName);
    // console.log(value);
    if (name && name === "code") {
        const re = /^[a-zA-Z0-9][a-zA-Z0-9_-\s]*$/;
        if (value === '' || re.test(value)) {
            return true;
        }
        return false;
    } else if (name && ["mobile", "ownersContactNumber", "phone"].indexOf(name) > -1) {
        const re = /^[0-9]*$/;
        if (value === '' || re.test(value)) {
            return true;
        }
        return false;
    }
    else if (name && ["whlprice", "price"].indexOf(name) > -1) {
        const re = /^\d*\.?(?:\d{1,2})?$/;
        if (value === '' || re.test(value)) {
            return true;
        }
        return false;
    } else if (name && name === "accountCode") {
        const re = /^[a-zA-Z0-9]*$/;
        if (value === '' || re.test(value)) {
            return true;
        }
        return false;
    } else if (name && name === "postalCode") {
        // const re = /^[a-zA-Z0-9][a-zA-Z0-9\s]*$/;
        const re = /^[0-9][0-9\s]*$/;
        if (value === '' || re.test(value)) {
            return true;
        }
        return false;
    } else if (name && ["ownersName"].indexOf(name) > -1) {
        const re = /^[a-zA-Z][a-zA-Z\s]*$/;
        if (value === '' || re.test(value)) {
            return true;
        }
        return false;
    } else if (name && name === "name" && ["user", "retailerUser"].indexOf(pageName) > -1) {
        const re = /^[a-zA-Z.-][a-zA-Z.-\s]*$/;
        if (value === '' || re.test(value)) {
            return true;
        }
        return false;
    } // else if (name && name === "password") {
    //     const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.!#$%^&*?])[A-Za-z\d@.!#$%^&*?]{8,16}$/;
    //     if (re.test(value)) {
    //         return true;
    //     }
    //     return false;
    // }
    //  else if (name && name === "email") {
    //     // eslint-disable-next-line no-useless-escapes
    //     const re = RegExp(
    //         /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/
    //     );
    //     if (re.test(value)) {
    //         return true;
    //     }
    //     return false;
    // } 
    else {
        const re = /([^\s]+)/;
        if (value === '' || re.test(value)) {
            return true;
            // this.setState({ [name]: value });
        }
        return false;
    }
}

export function addMonths(date, months) {
    // console.log(date);
    // console.log(months);

    let newDate = new Date(date);
    var day = newDate.getDate();
    newDate.setMonth(newDate.getMonth() + months);
    if (newDate.getDate() !== day)
        newDate.setDate(0);

    // console.log(newDate);
    return newDate ? newDate.toJSON().slice(0, 10).split`-`.join("-") : null;
}