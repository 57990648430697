import React from 'react';
import Loader from '../components/loader';


const Button = ({ disable, className, type, name, onclick, children, loading }) => {
    return (
        <button disabled={disable} className={className} onClick={onclick} type={type} >{loading ? <Loader colour="#ffffff" type="TailSpin" height="15" width="40" /> : name}{children}</button>
    )
}
export default Button;
