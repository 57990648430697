import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { rolenamelist } from '../../actions';
import SearchableDropDown from '../../components/searchableDropDown';
import ErrorView from '../../components/errorView';

let RolesDropDown = ({ ondropdownChange, value, placeholder, onSelectResetsInput, isSearchable, isLabel, errMessage }) => {
    const dispatch = useDispatch();
    let role_name_list_res = useSelector(state => state.role_name_list_res)
    let [searchList, setSearchList] = React.useState([]);
    let [isData, setIsData] = React.useState();
    React.useEffect(() => {
        let data = {};
        dispatch(rolenamelist(data));
        setIsData(true);
    }, [])

    React.useEffect(() => {
        let searchListWh = [];
        if (role_name_list_res && role_name_list_res.list && role_name_list_res.list.length > 0) {
            searchListWh = role_name_list_res.list.map(ele => {
                return {
                    value: ele._id,
                    label: ele.roleName
                }
              });
              setSearchList(searchListWh);
        } else {
            setIsData(false);
        }
    }, [role_name_list_res])

    return (
        <div>
            {isLabel === false ? null : <label className="control-label">Roles</label>}
            <SearchableDropDown
                noData={isData}
                isSearchable={isSearchable}
                onSelectResetsInput={onSelectResetsInput}
                value={value}
                onChange={ondropdownChange}
                options={searchList}
                placeholder={placeholder !== undefined ? placeholder : "Roles"}
            />
            <ErrorView message={errMessage} />
        </div>
    )
}
export default RolesDropDown;