import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { customers_top_10 } from '../../actions';
import { Link } from 'react-router-dom';
import { routehelp } from '../../Routehelper';
// import { routehelp } from '../../Routehelper';
import { errorRender, successRender, commonDebounce, loaderUpdate } from '../../helpers/functions';
// import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';

// const ButtonWithItag = lazy(() => import('../../components/buttonwithTagi'));
// const Pagination = lazy(() => import('../../components/pagination/index'));
// const LinkWithItag = lazy(() => import('../../components/linkwithItag'));
// const Entries = lazy(() => import('../../components/entries/entires'));
// const SearchInput = lazy(() => import('../../components/searchInput'));
const DataTable = lazy(() => import('../../components/dataTalble'));
const CardTitle = lazy(() => import('../../components/cardTitle'));
const Loader = lazy(() => import('../../components/loader'));

// let itemsPerPage = ITEM_PER_PAGES;
// let currentPage = CURRENT_PAGE;
// let sortBy = SORT_BY;
// let sort = SORT;

class TopCustomers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            mobile: '',
            usertype: '',
            successRequest: [{ name: "user_delete", msg: 'User has been Deleted Successfully' }, { name: "useractive_update", msg: 'Successfully Done' }],
            serverErrors: ["customers_top_10_res", "user_delete", "useractive_update"],
            isLoading: false,
            isFilter: false,
            tableHeaders: [
                {
                    name: 'name',
                    label: "Customer Name",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'agent',
                    label: "Agent",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'orders',
                    label: 'No of Orders',
                    type: "text",
                    sortable: false,
                    isShow: true
                }
            ]
        }
    }


    componentDidMount() {
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        this.loadResult()
    }

    loadResult = () => {
        let data;
        let filterData = JSON.parse(localStorage.getItem('filter-date'));
        if (!filterData) {
            data = {
                filter: {
                    createdAt: {
                        "from": this.state.startDate,
                        "to": this.state.endDate
                    }
                }
            }
        } else {
            data = filterData;
        }
        this.props.customers_top_10(data);
        this.setState({ isLoading: true })
    }

    componentWillUnmount() {
        localStorage.removeItem('filter-date');
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    callforDebounce = () => this.loadResult();

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "customers_top_10_res") });
            }
            this.state.serverErrors.forEach((el) => errorRender(nextProps, el, el === "customers_top_10_update" ? this.loadResult : undefined));
            this.state.successRequest.forEach((el) => successRender(nextProps, el.name, this.loadResult, el.msg));
        }
        return true;
    }

    render() {
        const { customers_top_10_res } = this.props;

        // orders_least_10 = [];
        // console.log(customers_top_10_res);

        const totResult = customers_top_10_res !== undefined ? customers_top_10_res.count : 0;

        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">

                                <div className="mr-3 mt-2 text-right">
                                    <Link to={routehelp.maindashboard} className="btn btn-primary">Back</Link>
                                </div>

                                <div className="card-header">
                                    <div className="card-title">
                                        Top 10 Customers
                                    </div>
                                </div>

                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="table-responsive">
                                                    {
                                                        totResult === undefined ?
                                                            <p className="text-center">No Data Found</p> :
                                                            this.state.isLoading ? <Loader /> :
                                                                customers_top_10_res &&
                                                                    customers_top_10_res.list &&
                                                                    customers_top_10_res.list.length === 0 ?
                                                                    <p className="text-center">No Data Found</p> :
                                                                    customers_top_10_res && customers_top_10_res.list && customers_top_10_res.list.length > 0 ?
                                                                        <DataTable headers={this.state.tableHeaders}
                                                                            data={customers_top_10_res} />
                                                                        :
                                                                        <p className="text-center">
                                                                            Something Went Wrong
                                                                        </p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        customers_top_10_res: state.customers_top_10_res
    };
}

const mapDispatchToProps = dispatch => ({
    customers_top_10: data => dispatch(customers_top_10(data))
});

TopCustomers = connect(
    mapStateToProps,
    mapDispatchToProps
)(TopCustomers);

export default TopCustomers;