import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { commissionRange, commissionRangeDelete } from '../../actions';
import { routehelp } from '../../Routehelper';
import { errorRender, successRender, commonDebounce, redirect, loaderUpdate } from '../../helpers/functions';
import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';

const Loader = lazy(() => import('../../components/loader'));
const Pagination = lazy(() => import('../../components/pagination/index'));
const DataTable = lazy(() => import('../../components/dataTalble'));
const Entries = lazy(() => import('../../components/entries/entires'));
const LinkWithItag = lazy(() => import('../../components/linkwithItag'));
// const SearchInput = lazy(() => import('../../components/searchInput'));

let itemsPerPage = ITEM_PER_PAGES;
let currentPage = CURRENT_PAGE;
let sortBy = SORT_BY;
let sort = SORT;

class CommissionRange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            deleteSuccess: "Commission Range Has been Deleted Successfully",
            serverErrors: ["commissionRangeDelete_res", "commissionRange_res"],
            tableHeaders: [
                {
                    name: 'min',
                    label: "Min Value",
                    type: "number",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'max',
                    label: "Max Value",
                    type: "number",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'commissionType',
                    label: "Commission Type",
                    type: "boolean",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'commissionLevel1',
                    label: "First Level",
                    type: "number",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'commissionLevel2',
                    label: "Second Level",
                    type: "number",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'commissionLevel3',
                    label: "Third Level",
                    type: "number",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'createdAt',
                    label: "CreatedAt",
                    type: "date",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'actions',
                    label: "Actions",
                    type: "button",
                    sortable: false,
                    isShow: true,
                    allactions: [
                        {
                            name: "edit",
                            title: "Edit",
                            classNameI: "ti-pencil",
                            className: "btn btn-link text-pramiry action-btn mr-2 p-0",
                            function: redirect
                        },
                        {
                            name: "delete",
                            title: "Delete",
                            classNameI: "ti-trash",
                            className: "btn btn-link text-danger action-btn p-0",
                            function: this.deleteRow
                        }
                    ]
                }
            ],
            mappingObj: {
                'percentage': "Percentage %",
                'flat': "Flat"
            }
        }
    }

    componentDidMount() {
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        this.loadResult();
    }

    componentWillUnmount() {
        currentPage = CURRENT_PAGE;
        itemsPerPage = ITEM_PER_PAGES;
    }

    loadResult = () => {
        let data = {}
        data.filter = { name: this.state.name ? this.state.name : undefined }
        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        data.skip = (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.commissionRange(data);
        this.setState({ isLoading: true });
    }


    resetfilter = () => {
        let data = {}
        data.filter = { name: '', code: '' }
        this.props.commissionRange(data);
    }

    sortBy = (key) => {
        sortBy = key;
        if (sort === 'desc') {
            sort = 'asc';
        } else {
            sort = 'desc';
        }
        this.loadResult();
    }

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    }

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    }

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    }

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    deleteRow = (e, item) => {
        if (window.confirm(`Are you sure you wish to delete "${item.name}" ?`)) {
            this.props.commissionRangeDelete(item._id);
            e.preventDefault();
        }
    }

    onChangeforDebounce = ({ target: { value } }) => {
        if (!/^\s/.test(value)) {
            this.setState({ name: value }, () => this.callforDebounce(value))
        }
    }

    callforDebounce = () => this.loadResult();

    shouldComponentUpdate(nextProps) {
        // console.log(nextProps);
        if (nextProps !== this.props) {
            // console.log(this.state.isLoading)
            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "commissionRange_res") });
            }
            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            successRender(nextProps, "commissionRangeDelete_res", this.loadResult, this.state.deleteSuccess);
        }
        return true;
    }

    render() {
        const { commissionRange_res } = this.props;
        let updatedCommissionRes = commissionRange_res || {};
        const totResult = updatedCommissionRes ? updatedCommissionRes.count : 0;

        if (updatedCommissionRes.list && updatedCommissionRes.list.length > 0) {
            updatedCommissionRes.list.forEach((ele) => {
                if (ele.isOtherMax) {
                    ele.max = "Above"
                }
            });
        }

        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        Commission Ranges
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">

                                        <div className="row">

                                            <div className="col-sm-12 col-md-6">
                                                {
                                                    totResult === 0 ? null :
                                                        <Entries
                                                            itemsPerPage={itemsPerPage}
                                                            handleChangeiItemsPerPage={
                                                                this.handleChangeiItemsPerPage
                                                            }
                                                        />
                                                }
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <div className="add-btn-icon mb-4">
                                                    <LinkWithItag to={routehelp.commissionRangeAdd} className="btn float-left  btn-primary mr-2" role="button" classNameI="fa fa-plus" title="Add Commission Range" />
                                                </div>
                                            </div>

                                        </div>

                                        {
                                            this.state.isLoading ? <Loader /> :
                                                totResult === undefined ?
                                                    <p className="text-center">No Data Found</p>
                                                    :
                                                    updatedCommissionRes !== undefined && updatedCommissionRes.count === 0 ?
                                                        <p className="text-center">No Data Found</p>
                                                        :
                                                        updatedCommissionRes && updatedCommissionRes.count > 0 ?
                                                            <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy}
                                                                sortByvalue={sortBy} sortvalue={sort} mappingObj={this.state.mappingObj}
                                                                data={updatedCommissionRes} props={this.props} />
                                                            : <p className="text-center">Something Went Wrong</p>
                                        }

                                        {
                                            totResult !== undefined ? updatedCommissionRes !== undefined && updatedCommissionRes.count === 0 ? "" :
                                                <Pagination
                                                    handleChangeiItemsPerPage={
                                                        this.handleChangeiItemsPerPage
                                                    }
                                                    handlePage={this.handlePage}
                                                    handleNext={this.handleNext}
                                                    handlePrevious={this.handlePrevious}
                                                    currentPage={currentPage}
                                                    itemsPerPage={itemsPerPage}
                                                    totResult={totResult}
                                                    key={currentPage}
                                                />
                                                : null
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        commissionRange_res: state.commissionRange_res,
        commissionRangeDelete_res: state.commissionRangeDelete_res
    };
}

const mapDispatchToProps = dispatch => ({
    commissionRange: data => dispatch(commissionRange(data)),
    commissionRangeDelete: item_id => dispatch(commissionRangeDelete(item_id))
});

CommissionRange = connect(
    mapStateToProps,
    mapDispatchToProps
)(CommissionRange);

export default CommissionRange;