/**********************************************************************************************************************
 * Copyright (C) 2020 RyDOT Infotech Pvt. Ltd - All Rights Reserved                                                   *
 *                                                                                                                    *
 * CONFIDENTIAL                                                                                                       *
 *                                                                                                                    *
 * All information contained herein is, and remains the property of RyDOT Infotech Pvt. Ltd and its partners,         *
 * if any. The intellectual and technical concepts contained herein are proprietary to RyDOT Infotech Pvt. Ltd and its*
 * partners and may be covered by their and Foreign Patents, patents in process, and are protected by trade secret or *
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless     *
 * prior written permission is obtained from RyDOT Infotech Pvt. Ltd.                                                 *
 *                                                                                                                    *
 **********************************************************************************************************************/
// import * as React from "react";
// import ReactDOM from "react-dom";
// import Favicon from "react-favicon";
import moment from 'moment';
const helperFunc = {
    getWhiteLabeling: function () {

        const user = JSON.parse(localStorage.getItem('userdata'));
        const defaultData = {
            header_text: 'Turnout',
            header_title: 'Attandance Management System',
            logo: '/turnout-white.png',
            favicon: 'white-favicon.png'
        };
        if (!!user && user.type && user.type === 'super') {
            return defaultData;
        } else {
            const clientSettings = JSON.parse(localStorage.getItem('clientSettings'));
            if (clientSettings) {
                const clientData = defaultData;
                clientData['header_text'] = clientSettings['header_text'] ? clientSettings['header_text'] : clientData['header_text'];
                clientData['header_title'] = clientSettings['header_title'] ? clientSettings['header_title'] : clientData['header_title'];
                clientData['footer_text'] = clientSettings['footer_text'] ? clientSettings['footer_text'] : clientData['footer_text'];
                clientData['logo'] = clientSettings['logo'] ? clientSettings['logo'] : clientData['logo'];
                clientData['turnoutDevice'] = clientSettings['turnoutDevice'] ? clientSettings['turnoutDevice'] : clientData['turnoutDevice'];
                return clientData;
            }
        }
        return defaultData;
    },
    updateLocalStorage: function (objData) {

        let clientSettings = this.getWhiteLabeling();
        clientSettings = { ...clientSettings, ...objData };
        localStorage.setItem("clientSettings", JSON.stringify(clientSettings));
        this.setWhiteLabeling(clientSettings);
    },
    setWhiteLabeling: function (objData) {
        setTimeout(() => {
            // setup page favicon
            // ReactDOM.render(
            // 	<div>
            // 		<Favicon url={"" + objData.logo} />
            // 	</div>
            // 	, document.getElementById('root'));
            //Setup page title
            document.title = objData.header_text; //Document Title
            const footerLabeling = document.getElementById('footer-branding');
            if (!!objData.footer_text) {
                if (footerLabeling && footerLabeling.innerHTML) {
                    footerLabeling.innerHTML = objData.footer_text; //Footer
                }
            }
            document.getElementById("favicon").href = objData.logo
            objData && objData.logo && document && document.getElementById("branding-logo") && document.getElementById("branding-logo").setAttribute && document.getElementById("branding-logo").setAttribute('src', objData.logo); //Branding Logo
            objData && objData.header_title && document && document.getElementById("branding-logo") && document.getElementById("branding-logo").setAttribute && document.getElementById("branding-logo").setAttribute('title', objData.header_title); //Branding Logo
            objData && objData.header_title && document && document.getElementById("branding-logo") && document.getElementById("branding-logo").setAttribute && document.getElementById("branding-logo").setAttribute('alt', objData.header_title); //Branding Logo
        }, 100);
    },
    clearFooter: function () {
        const footerLabeling = document.getElementById('footer-branding');
        if (footerLabeling && footerLabeling.innerHTML) {
            footerLabeling.innerHTML = ""; //Footer
        }
        document.title = "Turnout Management System";
        document.getElementById("favicon").href = "favicon.ico"; //FavIcon
        document.getElementById("branding-logo") && document.getElementById("branding-logo").setAttribute('src', ""); //Branding Logo
        document.getElementById("branding-logo") && document.getElementById("branding-logo").setAttribute('title', ""); //Branding Logo
        document.getElementById("branding-logo") && document.getElementById("branding-logo").setAttribute('alt', ""); //Branding Logo
    },
    getUserData: function () {
        const user = JSON.parse(localStorage.getItem("userdata"));
        return user;
    },

    getUserType: function () {
        const type = localStorage.getItem("type");
        return type;
    },
    formatDate: function (date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [day, month, year].join('/');
    },

    attendanceformatDate: function (date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    },

    isoToDate: function (time) {
        let gmtData = time.substring(0, 19);
        var s = new Date(gmtData).toLocaleString(undefined, {
            timeZone: 'Asia/Kolkata'
        });
        return s;
    },

    addhours: function (time, hour, min) {
        var dt = new Date(time);
        dt.setHours(dt.getHours() + hour);
        dt.setMinutes(dt.getMinutes() + min);
        return dt.toISOString();
    },

    addDays: function (days, compare) {
        // Add days to given date
        var date = new Date();
        date.setDate(date.getDate() - days);

        // if (compare) {
        // var date = new Date();
        // var time = moment(date).format('YYYY-MM-DD');
        // var res = time.split("-");
        // var year = parseInt(res[0]);
        // var month = parseInt(res[1]);
        // var date = parseInt(res[2]);
        // return new Date(year, month - 1, date, 0, 0, 0, 0);
        // } else {
        return moment(date).format('YYYY-MM-DD');
        // }
    },


    subtractDays: function (days) {
        // Add days to given date
        var date = new Date();
        date.setDate(date.getDate() - days);
        return moment(date).format('YYYY-MM-DD');
    },


    startOfMonth: function (day) {
        var date = new Date();
        // var time = moment(new Date(date.getFullYear(), date.getMonth(), day)).format('YYYY-MM-DD')
        // var res = time.split("-");
        // var year = parseInt(res[0]);
        // var month = parseInt(res[1]);
        // var date = parseInt(res[2]);
        return moment(new Date(date.getFullYear(), date.getMonth(), day)).format('YYYY-MM-DD')
    },

    validURL: function (str) {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    },

    secondsToHms: function (d) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        // var s = Math.floor(d % 3600 % 60);

        // var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
        // var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
        // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";

        h = h + "";
        m = m + "";

        h = h && h.length === 1 ? "0" + h : h;
        m = m && m.length === 1 ? "0" + m : m;

        if (h && h.length > 2) {
            return d;
        } else {
            return h + ":" + m;

        }
    },

    localDateAndTime: function (dateAndTime) {
        return new Date(dateAndTime).toLocaleDateString() + "-" + new Date(dateAndTime).toLocaleTimeString()
    },

    currentMonth: function () {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const d = new Date();
        return monthNames[d.getMonth()];
    },

    createdAtDateFormat: function (date) {
        return moment(date).format('DD-MM-YYYY');
    },

    filterCreatedAt: function (createdAtTime) {
        var time = moment(createdAtTime).format('YYYY-MM-DD')
        var res = time.split("-");
        var year = parseInt(res[0]);
        var month = parseInt(res[1]);
        var date = parseInt(res[2]);
        var values = {
            time_from: new Date(year, month - 1, date, 0, 0, 0, 0),
            time_to: new Date(year, month - 1, date, 23, 59, 59, 999)
        }
        return values;
    },

    selectedCompany: function () {
        let companyId = localStorage.getItem("companyId");
        return companyId;
    },

    commonDateFormat: function (date, timeformat) {
        let dateFormat = localStorage.getItem("dateFormat");
        if (timeformat) {
            return moment(date).format(dateFormat ? dateFormat + " , " + timeformat : "DD/MM/YYYY , " + timeformat);
        } else {
            return moment(date).format(dateFormat ? dateFormat : "DD/MM/YYYY");
        }
    },

    datePickerFormat: function (timeformat) {
        let pickerFormat = localStorage.getItem("pickerFormat");
        if (timeformat) {
            return pickerFormat ? pickerFormat + ',' + timeformat : 'dd/MM/yyyy ,' + timeformat;
        } else {
            return pickerFormat ? pickerFormat : 'dd/MM/yyyy';
        }
    },

    arrayToStr: function (array, byValue) {
        let tempArray = [];
        array && array.length > 0 && array.forEach((arrValue) => {
            tempArray.push("(" + arrValue[byValue] + ")")
        });
        return tempArray.toString();
    },

    dateArray: function (newStartDate, newEndDate) {
        var dayInMiliSeconds = 1000 * 60 * 60 * 24;
        var startDate = new Date(newStartDate);
        var endDate = new Date(newEndDate);
        var dates = [];
        var diff = (endDate.getTime() - startDate.getTime()) / dayInMiliSeconds;
        for (var i = 0; i <= diff; i++) {
            dates.push(new Date(startDate.getTime() + (dayInMiliSeconds * i)).toISOString().slice(0, 10));
        }
        return dates;
    },

    timeWithAmPm: function (date) {
        let isoDate = date;
        let result;
        if (isoDate === null || !isoDate) {
            return null;
        } else {
            let time = isoDate.match(/\d\d:\d\d/);
            let finalTime = time[0].split(":")
            var hours = finalTime[0]; // gives the value in 24 hours format
            var AmOrPm = hours >= 12 ? 'pm' : 'am';
            hours = (hours % 12) || 12;
            var minutes = finalTime[1];
            result = hours + ":" + minutes + " " + AmOrPm;
        }

        return result
    },

    getVideoDuration: function (file) {
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const media = new Audio(reader.result);
                media.onloadedmetadata = () => resolve(media.duration);
            };
            reader.readAsDataURL(file);
            reader.onerror = (error) => reject(error);
        })
    },

    // const startOfMonth = (date: any) => {
    //     var today: any = date
    //     let mm = String(today.getMonth() + 1).padStart(2, '0')
    //     let yyyy = today.getFullYear();
    //     let firstDate = yyyy + '-' + mm + '-' + "01";
    //     return firstDate
    // }

    // const currentDate = () => {
    //     var today: any = new Date();
    //     var dd = String(today.getDate()).padStart(2, '0');
    //     var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    //     var yyyy = today.getFullYear();
    //     return today = yyyy + '-' + mm + '-' + dd;
    // }

    mobileFeatureList: function () {
        return [
            {

                label: "My Orders",
                name: "myOrders",
                checked: "myOrders"
            },
            {
                label: "New Orders",
                name: "newOrders",
                checked: "newOrders"
            },
            {
                label: "Order Template",
                name: "orderTemplate",
                checked: "orderTemplate"
            },
            {
                label: "Stock",
                name: "stock",
                checked: "stock"
            },
            {
                label: "Customers",
                name: "customers",
                checked: "customers"
            },
            {
                label: "Out Standing",
                name: "outstanding",
                checked: "outstanding"
            },
            {
                label: "Dispatch Details",
                name: "dispatchDetails",
                checked: "dispatchDetails"
            },
            {
                label: "Order Commission",
                name: "commission",
                checked: "commission"
            },
            {
                label: "Product Commission",
                name: "productCommission",
                checked: "productCommission"
            },
            {
                label: "Sub Childs (User Hierarchy)",
                name: "childs",
                checked: "childs"
            },
            {
                label: "Advance",
                name: "advance",
                checked: "advance"
            },
            {
                label: "My Tours",
                name: "myTours",
                checked: "myTours"
            },
            {
                label: "Feedback",
                name: "feedback",
                checked: "feedback"
            },
        ];


    },


    webFeatureList: function () {
        let arrayList = [
            {

                label: "Dashboard",
                name: "dashboard",
                checked: "dashboard"
            },
            {

                label: "Zone",
                name: "zone",
                checked: "zone"
            },
            {

                label: "Region",
                name: "region",
                checked: "region"
            },
            {

                label: "Territory",
                name: "territory",
                checked: "territory"
            },
            {

                label: "Beat",
                name: "beat",
                checked: "beat"
            },
            {

                label: "Users",
                name: "users",
                checked: "users"
            },
            {

                label: "Customers",
                name: "customers",
                checked: "customers"
            },
            {

                label: "IndirectCustomers",
                name: "indirectCustomers",
                checked: "indirectCustomers"
            },
            {

                label: "SrASM",
                name: "srASM",
                checked: "srASM"
            },
            {

                label: "UserHierarchy",
                name: "userHierarchy",
                checked: "userHierarchy"
            },
            {

                label: "Brand",
                name: "brand",
                checked: "brand"
            },
            {

                label: "Product",
                name: "product",
                checked: "product"
            },
            {

                label: "Uom",
                name: "uom",
                checked: "uom"
            },
            {

                label: "Stock",
                name: "stock",
                checked: "stock"
            },
            {

                label: "Agency",
                name: "agency",
                checked: "agency"
            },
            {

                label: "Transporter",
                name: "transporter",
                checked: "transporter"
            },
            {

                label: "Scheme",
                name: "scheme",
                checked: "scheme"
            },
            {

                label: "Outstanding",
                name: "outstanding",
                checked: "outstanding"
            },
            {

                label: "DispatchDetails",
                name: "dispatchDetails",
                checked: "dispatchDetails"
            },
            {
                label: "Order",
                name: "order",
                checked: "order"
            },
            {
                label: "Order Template",
                name: "orderTemplate",
                checked: "orderTemplate"
            },
            {

                label: "Route Planning",
                name: "routePlanning",
                checked: "routePlanning"
            },
            {

                label: "Category Expense",
                name: "categoryExpense",
                checked: "categoryExpense"
            },
            {

                label: "Sub Category Expense",
                name: "subCategoryExpense",
                checked: "subCategoryExpense"
            },
            {

                label: "Expense Approval Expense",
                name: "expenseApprovalExpense",
                checked: "expenseApprovalExpense"
            },
            {

                label: "Advance Approval",
                name: "advanceApproval",
                checked: "advanceApproval"
            },
            {

                label: "Discount Master",
                name: "discountMaster",
                checked: "discountMaster"
            },
            {

                label: "User Retailers",
                name: "userRetailers",
                checked: "userRetailers"
            },
            {

                label: "Trending",
                name: "trending",
                checked: "trending"
            },
            {

                label: "Categories Retailers",
                name: "categoriesRetailers",
                checked: "categoriesRetailers"
            },
            {

                label: "Ecatalogue",
                name: "ecatalogue",
                checked: "ecatalogue"
            },
            {

                label: "Order Retailers",
                name: "orderRetailers",
                checked: "orderRetailers"
            },
            {
                label: "Commission in sidebar",
                name: "commission",
                checked: "commission"
            },
            {
                label: "Order Commission",
                name: "orderCommission",
                checked: "orderCommission"
            },
            {
                label: "Product Commission",
                name: "productCommission",
                checked: "productCommission"
            },
            {
                label: "Order Failed List (Third Party only)",
                name: "orderFailed",
                checked: "orderFailed"
            },
            {
                label: "Commission Range",
                name: "commissionRange",
                checked: "commissionRange"
            },
            {
                label: "Total Users Card",
                name: "totalUsers",
                checked: "totalUsers"
            },
            {
                label: "Total Products Card",
                name: "totalProducts",
                checked: "totalProducts"
            },
            {
                label: "Total Orders Card",
                name: "totalOrders",
                checked: "totalOrders"
            },
            {
                label: "Total Customers Card",
                name: "totalCustomers",
                checked: "totalCustomers"
            },
            {
                label: "Inactive Users Card",
                name: "inactiveUsers",
                checked: "inactiveUsers"
            },
            {
                label: "Inactive Customers Card",
                name: "inactiveCustomers",
                checked: "inactiveCustomers"
            },
            {
                label: "Top 10 Products Card",
                name: "top10Products",
                checked: "top10Products"
            },
            {
                label: "Least 10 Products Card",
                name: "least10Products",
                checked: "least10Products"
            },
            {
                label: "Top 10 Orders Card",
                name: "top10Orders",
                checked: "top10Orders"
            },
            {
                label: "Least 10 Orders Card",
                name: "least10Orders",
                checked: "least10Orders"
            },
            {
                label: "Top 10 Customers Card",
                name: "top10Customers",
                checked: "top10Customers"
            },
            {
                label: "Least 10 Customers Card",
                name: "least10Customers",
                checked: "least10Customers"
            },
            {
                label: "Zero Stock Card",
                name: "zeroStock",
                checked: "zeroStock"
            },
            {
                label: "Profile",
                name: "profile",
                checked: "profile"
            },
            {
                label: "Change Password",
                name: "changePassword",
                checked: "changePassword"
            },
            {
                label: "Mis Report Setting",
                name: "misReportSetting",
                checked: "misReportSetting"
            },
            {
                label: "Company Config",
                name: "companyConfig",
                checked: "companyConfig"
            },
            {
                label: "Email Config",
                name: "emailConfig",
                checked: "emailConfig"
            },
            {
                label: "Logs",
                name: "logs",
                checked: "logs"
            },
            {
                label: "Total Brands",
                name: "totalBrands",
                checked: "totalBrands"
            },
            {

                label: "Payment",
                name: "payment",
                checked: "payment"
            },
            {
                label: "Customers Visits",
                name: "customersVisits",
                checked: "customersVisits"
            },
            {
                label: "Stock History",
                name: "stockHistory",
                checked: "stockHistory"
            },
            {
                label: "Ready Stock Card",
                name: "readyStock",
                checked: "readyStock"
            },
            {
                label: "Sent Stock Card",
                name: "sentStock",
                checked: "sentStock"
            },
            {
                label: "Dashboard Graph (Brand vs Uom vs Order)",
                name: "brandVsUomG",
                checked: "brandVsUomG"
            },
            {
                label: "Dashboard Graph (Order vs Days)",
                name: "orderVsDaysG",
                checked: "orderVsDaysG"
            },
            {
                label: "Feedback",
                name: "feedback",
                checked: "feedback"
            },
        ]
        return arrayList
    },

    seriesTypeList: function () {
        return [
            { name: null },
            { name: "CLS" },
            { name: "ACC" },
            { name: "DTR" },
            { name: "WHS" },
            { name: "WHB" },
            { name: "RMB" },
            { name: "MID" },
            { name: "PIN" },
            { name: "RMG" },
            { name: "SRC" },
            { name: "ONL" },
            { name: "CDTR" },
            { name: "CWHS" },
            { name: "CWHB" },
            { name: "CRMB" },
            { name: "CMID" },
            { name: "CPIN" },
            { name: "CRMG" },
            { name: "ACS" },
            { name: "ICS" },
            { name: "CICS" },
            { name: "MIX" }
        ]
    },

    orderTypeList: function () {
        return [
            { value: "retailer", label: "RETAILER" },
            { value: "wholesaler", label: "WHOLESALER" }
        ]
    },

    userTypeFun: function () {
        return [
            // { value: "", label: "Select a Type" },
            { value: "ALL", label: "ALL" },
            { value: "CO", label: "ADMIN" },
            { value: "AG", label: "AGENT" },
            { value: "ASM", label: "AREA SALES MANAGER" },
            { value: "ASM2", label: "AREA SALES MANAGER 2" },
            { value: "SASM", label: "SR. AREA SALES MANAGER" },
            { value: "WHS", label: "WHOLESALER" },
            { value: "NONE", label: "NONE" }
        ];
    }
};

export default helperFunc;