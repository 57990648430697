import * as React from 'react';

const ErrorView = ({ message }) => {
    // console.log(message);
    let dynamicID = message ? message.split(" ") : "error_msg";
    // console.log(dynamicID[0]);
    return (
        <small id={"err_" + dynamicID[0].toLowerCase()} style={{ fontSize: 10, color: 'red' }}>
            {' '}
            {message}{' '}
        </small>
    )
}

export default ErrorView