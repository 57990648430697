import React, { Component } from 'react';
import LinkwithImg from '../components/linkwithimg';
import { connect } from 'react-redux';
import LinkWithItag from '../components/linkwithItag';
import ButtonWithItag from '../components/buttonwithTagi';
import { routehelp } from '../Routehelper';
import { versionUpdate, companyConfigSingle } from '../actions';
import { uploadUrl } from '../config';
import LinkWithSpanAndI from './linkwithspanAndI';
import { isPermission } from '../helpers/functions';


  let openSideView = [
    { name: "Admin User", value: false },
  ];
class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            toggle: false,
            version: localStorage.getItem('version'),
            versionId: localStorage.getItem('versionId'),
            companyLogo: "",
            uploadUrl: uploadUrl,
            logo: '',
            isData: false,
            webFeature: '',
            subFeature: {
                admin: false,
                role: false,
            },
            isSubmenu: false,
            list: [
                {
                    type: "CO",
                    to: routehelp.Profile,
                    class: "ti-user",
                    name: "Profile",
                    match: "profile"
                },
                {
                    type: "CO",
                    to: routehelp.changePassword,
                    class: "ti-lock",
                    name: "Change Password",
                    match: "changePassword"
                },
                {
                    type: "CO",
                    to: routehelp.misReportSetting,
                    class: "ti-layers-alt",
                    name: "Mis Report Setting",
                    match: "misReportSetting"
                },
                {
                    type: "SA",
                    to: routehelp.logs,
                    class: "ti-info-alt",
                    name: "Logs",
                    match: "logs"
                },
                {
                    type: "CO",
                    to: routehelp.config,
                    class: "ti-home",
                    name: "Company Config",
                    match: "companyConfig"
                },
                {
                    type: "CO",
                    to: routehelp.emailConfig,
                    class: "ti-email",
                    name: "Email Config",
                    match: "emailConfig"
                },
            ],
            adminroleList: [
                {
                    parentName: "Admin User",
                    isChild: true,
                    Child: [{
                      to: routehelp.admin,
                      classi: "fa tai-ico-Users-Customers",
                      match: "admin",
                      name: "Users"
                    },
                    {
                      to: routehelp.role,
                      classi: "fa tai-ico-Region",
                      match: "role",
                      name: "Roles"
                    }]
                  },
            ]
        }
    }

    componentDidMount() {
        this.props.companyConfigSingle({});
        const logoObj = JSON.parse(localStorage.getItem('companyLogo'));
        if (logoObj && logoObj.medium) {
            this.setState({ companyLogo: this.state.uploadUrl + logoObj.medium || "" });
        } else if (logoObj && logoObj.small) {
            this.setState({ companyLogo: this.state.uploadUrl + logoObj.small || "" });
        } else if (logoObj && logoObj.large) {
            this.setState({ companyLogo: this.state.uploadUrl + logoObj.large || "" });
        } else {
            this.setState({ companyLogo: "" });
        }
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    toggle = () => {
        let container = document.getElementById("root")
        if (this.state.toggle === false) {
            this.setState({ toggle: true });
            container.classList.add("left-sidebar-hidden");
        } else {
            this.setState({ toggle: false });
            container.classList.remove("left-sidebar-hidden");
        }
    }

    componentWillUnmount() {
        let container = document.getElementById("root");
        container.classList.remove("left-sidebar-hidden");
    }

    versionUpdate = (e) => {
        let versionId = this.state.versionId
        let data = {
            version: this.state.version
        }
        e.preventDefault();
        this.props.versionUpdate(data, versionId);
    }

    showMenu(val) {
        openSideView.forEach((item) => {
          if (item.name === val) {
            item.value = !item.value
            this.setState({isData: !this.state.isData})
          }
        })
        this.forceUpdate();
      }

    render() {
        const name = localStorage.getItem('name');
        const username = localStorage.getItem('username');
        const type = localStorage.getItem('type');
        const { companyConfigSingle_res } = this.props;

        return (
            <header className="app-header navbar">
                {
                    type === "SA" ? "" :
                        <div className="navbar-brand">
                            <div className="nav navbar-nav">
                                <li className="nav-item d-lg-none">
                                    <ButtonWithItag className="navbar-toggler mobile-leftside-toggler togglerCustom" type="button" classNameI="ti-align-right" />
                                </li>
                                {type === "SA" ? "" : <li className="nav-item d-md-down-none">
                                    {/* <LinkWithItag to="" className="nav-link navbar-toggler left-sidebar-toggler" classNameI=" ti-align-right" /> */}
                                    <button onClick={() => this.toggle()} className="nav-link navbar-toggler left-sidebar-toggler">
                                        <i className=" ti-align-right cursor-pointer"></i>
                                    </button>
                                </li>}
                            </div>
                            <LinkwithImg className="logo-2" to={routehelp.maindashboard} src={this.state.companyLogo} classNameImg="logoImage" alt="" />
                        </div>
                }

                <ul className="nav navbar-nav ml-auto">
                    <li className="nav-item dropdown dropdown-slide">
                        <LinkwithImg className="nav-link nav-pill user-avatar" src="/assets/img/user.png" alt="Radiant"/>

                        <div className="dropdown-menu dropdown-menu-right dropdown-menu-accout">

                            <div className="dropdown-header pb-3">
                                <div className="media d-user">
                                    <img className="align-self-center mr-3" id="innerImg" src="/assets/img/user.png" alt="Radiant" />
                                    <div className="media-body">
                                        <h5 className="mt-0 mb-0">{name}</h5>
                                        <span>{username}</span>
                                    </div>
                                </div>
                            </div>

                            {
                                this.state.list && this.state.list.length > 0 ?
                                    this.state.list.map((ele, index) => {
                                        let data = "";
                                        // console.log(type === ele.type, companyConfigSingle_res, companyConfigSingle_res.item, companyConfigSingle_res.item.webFeature, companyConfigSingle_res.item.webFeature[ele.match])
                                        if ((type === ele.type || type === "CA" ) && companyConfigSingle_res !== undefined && companyConfigSingle_res.item && companyConfigSingle_res.item.webFeature && companyConfigSingle_res.item.webFeature[ele.match] && isPermission(ele.match, "list")) {
                                            data = <LinkWithItag key={index} className="dropdown-item" to={ele.to} classNameI={ele.class} name={ele.name} />
                                        }
                                        return data;
                                    }) : null
                            }
                            {<LinkWithItag className="dropdown-item" to={routehelp.changePassword} classNameI=" ti-lock" name="Change Password" />}

                            {/* {type === "SA" ? "" : <LinkWithItag className="dropdown-item" to={routehelp.Profile} classNameI=" ti-user" name="Profile" />}
                            {type === "SA" ? "" : <LinkWithItag className="dropdown-item" to={routehelp.changePassword} classNameI=" ti-lock" name="Change Password" />} */}
                            {/* {type === "SA" ? "" : <LinkWithItag className="dropdown-item" to={routehelp.setting} classNameI=" ti-layers-alt" name="Setting" />} */}
                            {/* {type === "SA" ? "" : <LinkWithItag className="dropdown-item" to={routehelp.misReportSetting} classNameI=" ti-layers-alt" name="MIS Report Setting" />}
                            {type === "SA" ? <LinkWithItag className="dropdown-item" to={routehelp.logs} classNameI="ti-info-alt" name="Logs" /> : null}
                            {type === "SA" ? "" : <LinkWithItag className="dropdown-item" to={routehelp.config} classNameI="ti-home" name="Company Config" />}
                            {type === "SA" ? "" : <LinkWithItag className="dropdown-item" to={routehelp.emailConfig} classNameI="ti-email" name="Email Config" />} */}
                            {
                                type === "SA" ?
                                    <LinkWithItag className="dropdown-item" to={routehelp.version} classNameI="ti-unlock" name="Application Version" />
                                    : null
                            }
                            {
                                type === "SA" ?
                                    <LinkWithItag className="dropdown-item" to={routehelp.package} classNameI="ti-unlock" name="Package" />
                                    : null
                            }
                            {   type === "CO" ?
                                    this.state.adminroleList.map((ele, index) => {
                                        return (
                                        <>
                                            <li className="dropdown-item has-dropdown" key={index}>
                                                    <div id={"submenu-" + index} key={index} onClick={() => this.showMenu(ele.parentName)}>
                                                        <i className="fa tai-ico-Masters" aria-hidden="true"></i>
                                                        <span>{ele.parentName}</span>
                                                        <i className={openSideView.filter((e) => e.name === ele.parentName)[0].value  ? "fa fa-angle-up" : "fa fa-angle-down"} style={{ float: 'right' }} aria-hidden="true"></i>
                                                    </div>
                                                </li>
                                                {this.state.isData ? 
                                                    <ul className="treeview-menus">
                                                            {ele.Child.map((child, cindex) => {
                                                                return (
                                                                    <div title={child.name} key={cindex}>
                                                                        {<LinkWithSpanAndI
                                                                            to={child.to}
                                                                            classi={child.classi}
                                                                            aria-hidden="true"
                                                                            name={child.name} />}
                                                                    </div>
                                                                );
                                                            })}
                                                    </ul>
                                                : null}
                                        </>
                                        )
                                    }) : null
                            }

                            {/* <div className="sidebar-item" >
                            <div className="sidenav-link" id={"submenu-"}   >
                             <i className="fa tai-ico-Masters" aria-hidden="true"></i>
                                <span>Admin User</span>
                            <i className={"fa fa-angle-down"} style={{ float: 'right' }} aria-hidden="true"></i>
                            </div>
                            </div>   */}

                            <LinkWithItag className="dropdown-item" to={routehelp.logout} classNameI=" ti-unlock" name="Logout" />

                        </div>
                    </li>
                </ul>
            </header>
        )
    }
}


function mapStateToProps(state) {
    return {
        companyConfigSingle_res: state.companyConfigSingle_res
    };
}


const mapDispatchToProps = dispatch => ({
    companyConfigSingle: data => dispatch(companyConfigSingle(data)),
    versionUpdate: (data, versionId) => dispatch(versionUpdate(data, versionId))
});

Header = connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);

export default Header;