import React, { Fragment } from 'react';
import ErrorView from '../components/errorView';

const TextAreaInputField = ({ onChange, name, value, id, className, rows, errMessage, maxLength, placeholder }) => {
    return (
        <Fragment>
            <label htmlFor="exampleInputAddress" className="control-label">{placeholder}</label>
            <textarea placeholder={placeholder} onChange={onChange} name={name} id={id} value={value} className={className} rows={rows} maxLength={maxLength} />
            <ErrorView message={errMessage} />
        </Fragment>
    )
};

export default TextAreaInputField