import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import Inputfield from '../../components/inputfields';
import Buttons from '../../components/buttons';
import Links from '../../components/links';
import { routehelp } from '../../Routehelper';
import { trendCreate, trendsingle, trendUpdate } from '../../actions';
import { apiUrl, uploadUrl } from '../../config';
import { ProgressBar } from 'react-bootstrap';
import axios from 'axios';
import { errorRender, successRedriect, validate, getValue } from '../../helpers/functions';
import TextAreaInputField from '../../components/textAreaInputField';
import ErrorView from '../../components/errorView';
import toastr from 'reactjs-toastr';
import 'reactjs-toastr/lib/toast.css';
import Translate from '../../libs/translation';

const CardTitle = lazy(() => import('../../components/cardTitle'));
class TrendingAdd extends Component {
    state = {
        pageType: 'add',
        itemId: '',
        name: '',
        description: '',
        logo: "",
        videoObj: "",
        uploadPercentage: 0,
        videoUploadPercentage: 0,
        serverErrors: ["trend_create_res", "trend_single", "trend_update"],
        forRedirect: [
            { name: "trend_create_res", pageName: 'Trendings', pageUrl: 'trending', action: "Added" },
            { name: "trend_update", pageName: 'Trendings', pageUrl: 'trending', action: "Updated" }
        ],
        setFields: [
            { name: "name", stateName: "name", type: "text" },
            { name: "description", stateName: "description", type: "text" },
            { name: "img", stateName: "logo", type: "text" },
            { name: "video", stateName: "videoObj", type: "text" },
        ],
        nameError: '',
        descriptionError: '',
        logoError: '',
        videoError: '',
        isLoading: false
    }

    componentDidMount() {
        let id = this.props.match.params.item_id;
        if (id) {
            this.setState({ pageType: "update", itemId: id })
            this.props.trendsingle(id);
        }
    }

    handleChange = (name, e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [name]: e.target.value });
        }
    }

    onsubmit = (e) => {
        let validationFields = [
            { name: 'name', label: "Name", value: this.state.name, hasError: false, isRequired: true, isNumber: false, onlyChar: true, maxLength: 50, type: "text", message: "", errName: "nameError" },
            { name: 'logo', label: "Image", value: this.state.logo, hasError: false, isRequired: false, isNumber: false, onlyChar: false, type: "text", message: "", errName: "logoError" },
            { name: 'videoObj', label: "Video", value: this.state.videoObj, hasError: false, isRequired: false, isNumber: false, onlyChar: false, type: "text", message: "", errName: "videoError" },
            { name: 'description', label: "Description", value: this.state.description, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 200, type: "text", message: "", errName: "descriptionError" }
        ]
        let data = {
            name: this.state.name,
            description: this.state.description,
            img: this.state.logo,
            video: this.state.videoObj
        }
        e.preventDefault();
        let { newArr, valid } = validate(validationFields);
        newArr.map((el) => this.setState({ [el.errName]: el.message }));
        if (valid) {
            if (data.img !== "" || data.video !== "") {
                this.setState({ isLoading: true });
                validationFields = null;
                newArr = null;
                if (this.state.pageType === "update") {
                    this.props.trendUpdate(data, this.state.itemId);
                } else {
                    this.props.trendCreate(data);
                }
            } else {
                return toastr.error("Either Image or Video is required.", 'Validation', {
                    displayDuration: 5000
                });
            }
        }
    }



    uploadFile = ({ target: { files } }) => {

        if (files && files.length > 0 && files[0].size && files[0].size > 20971520) //20mb
        {
            toastr.error(Translate.translate("", 'error.message.image_size'), 'Error', {
                displayDuration: 5000
            });
            files = [];
            return true;
        }

        let data = new FormData();
        data.append('file', files[0])
        const token = localStorage.getItem('token');
        const options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total)
                // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
                if (percent < 100) {
                    this.setState({ uploadPercentage: percent })
                }
            }
        }
        axios.defaults.headers = { 'x-access-token': token }
        axios.post(apiUrl + "/api/trendings/upload", data, options).then(res => {
            this.setState({ logo: res.data.item.img, uploadPercentage: 100 }, () => {
                setTimeout(() => {
                    this.setState({ uploadPercentage: 0 })
                }, 1000);
            })
        })
    }


    getVideoDuration = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const media = new Audio(reader.result);
                media.onloadedmetadata = () => resolve(media.duration);
            };
            reader.readAsDataURL(file);
            reader.onerror = (error) => reject(error);
        });

    videoUploadFile = async ({ target: { files } }) => {
        // const duration = await this.getVideoDuration(files[0]);
        // console.log("duration--->", duration);

        let data = new FormData();
        if (files[0] && files[0].type === "video/mp4") {
            if ((files[0].size / (1024 * 1024)).toFixed(2) < 20) {
                data.append('file', files[0])
                const token = localStorage.getItem('token');
                const options = {
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let percent = Math.floor((loaded * 100) / total)
                        // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
                        if (percent < 100) {
                            this.setState({ videoUploadPercentage: percent })
                        }
                    }
                }
                axios.defaults.headers = { 'x-access-token': token }
                axios.post(apiUrl + "/api/trendings/upload-video", data, options).then(res => {
                    this.setState({ videoObj: res.data.item.video, videoUploadPercentage: 100 }, () => {
                        setTimeout(() => {
                            this.setState({ videoUploadPercentage: 0 })
                        }, 1000);
                    })
                })
            } else {
                return toastr.error("Video Size Should be Smaller then 20mb", 'Error', {
                    displayDuration: 5000
                })
            }
        } else {
            return toastr.error("Only Mp4 Format is Allowed", 'Error', {
                displayDuration: 5000
            });
        }
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) this.setState({ isLoading: false });
            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));
            if (nextProps.trend_single && nextProps.trend_single.success) {
                if (nextProps.trend_single.item) {
                    this.state.setFields.forEach((value) => {
                        if (value.type === "text") {
                            this.setState({ [value.stateName]: getValue(nextProps.trend_single.item, value.name) })
                        }
                    })
                }
                nextProps.trend_single.success = null;
            }
        }
        return true;
    }

    render() {
        const { videoUploadPercentage, uploadPercentage, pageType, isLoading } = this.state;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <CardTitle title={pageType === "update" ? "Update Trend" : "Add Trend"} />
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="form-group required col-12 col-lg-4">
                                            <Inputfield errMessage={this.state.nameError} handleChange={this.handleChange} type="text" name="name" maxlength="100" value={this.state.name} className="form-control" id="name" ariadescribedby="codeHelp" placeholder="Name" />
                                        </div>
                                        <div className="form-group required col-12 col-lg-4">
                                            <TextAreaInputField errMessage={this.state.descriptionError} onChange={this.onChange} maxLength="5000" type="text" name="description" value={this.state.description} className="form-control" id="description" aria-describedby="codeHelp" placeholder="Description" />
                                        </div>
                                        {this.state.logo && this.state.logo.length !== 0 ?
                                            <div className="form-group col-lg-4 mt-2">
                                                <div className="document-img mt-4">
                                                    <div onClick={() => { this.setState({ logo: "" }) }} className="close-btn"> <i className="fa fa-times"></i></div>
                                                        <img alt={"img"} className="images" src={uploadUrl + this.state.logo.medium} />
                                                    <div className="media-content">
                                                    </div>
                                                </div>
                                            </div> :
                                            <div className="form-group col-12 col-lg-4">
                                                <label htmlFor="exampleInputName1" className="control-label">Image</label>
                                                <input
                                                    maxLength="5"
                                                    onChange={this.uploadFile}
                                                    name="logo"
                                                    type="file"
                                                    className="form-control"
                                                    accept=".jpg, .jpeg, .png"
                                                />
                                                <small className="text-muted d-flex">Image extension such as .jpg, .jpeg, .png allow only and size limit is 20MB.</small>
                                                <ErrorView message={this.state.logoError} />
                                                <div className="progressbar-image">
                                                    {uploadPercentage > 0 && <ProgressBar now={uploadPercentage} active="true" label={`${uploadPercentage}%`} />}
                                                </div>
                                            </div>}
                                        {this.state.videoObj && this.state.videoObj.length !== 0 ?
                                            <div className="form-group col-lg-4">
                                                <div className="document-img">
                                                    <div onClick={() => { this.setState({ videoObj: "" }) }} className="close-btn"> <i className="fa fa-times"></i></div>
                                                    <video className='videos' controls>
                                                        <source src={uploadUrl + this.state.videoObj} type="video/mp4" />
                                                    </video>
                                                </div>
                                            </div> :
                                            <div className="form-group  col-12 col-lg-4">
                                                <label htmlFor="exampleInputName1" className="control-label">Video</label>
                                                <input
                                                    maxLength="5"
                                                    onChange={this.videoUploadFile}
                                                    name="videoObj"
                                                    type="file"
                                                    accept='video/mp4'
                                                    className="form-control"
                                                />
                                                <small className="text-muted d-flex">Video extension such as video/mp4 allow only and size limit is 20MB.</small>
                                                <ErrorView message={this.state.videoError} />
                                                <div className="progressbar-image">
                                                    {videoUploadPercentage > 0 && <ProgressBar now={videoUploadPercentage} active="true" label={`${videoUploadPercentage}%`} />}
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" disable={isLoading} loading={isLoading} name={pageType === "update" ? "Update" : "Save"} />
                            <Links to={routehelp.trending} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>
                </div>
            </main>

        )
    }
}

function mapStateToProps(state) {
    return {
        trend_create_res: state.trend_create_res,
        trend_single: state.trend_single,
        trend_update: state.trend_update
    };
}


const mapDispatchToProps = dispatch => ({
    trendCreate: data => dispatch(trendCreate(data)),
    trendsingle: id => dispatch(trendsingle(id)),
    trendUpdate: (data, id) => dispatch(trendUpdate(data, id))
});

TrendingAdd = connect(
    mapStateToProps,
    mapDispatchToProps
)(TrendingAdd);

export default TrendingAdd;