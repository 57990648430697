import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { delete_customer, customermanageupdate, customerUpload, customerexport, region_search, territory_search, beat_search, customermanagementlist } from '../../actions';
import { routehelp } from '../../Routehelper';
import toastr from 'reactjs-toastr';
import { CSVLink } from "react-csv";
import { errorRender, commonDebounce, successRender, loaderUpdate, redirect, fileDownload, isPermission } from '../../helpers/functions';
import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';
import 'reactjs-toastr/lib/toast.css';

const Pagination = lazy(() => import('../../components/pagination/index'));
const Entries = lazy(() => import('../../components/entries/entires'));
const LinkWithItag = lazy(() => import('../../components/linkwithItag'));
const ButtonWithItag = lazy(() => import('../../components/buttonwithTagi'));
const Loader = lazy(() => import('../../components/loader'));
const Userdropdown = lazy(() => import('../userManagement/userDropdwon'));
const Zonedropdown = lazy(() => import('../zoneManagement/zoneDropdwon'));
const RegionDropdown = lazy(() => import('../regionManagement/RegionDropdown'));
const Territorydropdown = lazy(() => import('../territoryManagement/territoryDropdown'));
const Beatdropdown = lazy(() => import('../beatsManagement/beatDropdwon'));
const DataTable = lazy(() => import('../../components/dataTalble'));
const CardTitle = lazy(() => import('../../components/cardTitle'));
const SearchInput = lazy(() => import('../../components/searchInput'));

let itemsPerPage = ITEM_PER_PAGES;
let currentPage = CURRENT_PAGE;
let sortBy = SORT_BY;
let sort = SORT;
let file = new FormData();


const csvData = [
    ["shopName", "address", "marketName", "customerType", "accountCode", "isOnline", "ownersName", "ownersContactNumber", "shopErpId", "shopSegmentation", "tag"],
    ["test Name 1", "test Address 1", "test market Name 1", "retailer", "17231", "Offline", "test1", "1234567890", "8128", "", ""],
    ["test Name 2", "test Address 2", "test market Name 2", "wholesaler", "17232", "online", "test2", "9876543210", "15473", "", ""]]

class Customermanagement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            mobile: '',
            usertype: '',
            isLoading: false,
            agent: '',
            agentUpload: null,
            zone: null,
            region: null,
            territory: null,
            beat: null,
            isFilter: false,
            ids: [],
            selectAll: false,
            deleteMessage: "",
            serverErrors: ["customermanagement_res", "customerUploadresponse", "customer_delete", "customer_update"],
            successRequest: [{ name: "customer_delete", msg: 'Customer has been Deleted Successfully' }, { name: "customer_update", msg: 'Customer Has been Updated Successfully' }],
            isOnlineMapping: {
                true: "Online",
                false: "Offline"
            },
            tableHeaders: [
                {
                    name: 'name',
                    label: "Name",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'code',
                    label: "Code",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'accountCode',
                    label: "Account Code",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'mobile',
                    label: "Mobile",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'customerType',
                    label: "Customer Type",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'isOnline',
                    label: "isOnline ?",
                    type: "boolean",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'agent.name',
                    label: "Agent",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'createdAt',
                    label: "CreatedAt",
                    type: "date",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'isActive',
                    label: "Status",
                    type: "checkbox",
                    sortable: true,
                    isShow: true,
                    function: this.checkhandler
                },
                {
                    name: 'actions',
                    label: "Actions",
                    type: "button",
                    sortable: false,
                    isShow: true,
                    allactions: [{
                        name: "edit",
                        title: "Edit",
                        classNameI: "ti-pencil",
                        className: "btn btn-link text-pramiry action-btn mr-2 p-0",
                        isPermission: "update",
                        function: redirect
                    }, {
                        name: "delete",
                        title: "Delete",
                        classNameI: "ti-trash",
                        className: "btn btn-link text-danger action-btn p-0",
                        isPermission: "delete",
                        function: this.deleteRow
                    }]
                }
            ],
            statusType:'',
            status: [
                { label: "All", value: 'all' },
                { label: "Active", value: "active" },
                { label: "Inactive", value: "inactive" }
            ],
            isBackButton: false,
            isExportLoading: false
        }
    }

    statusType = () => {
        if (this.state.statusType === "active") {
           return  true;
        };
        if (this.state.statusType === "inactive") {
           return  false;
        };
    }; 

    componentDidMount() {
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        this.loadResult();
        var params = this.queryStringParse(this.props.location.search);
        if (params && params.dashboard === 'true') {
            // console.log(params);
            this.setState({
                isBackButton: true
            });

        }
    }

    queryStringParse = function (string) {
        let parsed = {};
        if (string) {
            string = string.substring(string.indexOf('?') + 1)
            let p1 = string.split('&')
            p1.forEach((value) => {
                let params = value.split('=')
                parsed[params[0]] = params[1]
            });
        }
        return parsed
    }


    componentWillUnmount() {
        currentPage = CURRENT_PAGE;
        itemsPerPage = ITEM_PER_PAGES;
        this.setState(this.state);
    }


    loadResult = () => {
        if (this.state.isFilter === true) {
            currentPage = 1;
        }
        file = new FormData();
        let data = { customerType: this.state.usertype }
        data.filter = { name: this.state.name ? this.state.name : undefined, phone: this.state.mobile ? this.state.mobile : undefined, agent: this.state.agent && this.state.agent.value }
        data.filter.isActive = this.statusType();
        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        data.skip = this.state.name ? 0 : (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.customermanagementlist(data);
        this.setState({ isLoading: true, isFilter: false })
    }

    sortBy = (key) => {
        sortBy = key;
        if (sort === 'desc') {
            sort = 'asc';
        } else {
            sort = 'desc';
        }
        this.loadResult();
    }

    export = () => {
        let data = {
            filter: {
                customerType: this.state.usertype,
                // name: this.state.name ? this.state.name : undefined,
                // phone: this.state.mobile ? this.state.mobile : undefined,
                agent: this.state.agent && this.state.agent.value
            }
            
        };
        data.filter.isActive = this.statusType();
        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        this.setState({
            isExportLoading: true
        });
        this.props.customerexport(data);
    }


    toggleFilter = () => this.setState({ isFilter: !this.state.isFilter });

    ondropdownChangeagent = (e, t) => this.setState({ [e]: t });

    handleFile = event => {
        if (event.target.files && event.target.files.length) {
            // if (event.target.files[0].type === "text/csv") {
            if (event.target.files[0].name.includes(".csv")) {
                file.append('file', event.target.files[0]);
                file.append('agent', this.state.agentUpload && this.state.agentUpload.value);
                file.append('zone', this.state.zone && this.state.zone.value);
                file.append('region', this.state.region && this.state.region.value);
                file.append('territory', this.state.territory && this.state.territory.value);
                file.append('beat', this.state.beat && this.state.beat.value);
            } else {
                document.getElementById("myFile").value = "";
                toastr.error('Please Select only Csv file ', 'error', {
                    displayDuration: 5000, width: '400px'
                });
            }
        }
    };

    fileupload = () => {
        if (this.state.agentUpload === null) {
            return toastr.error('Please Select User ', 'error', {
                displayDuration: 2000, width: '400px'
            });
        }
        if (this.state.zone === null) {
            return toastr.error('Please Select Zone ', 'error', {
                displayDuration: 2000, width: '400px'
            });
        }
        if (this.state.region === null) {
            return toastr.error('Please Select Region ', 'error', {
                displayDuration: 2000, width: '400px'
            });
        }
        if (this.state.territory === null) {
            return toastr.error('Please Select Territory ', 'error', {
                displayDuration: 2000, width: '400px'
            });
        }
        if (this.state.beat === null) {
            return toastr.error('Please Select Beat ', 'error', {
                displayDuration: 2000, width: '400px'
            });
        }
        if (document.getElementById("myFile").value === "") {
            return toastr.error('Please Select File', 'error', {
                displayDuration: 2000, width: '400px'
            });
        } else {
            this.props.customerUpload(file);
        }
    }

    clearFile = () => {
        this.setState({ agentUpload: null, zone: null, region: null, territory: null, beat: null })
        document.getElementById("myFile").value = "";
        file = new FormData();
    }

    resetfilter = () => {
        let data = {}
        data.filter = { name: undefined, phone: undefined, type: undefined, agent: undefined, isActive: undefined }
        data.sortBy = sortBy;
        data.sort = sort;
        data.skip = (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.customermanagementlist(data);
        this.setState({ isFilter: false, isLoading: true, name: '', mobile: '', usertype: '', agent: null,  statusType: "all" })
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    }

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    }

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    }

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }

    checkhandler = (e, obj) => {
        this.setState({ [e.target.name]: e.target.checked });
        let id = e.target.name;
        let data = {
            name: obj.name,
            code: obj.code,
            isActive: e.target.checked
        }
        this.props.customermanageupdate(data, id)
    }

    deleteRow = (e, item) => {
        if (window.confirm(`Are you sure you wish to delete "${item.name}" ?`)) {
            this.props.delete_customer(item._id);
            e.preventDefault();
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {

            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "customermanagement_res") });
            }

            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            this.state.successRequest.forEach((el) => successRender(nextProps, el.name, this.loadResult, el.msg));
            if (nextProps.customerUploadresponse !== undefined && nextProps.customerUploadresponse.success === true) {
                toastr.success("File has been uploaded Succesfully");
                this.setState({ agentUpload: null, zone: null, region: null, territory: null, beat: null })
                document.getElementById("myFile").value = "";
                this.loadResult();
                nextProps.customerUploadresponse.success = null;
            }

            if (nextProps.customerexportresponse !== undefined && nextProps.customerexportresponse.success === true) {
                fileDownload(nextProps, "customerexportresponse", "fileName");
                this.setState({
                    isExportLoading: false
                });
            }

        }
        return true;
    }

    ondropdownChange(val, name) {
        if (val && val.value) {
            if (name === "zone") {
                if (this.state.zone !== null) {
                    if (val.value !== this.state.zone.value) {
                        this.setState({ region: null, territory: null, beat: null });
                        let data = {
                            zone: val.value
                        };
                        this.props.region_search(data);
                    }
                } else {
                    let data = {
                        zone: val.value
                    };
                    this.props.region_search(data);
                }
            }
            if (name === "region") {
                if (this.state.region !== null) {
                    if (val.value !== this.state.region.value) {
                        this.setState({ territory: null, beat: null });
                        let data = {
                            region: val.value
                        };
                        this.props.territory_search(data);
                    }
                } else {
                    let data = {
                        region: val.value
                    };
                    this.props.territory_search(data);
                }
            }
            if (name === "territory") {
                if (this.state.territory !== null) {
                    if (val.value !== this.state.territory.value) {
                        this.setState({ beat: null });
                        let data = {
                            territory: val.value
                        };
                        this.props.beat_search(data);
                    }
                } else {
                    let data = {
                        territory: val.value
                    };
                    this.props.beat_search(data);
                }
            }
            this.setState({ [name]: val });
        }
    }

    handleChange = (event) => {
        if (event.target.checked) {
            this.setState({ [event.target.name]: event.target.checked })
            this.state.ids.push({ id: event.target.id })
        } else {
            for (var i = 0; i < this.state.ids.length; i++) {
                if (this.state.ids[i].id === event.target.id) {
                    this.setState({ [event.target.name]: event.target.checked })
                    this.state.ids.splice(i, 1);
                    i--;
                }
            }
        }
    }

    selectAll(e) {
        if (e.target.checked) {
            let { customermanagement_res } = this.props;
            if (customermanagement_res !== undefined && customermanagement_res.list) {
                this.setState({ [e.target.name]: e.target.checked })
                customermanagement_res.list.map((item) => {
                    return this.state.ids.push({ id: item._id })
                })
            }
        }
        if (this.state.ids.length === 0) {
            this.setState({ selectAll: false })
        } else {
            this.setState({ selectAll: true })
        }
    }

    onChangeforDebounce = ({ target: { value } }) => {
        if (!/^\s/.test(value)) {
            this.setState({ inputValue: value, name: value }, () => this.callforDebounce(value))
        }
    }

    callforDebounce = () => this.loadResult();

    render() {
        const { name } = this.state;
        let { customermanagement_res } = this.props;
        const totResult = customermanagement_res !== undefined ? customermanagement_res.count : 0;

        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                {
                                    this.state.isBackButton ? <div className="mr-3 mt-2 text-right">
                                        <Link to={routehelp.maindashboard} className="btn btn-primary">Back</Link>
                                    </div> : null
                                }
                                <div className="card-header">
                                <CardTitle title= "Customer Management" />
                                </div>
                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                {totResult === undefined ? "" : <Entries
                                                    itemsPerPage={itemsPerPage}
                                                    handleChangeiItemsPerPage={
                                                        this.handleChangeiItemsPerPage
                                                    }
                                                />}
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="add-btn-icon mb-4">
                                                    <SearchInput onChangeforDebounce={this.onChangeforDebounce} value={name} />
                                                    {isPermission("customers", "create") ? 
                                                        <LinkWithItag to={routehelp.Customeradd} className="btn float-left  btn-primary mr-2" role="button" classNameI="fa fa-plus" title="Add Customer" />
                                                    : null}
                                                    <ButtonWithItag classNameI="fa tai-ico-Export" disabled={this.state.isExportLoading} onclick={this.export} type="button" className="btn float-left btn-primary mr-2" title="Export as a file" data-toggle="tooltip" data-placement="top" data-original-title="Click to Upload all" />
                                                    <ButtonWithItag classNameI="ti-filter" type="button" onclick={this.toggleFilter} className="btn float-left btn-primary mr-2" data-toggle="tooltip" data-placement="top" title="Filter" data-original-title="Click for Filter option" />
                                                    <ButtonWithItag classNameI="fa tai-ico-import" type="button" className="btn float-left btn-primary mr-2" datatoggle="modal" datatarget=".bd-example-modal-lg" dataplacement="top" title="Import a file" data-original-title="Click to download all" />
                                                    <CSVLink
                                                        data={csvData}
                                                        filename={"customerSample.csv"}
                                                        className="btn float-left btn-primary"
                                                        target="_blank"
                                                        title="Sample"
                                                    >
                                                        <i className="fa fa-file-excel-o"></i>
                                                    </CSVLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" style={{ display: "none" }} aria-hidden="true">
                                            <div className="modal-dialog modal-lg">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="myLargeModalLabel">Customer Upload</h5>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">×</span>
                                                        </button>
                                                    </div>
                                                    <div className="card-body">

                                                        <div className=" form-group required">
                                                            <Userdropdown
                                                                ondropdownChange={this.ondropdownChangeagent}
                                                                name="agentUpload"
                                                                value={this.state.agentUpload}
                                                                className="form-control form-control-square"
                                                                placeholder="User"
                                                            />
                                                        </div>

                                                        <div className=" form-group required">
                                                            <Zonedropdown
                                                                ondropdownChange={(e) => this.ondropdownChange(e, "zone")}
                                                                placeholder="Search Zone"
                                                                value={this.state.zone}
                                                            />
                                                        </div>

                                                        <div className=" form-group required">
                                                            <RegionDropdown
                                                                ondropdownChange={(e) => this.ondropdownChange(e, "region")}
                                                                placeholder="Search Region"
                                                                value={this.state.region}
                                                            />
                                                        </div>

                                                        <div className=" form-group required">
                                                            <Territorydropdown
                                                                ondropdownChange={(e) => this.ondropdownChange(e, "territory")}
                                                                placeholder="Search Territory"
                                                                value={this.state.territory}
                                                            />
                                                        </div>

                                                        <div className=" form-group required">
                                                            <Beatdropdown
                                                                ondropdownChange={(e) => this.ondropdownChange(e, "beat")}
                                                                placeholder="Search Beat"
                                                                value={this.state.beat}
                                                            />
                                                        </div>

                                                        <div className="custom-file p-2 form-group required">
                                                            <input accept=".csv" onChange={this.handleFile} type="file" id="myFile" name="filename2"></input>
                                                        </div>

                                                    </div>

                                                    <div className="modal-footer">
                                                        <button type="button" onClick={this.fileupload} data-dismiss="modal" className="btn btn-primary">Save changes</button>
                                                        <button type="button" className="btn btn-secondary" onClick={this.clearFile} data-dismiss="modal">Close</button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        {totResult === undefined ? <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={""} props={this.props}  moduleName={"customers"}/> :
                                            this.state.isLoading ? <Loader /> : customermanagement_res !== undefined && customermanagement_res.count === 0 ? "No Data Found" : customermanagement_res && customermanagement_res.count > 0 ?
                                                <div className="row">
                                                    <div className="col-sm-12 display-grid">
                                                        <div className="table-responsive">
                                                            <DataTable mappingObj={this.state.isOnlineMapping} headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={customermanagement_res} props={this.props} moduleName={"customers"}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                : "Something Went Wrong"}

                                        {totResult !== undefined ?
                                            <Pagination
                                                handleChangeiItemsPerPage={
                                                    this.handleChangeiItemsPerPage
                                                }
                                                handlePage={this.handlePage}
                                                handleNext={this.handleNext}
                                                handlePrevious={this.handlePrevious}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                totResult={totResult}
                                                key={currentPage}
                                            />
                                            : ""}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={!this.state.isFilter ? "right-sidebarfilter hidden" : "right-sidebarfilter show"}>

                    <div className="modal-header bg-primary rounded-0">
                        <h6 className="filter-modal-title">Filter</h6>
                        <button type="button" onClick={this.toggleFilter} className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" className="navbar-toggler right-sidebar-toggler pointer">×</span>
                        </button>
                    </div>

                    <div className="card-body">
                        <form>
                            <div className="form-group row">
                                <label htmlFor="Mobile" className="col-sm-3 col-form-label">Type</label>
                                <div className="col-sm-9">
                                    <select className="form-control" name="usertype" value={this.state.usertype} onChange={this.onChange} id="usertype">
                                        <option value="">Select a Type</option>
                                        <option value="retailer">Retailer</option>
                                        <option value="wholesaler">Wholesaler</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Status</label>
                                <div className="col-sm-9">
                                    <select className="form-control" name="statusType" value={this.state.statusType} onChange={this.onChange} id="statusType">
                                    {
                                        this.state.status.map((ele, key) =>
                                            <option value={ele.value} id={key} key={key}>{ele.label}</option>
                                        )
                                    }
                                    </select>
                                </div>
                            </div>
                                <Userdropdown
                                    ondropdownChange={this.ondropdownChangeagent}
                                    name="agent"
                                    value={this.state.agent}
                                    className="form-control"
                                    placeholder="User"
                                />
                        </form>
                    </div>

                    <div className="col-md-6 mb-4 offset-md-3 text-center">
                        <button onClick={this.loadResult} data-dismiss="modal" type="button" aria-hidden="true" className="btn btn-primary navbar-toggler right-sidebar-toggler pointer mr-1">Apply </button>
                        <button type="button" data-dismiss="modal" onClick={this.resetfilter} className="btn btn-outline-dark">Reset</button>
                    </div>

                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        customermanagement_res: state.customermanagement_res,
        customer_delete: state.customer_delete,
        region_response_search: state.region_response_search,
        territory_response_search: state.territory_response_search,
        beat_response_search: state.beat_response_search,
        customerUploadresponse: state.customerUploadresponse,
        customerexportresponse: state.customerexportresponse,
        customer_update: state.customer_update
    };
}

const mapDispatchToProps = dispatch => ({
    customermanagementlist: data => dispatch(customermanagementlist(data)),
    delete_customer: item_id => dispatch(delete_customer(item_id)),
    customermanageupdate: (data, id) => dispatch(customermanageupdate(data, id)),
    region_search: data => dispatch(region_search(data)),
    territory_search: data => dispatch(territory_search(data)),
    beat_search: data => dispatch(beat_search(data)),
    customerUpload: file => dispatch(customerUpload(file)),
    customerexport: data => dispatch(customerexport(data))
});

Customermanagement = connect(
    mapStateToProps,
    mapDispatchToProps
)(Customermanagement);

export default Customermanagement;