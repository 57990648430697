import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { orderFailedList } from '../../actions';
import { errorRender, successRender, commonDebounce, loaderUpdate } from '../../helpers/functions';
import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';

const Loader = lazy(() => import('../../components/loader'));
const Pagination = lazy(() => import('../../components/pagination/index'));
const DataTable = lazy(() => import('../../components/dataTalble'));
const Entries = lazy(() => import('../../components/entries/entires'));

let itemsPerPage = ITEM_PER_PAGES;
let currentPage = CURRENT_PAGE;
let sortBy = SORT_BY;
let sort = SORT;

class OrderCreateFailed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            viewOrder: null,
            serverErrors: ["orderFailedList_res"],
            tableHeaders: [
                {
                    name: 'thirdPartyOrderID',
                    label: "Third Party Order ID",
                    type: "test",
                    sortable: false,
                    isShow: true
                },
                // {
                //     name: 'createdByDetails',
                //     label: "Order Created By",
                //     type: "loop",
                //     keyName: "name",
                //     isLink: false,
                //     sortable: false,
                //     isShow: true
                // },
                {
                    name: 'createdAt',
                    label: "CreatedAt",
                    type: "date",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'actions',
                    label: "Actions",
                    type: "button",
                    sortable: false,
                    isShow: true,
                    allactions: [
                        {
                            classNameI: "fa fa-exclamation-triangle",
                            type: "button",
                            className: "btn btn-link text-danger action-btn p-0",
                            datatoggle: "modal",
                            datatarget: ".bd-example-modal-lg",
                            dataplacement: "top",
                            title: "Order Details",
                            dataoriginaltitle: "Click to Order Details",
                            name: "view",
                            function: this.viewDetails
                        }
                    ]
                }
            ]
        }
    }

    viewDetails = (e, item) => {
        // console.log(item);
        this.setState({
            viewOrder: item
        });
    }

    componentDidMount() {
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        this.loadResult();
    }

    componentWillUnmount() {
        currentPage = CURRENT_PAGE;
        itemsPerPage = ITEM_PER_PAGES;
    }


    loadResult = () => {
        let data = {}
        data.filter = { name: this.state.name ? this.state.name : undefined }
        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        data.skip = (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.orderFailedList(data);
        this.setState({ isLoading: true });
    }

    resetfilter = () => {
        let data = {}
        data.filter = { name: '', code: '' }
        this.props.orderFailedList(data);
    }

    sortBy = (key) => {
        sortBy = key;
        if (sort === 'desc') {
            sort = 'asc';
        } else {
            sort = 'desc';
        }
        this.loadResult();
    }

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    }

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    }

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    }

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    onChangeforDebounce = ({ target: { value } }) => {
        if (!/^\s/.test(value)) {
            this.setState({ name: value }, () => this.callforDebounce(value))
        }
    }

    callforDebounce = () => this.loadResult();

    shouldComponentUpdate(nextProps) {
        // console.log(nextProps);
        if (nextProps !== this.props) {
            // console.log(this.state.isLoading)
            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "orderFailedList_res") });
            }
            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            successRender(nextProps, "uom_delete", this.loadResult, this.state.deleteSuccess);
        }
        return true;
    }

    render() {
        const { orderFailedList_res } = this.props;
        const totResult = orderFailedList_res ? orderFailedList_res.count : 0;

        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        Order Failed List
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">

                                        <div className="row">

                                            <div className="col-sm-12 col-md-6">
                                                {totResult === 0 ? null :
                                                    <Entries
                                                        itemsPerPage={itemsPerPage}
                                                        handleChangeiItemsPerPage={
                                                            this.handleChangeiItemsPerPage
                                                        }
                                                    />
                                                }
                                            </div>

                                        </div>

                                        {
                                            totResult === undefined ? <p className="text-center">No Data Found</p> :
                                                this.state.isLoading ?
                                                    <Loader />
                                                    :
                                                    orderFailedList_res !== undefined && orderFailedList_res.count === 0 ?
                                                        <p className="text-center">No Data Found</p>
                                                        :
                                                        orderFailedList_res && orderFailedList_res.count > 0 ?
                                                            <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy}
                                                                sortByvalue={sortBy} sortvalue={sort}
                                                                data={orderFailedList_res} props={this.props} />
                                                            : <p className="text-center">Something Went Wrong</p>
                                        }

                                        {totResult !== undefined ? orderFailedList_res !== undefined && orderFailedList_res.count === 0 ? "" :
                                            <Pagination
                                                handleChangeiItemsPerPage={
                                                    this.handleChangeiItemsPerPage
                                                }
                                                handlePage={this.handlePage}
                                                handleNext={this.handleNext}
                                                handlePrevious={this.handlePrevious}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                totResult={totResult}
                                                key={currentPage}
                                            />
                                            : ""}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade bd-example-modal-lg"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myLargeModalLabel"
                    style={{ display: "none" }}
                    aria-hidden="true">

                    <div className="modal-dialog modal-lg modal-custom-width">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="myLargeModalLabel">
                                    Orders Details
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {/* {console.log(this.state.viewOrder)} */}
                            <div className="modal-body">
                                {
                                    this.state.viewOrder ?
                                        <div className="card-body">
                                            <strong>Third Party Order ID:</strong>
                                            <pre>{this.state.viewOrder.thirdPartyOrderID || "NA"}</pre>
                                            <strong>Request JSON:</strong>
                                            <pre className="alert alert-info font-weight-bold">{JSON.stringify(this.state.viewOrder.reqJson, null, 4)}</pre>
                                            <strong>Error:</strong>
                                            <pre className="alert alert-danger font-weight-bold">{JSON.stringify(this.state.viewOrder.error, null, 4)}
                                            </pre>
                                        </div>
                                        :
                                        <div>No Data Found</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        orderFailedList_res: state.orderFailedList_res,
        uom_delete: state.uom_delete
    };
}

const mapDispatchToProps = dispatch => ({
    orderFailedList: data => dispatch(orderFailedList(data))
});

OrderCreateFailed = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderCreateFailed);

export default OrderCreateFailed;