import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { package_list, package_delete } from '../../actions';
import { routehelp } from '../../Routehelper';
import Footer from '../../components/footer';
import Header from '../../components/header';
import { errorRender, successRender, commonDebounce, redirect, loaderUpdate } from '../../helpers/functions';
import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';

const Loader = lazy(() => import('../../components/loader'));
const Pagination = lazy(() => import('../../components/pagination/index'));
const DataTable = lazy(() => import('../../components/dataTalble'));
const Entries = lazy(() => import('../../components/entries/entires'));
const LinkWithItag = lazy(() => import('../../components/linkwithItag'));
// const SearchInput = lazy(() => import('../../components/searchInput'));

let itemsPerPage = ITEM_PER_PAGES;
let currentPage = CURRENT_PAGE;
let sortBy = SORT_BY;
let sort = SORT;

class PcakageList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            deleteSuccess: "Package Has been Deleted Successfully",
            serverErrors: ["package_delete", "package_list_res"],
            tableHeaders: [
                {
                    name: 'name',
                    label: "Package Name",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'createdAt',
                    label: "Created At",
                    type: "date",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'actions',
                    label: "Actions",
                    type: "button",
                    sortable: false,
                    isShow: true,
                    allactions: [{
                        name: "edit",
                        title: "Edit",
                        classNameI: "ti-pencil",
                        className: "btn btn-link text-pramiry action-btn mr-2 p-0",
                        function: redirect
                    },
                    {
                        name: "delete",
                        title: "Delete",
                        classNameI: "ti-trash",
                        className: "btn btn-link text-danger action-btn p-0",
                        function: this.deleteRow
                    }]
                }
            ]
        }
    };

    componentDidMount() {
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        this.loadResult();
    };

    componentWillUnmount() {
        currentPage = CURRENT_PAGE;
        itemsPerPage = ITEM_PER_PAGES;
    };

    loadResult = () => {
        let data = {};
        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        data.skip = this.state.name ? 0 : (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.setState({ isLoading: true });
        this.props.package_list(data);
    };

    resetfilter = () => {
        let data = {};
        // data.filter = { name: '', code: '' }
        this.props.package_list(data);
    };

    sortBy = (key) => {
        sortBy = key;
        if (sort === 'desc') {
            sort = 'asc';
        } else {
            sort = 'desc';
        }
        this.loadResult();
    };

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    };

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    };

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    };

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    };

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    deleteRow = (e, item) => {
        if (window.confirm(`Are you sure you wish to delete "${item.name}" ?`)) {
            this.props.package_delete(item._id);
            e.preventDefault();
        }
    };

    onChangeforDebounce = ({ target: { value } }) => {
        if (!/^\s/.test(value)) {
            this.setState({ name: value }, () => this.callforDebounce(value))
        }
    };

    callforDebounce = () => this.loadResult();

    shouldComponentUpdate(nextProps) {
        // console.log(nextProps);
        if (nextProps !== this.props) {
            // console.log(this.state.isLoading)
            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "package_list_res") });
            }
            this.setState({
                isLoading: false
            });
            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            successRender(nextProps, "package_delete_res", this.loadResult, this.state.deleteSuccess);
        }
        return true;
    };

    render() {
        const { package_list_res } = this.props;
        const totResult = package_list_res ? package_list_res.count : 0;
        return (
            <Suspense fallback={<div></div>}>
                <div className="app header-fixed left-sidebar-fixed scrollbarcustom left-sidebar-hidden">
                    <Header />
                    <div className="app-body">
                        <main className="main-content ml-0">
                            <div className="row mx-0">
                                <div className=" col-sm-12 mt-4">
                                    <div className="card card-shadow mb-4">
                                        <div className="card-header">
                                            <div className="card-title">
                                                Packages
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-6">
                                                        {
                                                            totResult === 0 ? null :
                                                                <Entries
                                                                    itemsPerPage={itemsPerPage}
                                                                    handleChangeiItemsPerPage={
                                                                        this.handleChangeiItemsPerPage
                                                                    }
                                                                />
                                                        }
                                                    </div>

                                                    <div className="col-sm-12 col-md-6">
                                                        <div className="add-btn-icon mb-4">
                                                            <LinkWithItag to={routehelp.packageAdd} className="btn float-left  btn-primary mr-2" role="button" classNameI="fa fa-plus" title="Add UOM" />
                                                        </div>
                                                    </div>

                                                </div>

                                                {
                                                    this.state.isLoading ?
                                                        <Loader />
                                                        :
                                                        package_list_res && package_list_res.count > 0 ?
                                                            <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy}
                                                                sortByvalue={sortBy} sortvalue={sort}
                                                                data={package_list_res} props={this.props} />
                                                            :
                                                            <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy}
                                                                sortByvalue={sortBy} sortvalue={sort}
                                                                data={[]} props={this.props} />
                                                }
                                                {
                                                    totResult !== undefined ? package_list_res !== undefined && package_list_res.count === 0 ? "" :
                                                        <Pagination
                                                            handleChangeiItemsPerPage={
                                                                this.handleChangeiItemsPerPage
                                                            }
                                                            handlePage={this.handlePage}
                                                            handleNext={this.handleNext}
                                                            handlePrevious={this.handlePrevious}
                                                            currentPage={currentPage}
                                                            itemsPerPage={itemsPerPage}
                                                            totResult={totResult}
                                                            key={currentPage}
                                                        />
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                    <Footer className="ml-0" />
                </div>
            </Suspense>
        )
    };
};

function mapStateToProps(state) {
    return {
        package_list_res: state.package_list_res,
        package_delete_res: state.package_delete_res
    };
}

const mapDispatchToProps = dispatch => ({
    package_list: data => dispatch(package_list(data)),
    package_delete: item_id => dispatch(package_delete(item_id))
});

PcakageList = connect(
    mapStateToProps,
    mapDispatchToProps
)(PcakageList);

export default PcakageList;