import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper, Polyline } from 'google-maps-react';
import InfoWindowEx from '../components/InfoWindowEx';
class Maps extends Component {
    state = {
        lat: '',
        lng: ''
    }
    // this.onMarkerClick = this.onMarkerClick.bind(this)
    // onMarkerClick = (props, marker, e) => {
    //     this.setState({
    //         selectedPlace: props.name,
    //         activeMarker: marker,
    //         showingInfoWindow: true
    //     });
    // };

    render() {
        let { pathCoordinates, ordermanagement_res, name, onMarkerClick, showingInfoWindow, activeMarker } = this.props;
        let lat;
        let lng;
        if (pathCoordinates && pathCoordinates.length > 0) {
            // var cor = {
            //     lat: pathCoordinates[0].lat,
            //     lng: pathCoordinates[0].lng
            // }
            lat = pathCoordinates[0].lat;
            lng = pathCoordinates[0].lng;
            // console.log('called');
        }
        // console.log(lat, lng);
        // console.log(pathCoordinates);
        if (pathCoordinates && pathCoordinates.length > 0) {
            return (
                <Map
                    google={window.google}
                    zoom={20}
                    style={{ width: '100%', height: '80vh', position: 'relative' }}
                    center={{ lat: lat, lng: lng }}
                >
                    <Polyline
                        path={pathCoordinates}
                        options={{
                            strokeColor: 'blue',
                            strokeOpacity: 1,
                            strokeWeight: 2,
                            icons: [{
                                icon: { path: window && window.google && window.google.maps && window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
                                offset: '0',
                                repeat: '100px',
                                Color: 'red'
                            }]
                        }}
                    />

                    {ordermanagement_res !== undefined && ordermanagement_res.list ? ordermanagement_res.list.map(
                        // eslint-disable-next-line
                        (item, index) => {
                            if (item.latitude && item.latitude !== "" && item.longitude && item.longitude !== "") {
                                return (<Marker onClick={onMarkerClick} title={index + 1 + ""} name={item.customer.name} key={index} position={{
                                    lat: item.latitude, lng: item.longitude
                                }} />)
                            }

                        }) : ''}
                    <InfoWindowEx
                        marker={activeMarker}
                        visible={showingInfoWindow}
                    >
                        <div>
                            <h6>{name}</h6>
                        </div>
                    </InfoWindowEx>
                </Map>
            )
        } else {
            return (
                <div className="mt-5 mb-5 text-center">
                    <p>No Record Found</p>
                </div>
            );
        }
    }
}
export default GoogleApiWrapper({
    apiKey: ("AIzaSyDu46HWEpYBkv6K6W3BF4GAuYaEOLpVp3A")
})(Maps);