import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { routehelp } from '../../Routehelper';
import { regionmanagecreate, regionsingle, regionmanageupdate } from '../../actions';
import { errorRender, validate, successRedriect, inputCheck } from '../../helpers/functions';

const Zonedropdown = lazy(() => import('../zoneManagement/zoneDropdwon'));
const Links = lazy(() => import('../../components/links'));
const Inputfield = lazy(() => import('../../components/inputfields'));
const CardTitle = lazy(() => import('../../components/cardTitle'));
const Buttons = lazy(() => import('../../components/buttons'));
const Loader = lazy(() => import('../../components/loader'));
class Region_manage_add extends Component {
    state = {
        pageType: 'add',
        itemId: '',
        name: '',
        code: '',
        zone: null,
        pageName: 'Region',
        pageUrl: 'Regionmanagement',
        serverErrors: ["region_create_res", "region_update", "region_single"],
        setFields: [{ name: "name", type: "text" }, { name: "code", type: "text" }, { name: "zone", type: "select" }],
        forRedirect: [
            { name: "region_create_res", pageName: 'Region', pageUrl: 'Regionmanagement', action: "Added" },
            { name: "region_update", pageName: 'Region', pageUrl: 'Regionmanagement', action: "Updated" }
        ],
        nameError: '',
        codeError: '',
        zoneError: '',
        isLoading: false,
        getSingleLoader: false
    }

    componentDidMount() {
        let id = this.props.match.params.item_id;
        if (id) {
            this.setState({ pageType: "update", itemId: id, getSingleLoader: true })
            this.props.regionsingle(id);
        }
    }



    handleChange = (name, e) => {
        let isValid = inputCheck(name, e.target.value);

        if (isValid) {
            this.setState({ [name]: e.target.value });
        }
        // const re = /([^\s]+)/;
        // if (e.target.value === '' || re.test(e.target.value)) {
        //     this.setState({ [name]: e.target.value });
        // }
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    ondropdownChange = (val, name) => this.setState({ [name]: val });

    onsubmit = (e) => {
        let validationFields = [
            { name: 'name', label: "Name", value: this.state.name, hasError: false, isRequired: true, isNumber: false, onlyChar: true, maxLength: 50, type: "text", message: "", errName: "nameError" },
            { name: 'code', label: "Code", value: this.state.code, hasError: false, isRequired: true, isNumber: false, onlyChar: true, maxLength: 20, type: "text", message: "", errName: "codeError" },
            { name: 'zone', label: "Zone", value: this.state.zone, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "zoneError" },
        ]
        let data = {
            name: this.state.name,
            code: this.state.code,
            zone: this.state.zone && this.state.zone.value
        }
        e.preventDefault();
        let { newArr, valid } = validate(validationFields);
        if (valid) {
            this.setState({ isLoading: true })
            if (this.state.pageType === "update") {
                this.props.regionmanageupdate(data, this.state.itemId);
            } else {
                this.props.regionmanagecreate(data);
            }
            validationFields = null;
            newArr = null;
        } else {
            newArr.forEach((el) => this.setState({ [el.errName]: el.message }))
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: false })
            }
            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));
            if (nextProps.region_single && nextProps.region_single.success) {
                this.setState({ getSingleLoader: false });
                if (nextProps.region_single.item) {
                    this.state.setFields.forEach((value) => {
                        if (value.type === "text") {
                            this.setState({ [value.name]: nextProps.region_single.item[value.name] })
                        } else if (value.type === "select" && nextProps.region_single.item[value.name]) {
                            let obj = {
                                label: nextProps.region_single.item[value.name].name,
                                value: nextProps.region_single.item[value.name]._id
                            }
                            this.setState({ [value.name]: obj });
                            obj = null
                        }
                    })
                    nextProps.region_single.success = null;
                }
            }
        }
        return true;
    }

    render() {
        const { pageType, isLoading, getSingleLoader } = this.state;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                <CardTitle title={pageType === "update" ? "Update Region" : "Add Region"}/>
                                </div>
                                {getSingleLoader ? <Loader /> :
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="form-group required col-12 col-lg-4">
                                                <Inputfield errMessage={this.state.nameError} handleChange={this.handleChange} type="text" name="name" maxlength="100" value={this.state.name} className="form-control" id="name" ariadescribedby="codeHelp" placeholder="Name" />
                                            </div>
                                            <div className="form-group required col-0 col-lg-4">
                                                <Inputfield errMessage={this.state.codeError} handleChange={this.handleChange} type="code" name="code" maxlength="10" value={this.state.code} className="form-control" id="code" aria-describedby="codeHelp" placeholder="Code" />
                                            </div>
                                            <div className="col-md-6 form-group required col-12 col-lg-4">
                                                <Zonedropdown
                                                    ondropdownChange={(e) => this.ondropdownChange(e, "zone")}
                                                    placeholder="Search Zone"
                                                    value={this.state.zone}
                                                    errMessage={this.state.zoneError}
                                                />
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" disable={isLoading || getSingleLoader} loading={isLoading} name={pageType === "update" ? "Update" : "Save"} />
                            <Links to={routehelp.Regionmanagement} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>
                </div>
            </main>

        )
    }
}

function mapStateToProps(state) {
    return {
        region_create_res: state.region_create_res,
        region_single: state.region_single,
        region_update: state.region_update
    };
}


const mapDispatchToProps = dispatch => ({
    regionsingle: data => dispatch(regionsingle(data)),
    regionmanagecreate: data => dispatch(regionmanagecreate(data)),
    regionmanageupdate: (data, id) => dispatch(regionmanageupdate(data, id))
});

Region_manage_add = connect(
    mapStateToProps,
    mapDispatchToProps
)(Region_manage_add);

export default Region_manage_add;