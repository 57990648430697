import React, { lazy, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { country_search, country_create } from '../../actions';
import SearchableDropDown from '../../components/searchableDropDown';
import { optionsList } from '../../helpers/functions';
import ErrorView from '../errorView';
import toastr from 'reactjs-toastr';

let Countrydropdown = ({ ondropdownChange, value, placeholder, errMessage }) => {
    const dispatch = useDispatch();

    let country_response_search = useSelector(state => state.country_response_search);
    let country_create_res = useSelector(state => state.country_create_res);

    let [searchList, setSearchList] = React.useState([]);
    let [isCreate, setCreate] = React.useState(false);
    let [country, setCountry] = React.useState("");
    let [countryError, setCountryError] = React.useState("");

    React.useEffect(() => {
        let data = {};
        dispatch(country_search(data));
    }, []);

    React.useEffect(() => {
        if (country_response_search !== undefined && country_response_search.list) {
            setSearchList(optionsList(country_response_search.list))
        };
    }, [country_response_search]);

    React.useEffect(() => {
        // console.log("called");
        if (country_create_res && country_create_res.success) {
            dispatch(country_search({}));
            toastr.success('Country Has been Added Successfully', 'success');
            setCreate(false);
            setCountry("");
        } else {
            if (country_create_res) {
                country_create_res.errors.map((item, index) =>
                    toastr.error(item.msg, 'Error', {
                        displayDuration: 5000
                    })
                );
            }
        };
    }, [country_create_res]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setCountry(value);
        setCountryError("");
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!country) {
            setCountryError("country is required");
        } else {
            dispatch(country_create({
                name: country,
                isActive: true
            }));
        }
    };

    const handleAdd = () => {
        setCreate(!isCreate);
        setCountry("");
    };

    return (
        <div>
            {/* <pre>{country_create_res}</pre> */}
            <div className='d-flex w-100 justify-content-between'>
                <label className="control-label">Country</label>
                <span className='add-btn ml-auto d-inline-block float-right' onClick={handleAdd} title='Add Country'><i className='fa fa-plus-circle'></i></span>
            </div>
            {
                !isCreate ?
                    <Fragment>
                        <SearchableDropDown
                            value={value}
                            onChange={ondropdownChange}
                            options={searchList}
                            instanceId="country-id"
                            prefixValue="country"
                            placeholder={placeholder !== undefined ? placeholder : "Country"}
                        />
                        <ErrorView message={errMessage} />
                    </Fragment>
                    :
                    <Fragment>
                        <div className="input-group add-input">
                            <input onChange={handleInputChange}
                                maxLength="100"
                                type="text"
                                name="country"
                                value={country}
                                className="form-control"
                                id="country-name"
                                placeholder="Country Name" />
                            <div className="input-group-append">
                                <span className="input-group-text" onClick={handleSubmit} title='create'>
                                    <i className='fa fa-plus'></i>
                                </span>
                            </div>
                        </div>
                        <ErrorView message={countryError} />
                        {/* <input onChange={handleInputChange}
                            maxLength="100"
                            type="text"
                            name="country"
                            value={country}
                            className="form-control"
                            id="country-name"
                            placeholder="Country Name" />
                        <ErrorView message={countryError} />
                        <button type="button" onClick={handleSubmit}>Add</button> */}
                    </Fragment>
            }
        </div>
    )
}

export default Countrydropdown;