import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { orderTemplate_list, orderTemplate_delete, orderTemplate_upload, orderTemplate_export, orderTemplate_refresh } from '../../actions';
import { routehelp } from '../../Routehelper';
import { errorRender, successRender, commonDebounce, redirect, loaderUpdate, fileDownload } from '../../helpers/functions';
import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';
import { CSVLink } from "react-csv";
import 'reactjs-toastr/lib/toast.css';
import toastr from 'reactjs-toastr';
import Translate from '../../libs/translation';
import Inputfield from '../../components/inputfields';

const SingleBrandDropDown = lazy(() => import('../brandsManagement/singleBrandDropDown'));
const Loader = lazy(() => import('../../components/loader'));
const Pagination = lazy(() => import('../../components/pagination/index'));
const DataTable = lazy(() => import('../../components/dataTalble'));
const Entries = lazy(() => import('../../components/entries/entires'));
const LinkWithItag = lazy(() => import('../../components/linkwithItag'));
const ButtonWithItag = lazy(() => import('../../components/buttonwithTagi'));

// const SearchInput = lazy(() => import('../../components/searchInput'));

let itemsPerPage = ITEM_PER_PAGES;
let currentPage = CURRENT_PAGE;
let sortBy = SORT_BY;
let sort = SORT;
let file = new FormData();

const csvData = [
    ["product", "shade", "quantity", "uomcode"],
    ["TEST-001", "6", "50", "UNIT"],
    ["TEST-002", "7", "10", "UNIT"],
    ["TEST-002", "6", "10", "UNIT"]
];

class OrderTemplateList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            templateName: '',
            templateNameError: '',
            isLoading: false,
            deleteSuccess: "Order Template Has been Deleted Successfully",
            serverErrors: ["orderTemplate_refresh_res", "orderTemplate_delete_res", "orderTemplate_list_res", "orderTemplate_export_res", "orderTemplate_upload_res"],
            tableHeaders: [
                {
                    name: 'name',
                    label: "Name",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'totalQuantity',
                    label: "Order Template Total Quantity",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'totalAmount',
                    label: "Order Template Total Amount",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                {
                    label: "Possible Production Qty",
                    name: 'possibleProduction.quantity',
                    othername: 'possibleProduction.date',
                    type: "text-label",
                    sortable: false,
                    isShow: true,
                    textlabel: " as on ",
                    otherType: "date_time"
                },
                // {
                //     name: 'possibleProduction.date',
                //     label: "Possible Production Date",
                //     type: "date_time",
                //     sortable: false,
                //     isShow: true
                // },
                {
                    name: 'createdAt',
                    label: "CreatedAt",
                    type: "date",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'actions',
                    label: "Actions",
                    type: "button",
                    sortable: false,
                    isShow: true,
                    allactions: [{
                        name: "edit",
                        title: "Edit",
                        classNameI: "ti-pencil",
                        className: "btn btn-link text-pramiry action-btn mr-2 p-0",
                        function: redirect
                    }, {
                        name: "delete",
                        title: "Delete",
                        classNameI: "ti-trash",
                        className: "btn btn-link text-danger action-btn p-0",
                        function: this.deleteRow
                    }]
                }
            ],
            brand: '',
            isExportLoading: false
        };
    }

    componentDidMount() {
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        this.loadResult();
    }

    componentWillUnmount() {
        currentPage = CURRENT_PAGE;
        itemsPerPage = ITEM_PER_PAGES;
    }

    loadResult = () => {
        let data = {}
        data.filter = { name: this.state.name ? this.state.name : undefined }
        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        data.skip = this.state.name ? 0 : (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.orderTemplate_list(data);
        this.setState({ isLoading: true });
    }

    resetfilter = () => {
        let data = {}
        data.filter = { name: '', code: '' }
        this.props.orderTemplate_list(data);
    }

    sortBy = (key) => {
        sortBy = key;
        if (sort === 'desc') {
            sort = 'asc';
        } else {
            sort = 'desc';
        }
        this.loadResult();
    }

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    }

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    }

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    }

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    deleteRow = (e, item) => {
        if (window.confirm(`Are you sure you wish to delete "${item.name}" ?`)) {
            this.props.orderTemplate_delete(item._id);
            e.preventDefault();
        }
    }

    onChangeforDebounce = ({ target: { value } }) => {
        if (!/^\s/.test(value)) {
            this.setState({ name: value }, () => this.callforDebounce(value))
        }
    }

    callforDebounce = () => this.loadResult();

    ondropdownChange = (e, t) => {
        this.setState({ [t]: e });
    }

    shouldComponentUpdate(nextProps) {
        let req = {};
        if (nextProps !== this.props) {

            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "orderTemplate_list_res") });
            };

            if (nextProps.orderTemplate_refresh_res !== undefined && nextProps.orderTemplate_refresh_res.success === true) {
                toastr.success('Order Template sync process is completed successfully', "Sync Details", {
                    displayDuration: 10000
                });
                this.loadResult();
                nextProps.orderTemplate_refresh_res.success = null;
            };

            if (nextProps.orderTemplate_export_res !== undefined && nextProps.orderTemplate_export_res.success === true) {
                this.setState({
                    isExportLoading: false
                });
                fileDownload(nextProps, "orderTemplate_export_res", "fileName");
            }

            if (nextProps.orderTemplate_upload_res !== undefined) {
                if (nextProps.orderTemplate_upload_res.success === true) {
                    toastr.success('Order Template is uploaded successfully', "Upload Details", {
                        displayDuration: 10000
                    });
                    this.setState({ brand: null, templateName: "" });
                    document.getElementById("myFile").value = "";
                    this.loadResult();
                    nextProps.orderTemplate_upload_res.success = null;
                }
            };

            if (nextProps.orderTemplate_upload_res !== undefined && nextProps.orderTemplate_upload_res.success === false && this.props !== nextProps) {
                nextProps.orderTemplate_upload_res.errors.map((item, index) =>
                    toastr.error(Translate.translate(req, item.code), 'Error', {
                        displayDuration: 5000
                    })
                );
                this.setState({ brand: "" });
                document.getElementById("myFile").value = "";
                nextProps.orderTemplate_upload_res.success = null;
            };

            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            successRender(nextProps, "orderTemplate_delete_res", this.loadResult, this.state.deleteSuccess);
        }
        return true;
    }


    export = () => {
        this.setState({
            isExportLoading: true
        });
        this.props.orderTemplate_export({});
    }


    handleFile = (event) => {
        if (event.target.files && event.target.files.length) {
            if (event.target.files[0].name.includes(".csv")) {
                file.append('file', event.target.files[0]);
                file.append('brand', this.state.brand && this.state.brand.value);
                file.append('name', this.state.templateName);
            } else {
                document.getElementById("myFile").value = "";
                toastr.error('Please Select only Csv file ', 'error', {
                    displayDuration: 5000, width: '400px'
                });
            }
        }
    };

    fileupload = () => {
        // console.log(this.state.brand, this.state.templateName);
        if (!this.state.brand) {
            return toastr.error('Please Select Brand ', 'error', {
                displayDuration: 2000
            });
        } else if (!this.state.templateName) {
            return toastr.error('Please Select Template Name', 'error', {
                displayDuration: 2000
            });
        } else if (document.getElementById("myFile").value === "") {
            return toastr.error('Please Select File ', 'error', {
                displayDuration: 2000
            });
        } else {
            this.props.orderTemplate_upload(file);
        }
    };

    handleChange = (name, e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [name]: e.target.value });
        }
    }

    syncOrderTemplate = () => {
        alert("Please wait for sometimes to complete sync process. Do not touch or close or refresh on browser.");
        this.setState({
            isLoading: true
        });
        this.props.orderTemplate_refresh({});
    };


    render() {
        const { orderTemplate_list_res } = this.props;
        const totResult = orderTemplate_list_res ? orderTemplate_list_res.count : 0;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        Order Template
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">

                                        <div className="row">

                                            <div className="col-sm-12 col-md-6">
                                                {totResult === 0 ? null :
                                                    <Entries
                                                        itemsPerPage={itemsPerPage}
                                                        handleChangeiItemsPerPage={
                                                            this.handleChangeiItemsPerPage
                                                        }
                                                    />
                                                }
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <div className="add-btn-icon mb-4">

                                                    <div onClick={ev => {
                                                        if (window.confirm(`Are you sure you want to sync latest product details with order templates. as result it will reflect the product  price and order template amount?`))
                                                            this.syncOrderTemplate()
                                                    }} className="mr-3 cursor-pointer" title="Sync Latest Product Details">
                                                        <i className={this.state.isLoading ? "fa-spin fa-2x text-primary fa fa-refresh" : "fa-2x text-primary fa fa-refresh"}></i>
                                                    </div>

                                                    {/* <ButtonWithItag onclick={ev => {
                                                        if (window.confirm(`Are you sure you want to sync latest product details with order templates. as result it will reflect the product  price and order template amount?`))
                                                            this.syncOrderTemplate()
                                                    }}
                                                        type="button"
                                                        classNameI={this.state.isLoading ? "fa-spin fa-2x text-primary fa fa-refresh" : "fa-2x text-primary fa fa-refresh"}
                                                        className="btn btn-primary action-btn mr-2"
                                                        title="Sync Latest Product Details" /> */}

                                                    {/* <SearchInput onChangeforDebounce={this.onChangeforDebounce} value={name} /> */}
                                                    <ButtonWithItag classNameI="fa tai-ico-Export" disabled={this.state.isExportLoading} type="button" onclick={this.export} className="btn btn-primary mr-1" data-toggle="tooltip" data-placement="top" title="Export as a file" data-original-title="Click to Upload all" />
                                                    {/* <ButtonWithItag classNameI="ti-filter" type="button" onclick={this.toggleFilter} className="btn btn-primary mr-1" data-toggle="tooltip" data-placement="top" title="Filter" data-original-title="Click for Filter option" /> */}
                                                    <ButtonWithItag classNameI="fa tai-ico-import" type="button" className="btn btn-primary mr-1" datatoggle="modal" datatarget=".bd-example-modal-lg" dataplacement="top" title="Import a file" data-original-title="Click to download all" />
                                                    <LinkWithItag to={routehelp.orderTemplateListAdd} className="btn float-left  btn-primary mr-2" role="button" classNameI="fa fa-plus" title="Add UOM" />
                                                    <CSVLink
                                                        data={csvData}
                                                        filename={"sampleOrderTemplate.csv"}
                                                        className="btn btn-primary"
                                                        target="_blank"
                                                        title="Sample"
                                                    >
                                                        <i className="fa fa-file-excel-o"></i>
                                                    </CSVLink>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" style={{ display: "none" }} aria-hidden="true">
                                            <div className="modal-dialog modal-lg">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="myLargeModalLabel">Order Template Upload</h5>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">×</span>
                                                        </button>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className='row mt-1'>
                                                            <div className="col-md-4 form-group required">
                                                                <SingleBrandDropDown
                                                                    ondropdownChange={(e) => this.ondropdownChange(e, "brand")}
                                                                    placeholder="Search Brand"
                                                                    value={this.state.brand}
                                                                />
                                                            </div>
                                                            <div className='col-md-4'>
                                                                <label for="templateName" className=''>Template Name&nbsp;<sup className='text-danger'>*</sup></label>
                                                                <Inputfield errMessage={this.state.templateNameError}
                                                                    noLabel={true}
                                                                    handleChange={this.handleChange}
                                                                    type="text"
                                                                    name="templateName"
                                                                    maxlength="100"
                                                                    value={this.state.templateName}
                                                                    className="form-control"
                                                                    id="templateName"
                                                                    ariadescribedby="codeHelp"
                                                                    placeholder="Template Name" />
                                                            </div>
                                                        </div>
                                                        <div className='row mt-1'>
                                                            <div className="custom-file mt-2 col">
                                                                <input accept=".csv" onChange={this.handleFile} type="file" id="myFile" name="filename2"></input>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" onClick={this.fileupload} data-dismiss="modal" className="btn btn-primary">Save changes</button>
                                                        <button type="button" className="btn btn-secondary" onClick={this.clearFile} data-dismiss="modal">Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            totResult === undefined ? <p className="text-center">No Data Found</p> :
                                                this.state.isLoading ?
                                                    <Loader />
                                                    :
                                                    orderTemplate_list_res !== undefined && orderTemplate_list_res.count === 0 ?
                                                        <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy}
                                                            sortByvalue={sortBy} sortvalue={sort}
                                                            data={[]} props={this.props} />
                                                        :
                                                        orderTemplate_list_res && orderTemplate_list_res.count > 0 ?
                                                            <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy}
                                                                sortByvalue={sortBy} sortvalue={sort}
                                                                data={orderTemplate_list_res} props={this.props} />
                                                            : <p className="text-center">Something Went Wrong</p>
                                        }

                                        {totResult !== undefined ? orderTemplate_list_res !== undefined && orderTemplate_list_res.count === 0 ? "" :
                                            <Pagination
                                                handleChangeiItemsPerPage={
                                                    this.handleChangeiItemsPerPage
                                                }
                                                handlePage={this.handlePage}
                                                handleNext={this.handleNext}
                                                handlePrevious={this.handlePrevious}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                totResult={totResult}
                                                key={currentPage}
                                            />
                                            : ""}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        orderTemplate_list_res: state.orderTemplate_list_res,
        orderTemplate_delete_res: state.orderTemplate_delete_res,
        orderTemplate_upload_res: state.orderTemplate_upload_res,
        orderTemplate_export_res: state.orderTemplate_export_res,
        orderTemplate_refresh_res: state.orderTemplate_refresh_res,
    };
}

const mapDispatchToProps = dispatch => ({
    orderTemplate_list: data => dispatch(orderTemplate_list(data)),
    orderTemplate_delete: item_id => dispatch(orderTemplate_delete(item_id)),
    orderTemplate_upload: file => dispatch(orderTemplate_upload(file)),
    orderTemplate_export: data => dispatch(orderTemplate_export(data)),
    orderTemplate_refresh: data => dispatch(orderTemplate_refresh(data)),
});

OrderTemplateList = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderTemplateList);

export default OrderTemplateList;