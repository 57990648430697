import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { commissionList, delete_commission, orderSingle, commissionSingle, companyConfigSingle } from '../../actions';
import { errorRender, successRender, commonDebounce, loaderUpdate } from '../../helpers/functions';
import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';
import { Fragment } from 'react';

const Loader = lazy(() => import('../../components/loader'));
const Pagination = lazy(() => import('../../components/pagination/index'));
const DataTable = lazy(() => import('../../components/dataTalble'));
const Entries = lazy(() => import('../../components/entries/entires'));
// const LinkWithItag = lazy(() => import('../../components/linkwithItag'));
// const SearchInput = lazy(() => import('../../components/searchInput'));

let itemsPerPage = ITEM_PER_PAGES;
let currentPage = CURRENT_PAGE;
let sortBy = SORT_BY;
let sort = SORT;
var dateFormat = require('dateformat');

class CommissionList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            limit: 10,
            viewDetailsCount: 0,
            userID: null,
            selectedAgentID: null,
            deleteSuccess: "Commission Has been Deleted Successfully",
            serverErrors: ["commission_delete", "commission_res"],
            viewOrderDetails: {},
            tableHeaders: [
                {
                    name: 'userDetails',
                    label: "User",
                    type: "loop",
                    keyName: "name",
                    isLink: false,
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'userDetails',
                    label: "Sponsor Code",
                    type: "loop",
                    keyName: "code",
                    isLink: false,
                    sortable: false,
                    isShow: true
                },
                // {
                //     name: 'ordersList',
                //     label: "Order Number",
                //     type: "loop",
                //     isLink: true,
                //     function: this.callOrderDetails,
                //     keyName: "companyOrderNumber",
                //     sortable: false,
                //     isShow: true
                // },
                // {
                //     name: 'totalCommission',
                //     label: "Wallet based on order",
                //     type: "text",
                //     sortable: false,
                //     isShow: true
                // },
                // {
                //     name: 'totalPerProductCommission',
                //     label: "Wallet based on per product",
                //     type: "text",
                //     sortable: false,
                //     isShow: true
                // },
                {
                    name: 'createdAt',
                    label: "CreatedAt",
                    type: "date",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'actions',
                    label: "Actions",
                    type: "button",
                    sortable: false,
                    isShow: true,
                    allactions: [
                        {
                            classNameI: "ti-eye",
                            type: "button",
                            className: "btn btn-link text-primary action-btn p-0",
                            datatoggle: "modal",
                            datatarget: ".bd-example-modal-lg",
                            dataplacement: "top",
                            title: "Order Details",
                            dataoriginaltitle: "Click to Order Details",
                            name: "view",
                            function: this.viewDetails
                        },
                        {
                            name: "delete",
                            title: "Delete",
                            classNameI: "ti-trash",
                            className: "btn btn-link text-danger action-btn p-0",
                            function: this.deleteRow
                        }
                    ]
                }
            ],
            commissionObj: {
                "percentage": "%",
                "flat": "Flat",
                "": "NA"
            },
            bothDis: false,
            productDis: false,
            orderDis: false,
            viewLoading: false
        };
    }

    callOrderDetails = (e, order) => {
        // console.log(order);
        this.props.orderSingle(order._id);
    }

    componentDidMount() {
        this.setTableDetails();
        this.props.companyConfigSingle({});
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        this.loadResult();
    }

    invoiceOpen = (url) => {
        if (url) {
            window.open(url, "_blank");
        } else {
            alert("order ID is missing");
        }
    };

    viewDetails = (e, item) => {
        // console.log(item);

        this.setState({
            selectedAgentID: item.user,
            userID: item._id,
            viewLoading: true
        });

        this.props.commissionSingle(item._id, {
            "limit": this.state.limit,
            "sortBy": "createdAt",
            "sort": "desc"
        });

    }

    viewMore() {
        this.setState({
            limit: this.state.limit + 10,
            viewLoading: true
        }, () => {
            this.props.commissionSingle(this.state.userID, {
                "limit": this.state.limit,
                "sortBy": "createdAt",
                "sort": "desc"
            });
        });
    }

    componentWillUnmount() {
        currentPage = CURRENT_PAGE;
        itemsPerPage = ITEM_PER_PAGES;
    }

    loadResult = () => {
        let data = {}
        data.filter = { name: this.state.name ? this.state.name : undefined }
        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        data.skip = (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.commissionList(data);
        this.setState({ isLoading: true });
    }


    resetfilter = () => {
        let data = {}
        data.filter = { name: '', code: '' }
        this.props.commissionList(data);
    }

    sortBy = (key) => {
        sortBy = key;
        if (sort === 'desc') {
            sort = 'asc';
        } else {
            sort = 'desc';
        }
        this.loadResult();
    }

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    }

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    }

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    }

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value })
    };

    deleteRow = (e, item) => {
        if (window.confirm(`Are you sure you wish to delete "${item.name}" ?`)) {
            this.props.delete_commission(item._id);
            e.preventDefault();
        }
    }

    onChangeforDebounce = ({ target: { value } }) => {
        if (!/^\s/.test(value)) {
            this.setState({ name: value }, () => this.callforDebounce(value))
        }
    }

    callforDebounce = () => this.loadResult();

    setTableDetails = () => {
        if (this.state.bothDis && !this.state.orderDis && !this.state.productDis) {
            //check data already present or not 
            let flagArr = this.state.tableHeaders.filter((ele) => {
                if (["totalCommission", "totalPerProductCommission"].indexOf(ele.name) > -1) return ele;
            });
            if (flagArr.length === 0) {
                let newTable = this.state.tableHeaders;
                newTable.splice(2, 0, {
                    name: 'totalCommission',
                    label: "Wallet Amount (Order)",
                    type: "text",
                    sortable: false,
                    isShow: true
                });

                newTable.splice(3, 0, {
                    name: 'totalPerProductCommission',
                    label: "Wallet Amount (Product)",
                    type: "text",
                    sortable: false,
                    isShow: true
                });

                this.setState({
                    tableHeaders: newTable
                });
            }

        } else if (!this.state.bothDis && this.state.orderDis && !this.state.productDis) {
            //check data already present or not 
            let flagArr = this.state.tableHeaders.filter((ele) => {
                if (["totalCommission"].indexOf(ele.name) > -1) return ele;
            });
            if (flagArr.length === 0) {
                let newTable = this.state.tableHeaders;
                newTable.splice(2, 0, {
                    name: 'totalCommission',
                    label: "Wallet Amount",
                    type: "text",
                    sortable: false,
                    isShow: true
                });

                this.setState({
                    tableHeaders: newTable
                });
            }
        } else if (!this.state.bothDis && !this.state.orderDis && this.state.productDis) {
            //check data already present or not 
            let flagArr = this.state.tableHeaders.filter((ele) => {
                if (["totalPerProductCommission"].indexOf(ele.name) > -1) return ele;
            });

            if (flagArr.length === 0) {
                let newTable = this.state.tableHeaders;
                newTable.splice(2, 0, {
                    name: 'totalPerProductCommission',
                    label: "Wallet Amount",
                    type: "text",
                    sortable: false,
                    isShow: true
                });
                this.setState({
                    tableHeaders: newTable
                });
            }
        }
    }

    shouldComponentUpdate(nextProps) {
        // console.log(nextProps);
        if (nextProps !== this.props) {
            // console.log(this.state.isLoading)
            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "commission_res") });
            }

            if (this.state.viewLoading) {
                this.setState({ viewLoading: false });
            };

            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            successRender(nextProps, "commission_delete", this.loadResult, this.state.deleteSuccess);

            // console.log(nextProps.commission_single);

            if (nextProps.commission_single !== undefined && nextProps.commission_single.success === true) {
                this.setState({
                    viewOrderDetails: nextProps.commission_single.item,
                    viewDetailsCount: nextProps.commission_single.count
                });
            }

            // console.log(nextProps.companyConfigSingle_res);
            if (nextProps.companyConfigSingle_res && nextProps.companyConfigSingle_res !== undefined && nextProps.companyConfigSingle_res.success === true) {
                // console.log(nextProps.companyConfigSingle_res.item);
                if (nextProps.companyConfigSingle_res.item) {
                    let webFeature = nextProps.companyConfigSingle_res.item.webFeature;

                    if (webFeature.orderCommission && webFeature.productCommission) {
                        this.setState({
                            bothDis: true,
                            orderDis: false,
                            productDis: false
                        }, () => {
                            this.setTableDetails();
                        });
                    } else if (webFeature.orderCommission) {
                        this.setState({
                            bothDis: false,
                            orderDis: true,
                            productDis: false
                        }, () => {
                            this.setTableDetails();
                        });
                    } else if (webFeature.productCommission) {
                        this.setState({
                            bothDis: false,
                            orderDis: false,
                            productDis: true
                        }, () => {
                            this.setTableDetails();
                        });
                    };

                };
                // nextProps.companyConfigSingle_res.success = null;
            }

        }
        return true;
    }

    render() {
        const { commission_res } = this.props;
        const totResult = commission_res ? commission_res.count : 0;

        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        Commission Management
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">

                                        <div className="row">

                                            <div className="col-sm-12 col-md-6">
                                                {
                                                    totResult === 0 ? null :
                                                        <Entries
                                                            itemsPerPage={itemsPerPage}
                                                            handleChangeiItemsPerPage={
                                                                this.handleChangeiItemsPerPage
                                                            }
                                                        />
                                                }
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <div className="add-btn-icon mb-4">
                                                    {/* <SearchInput onChangeforDebounce={this.onChangeforDebounce} value={name} /> */}
                                                    {/* <LinkWithItag to={routehelp.uomAdd} className="btn float-left  btn-primary mr-2" role="button" classNameI="fa fa-plus" title="Add UOM" /> */}
                                                </div>
                                            </div>

                                        </div>

                                        {
                                            totResult === undefined ? <p className="text-center">No Data Found</p> :
                                                this.state.isLoading ?
                                                    <Loader />
                                                    :
                                                    commission_res !== undefined && commission_res.count === 0 ?
                                                        <p className="text-center">No Data Found</p>
                                                        :
                                                        commission_res && commission_res.count > 0 ?
                                                            <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy}
                                                                sortByvalue={sortBy} sortvalue={sort}
                                                                data={commission_res} props={this.props} />
                                                            : <p className="text-center">Something Went Wrong</p>
                                        }

                                        {totResult !== undefined ? commission_res !== undefined && commission_res.count === 0 ? "" :
                                            <Pagination
                                                handleChangeiItemsPerPage={
                                                    this.handleChangeiItemsPerPage
                                                }
                                                handlePage={this.handlePage}
                                                handleNext={this.handleNext}
                                                handlePrevious={this.handlePrevious}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                totResult={totResult}
                                                key={currentPage}
                                            />
                                            : ""}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="modal fade bd-example-modal-lg"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myLargeModalLabel"
                    style={{ display: "none" }}
                    aria-hidden="true"
                >
                    <div className="modal-dialog commission-modal-lg modal-custom-width">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="myLargeModalLabel">
                                    Orders Details
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className='row'>

                                    <div className="col-sm-4">
                                        <strong>
                                            {this.state.viewOrderDetails && this.state.viewOrderDetails.user && this.state.viewOrderDetails.user.name
                                                ? this.state.viewOrderDetails.user.name : "NA"}
                                        </strong>
                                    </div>

                                    {

                                        this.state.bothDis ?
                                            <Fragment>
                                                <div className="col-sm-4">
                                                    <strong> Wallet Amount (Order): </strong>
                                                    <span className='alert-success'><strong>{this.state.viewOrderDetails.totalCommission || 0}</strong></span>
                                                </div>

                                                <div className="col-sm-4">
                                                    <strong> Wallet Amount (Product): </strong>
                                                    <span className='alert-success'><strong>{this.state.viewOrderDetails.totalPerProductCommission || 0}</strong></span>
                                                </div>
                                            </Fragment> : null
                                    }

                                    {
                                        this.state.orderDis ?
                                            <div className="col-sm-4">
                                                <strong> Wallet Amount: </strong>
                                                <span className='alert-success'><strong>{this.state.viewOrderDetails.totalCommission || 0}</strong></span>
                                            </div>
                                            : null
                                    }

                                    {
                                        this.state.productDis ?
                                            <div className="col-sm-4">
                                                <strong> Wallet Amount: </strong>
                                                <span className='alert-success'><strong>{this.state.viewOrderDetails.totalPerProductCommission || 0}</strong></span>
                                            </div>
                                            : null
                                    }

                                </div>

                                <div className='row mt-2 mb-2'>
                                    <div className='col-md-4'>
                                        Total Order Count : <strong>{this.state.viewDetailsCount || 0} </strong>
                                    </div>
                                    <div className='col-md-4'>
                                        Display Order Count : <strong>{this.state.viewOrderDetails.ordersList && this.state.viewOrderDetails.ordersList.length ? this.state.viewOrderDetails.ordersList.length : 0}</strong>
                                    </div>
                                    <div className='col-md-4 justify-content-end d-flex'>
                                        {
                                            this.state.viewDetailsCount > 0 && this.state.viewDetailsCount !== this.state.viewOrderDetails.ordersList.length ?
                                                <button type="button" className="btn btn-success" onClick={(e) => this.viewMore()}>Load More</button> : null
                                        }
                                    </div>
                                </div>

                                <div className='row mx-0 mt-3'>

                                    <div className='col-12 alert-warning'>
                                        Note : Commission Amount is calculated on <b>quantity * (diamond + labour)</b> price only
                                    </div>



                                    <table className='table table-bordered'>
                                        <thead>
                                            <tr>
                                                <th>Order No</th>
                                                <th>Order Total Amt</th>
                                                <th>Order Total Qty</th>
                                                <th>Order Product Details</th>
                                                <th>Order Created At</th>
                                                <th>Order Created By</th>
                                                {
                                                    this.state.bothDis || this.state.orderDis ?
                                                        <th>Commission (Order)</th> : null
                                                }
                                            </tr>
                                        </thead>

                                        {
                                            this.state.viewLoading ?
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="10" className='text-center'>
                                                            <Loader />
                                                        </td>
                                                    </tr>
                                                </tbody> :
                                                <tbody>
                                                    {
                                                        this.state.viewOrderDetails.ordersList && this.state.viewOrderDetails.ordersList.length > 0 ?
                                                            this.state.viewOrderDetails.ordersList.map((ele, index) =>
                                                                <tr key={index}>

                                                                    <td>{ele.companyOrderNum}</td>
                                                                    <td>{ele.totalAmount}</td>
                                                                    <td>{ele.totalQuantity}</td>
                                                                    <td>

                                                                        {
                                                                            ele.thirdParty ?
                                                                                <div>
                                                                                    ID : <strong> {ele.thirdParty.orderID ? ele.thirdParty.orderID : "NA"}</strong>
                                                                                </div> : null
                                                                        }

                                                                        <div className='table-cell-scroll'>

                                                                            {
                                                                                this.state.bothDis || this.state.productDis ?
                                                                                    <div>
                                                                                        <strong>Commission (Products):</strong>&nbsp;&nbsp;
                                                                                        {
                                                                                            ele && ele.productCommiAmt
                                                                                                ? ele.productCommiAmt : 0
                                                                                        }
                                                                                        <br />
                                                                                    </div> : null
                                                                            }

                                                                            {
                                                                                ele.products && ele.products.length > 0 ?
                                                                                    ele.products.map((p, index2) =>
                                                                                        <div key={index2} className="product-details">
                                                                                            <div className='d-flex  row position-relative' >
                                                                                                <button type='button' className='icon-btn' title='Invoice' onClick={() => this.invoiceOpen(ele.invoiceURL ? ele.invoiceURL : null)}><i className='fa fa-file-o'></i></button>
                                                                                                <div className='col-12 col-md-6 mb-3'>
                                                                                                    <strong>Product Details</strong>

                                                                                                    <div className='row'>
                                                                                                        <div className='col-12'>
                                                                                                            <div className='d-flex'>
                                                                                                                <div className='mr-3 w-120'>
                                                                                                                    <strong>Name</strong>

                                                                                                                </div>
                                                                                                                <div>{p.productName}</div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className='col-12'>
                                                                                                            <div className='d-flex'>
                                                                                                                <div className='mr-3 w-120'>
                                                                                                                    <strong>Price</strong>
                                                                                                                </div>
                                                                                                                <div>{p.productPrice}</div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className='col-12'>
                                                                                                            <div className='d-flex'>
                                                                                                                <div className='mr-3 w-120'>
                                                                                                                    <strong>Qty</strong>
                                                                                                                </div>
                                                                                                                <div>{p.subTotalQuantity || 0}</div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className='col-12'>
                                                                                                            <div className='d-flex'>
                                                                                                                <div className='mr-3 w-120'>
                                                                                                                    <strong>Discount</strong>
                                                                                                                </div>
                                                                                                                <div>{p.discount || 0}</div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className='col-12'>
                                                                                                            <div className='d-flex'>
                                                                                                                <div className='mr-3 w-120'>
                                                                                                                    <strong>Net Amt</strong>
                                                                                                                </div>
                                                                                                                <div>{p.netPrice || 0}</div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='col-12 col-md-6'>
                                                                                                    <strong>Commission Details</strong>
                                                                                                    <div className='row'>
                                                                                                        {
                                                                                                            this.state.bothDis || this.state.productDis ?
                                                                                                                <Fragment>
                                                                                                                    {
                                                                                                                        p.productCommission ?
                                                                                                                            <Fragment>
                                                                                                                                <div className='col-12'>
                                                                                                                                    <div className='d-flex'>
                                                                                                                                        <div className='w-120'>
                                                                                                                                            <strong>Commission Amt on</strong>
                                                                                                                                        </div>
                                                                                                                                        <div>{p.subTotal || 0}</div>
                                                                                                                                    </div>
                                                                                                                                </div>

                                                                                                                                <div className='col-12 '>
                                                                                                                                    <div className='d-flex'>
                                                                                                                                        <div className='w-120'>
                                                                                                                                            <strong>Type</strong>
                                                                                                                                        </div>
                                                                                                                                        <div>{this.state.commissionObj[p.productCommission.commissionType] || "NA"}</div>
                                                                                                                                    </div>
                                                                                                                                </div>

                                                                                                                                <div className='col-12 '>
                                                                                                                                    <div className='d-flex'>
                                                                                                                                        <div className='w-120'>
                                                                                                                                            <strong>Value</strong>
                                                                                                                                        </div>
                                                                                                                                        <div>{p.productCommission.commissionValue || 0}</div>
                                                                                                                                    </div>
                                                                                                                                </div>

                                                                                                                                <div className='col-12'>
                                                                                                                                    <div className='d-flex'>
                                                                                                                                        <div className='w-120'>
                                                                                                                                            <strong>Commission Get</strong>
                                                                                                                                        </div>
                                                                                                                                        <div>{p.productCommission.amount || 0}</div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </Fragment>
                                                                                                                            :
                                                                                                                            <span>No Data Found</span>
                                                                                                                    }
                                                                                                                </Fragment> : null
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='col-12 col-md-4'>
                                                                                                    <div className=''>
                                                                                                        <div className='mr-3 w-120'>
                                                                                                            <strong>Diamond</strong>
                                                                                                        </div>
                                                                                                        <div>{p.diamond || 0}</div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='col-12 col-md-4'>
                                                                                                    <div className=''>
                                                                                                        <div className='mr-3 w-120'>
                                                                                                            <strong>Labour</strong>
                                                                                                        </div>
                                                                                                        <div>{p.labour || 0}</div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='col-12 col-md-4'>
                                                                                                    <div className=''>
                                                                                                        <div className='mr-3 w-120'>
                                                                                                            <strong>Metal</strong>
                                                                                                        </div>
                                                                                                        <div>{p.metal || 0}</div>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    ) :
                                                                                    <div>
                                                                                        <span>
                                                                                            No Data Found
                                                                                        </span>
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td>{ele.createdAt ? dateFormat(new Date(ele.createdAt), 'mmm d, yyyy') : "NA"}</td>
                                                                    <td>{ele.createdBy.name || "NA"}</td>
                                                                    {
                                                                        this.state.bothDis || this.state.orderDis ?
                                                                            <td>
                                                                                {
                                                                                    ele.orderCommission ?
                                                                                        <div className='table-cell-scroll'>
                                                                                            <strong>Amount</strong> {ele.orderCommission.amount || 0}&nbsp;&nbsp;&nbsp;
                                                                                            <strong>Type</strong> {this.state.commissionObj[ele.orderCommission.commissionType] || "NA"}&nbsp;&nbsp;&nbsp;
                                                                                            <strong>Value</strong> {ele.orderCommission.commissionValue || 0}
                                                                                        </div>
                                                                                        :
                                                                                        <span>No Data Found</span>
                                                                                }
                                                                            </td> : null
                                                                    }

                                                                </tr>
                                                            ) : <tr className='text-center'>
                                                                <td colSpan="6"><p>No Data Found</p></td>
                                                            </tr>
                                                    }
                                                </tbody>
                                        }


                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        commission_res: state.commission_res,
        order_single: state.order_single,
        commission_delete: state.commission_delete,
        commission_single: state.commission_single,
        companyConfigSingle_res: state.companyConfigSingle_res
    };
}

const mapDispatchToProps = dispatch => ({
    commissionList: data => dispatch(commissionList(data)),
    orderSingle: data => dispatch(orderSingle(data)),
    commissionSingle: (item_id, data) => dispatch(commissionSingle(item_id, data)),
    delete_commission: item_id => dispatch(delete_commission(item_id)),
    companyConfigSingle: data => dispatch(companyConfigSingle(data))
});

CommissionList = connect(
    mapStateToProps,
    mapDispatchToProps
)(CommissionList);

export default CommissionList;