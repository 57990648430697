import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { expenseCatagoryList, delete_ExpenseCatagory } from '../../actions';
import { routehelp } from '../../Routehelper';
import Pagination from '../../components/pagination/index';
import Entries from '../../components/entries/entires';
import LinkWithItag from '../../components/linkwithItag';
import DataTable from '../../components/dataTalble';
import Loader from '../../components/loader';
import { errorRender, successRender, commonDebounce, redirect, loaderUpdate, isPermission } from '../../helpers/functions';
import SearchInput from '../../components/searchInput';
const CardTitle = lazy(() => import('../../components/cardTitle'));



let itemsPerPage = 50;
let currentPage = 1;
let sortBy = '';
let sort = '';
class AllowanceSubCatagory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            code: '',
            isLoading: false,
            deleteMessage: "Expense Sub Category has been Deleted Successfully",
            serverError: ["expenseCatagoryListData", "delete_ExpenseCatagoryData"],
            tableHeaders: [
                {
                    name: 'name',
                    label: "Sub Category Name",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'code',
                    label: "Code",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'parentId.name',
                    label: "Category",
                    type: "text",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'createdAt',
                    label: "CreatedAt",
                    type: "date",
                    sortable: true,
                    isShow: true
                },
                {
                    name: 'actions',
                    label: "Actions",
                    type: "button",
                    sortable: false,
                    isShow: true,
                    allactions: [{
                        name: "edit",
                        title: "Edit",
                        classNameI: "ti-pencil",
                        className: "btn btn-link text-pramiry action-btn mr-2 p-0",
                        isPermission: "update",
                        function: redirect
                    }, {
                        name: "delete",
                        title: "Delete",
                        classNameI: "ti-trash",
                        className: "btn btn-link text-danger action-btn p-0",
                        isPermission: "delete",
                        function: this.deleteRow
                    }]
                }
            ]
        }
    }


    componentDidMount() {
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        this.loadResult();
    }


    componentWillUnmount() {
        currentPage = 1;
        itemsPerPage = 50;
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    loadResult = () => {
        let data = {}
        data.type = "sub-categories";
        data.filter = { name: this.state.name ? this.state.name : undefined }
        data.sortBy = sortBy === "" ? "createdAt" : sortBy;
        data.sort = sort === "" ? "desc" : sort;
        data.skip = this.state.name ? 0 : (currentPage - 1) * itemsPerPage;
        data.limit = itemsPerPage;
        this.props.expenseCatagoryList(data);
        this.setState({ isLoading: true })
    }

    resetfilter = () => {
        let data = {}
        data.filter = { name: undefined }
        this.props.expenseCatagoryList(data);
    }

    sortBy = (key) => {
        sortBy = key;
        if (sort === 'desc') {
            sort = 'asc';
        } else {
            sort = 'desc';
        }
        this.loadResult();
    }

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    }

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    }

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    }

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }


    deleteRow = (e, item) => {
        if (window.confirm(`Are you sure you wish to delete "${item.name}" ?`)) {
            this.props.delete_ExpenseCatagory(item._id);
            e.preventDefault();
        }
    }



    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "expenseCatagoryListData") });
            }
            this.state.serverError.forEach((el) => errorRender(nextProps, el))
            successRender(nextProps, "delete_ExpenseCatagoryData", this.loadResult, this.state.deleteMessage);
        }
        return true;
    }

    onChangeforDebounce = ({ target: { value } }) => {
        if (!/^\s/.test(value)) {
            this.setState({ name: value }, () => this.callforDebounce(value))
        }
    }
    callforDebounce = () => this.loadResult();

    render() {
        const { expenseCatagoryListData } = this.props;
        const { name } = this.state;
        const totResult = expenseCatagoryListData !== undefined ? expenseCatagoryListData.count : 0;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                <CardTitle title="Expense Sub Category"/>
                                </div>
                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                {totResult === undefined ? "" : <Entries
                                                    itemsPerPage={itemsPerPage}
                                                    handleChangeiItemsPerPage={
                                                        this.handleChangeiItemsPerPage
                                                    }
                                                />}
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="add-btn-icon mb-4">
                                                    <SearchInput onChangeforDebounce={this.onChangeforDebounce} value={name} />
                                                    {isPermission("subCategoryExpense", "create") ? 
                                                        <LinkWithItag to={routehelp.allowanceSubCatagoryAdd} className="btn float-left  btn-primary mr-2" role="button" classNameI="fa fa-plus" title="Add Sub Category" />
                                                    : null}
                                                </div>
                                            </div>
                                        </div>
                                        {totResult === undefined ? <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={""} props={this.props} moduleName={"subCategoryExpense"} /> :
                                            this.state.isLoading ? <Loader /> : expenseCatagoryListData !== undefined && expenseCatagoryListData.count === 0 ? "No Data Found" : expenseCatagoryListData && expenseCatagoryListData.count > 0 ? <div className="row">
                                                <div className="col-sm-12 display-grid">
                                                    <div className="table-responsive">
                                                        <DataTable mappingObj={this.state.isOnlineMapping} headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={expenseCatagoryListData} props={this.props} moduleName={"subCategoryExpense"} />
                                                    </div>
                                                </div>
                                            </div> : "Something Went Wrong"}
                                        {totResult !== undefined ? expenseCatagoryListData !== undefined && expenseCatagoryListData.count === 0 ? "" :
                                            <Pagination
                                                handleChangeiItemsPerPage={
                                                    this.handleChangeiItemsPerPage
                                                }
                                                handlePage={this.handlePage}
                                                handleNext={this.handleNext}
                                                handlePrevious={this.handlePrevious}
                                                currentPage={currentPage}
                                                itemsPerPage={itemsPerPage}
                                                totResult={totResult}
                                                key={currentPage}
                                            />
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        expenseCatagoryListData: state.expenseCatagoryListData,
        delete_ExpenseCatagoryData: state.delete_ExpenseCatagoryData
    };
}


const mapDispatchToProps = dispatch => ({
    expenseCatagoryList: data => dispatch(expenseCatagoryList(data)),
    delete_ExpenseCatagory: item_id => dispatch(delete_ExpenseCatagory(item_id))
});

AllowanceSubCatagory = connect(
    mapStateToProps,
    mapDispatchToProps
)(AllowanceSubCatagory);

export default AllowanceSubCatagory;