import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { routehelp } from '../../Routehelper';
import { user, srAsmUserAssign } from '../../actions';
import Select from 'react-select';
import toastr from 'reactjs-toastr';
import { errorRender, successRedriect, singleArrFunc } from '../../helpers/functions';

const Buttons = lazy(() => import('../../components/buttons'));
const Links = lazy(() => import('../../components/links'));
const SrAsmDropDown = lazy(() => import('../SrASM-UsersAssignment/srAsmDropdwon'));
const CardTitle = lazy(() => import('../../components/cardTitle'));
class SrAsmUsersAssignAdd extends Component {
    state = {
        name: '',
        agent: '',
        userAgent: [],
        userAgent_array: [],
        userAsm: [],
        userAsm_array: [],
        userAsm2: [],
        userAsm2_array: [],
        isLoading: false,
    }

    componentDidMount() {
        let data = {
            type: ["AG", "ASM", "ASM2"]
        };
        data.sortBy = "name";
        data.sort = "asc";
        this.props.user(data);
    }


    ondropdownChange = (e, t) => this.setState({ [e]: t, userAgent_array: [], userAsm_array: [], userAsm2_array: [] });


    validate = () => {
        if (this.state.agent.length === 0) {
            toastr.error('Please Select Senior ASM', 'error');
        }
        return true;
    };

    onsubmit = (e) => {
        if (this.state.agent.length === 0) {
            toastr.error('Please select Sr. Asm For assign', 'error');
        } else if (this.state.userAgent_array.length !== 0 || this.state.userAsm_array.length !== 0 || this.state.userAsm2_array.length !== 0) {
            let data = {
                srAsm: this.state.agent.value,
                agent: singleArrFunc(this.state.userAgent_array, "value"),
                asm: singleArrFunc(this.state.userAsm_array, "value"),
                asm2: singleArrFunc(this.state.userAsm2_array, "value")
            }
            e.preventDefault();
            this.setState({ isLoading: true })
            this.props.srAsmUserAssign(data);
        } else {
            toastr.error('Please select minimum one user from Agent, ASM or ASM2', 'error');
        }
    }

    multiValueSelect = (value, name) => this.setState({ [name]: value })

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }
            errorRender(nextProps, "srasmassigncreate");
            successRedriect(nextProps, "srasmassigncreate", "Sr. ASM", this.props, "SrAsmAssignment", "Added");
            if (nextProps.user_response_search !== undefined && nextProps.user_response_search.success === true) {
                nextProps.user_response_search.list.forEach((item, index) => {
                    if (item.type === "AG") {
                        this.state.userAgent.push({
                            label: item.name,
                            value: item._id
                        })
                    } else if (item.type === "ASM") {
                        this.state.userAsm.push({
                            label: item.name,
                            value: item._id
                        })
                    } else if (item.type === "ASM2") {
                        this.state.userAsm2.push({
                            label: item.name,
                            value: item._id
                        })
                    }
                })
                nextProps.user_response_search.success = null;
            }
        }
        return true;
    }

    render() {
        const { isLoading } = this.state;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                <CardTitle title="Add Sr. Asm Users Assignment"/>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="form-group required col-12 col-lg-4">
                                            <SrAsmDropDown
                                                ondropdownChange={(e) => this.ondropdownChange("agent", e)}
                                                value={this.state.agent}
                                                placeholder="Sr. ASM"
                                            />
                                        </div>
                                        <div className=" form-group  col-12 col-lg-4">
                                            <label className="control-label">Agent</label>
                                            <Select closeMenuOnSelect={false}
                                                classNamePrefix="agent"
                                                instanceId='agent-id'
                                                placeholder='Select Agent' onChange={(e) => this.multiValueSelect(e, "userAgent_array")} options={this.state.userAgent} value={this.state.userAgent_array} isMulti={true} isSearchable={true} />
                                        </div>
                                        <div className=" form-group col-12 col-lg-4">
                                            <label className="control-label">Area Sales Manager</label>
                                            <Select closeMenuOnSelect={false} classNamePrefix="agent_sales_manager" placeholder='Select ASM' onChange={(e) => this.multiValueSelect(e, "userAsm_array")} options={this.state.userAsm} value={this.state.userAsm_array} isMulti={true} isSearchable={true} instanceId='area_agent_sales-id'/>
                                        </div>
                                        <div className=" form-group col-12 col-lg-4">
                                            <label className="control-label">Area Sales Manager 2</label>
                                            <Select closeMenuOnSelect={false} classNamePrefix="agent_sales_manager_2" placeholder='Select ASM 2' onChange={(e) => this.multiValueSelect(e, "userAsm2_array")} options={this.state.userAsm2} value={this.state.userAsm2_array} isMulti={true} isSearchable={true} instanceId='area_agent_sales_2-id' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" disable={isLoading} loading={isLoading} name="Save" />
                            <Links to={routehelp.SrAsmAssignment} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>
                </div>
            </main >
        )
    }
}

function mapStateToProps(state) {
    return {
        user_response_search: state.user_response_search,
        srasmassigncreate: state.srasmassigncreate,
    };
}


const mapDispatchToProps = dispatch => ({
    user: data => dispatch(user(data)),
    srAsmUserAssign: data => dispatch(srAsmUserAssign(data))
});

SrAsmUsersAssignAdd = connect(
    mapStateToProps,
    mapDispatchToProps
)(SrAsmUsersAssignAdd);

export default SrAsmUsersAssignAdd;