import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { product_least_10, brandmanagementlist } from '../../actions';
import { Link } from 'react-router-dom';
import { routehelp } from '../../Routehelper';
import { errorRender, commonDebounce, loaderUpdate } from '../../helpers/functions';
// import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';

const DataTable = lazy(() => import('../../components/dataTalble'));
const CardTitle = lazy(() => import('../../components/cardTitle'));
const Loader = lazy(() => import('../../components/loader'));

// let itemsPerPage = ITEM_PER_PAGES;
// let currentPage = CURRENT_PAGE;
// let sortBy = SORT_BY;
// let sort = SORT;

const now = new Date();

class LeastProducts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            mobile: '',
            usertype: '',
            startDate: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7),
            endDate: new Date(),
            // successRequest: [{ name: "user_delete", msg: 'User has been Deleted Successfully' }, { name: "useractive_update", msg: 'Successfully Done' }],
            serverErrors: ["product_least_10_res", "user_delete", "useractive_update"],
            isLoading: false,
            isFilter: false,
            tableHeaders: [
                {
                    name: 'name',
                    label: "Design Number",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'code',
                    label: "Shade Number",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                // {
                //     name: 'itemCode',
                //     label: "Item Code",
                //     type: "text",
                //     sortable: false,
                //     isShow: true
                // },
                // {
                //     name: 'brand.name',
                //     label: "Brand",
                //     type: "text",
                //     sortable: false,
                //     isShow: true
                // },
                {
                    name: 'price',
                    label: "Ex-mill price",
                    type: "text",
                    sortable: false,
                    isShow: true
                },
                {
                    name: 'sales',
                    label: "No. of Units sold",
                    type: "text",
                    sortable: false,
                    isShow: true
                }
            ]
        }
    }

    componentDidMount() {
        this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
        this.loadResult()
    }

    // componentWillUnmount() {
    //     currentPage = CURRENT_PAGE;
    //     itemsPerPage = ITEM_PER_PAGES;
    // }

    toggleFilter = () => this.setState({ isFilter: !this.state.isFilter })

    loadResult = async () => {
        let data;
        let filterData = JSON.parse(localStorage.getItem('filter-date'));
        if (!filterData) {
            data = {
                filter: {
                    createdAt: {
                        "from": this.state.startDate,
                        "to": this.state.endDate
                    }
                }
            }
        } else {
            data = filterData;
        }
        await this.props.product_least_10(data);
        await this.props.brandmanagementlist({
            "filter": {
                "code": ""
            },
            "sortBy": "createdAt",
            "sort": "desc",
            "skip": 0,
            "limit": 50
        });
        this.setState({ isLoading: true })
    }

    componentWillUnmount() {
        localStorage.removeItem('filter-date');
    }

    // sortBy = (key) => {
    //     sortBy = key;
    //     if (sort === 'desc') {
    //         sort = 'asc';
    //     } else {
    //         sort = 'desc';
    //     }
    //     this.loadResult();
    // }

    // resetfilter = () => {
    //     let data = {}
    //     data.filter = { name: undefined, mobile: undefined, type: undefined }
    //     data.sortBy = sortBy;
    //     data.sort = sort;
    //     data.skip = (currentPage - 1) * itemsPerPage;
    //     data.limit = itemsPerPage;
    //     this.props.product_least_10(data);
    //     this.setState({ isFilter: false, isLoading: true, name: '', mobile: '', usertype: '' })
    // }

    onChange = async (e) => {
        let data;
        let filterData = JSON.parse(localStorage.getItem('filter-date'));
        if (filterData) {
            data = filterData;
        } else {
            data = {
                filter: {
                    createdAt: {
                        "from": this.state.startDate,
                        "to": this.state.endDate
                    }
                }
            }
        }


        data.filter.brand = e.target.value;
        // console.log(data);
        await this.props.product_least_10(data);
        this.setState({
            isLoading: true
        });

    }

    // handleNext = () => {
    //     currentPage = currentPage + 1;
    //     this.loadResult();
    // }

    // handlePrevious = () => {
    //     currentPage = currentPage - 1;
    //     this.loadResult();
    // }

    // handlePage = (page) => {
    //     currentPage = page;
    //     this.loadResult();
    // }

    // handleChangeiItemsPerPage = (items) => {
    //     currentPage = 1;
    //     itemsPerPage = items;
    //     this.loadResult();
    // }

    // shouldComponentUpdate(nextProps) {
    //     if (nextProps !== this.props) {
    //         if (this.state.isLoading) {
    //             this.setState({ isLoading: loaderUpdate(nextProps, "product_least_10_res") });
    //         }
    //         this.state.serverErrors.forEach((el) => errorRender(nextProps, el, el === "useractive_update" ? this.loadResult : undefined));
    //         this.state.successRequest.forEach((el) => successRender(nextProps, el.name, this.loadResult, el.msg));
    //     }
    //     return true;
    // }

    callforDebounce = () => this.loadResult();

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: loaderUpdate(nextProps, "product_least_10_res") });
            }
            this.state.serverErrors.forEach((el) => errorRender(nextProps, el, el === "product_least_10_update" ? this.loadResult : undefined));
            // this.state.successRequest.forEach((el) => successRender(nextProps, el.name, this.loadResult, el.msg));
        }
        return true;
    }

    render() {
        const { product_least_10_res, brandmanagement_res } = this.props;
        // const { name } = this.state;
        const totResult = product_least_10_res !== undefined ? product_least_10_res.count : 0;
        // console.log(product_least_10_res);
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">

                                <div className="mr-3 mt-2 text-right">
                                    <Link to={routehelp.maindashboard} className="btn btn-primary">Back</Link>
                                </div>

                                <div className="card-header">
                                    <div className="card-title">
                                        Least 10 Products
                                    </div>
                                </div>

                                <div className="mt-4">
                                    {
                                        brandmanagement_res && brandmanagement_res.list && brandmanagement_res.list.length > 0 ?
                                            <div className="col-sm-3 ml-auto">
                                                <select className="form-control" name="brands"
                                                    onChange={(e) => this.onChange(e)}>
                                                    <option value="">Select Brand</option>
                                                    {
                                                        brandmanagement_res.list.map(element =>
                                                            // console.log(element.name)
                                                            element.name ? <option value={element._id} key={element._id}>{element.name}</option> : "NA"
                                                        )
                                                    }
                                                </select>
                                            </div>
                                            : null
                                    }
                                </div>

                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">
                                        <div className="row">
                                            <div className="col-sm-12">

                                                <div className="alert alert-info font-weight-bold">
                                                    Note: For Brand D & J , Freefilt and Elitus the unit is "Taka", and For Vercelli the unit is "Meters".
                                                </div>

                                                <div className="table-responsive">
                                                    {
                                                        totResult === undefined ?
                                                            <p className="text-center">No Data Found</p> :
                                                            this.state.isLoading ? <Loader /> :
                                                                product_least_10_res !== undefined &&
                                                                    product_least_10_res.list.length === 0 ?
                                                                    <p className="text-center">No Data Found</p> :
                                                                    product_least_10_res && product_least_10_res.list && product_least_10_res.list.length > 0 ?
                                                                        <DataTable headers={this.state.tableHeaders}
                                                                            data={product_least_10_res} />
                                                                        :
                                                                        <p className="text-center">
                                                                            Something Went Wrong
                                                                        </p>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        product_least_10_res: state.product_least_10_res,
        brandmanagement_res: state.brandmanagement_res,
    };
}

const mapDispatchToProps = dispatch => ({
    product_least_10: data => dispatch(product_least_10(data)),
    brandmanagementlist: data => dispatch(brandmanagementlist(data))
});

LeastProducts = connect(
    mapStateToProps,
    mapDispatchToProps
)(LeastProducts);

export default LeastProducts;