import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { routehelp } from '../Routehelper';
import { logout } from '../actions';
import toastr from 'reactjs-toastr';
import 'reactjs-toastr/lib/toast.css';

class Logout extends Component {
    componentDidMount() {
        let data = {};
        this.props.logout(data);
    }
    render() {
        let { logout_res } = this.props;
        if (logout_res !== undefined) {
            if (logout_res.success === true) {
                localStorage.clear();
                logout_res.success = null;
                toastr.success('Successfully logged out', 'success');
                return <Redirect to={routehelp.login} />;
            }
        }
        return (
            <div >
                Please Wait...
            </div>
        )

    }
}

function mapStateToProps(state) {
    return { logout_res: state.logout_res };
}


const mapDispatchToProps = dispatch => ({
    logout: data => dispatch(logout(data))
});

Logout = connect(
    mapStateToProps,
    mapDispatchToProps
)(Logout);

export default Logout;