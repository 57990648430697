import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

class Pagination extends Component {
  // state = {
  //   options: [
  //     {
  //       name: '5',
  //       value: 5
  //     },
  //     {
  //       name: '10',
  //       value: 10
  //     },
  //     {
  //       name: '25',
  //       value: 25
  //     },
  //     {
  //       name: '50',
  //       value: 50
  //     },
  //     {
  //       name: '100',
  //       value: 100
  //     }
  //   ],
  //   value: this.props.itemsPerPage
  // };

  // handleChange = event => {
  //   this.setState({ value: event.target.value });

  //   this.props.handleChangeiItemsPerPage(event.target.value);
  // };
  render() {
    let { currentPage, totResult, itemsPerPage } = this.props;
    // const { options, value } = this.state;
    // let pages = Math.floor(totResult / itemsPerPage);
    let totalPages = Math.ceil(totResult / itemsPerPage);
    let pageArr = [];
    let startPage, endPage;
    let offset = (currentPage - 1) * itemsPerPage + 1;
    // console.log(offset, itemsPerPage, currentPage);
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    for (var i = startPage; i <= endPage; i++) {
      pageArr.push(i);
    }
    return (
      <div className="row align-items-center">
        {/* <div className="col-sm-12 col-md-5">
          <div className="dataTables_info dataTables_length" id="zero_config_info" role="status" aria-live="polite">
            <label className="mb-0">
              Show
              <select onChange={this.handleChange} value={value} name="zero_config_length" aria-controls="zero_config" className="form-control form-control-sm mx-1" >
                {options.map(item => (<option key={item.value} value={item.value}>
                  {item.name}</option>))}
              </select>
              entries
            </label>
          </div>
        </div> */}
        {totResult !== undefined && totResult !== 0 ?
          <div className="col-sm-12 col-md-5">
            <div
              className="dataTables_info"
              id="zero_config_info"
              role="status"
              aria-live="polite"
            >
              Showing <strong>{offset}</strong> to{' '}
              <strong>  {currentPage * itemsPerPage > totResult
                ? totResult
                : currentPage * itemsPerPage} </strong >{' '}
              of <strong>{totResult}</strong> entries
            </div>
          </div> : ""}

        <div className="col-sm-12 col-md-7">
          <div
            className="dataTables_paginate paging_simple_numbers float-right"
            id="zero_config_paginate"
          >
            <ul className="pagination m-0">
              {currentPage > 1 ? (
                <li
                  className="paginate_button page-item previous"
                  id="zero_config_previous"
                >
                  <Link
                    to="#"
                    // href="true"
                    aria-controls="zero_config"
                    data-dt-idx="0"
                    tabIndex="0"
                    className="page-link"
                    onClick={this.props.handlePrevious}
                  >
                    Previous
                  </Link>
                </li>
              ) : (
                ''
              )}
              {pageArr !== undefined
                ? pageArr.map((page, index) =>
                  page === currentPage ? (
                    <li key={index} className="paginate_button page-item active">
                      <Link
                        to="#"
                        // href="javascript:void(0)"
                        aria-controls="zero_config"
                        data-dt-idx="{page}"
                        data-id={index}
                        tabIndex="0"
                        className="page-link "
                        onClick={page === currentPage ? null : () => this.props.handlePage(page)}
                      >
                        {page}
                      </Link>
                    </li>
                  ) : (
                    <li key={index} className="paginate_button page-item ">
                      <Link
                        to="#"
                        // href="javascript:void(0)"
                        aria-controls="zero_config"
                        data-dt-idx="{page}"
                        tabIndex="0"
                        className="page-link "
                        onClick={() => this.props.handlePage(page)}
                      >
                        {page}
                      </Link>
                    </li>
                  )
                )
                : ''}
              {currentPage < totalPages ? (
                <li
                  className="paginate_button page-item next"
                  id="zero_config_next"
                >
                  <Link
                    to="#"
                    // href="javascript:void(0)"
                    aria-controls="zero_config"
                    data-dt-idx="7"
                    tabIndex="0"
                    className="page-link"
                    onClick={this.props.handleNext}
                  >
                    Next
                  </Link>
                </li>
              ) : (
                ''
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Pagination;
