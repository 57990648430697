import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { routehelp } from '../../Routehelper';
import { userHierarchyCreate, userHierarchySingle, userHierarchyUpdate, agent_search } from '../../actions';
import { errorRender, validate, successRedriect } from '../../helpers/functions';
import Select from 'react-select';
import ErrorView from '../../components/errorView';

const Links = lazy(() => import('../../components/links'));
const Buttons = lazy(() => import('../../components/buttons'));
const Loader = lazy(() => import('../../components/loader'));

class UserHierarchyForm extends Component {
    state = {
        pageType: 'add',
        itemId: '',
        setFields: [
            { name: "parent", type: "select" },
            { name: "child", type: "select" },
        ],
        serverErrors: ["userHierarchyCreate_res", "userHierarchyUpdate_res", "userHierarchySingle_res"],
        forRedirect: [
            { name: "userHierarchyCreate_res", pageName: 'User Hierarchy', pageUrl: 'userHierarchyList', action: "Added" },
            { name: "userHierarchyUpdate_res", pageName: 'User Hierarchy', pageUrl: 'userHierarchyList', action: "Updated" }
        ],
        isLoading: false,
        getSingleLoader: false,
        parent: "",
        child: "",
        childError: "",
        childOptions: "",
        parentOptions: "",
        parentError: "",
        lastRemoveDetails: ""
    }

    componentDidMount() {
        let id = this.props.match.params.item_id;
        if (id) {
            this.setState({ pageType: "update", itemId: id, getSingleLoader: true })
            this.props.userHierarchySingle(id);
        }

        this.props.agent_search({
            type: ["AG", "ASM", "ASM2", "WHS", "RTL", "SASM"],
            sort: "asc",
            sortBy: "name"
        });

    }

    componentWillUnmount() {
        this.setState({});
    }

    onsubmit = (e) => {

        let validationFields = [
            { name: 'parent', label: "Parent", value: this.state.parent, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "parentError" },
            { name: 'child', label: "child", value: this.state.child, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "childError" }
        ];
        e.preventDefault();
        let { newArr, valid } = validate(validationFields);
        let data = {
            parent: {
                _id: this.state.parent.value,
                name: this.state.parent.label,
                type: this.state.parent.type,
                code: this.state.parent.code
            },
            child: []
        }
        if (this.state.child && this.state.child.length > 0) {
            data.child = this.state.child.map((ele) => {
                return {
                    _id: ele.value,
                    name: ele.label,
                    type: ele.type,
                    code: ele.code
                }
            })
        }
        if (valid) {
            this.setState({ isLoading: true });
            if (this.state.pageType === "update") {
                this.props.userHierarchyUpdate(data, this.state.itemId);
            } else {
                this.props.userHierarchyCreate(data);
            }
            validationFields = null;
            newArr = null;
        } else {
            newArr.forEach((el) => this.setState({ [el.errName]: el.message }))
        }

    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {

            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }

            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));

            //user list res
            if (nextProps.agent_response_search !== undefined && nextProps.agent_response_search.success === true) {
                let parentList = [];
                let childList = [];
                if (nextProps.agent_response_search.list && nextProps.agent_response_search.list.length > 0) {
                    parentList = nextProps.agent_response_search.list.filter((ele) => ele.type === "ASM") || [];
                    childList = nextProps.agent_response_search.list.filter((ele) => ele.type === "AG") || [];
                    parentList = parentList.map((ele) => {
                        // console.log(ele)
                        return {
                            label: ele.name,
                            value: ele._id,
                            type: ele.type,
                            code: ele.code
                        }
                    });
                    childList = childList.map((ele) => {
                        return {
                            label: ele.name,
                            value: ele._id,
                            type: ele.type,
                            code: ele.code
                        }
                    });
                }

                this.setState({
                    parentOptions: parentList,
                    childOptions: childList
                }, () => {
                    this.removeParentFromChildOption();
                });


            }

            //updated data get res
            if (nextProps.userHierarchySingle_res && nextProps.userHierarchySingle_res.success) {
                this.setState({ getSingleLoader: false });
                this.state.setFields.forEach((value) => {
                    if (value.type === "select") {
                        if (value.name === "parent") {
                            let parentSelected = {};
                            parentSelected = {
                                label: nextProps.userHierarchySingle_res.item.parent.name || "",
                                value: nextProps.userHierarchySingle_res.item.parent._id || "",
                                _id: nextProps.userHierarchySingle_res.item.parent._id || "",
                                type: nextProps.userHierarchySingle_res.item.parent.type || "",
                                code: nextProps.userHierarchySingle_res.item.parent.code || ""
                            };
                            this.setState({
                                parent: parentSelected
                            });
                        }
                        if (value.name === "child") {
                            let childSelected = [];
                            if (nextProps.userHierarchySingle_res.item.child && nextProps.userHierarchySingle_res.item.child.length > 0) {
                                childSelected = nextProps.userHierarchySingle_res.item.child.map((ele) => {
                                    return {
                                        label: ele.name,
                                        value: ele._id,
                                        _id: ele._id,
                                        type: ele.type,
                                        code: ele.code
                                    }
                                })
                            }
                            this.setState({
                                child: childSelected
                            }, () => {
                                this.removeParentFromChildOption();
                            });
                        }
                    }
                });
                nextProps.userHierarchySingle_res.success = null;
            }
        }
        return true;
    }

    ondropdownChangeagent = (e, t) => {
        this.setState({ [e]: t }, () => {
            if (e === "parent") {
                this.removeParentFromChildOption()
            }
        });
    }

    removeParentFromChildOption = () => {
        if (this.state.parent && this.state.parent.value) {

            let newChilds = this.state.childOptions || [];

            // console.log(this.state.parent);
            // console.log(newChilds);

            newChilds.forEach((ele, index) => {
                if (ele && ele.value === this.state.parent.value) {
                    this.setState({
                        lastRemoveDetails: this.state.parent
                    })
                    newChilds.splice(index, 1);
                }
            });

            // console.log(newChilds.filter((ele) => ele.value !== this.state.lastRemoveDetails.value).length !== 0)
            if (newChilds.filter((ele) => ele.value !== this.state.lastRemoveDetails.value).length !== 0) {
                newChilds.push(this.state.lastRemoveDetails);
            }

            this.setState({
                childOptions: newChilds
            });

        }
    }

    render() {
        const { pageType, isLoading, getSingleLoader } = this.state;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        {pageType === "update" ? "Update User Hierarchy" : "Add User Hierarchy"}
                                    </div>
                                </div>
                                {
                                    getSingleLoader ? <Loader /> :
                                        <div className="card-body">
                                            <div className="row">

                                                {/* {console.log(this.state.parentOptions.length)} */}

                                                <div className="form-group required col-12 col-lg-6">
                                                    <label foe="parent">Parent</label>
                                                    <Select
                                                        name={"parent"}
                                                        value={this.state.parent}
                                                        onChange={value => {
                                                            this.ondropdownChangeagent("parent", value);
                                                        }}
                                                        classNamePrefix={"parent"}
                                                        options={this.state.parentOptions || []}
                                                        placeholder={"parent"}
                                                        isMulti={false}
                                                        instanceId={'parent-id'}
                                                    />
                                                    <ErrorView message={this.state.parentError} />
                                                </div>

                                                {/* {console.log(this.state.childOptions.length)} */}

                                                <div className="form-group required col-12 col-lg-6">
                                                    <label for="child">Child</label>
                                                    <Select
                                                        name={"child"}
                                                        value={this.state.child}
                                                        onChange={value => {
                                                            this.ondropdownChangeagent("child", value);
                                                        }}
                                                        classNamePrefix={"child"}
                                                        options={this.state.childOptions || []}
                                                        placeholder={"Child"}
                                                        isMulti={true}
                                                        instanceId={'child-id'}
                                                    />
                                                    <ErrorView message={this.state.childError} />
                                                </div>

                                                <div className="form-group col-12 col-lg-4">
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" disable={isLoading || getSingleLoader} loading={isLoading} name={pageType === "update" ? "Update" : "Save"} />
                            <Links to={routehelp.userHierarchyList} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>

                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        agent_response_search: state.agent_response_search,
        userHierarchyCreate_res: state.userHierarchyCreate_res,
        userHierarchySingle_res: state.userHierarchySingle_res,
        userHierarchyUpdate_res: state.userHierarchyUpdate_res
    };
}


const mapDispatchToProps = dispatch => ({
    agent_search: data => dispatch(agent_search(data)),
    userHierarchySingle: data => dispatch(userHierarchySingle(data)),
    userHierarchyCreate: data => dispatch(userHierarchyCreate(data)),
    userHierarchyUpdate: (data, id) => dispatch(userHierarchyUpdate(data, id))
});

UserHierarchyForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserHierarchyForm);

export default UserHierarchyForm;