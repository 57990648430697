import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { companylist } from '../../actions';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { routehelp } from '../../Routehelper';
import LinkWithItag from '../../components/linkwithItag';
import CompanyCard from '../../components/companycard';

require('react-datetime');
var dateFormat = require('dateformat');
class Primarydashboard extends Component {

    componentDidMount() {
        let data = {
            limit: 100000,
            sortBy: 'createdAt',
            sort: 'desc'
        };
        this.props.companylist(data);
    }

    render() {

        let { companylist_res } = this.props;
        let type = localStorage.getItem('type');

        if (type === "SA") {
            return (
                <div className="app header-fixed left-sidebar-fixed scrollbarcustom left-sidebar-hidden">
                    <Header />
                    <div className="app-body">
                        <main className="main-content">
                            <div className="container-fluid max-width-100">
                                <div className='mt-4 text-right'>
                                    <LinkWithItag to={routehelp.companyAdd} className="btn btn-primary" role="button" classNameI="ti-plus pr-2" name="Add New" />
                                </div>
                                <div className="row">
                                    {
                                        companylist_res !== undefined && companylist_res.list && companylist_res.list.map((item, index) =>
                                            <CompanyCard key={index} name={item.name} email={item.email} phone={item.phone} id={item._id} logo={item && item.logo && item.logo.medium} createdAt={dateFormat(new Date(item.createdAt), 'mmm d, yyyy')} />
                                        )
                                    }
                                </div>
                            </div>
                        </main>
                    </div>
                    <div className='no-margin-footer'>
                        <Footer />
                    </div>
                </div>
            )
        } else {
            return <Redirect to={routehelp.logout} />
        }

    }
}

function mapStateToProps(state) {
    return { companylist_res: state.companylist_res };
}

const mapDispatchToProps = dispatch => ({
    companylist: data => dispatch(companylist(data))
});

Primarydashboard = connect(
    mapStateToProps,
    mapDispatchToProps
)(Primarydashboard);

export default Primarydashboard;