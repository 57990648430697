import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import Inputfield from '../../components/inputfields';
import Buttons from '../../components/buttons';
import Links from '../../components/links';
import { routehelp } from '../../Routehelper';
import { errorRender, successRedriect, validate, inputCheck } from '../../helpers/functions';
import { discountCreate, discountSingle, discountUpdate } from '../../actions';
import moment from 'moment';
import 'reactjs-toastr/lib/toast.css';

const CardTitle = lazy(() => import('../../components/cardTitle'));
const SingleBrandDropDown = lazy(() => import('../brandsManagement/singleBrandDropDown'));
const ProductDropdown = lazy(() => import('../productManagement/productDropdown'));

class DiscountMasterAdd extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pageType: "add",
            name: "",
            nameError: "",
            description: "",
            descriptionError: "",
            incDicountType: "",
            incDicountTypeError: "",
            incAmount: "",
            incAmountError: "",
            incUnit: "",
            incUnitError: "",
            incAmtOperation: "",
            incAmtOperationError: "",
            incValue: "",
            incValueError: "",
            decDicountType: "",
            decDicountTypeError: "",
            decAmount: "",
            decAmountError: "",
            decUnit: "",
            decUnitError: "",
            decAmtOperation: "",
            decAmtOperationError: "",
            decValue: "",
            decValueError: "",
            maxDiscount: "",
            maxDiscountError: "",
            brands: "",
            brandsError: "",
            productsError: "",
            products: "",
            startDateError: "",
            startDate: "",
            endDate: "",
            endDateError: "",
            serverErrors: ["discountCreateRes", "discountUpdateRes ", "discount_singleRes"],
            forRedirect: [
                { name: "discountCreateRes", pageName: 'Discount', pageUrl: 'discountMaster', action: "Added" },
                { name: "discountUpdateRes", pageName: 'Discount', pageUrl: 'discountMaster', action: "Updated" }
            ],
            setFields: [
                { name: "Name", stateName: "name", type: "text" },
                { name: "Description", stateName: "description", type: "text" },

                { name: "Brand", stateName: "brands", type: "select" },
                { name: "Product", stateName: "products", type: "select" },

                { name: "incDicountType", stateName: "incDicountType", type: "select" },
                { name: "incAmount", stateName: "incAmount", type: "text" },
                { name: "incUnit", stateName: "incUnit", type: "select" },
                { name: "incAmtOperation", stateName: "incAmtOperation", type: "text" },
                { name: "incValue", stateName: "incValue", type: "text" },

                { name: "decDicountType", stateName: "decDicountType", type: "select" },
                { name: "decAmount", stateName: "decAmount", type: "text" },
                { name: "decUnit", stateName: "decUnit", type: "select" },
                { name: "decAmtOperation", stateName: "decAmtOperation", type: "text" },
                { name: "decValue", stateName: "decValue", type: "text" },

                { name: "maxDiscount", stateName: "maxDiscount", type: "text" },

                { name: "startDate", stateName: "startDate", type: "text" },
                { name: "endDate", stateName: "endDate", type: "text" }

            ],
            isLoading: false,
            getSingleLoader: false,
            productFilter: {}
        }
    }

    componentDidMount() {
        let id = this.props.match.params.item_id;
        if (id) {
            this.setState({ pageType: "update", itemId: id, getSingleLoader: true });
            this.props.discountSingle(id);
        }
    }

    ondropdownChange = (e, t) => {
        this.setState({ [t]: e });
        // if (t === "brands") {
        //     this.setState({
        //         productFilter: {
        //             brand: e.value
        //         },
        //         products: []
        //     });
        // }
    }

    handleChange = (name, e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [name]: e.target.value });
        }
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onsubmit = (e) => {

        let validationFields = [
            { name: 'name', label: "Title", value: this.state.name, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 50, type: "text", message: "", errName: "nameError" },
            { name: 'description', label: "Description", value: this.state.description, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 50, type: "text", message: "", errName: "descriptionError" },
            { name: 'brands', label: "Brand", value: this.state.brands, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "brandsError" },
            // { name: 'products', label: "Product", value: this.state.products, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "productsError" },
            { name: 'incDicountType', label: "Discount Type", value: this.state.incDicountType, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "incDicountTypeError" },
            { name: 'decDicountType', label: "Discount Type", value: this.state.decDicountType, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "decDicountTypeError" },
            { name: 'incAmount', label: "Amount", value: this.state.incAmount, hasError: false, isRequired: true, isNumber: true, onlyChar: false, type: "text", message: "", errName: "incAmountError" },
            { name: 'decAmount', label: "Amount", value: this.state.decAmount, hasError: false, isRequired: true, isNumber: true, onlyChar: false, type: "text", message: "", errName: "decAmountError" },
            { name: 'incUnit', label: "On Field", value: this.state.incUnit, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "incUnitError" },
            { name: 'decUnit', label: "On Field", value: this.state.decUnit, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "decUnitError" },
            { name: 'incAmtOperation', label: "Amount Operations", value: this.state.incAmtOperation, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "incAmtOperationError" },
            { name: 'decAmtOperation', label: "Amount Operations", value: this.state.decAmtOperation, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "decAmtOperationError" },
            { name: 'incValue', label: "Value", value: this.state.incValue, hasError: false, isRequired: true, isNumber: true, onlyChar: false, type: "text", message: "", errName: "incValueError" },
            { name: 'decValue', label: "Value", value: this.state.decValue, hasError: false, isRequired: true, isNumber: true, onlyChar: false, type: "text", message: "", errName: "decValueError" },
            { name: 'maxDiscount', label: "Maximum Discount", value: this.state.maxDiscount, hasError: false, isRequired: true, isNumber: true, onlyChar: false, type: "text", message: "", errName: "maxDiscountError" },
            { name: 'startDate', label: "Start Date", value: this.state.startDate, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "text", message: "", errName: "startDateError" },
            { name: 'endDate', label: "End Date", value: this.state.endDate, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "text", message: "", errName: "endDateError" }
        ];

        e.preventDefault();

        let { newArr, valid } = validate(validationFields);

        newArr.map((el) => this.setState({ [el.errName]: el.message }));

        // let products = [];

        // if (this.state.products && this.state.products.length > 0) {
        //     products = this.state.products.map((ele) => {
        //         return ele.value
        //     });
        // }

        let data = {
            name: this.state.name,
            description: this.state.description,
            incrDiscount: { unit: this.state.incDicountType, amount: this.state.incAmount },
            incrDiscCriteria: [{ unit: this.state.incUnit, amtOperation: this.state.incAmtOperation, value: this.state.incValue }],
            decrDiscount: { unit: this.state.decDicountType, amount: this.state.decAmount },
            decrDiscCriteria: [{ unit: this.state.decUnit, amtOperation: this.state.decAmtOperation, value: this.state.decValue }],
            // categories: this.state.categories,
            brands: this.state.brands.value,
            // products: products,
            // productDisplayList: this.state.products,
            brandDisplayList: this.state.brands,
            maxDiscount: this.state.maxDiscount,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            isActive: true
        }

        e.preventDefault();

        if (valid) {
            if (this.state.pageType === "update") {
                this.props.discountUpdate(data, this.state.itemId);
            } else {
                this.props.discountCreate(data);
            }
        }
    }

    shouldComponentUpdate = (nextProps) => {

        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }
            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));

            if (nextProps.discount_singleRes && nextProps.discount_singleRes.success) {
                nextProps.discount_singleRes.success = null;
                this.setState({ getSingleLoader: false });
                if (nextProps.discount_singleRes.item) {
                    // console.log(nextProps.discount_singleRes.item);
                    this.setState({
                        name:
                            nextProps.discount_singleRes !== undefined && nextProps.discount_singleRes.item && nextProps.discount_singleRes.item.name
                                ? nextProps.discount_singleRes.item.name
                                : '',
                        description:
                            nextProps.discount_singleRes !== undefined && nextProps.discount_singleRes.item && nextProps.discount_singleRes.item.description
                                ? nextProps.discount_singleRes.item.description
                                : '',
                        incDicountType:
                            nextProps.discount_singleRes !== undefined && nextProps.discount_singleRes.item && nextProps.discount_singleRes.item.incrDiscount && nextProps.discount_singleRes.item.incrDiscount.unit
                                ? nextProps.discount_singleRes.item.incrDiscount.unit
                                : '',
                        incAmount:
                            nextProps.discount_singleRes !== undefined && nextProps.discount_singleRes.item && nextProps.discount_singleRes.item.incrDiscount && nextProps.discount_singleRes.item.incrDiscount.amount
                                ? nextProps.discount_singleRes.item.incrDiscount.amount
                                : '',
                        incUnit:
                            nextProps.discount_singleRes !== undefined && nextProps.discount_singleRes.item && nextProps.discount_singleRes.item.incrDiscCriteria && nextProps.discount_singleRes.item.incrDiscCriteria[0].unit
                                ? nextProps.discount_singleRes.item.incrDiscCriteria[0].unit
                                : '',
                        incAmtOperation:
                            nextProps.discount_singleRes !== undefined && nextProps.discount_singleRes.item && nextProps.discount_singleRes.item.incrDiscCriteria && nextProps.discount_singleRes.item.incrDiscCriteria[0].amtOperation
                                ? nextProps.discount_singleRes.item.incrDiscCriteria[0].amtOperation
                                : '',
                        incValue:
                            nextProps.discount_singleRes !== undefined && nextProps.discount_singleRes.item && nextProps.discount_singleRes.item.incrDiscCriteria && nextProps.discount_singleRes.item.incrDiscCriteria[0].value
                                ? nextProps.discount_singleRes.item.incrDiscCriteria[0].value
                                : '',
                        decDicountType:
                            nextProps.discount_singleRes !== undefined && nextProps.discount_singleRes.item && nextProps.discount_singleRes.item.decrDiscount && nextProps.discount_singleRes.item.decrDiscount.unit
                                ? nextProps.discount_singleRes.item.decrDiscount.unit
                                : '',
                        decAmount:
                            nextProps.discount_singleRes !== undefined && nextProps.discount_singleRes.item && nextProps.discount_singleRes.item.decrDiscount && nextProps.discount_singleRes.item.decrDiscount.amount
                                ? nextProps.discount_singleRes.item.decrDiscount.amount
                                : '',
                        decUnit:
                            nextProps.discount_singleRes !== undefined && nextProps.discount_singleRes.item && nextProps.discount_singleRes.item.decrDiscCriteria && nextProps.discount_singleRes.item.decrDiscCriteria[0].unit
                                ? nextProps.discount_singleRes.item.decrDiscCriteria[0].unit
                                : '',
                        decAmtOperation:
                            nextProps.discount_singleRes !== undefined && nextProps.discount_singleRes.item && nextProps.discount_singleRes.item.decrDiscCriteria && nextProps.discount_singleRes.item.decrDiscCriteria[0].amtOperation
                                ? nextProps.discount_singleRes.item.decrDiscCriteria[0].amtOperation
                                : '',
                        decValue:
                            nextProps.discount_singleRes !== undefined && nextProps.discount_singleRes.item && nextProps.discount_singleRes.item.decrDiscCriteria && nextProps.discount_singleRes.item.decrDiscCriteria[0].value
                                ? nextProps.discount_singleRes.item.decrDiscCriteria[0].value
                                : '',
                        maxDiscount:
                            nextProps.discount_singleRes !== undefined && nextProps.discount_singleRes.item && nextProps.discount_singleRes.item.decrDiscount && nextProps.discount_singleRes.item.maxDiscount
                                ? nextProps.discount_singleRes.item.maxDiscount
                                : '',
                        startDate:
                            nextProps.discount_singleRes !== undefined && nextProps.discount_singleRes.item && nextProps.discount_singleRes.item && nextProps.discount_singleRes.item.startDate
                                ? moment(nextProps.discount_singleRes.item.startDate).format("YYYY-MM-DD")
                                : '',
                        endDate:
                            nextProps.discount_singleRes !== undefined && nextProps.discount_singleRes.item && nextProps.discount_singleRes.item && nextProps.discount_singleRes.item.endDate
                                ? moment(nextProps.discount_singleRes.item.endDate).format("YYYY-MM-DD")
                                : '',
                        brands:
                            nextProps.discount_singleRes !== undefined && nextProps.discount_singleRes.item && nextProps.discount_singleRes.item.brandDisplayList
                                ? nextProps.discount_singleRes.item.brandDisplayList
                                : [],
                        products:
                            nextProps.discount_singleRes !== undefined && nextProps.discount_singleRes.item && nextProps.discount_singleRes.item.productDisplayList
                                ? nextProps.discount_singleRes.item.productDisplayList
                                : []
                    }, () => {
                        this.setState({
                            productFilter: {
                                brand: nextProps.discount_singleRes.item.brands[0]
                            }
                        });
                    });
                }
            }

        }
        return true;
    }

    render() {
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                <CardTitle title="Add Discount"/>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="form-group required col-12 col-lg-6">
                                            <Inputfield errMessage={this.state.nameError} handleChange={this.handleChange} type="text" name="name" maxlength="20" value={this.state.name} className="form-control" id="name" ariadescribedby="codeHelp" placeholder="Title" />
                                        </div>

                                        <div className="form-group required col-12 col-lg-6">
                                            <label htmlFor="exampleInputcode" className="control-label">Description</label>
                                            <textarea onChange={this.onChange} maxLength="10" type="text" name="description" value={this.state.description} className="form-control" id="description" aria-describedby="codeHelp" placeholder="Description" />
                                            <small style={{ fontSize: 10, color: 'red' }}>
                                                {' '}
                                                {this.state.descriptionError}{' '}
                                            </small>
                                        </div>
                                    </div>

                                    <div>
                                        <h5 className="card-title">
                                            Increase Discount
                                        </h5>
                                        <div className="row">
                                            <div className="form-group col required">
                                                <label htmlFor="discountType" className="control-label">Discount Type</label>
                                                <select
                                                    className="form-control"
                                                    name="incDicountType"
                                                    value={this.state.incDicountType}
                                                    onChange={this.onChange}
                                                >
                                                    <option value="">Select Type</option>
                                                    <option value="percent">Percent</option>
                                                    <option value="fixed">Fixed</option>
                                                </select>
                                                <small style={{ fontSize: 10, color: 'red' }}>
                                                    {' '}
                                                    {this.state.incDicountTypeError}{' '}
                                                </small>
                                            </div>

                                            <div className="form-group col required">
                                                <Inputfield errMessage={this.state.incAmountError} handleChange={this.handleChange} type="text" name="incAmount" maxlength="20" value={this.state.incAmount} className="form-control" id="incAmount" ariadescribedby="codeHelp" placeholder="Amount" />
                                            </div>

                                            <div className="form-group col required">
                                                <label htmlFor="exampleInputName1" className="control-label">On field</label>
                                                <select
                                                    className="form-control"
                                                    name="incUnit"
                                                    value={this.state.incUnit}
                                                    onChange={this.onChange}>
                                                    <option value="">Select Unit</option>
                                                    <option value="quantity">Quantity</option>
                                                    <option value="total">Total</option>
                                                    <option value="price">Price</option>
                                                </select>
                                                <small style={{ fontSize: 10, color: 'red' }}>
                                                    {' '}
                                                    {this.state.incUnitError}{' '}
                                                </small>
                                            </div>

                                            <div className="form-group col required">
                                                <label htmlFor="exampleInputName1" className="control-label">Amount Operation</label>
                                                <select
                                                    className="form-control"
                                                    name="incAmtOperation"
                                                    value={this.state.incAmtOperation}
                                                    onChange={this.onChange}>
                                                    <option value="">Select Operation</option>
                                                    <option value="<">{'<'}</option>
                                                    <option value=">">{'>'}</option>
                                                    <option value="<=">{'<='}</option>
                                                    <option value=">=">{'>='}</option>
                                                    <option value="+">{'+'}</option>
                                                    <option value="-">{'-'}</option>
                                                    <option value="/">{'/'}</option>
                                                    <option value="*">{'*'}</option>
                                                </select>
                                                <small style={{ fontSize: 10, color: 'red' }}>
                                                    {' '}
                                                    {this.state.incAmtOperationError}{' '}
                                                </small>
                                            </div>

                                            <div className="form-group col required">
                                                <Inputfield errMessage={this.state.incValueError} handleChange={this.handleChange} maxlength="10" type="text" name="incValue" value={this.state.incValue} className="form-control" id="incValue" aria-describedby="codeHelp" placeholder="Value" />
                                            </div>

                                        </div>
                                    </div>

                                    <div>
                                        <h5 className="card-title">
                                            Decrease Discount
                                        </h5>
                                        <div className="row">
                                            <div className="form-group col required">
                                                <label htmlFor="exampleInputName1" className="control-label">Discount Type</label>
                                                <select
                                                    className="form-control"
                                                    name="decDicountType"
                                                    value={this.state.decDicountType}
                                                    onChange={this.onChange}>
                                                    <option value="">Select Type</option>
                                                    <option value="percent">Percent</option>
                                                    <option value="fixed">Fixed</option>
                                                </select>
                                                <small style={{ fontSize: 10, color: 'red' }}>
                                                    {' '}
                                                    {this.state.decDicountTypeError}{' '}
                                                </small>
                                            </div>

                                            <div className="form-group col required">
                                                <Inputfield errMessage={this.state.decAmountError} handleChange={this.handleChange} type="text" name="decAmount" maxlength="20" value={this.state.decAmount} className="form-control" id="decAmount" ariadescribedby="codeHelp" placeholder="Amount" />
                                            </div>

                                            <div className="form-group col required">
                                                <label htmlFor="onField" className="control-label">On field</label>
                                                <select
                                                    className="form-control"
                                                    name="decUnit"
                                                    value={this.state.decUnit}
                                                    onChange={this.onChange}
                                                >
                                                    <option value="">Select Unit</option>
                                                    <option value="quantity">Quantity</option>
                                                    <option value="total">Total</option>
                                                    <option value="price">Price</option>
                                                </select>
                                                <small style={{ fontSize: 10, color: 'red' }}>
                                                    {' '}
                                                    {this.state.decUnitError}{' '}
                                                </small>
                                            </div>


                                            <div className="form-group col required">
                                                <label htmlFor="decAmtOperation" className="control-label">Amount Operation</label>
                                                <select
                                                    className="form-control"
                                                    name="decAmtOperation"
                                                    value={this.state.decAmtOperation}
                                                    onChange={this.onChange}>
                                                    <option value="">Select Operation</option>
                                                    <option value="<">{'<'}</option>
                                                    <option value=">">{'>'}</option>
                                                    <option value="<=">{'<='}</option>
                                                    <option value=">=">{'>='}</option>
                                                    <option value="+">{'+'}</option>
                                                    <option value="-">{'-'}</option>
                                                    <option value="/">{'/'}</option>
                                                    <option value="*">{'*'}</option>
                                                </select>
                                                <small style={{ fontSize: 10, color: 'red' }}>
                                                    {' '}
                                                    {this.state.decAmtOperationError}{' '}
                                                </small>
                                            </div>

                                            <div className="form-group col required">
                                                <Inputfield errMessage={this.state.decValueError} handleChange={this.handleChange} maxlength="10" type="text" name="decValue" value={this.state.decValue} className="form-control" id="decValue" aria-describedby="codeHelp" placeholder="Value" />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-group col required">
                                            <Inputfield errMessage={this.state.maxDiscountError} handleChange={this.handleChange} type="text" name="maxDiscount" value={this.state.maxDiscount} className="form-control" id="maxDiscount" aria-describedby="codeHelp" placeholder="Maximum Discount" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-group col required">
                                            <SingleBrandDropDown
                                                ondropdownChange={(e) => this.ondropdownChange(e, "brands")}
                                                name="brands"
                                                value={this.state.brands}
                                                className="form-control form-control-square"
                                                onSelectResetsInput={true}
                                                isMulti={false}
                                                isSearchable={true}
                                                errMessage={this.state.brandsError}
                                            />
                                        </div>

                                        {/* {console.log(this.state.brands, this.state.productFilter, this.state.pageType)}
                                        {console.log(this.state.brands && this.state.productFilter && this.state.pageType === "add")} */}

                                        {/* <div className="form-group col required">
                                            <ProductDropdown
                                                name="products"
                                                ondropdownChange={(e) => this.ondropdownChange(e, "products")}
                                                value={this.state.products}
                                                isMulti={true}
                                                isSearchable={true}
                                                closeMenuOnSelect={false}
                                                options={this.state.options}
                                                placeholder="Product"
                                                errMessage={this.state.productsError}
                                                filterBy={this.state.productFilter}
                                                dontPreload={(this.state.brands && this.state.productFilter && this.state.pageType === "add") ||
                                                    (this.state.brands && this.state.productFilter.brand && this.state.pageType === "update") ? false : true}
                                            />
                                        </div> */}

                                    </div>

                                    <div className="row">
                                        <div className="form-group col required">
                                            <Inputfield errMessage={this.state.startDateError} handleChange={this.handleChange} type="date" name="startDate" value={this.state.startDate} className="form-control" id="startDate" aria-describedby="codeHelp" placeholder="Start Date" />
                                        </div>

                                        <div className="form-group col required">
                                            <Inputfield errMessage={this.state.endDateError} handleChange={this.handleChange} type="date" name="endDate" value={this.state.endDate} className="form-control" id="endDate" aria-describedby="codeHelp" placeholder="End date" />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" name="Save" />
                            <Links to={routehelp.discountMaster} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        discountCreateRes: state.discountCreateRes,
        discount_singleRes: state.discount_singleRes,
        discountUpdateRes: state.discountUpdateRes
    };
}

const mapDispatchToProps = dispatch => ({
    discountCreate: data => dispatch(discountCreate(data)),
    discountSingle: data => dispatch(discountSingle(data)),
    discountUpdate: (data, id) => dispatch(discountUpdate(data, id))
});

DiscountMasterAdd = connect(
    mapStateToProps,
    mapDispatchToProps
)(DiscountMasterAdd);

export default DiscountMasterAdd;