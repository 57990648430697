import React from 'react';

class ExportModal extends React.Component {
    render() {
        if (!this.props.show) {
            return null;
        }
        return (
            <div id="myModal" className="custom-modal">
                <div className="custom-modal-content">
                    <div className="modal-header">
                        <h4>Export CSV</h4>
                        <button type="button" className="close cursor-pointer" aria-label="Close" onClick={this.props.onClose}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                        <p className="modal-title fs-5" id="exampleModalLabel">
                            Please enter your email address. The {this.props.title} list will be sent to you within 1 hour.
                        </p>
                        <input
                            type="email"
                            id="email"
                            className="form-control mt-2"
                            placeholder="Enter Your Email"
                            value={this.props.value}
                            onChange={(e) => this.props.onChange(e.target.value)}
                        />
                        <small style={{ fontSize: 10, color: 'red' }}>
                            {this.props.emailError}
                        </small>
                    </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={this.props.onClose}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={this.props.onAction}>Export</button>
                    </div>
                    
                </div>
            </div>
        );
    }
}

export default ExportModal;
